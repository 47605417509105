import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../auth";
import {
  getResultWithId,
  GetQuestionList,
  getMockResult,
  getMockScores,
} from "../app_backend";
import Answer from "../components/Answer";
import Navbar from "../components/NavBar";
import LoadingScreen from "../components/LoadingScreen";

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid'

import Results from "../components/Results";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { VALID_SUBSCRIPTION } from "../Stripe";
import HorizontalSubjectTabs from "../components/Practice/HorizontalSubjectTabs";
import MockOverview from "../components/MockOverview";
import CircularScoreWithLabel from "../components/CircularScoreWithLabel";


function MockResultsAfterSubmit({ }) {
  const [user, loading, error] = useAuthState(auth);
  const params = useParams();
  const navigate = useNavigate();

  const [quizId, setQuizId] = useState("");

  const [mockData, setMockData] = useState([]);
  const [currentData, setCurrentData] = useState({});

  const [readingScore, setReadingScore] = useState(0);
  const [mathScore, setMathScore] = useState(0);
  const [thinkingScore, setThinkingScore] = useState(0);
  const [writingScore, setWritingScore] = useState(0);
  const [date, setDate] = useState("");

  const [readingMax, setReadingMax] = useState(0);
  const [mathMax, setMathMax] = useState(0);
  const [thinkingMax, setThinkingMax] = useState(0);

  const [selectedSubject, setSelectedSubject] = useState("overview");

  const [authLoading, setAuthLoading] = useState(true);
  const [resultsLoading, setResultsLoading] = useState(true);

  const fetchResult = async () => {
    try {
      const data = await getMockResult(user?.uid, params.mock);

      setMockData(data.data_arr);
      setCurrentData(data.data_arr[0]);

      setQuizId(data.quiz_id);

      const data2 = await getMockScores(user?.uid, data.data_arr);
      // console.log(data2)
      setReadingScore(data2.scores.reading.score);
      setMathScore(data2.scores.maths.score);
      setThinkingScore(data2.scores.thinking.score);
      setDate(data2.date);
      setReadingMax(data2.scores.reading.attempt_answers.length)
      setMathMax(data2.scores.maths.attempt_answers.length)
      setThinkingMax(data2.scores.thinking.attempt_answers.length)
      if (data2.scores.writing.score === undefined) {
        setWritingScore(null)
      } else {
        setWritingScore(data2.scores.writing.score)
      }

      setResultsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchResult();
    setAuthLoading(false);
  }, [user, loading]);

  const subjectArr = ["reading", "maths", "thinking", "writing"];

  useEffect(() => {
    let idx = subjectArr.indexOf(selectedSubject);
    if (selectedSubject !== "overview") {
      setCurrentData(mockData[idx]);
    }
  }, [selectedSubject]);

  if (authLoading || resultsLoading) {
    return <LoadingScreen text={"Loading..."} />;
  }

  return (
    <Box
      className='dashboard'
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Navbar showTokens={true} />
      <Box
        sx={{ mx: "4vw", py: 3, height: '100%' }}
      >
        <Box sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
          <Grid container direction='column' sx={{ height: '100%' }}>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Box sx={{ display: 'flex', mb: 1 }}>
                  <Paper elevation={0} sx={{ borderRadius: 4, backgroundColor: '#ffffff' }}>
                    <Box sx={{ p: 3, display: 'flex', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box sx={{ mr: 4, width: '220px', overflowWrap: 'break-word' }}>
                        <Typography variant='h5' sx={{ fontWeight: 800 }}>{quizId}</Typography>
                        <Typography variant='h6' sx={{ color: '#1f2f98', fontSize: '14pt' }}>{date}</Typography>
                      </Box>
                      <Grid container spacing={2} sx={{ ml: 4, }}>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <Box sx={{ textAlign: 'center', overflowWrap: 'break-word' }}>
                            <CircularScoreWithLabel score={readingScore} max={readingMax} />
                            <Typography sx={{ fontSize: '12pt', fontWeight: 600, color: '#3a3a3a', lineHeight: 1 }}>Reading</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <Box sx={{ textAlign: 'center', overflowWrap: 'break-word' }}>
                            <CircularScoreWithLabel score={mathScore} max={mathMax} />
                            <Typography sx={{ fontSize: '12pt', fontWeight: 600, color: '#3a3a3a', lineHeight: 1 }}>Mathematical Reasoning</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <Box sx={{ textAlign: 'center', overflowWrap: 'break-word' }}>
                            <CircularScoreWithLabel score={thinkingScore} max={thinkingMax} />
                            <Typography sx={{ fontSize: '12pt', fontWeight: 600, color: '#3a3a3a', lineHeight: 1 }}>Thinking<br />Skills</Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                          <Box sx={{ textAlign: 'center', overflowWrap: 'break-word' }}>
                            <CircularScoreWithLabel score={writingScore} max={20} />
                            <Typography sx={{ fontSize: '12pt', fontWeight: 600, color: '#3a3a3a', lineHeight: 1 }}>Writing</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                </Box>
                <Button variant="outlined" sx={{ height: '3rem', borderRadius: 8, bgcolor: '#f1f4ff' }} onClick={() => navigate("/Dashboard")}>
                  <Typography color='primary' sx={{ paddingRight: 1 }}>back to dashboard</Typography>
                  <ExitToAppIcon color='primary' />
                </Button>
              </Box>
            </Grid>
            <Grid item xs sm md lg xl sx={{ height: '100%', overflow: 'auto' }}>
              <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <HorizontalSubjectTabs selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} overview={true} />
                {selectedSubject !== "overview" ?
                  <Results
                    subject={currentData.subject}
                    quiz={currentData.mock_id}
                    attemptId={currentData.attempt_id}
                    subscription={VALID_SUBSCRIPTION}
                    mockStyle={true}
                  />
                  :
                  <MockOverview
                    user_id={user.uid}
                    mockData={mockData}
                    readingScores={{ score: readingScore, maxScore: readingMax }}
                    mathScores={{ score: mathScore, maxScore: mathMax }}
                    thinkingScores={{ score: thinkingScore, maxScore: thinkingMax }}
                    writingScores={{ score: writingScore, maxScore: 20 }}
                  />
                }
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Paper
            elevation={0}
            sx={{ borderRadius: 6, width: "32rem", backgroundColor: "#ffffff" }}
          >
            <Box sx={{ p: 3 }}>
              {date ? (
                <Box>
                  <Typography variant='h3' sx={{ fontWeight: 800 }}>{quizId}</Typography>
                  <Typography variant='h5' sx={{ color: '#1f2f98', marginBottom: '10px' }}>{date}</Typography>
                  <Typography variant='h6'>Reading: {readingScore}/{readingMax}</Typography>
                  <Typography variant='h6'>Mathematical Reasoning: {mathScore}/{mathMax}</Typography>
                  <Typography variant='h6'>Thinking Skills: {thinkingScore}/{thinkingMax}</Typography>
                  <Typography variant='h6'>Writing: Wait for marking</Typography>
                </Box>
              ) : (
                <Box sx={{ width: "13rem" }}>
                  <Skeleton animation="wave" sx={{ fontSize: "4rem" }} />
                  <Skeleton animation="wave" sx={{ fontSize: "2rem" }} />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </Box>
              )}
            </Box>
          </Paper>
          <Button
            variant="outlined"
            sx={{ height: "3rem", borderRadius: 8 }}
            onClick={() => navigate("/Dashboard")}
          >
            <Typography color="primary" sx={{ paddingRight: 1 }}>
              back to dashboard
            </Typography>
            <ExitToAppIcon color="primary" />
          </Button>
        </Box>
        <HorizontalSubjectTabs selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} overview={true} />
        {selectedSubject !== "overview" ?
          <Results
            subject={currentData.subject}
            quiz={currentData.mock_id}
            attemptId={currentData.attempt_id}
            subscription={VALID_SUBSCRIPTION}
          />
          :
          <MockOverview
            user_id={user.uid}
            mockData={mockData}
            readingScores={{ score: readingScore, maxScore: readingMax }}
            mathScores={{ score: mathScore, maxScore: mathMax }}
            thinkingScores={{ score: thinkingScore, maxScore: thinkingMax }}
            writingScores={{ score: null, maxScore: 20 }}
          />
        } */}
      </Box>
    </Box>
  );
}

export default MockResultsAfterSubmit;
