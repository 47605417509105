import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"

import { ReactComponent as LogoSVG } from "../images/logo-full-light.svg"

import "../style/Navbar.css"
import MarkingTokensDisplay from "./MarkingTokensDisplay";

function Navbar({ showTokens = false }) {
  const navigate = useNavigate();

  return (
    <div>
      <AppBar
        elevation={0}
        color='primary'
      >
        <Toolbar sx={{ mx: '10vw', height: '4rem' }}>
          <Box sx={{
            display: 'flex', justifyContent: 'space-between',
            width: '100%', height: '100%'
          }}>
            <Button onClick={() => navigate(`/Dashboard`)}>
              <LogoSVG style={{ height: '3rem' }} />
            </Button>
            {showTokens ? <MarkingTokensDisplay /> : null}
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default Navbar;
