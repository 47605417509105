import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';

import Revision from "./Revision";
import ReviewResultsList from "./ReviewResultsList";
import QuizResults from "./QuizResults";
import { VALID_SUBSCRIPTION } from "../Stripe";
import { getBreakPointStyle } from "../components/Mobile/StyleHelper";

const ReviewPage = ({ user_id }) => {
  const [selectedPage, setSelectedPage] = useState('revision')
  const [selectedSubject, setSelectedSubject] = useState('reading')
  const [selectedAttemptId, setSelectedAttemptId] = useState('')

  const Selector = () => {
    switch (selectedPage) {
      case "revision":
        return (
          <Revision
            user_id={user_id}
            setSelectedPage={setSelectedPage}
            setSelectedSubject={setSelectedSubject}
            selectedSubject={selectedSubject}
          />
        )
      case "results_list":
        return (
          <ReviewResultsList
            subject={selectedSubject}
            setSelectedPage={setSelectedPage}
            setSelectedAttemptId={setSelectedAttemptId}
          />
        )
      case "results":
        return (
          <QuizResults
            subject={selectedSubject}
            quiz={"review"}
            attemptId={selectedAttemptId}
            setSelectedPage={setSelectedPage}
            subscription={VALID_SUBSCRIPTION}
            page={"results_list"}
          />
        )
    }
  }

  return (
    <Box sx={{ m: getBreakPointStyle(0, 3), width: '100%', flexGrow: 1 }}>
      <Selector />
    </Box>
  )
}

export default ReviewPage