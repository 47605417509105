import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function Learn12() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography variant="h4" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          What is Probability?
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          Probability describes how likely it is that an event will occur. It is
          the study of chance. For example, probability is used to calculate the
          chances it will rain tomorrow or the chances that the home sporting
          team will win.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
        }}
      >
        <Typography sx={{ paddingBottom: 1 }}>
          The probability of an event occurring is always between 0 and 1, where
          0 means impossible to occur and 1 means certain. Probability is often
          written as a fraction (however can also be converted to percentages
          and decimals) and can indicate the frequency of an event happening
          over a large number of trials.
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            my: 1,
            backgroundColor: "#d9e7fe",
            marginBottom: 2,
          }}
        >
          <Typography sx={{}}>
            <b>Example 1:</b> The probability of rolling a two in a six-sided
            dice is 1 in 6 or{" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>1</sup>&frasl;<sub>6</sub>
            </span>
            . This is because out of the six possible outcomes; two occur once.
          </Typography>
        </Alert>
        <Typography sx={{ paddingBottom: 2 }}>
          The formula can be written as:
        </Typography>
        <Typography sx={{ paddingBottom: 2 }}>
          Probability ={" "}
          <span style={{ fontSize: "16pt" }}>
            <sup>Number of favourable outcomes</sup>&frasl;
            <sub>Total number of outcomes</sub>
          </span>
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            my: 1,
            backgroundColor: "#d9e7fe",
            marginBottom: 2,
          }}
        >
          <Typography sx={{}}>
            <b>Example 2:</b> The probability of getting a head when tossing a
            two-sided coin once, the number of favourable outcomes is 1, and the
            total number of outcomes is 2 (heads + tails).
          </Typography>
        </Alert>
      </Box>
    </Box>
  );
}

export default Learn12;
