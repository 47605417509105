import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function VerticalSubjectTabs({ selectedItem, setSelectedItem, itemList, labelList }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: 'column',
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        mt: 2
      }}
    >
      <Box sx={{ borderRadius: 3, backgroundColor: "#F1F4FF", width: '100%', px: 1.5, py: 3 }}>
        <Stack gap={2.5}>
          {itemList.map((item, i) => {
            return (
              <Button
                variant='contained'
                color={selectedItem === item ? 'primary' : 'white_paper'}
                disableElevation
                onClick={() => {
                  setSelectedItem(item);
                }}
                sx={{ px: 2, py: '12px', borderRadius: 2, textTransform: 'none', textAlign: 'left' }}
                key={i}
              >
                <Typography sx={{ fontSize: '12pt', textAlign: 'left' }}>{labelList[i]}</Typography>
              </Button>
            )
          })}
        </Stack>
      </Box>
    </Box>
  );
}

