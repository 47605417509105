import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { ReactComponent as MarkingToken } from '../images/MarkingToken.svg';
import '../style/MarkingTokensDisplay.css';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../auth';
import { getMarkingTokens } from '../app_backend';
import { Skeleton, Tooltip } from '@mui/material';
import LoadingScreen from './LoadingScreen';

const MarkingTokensDisplay = ({ refresh, box = true, mobile = false }) => {
  const [user, loading, error] = useAuthState(auth);
  const [tokens, setTokens] = useState(0);
  const [isLoading, setLoading] = useState(true);

  const fetchTokens = async () => {
    const numTokens = await getMarkingTokens(user.uid);
    setTokens(numTokens);
    if (numTokens !== undefined || numTokens !== null) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(fetchTokens, 500);
  }, [refresh]);

  if (mobile) {
    return;
  }

  if (box) {
    return (
      <Tooltip title={'Visit store for more Marking Tokens'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#2E41BC',
            borderRadius: 6,
            my: '11px',
            color: '#ffffff',
            fontWeight: 700,
            gap: '10px',
            px: 1,
          }}
        >
          <MarkingToken className='token-image' />
          {isLoading ? (
            <Skeleton variant='text' width={13} sx={{ fontSize: '1rem' }} />
          ) : (
            tokens
          )}
        </Box>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={'Visit store for more Marking Tokens'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '50px',
            alignItems: 'center',
            fontWeight: 700,
            gap: '10px',
            px: 1,
          }}
        >
          <MarkingToken className='token-image' />
          {isLoading ? (
            <Skeleton variant='text' width={13} sx={{ fontSize: '1rem' }} />
          ) : (
            tokens
          )}
        </Box>
      </Tooltip>
    );
  }
};

export default MarkingTokensDisplay;
