import React from 'react'
import "../style/NoMobile.css"

const NoMobile = () => {
  return (
    <div className='no_mobile_content'>
      <div className='no_mobile_text'>
        Sorry! For the best learning experience, please use a laptop or desktop device.
      </div>
    </div>
  )
}

export default NoMobile