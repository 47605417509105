import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import UpgradeRoundedIcon from '@mui/icons-material/UpgradeRounded';
import AutoGraphRoundedIcon from '@mui/icons-material/AutoGraphRounded';
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 16,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #1568f2 0%, #27a1eb 50%, #6dd9f7 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #1568f2 0%, #27a1eb 50%, #6dd9f7 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 32,
  height: 32,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient(136deg, #1568f2 0%, #27a1eb 50%, #6dd9f7 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.2)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient(136deg, #1568f2 0%, #27a1eb 50%, #6dd9f7 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <HowToRegRoundedIcon />,
    2: <UpgradeRoundedIcon />,
    3: <AutoGraphRoundedIcon />,
    4: <DateRangeRoundedIcon />
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['Try for free', 'Upgrade plan', 'Practice & Improve', 'Selective Test Success!'];

export default function RegisterStepper() {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
        {steps.map((label, i) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{i === 1 ? <b>{label}</b> : label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}