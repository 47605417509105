import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../auth";

import { fetchPaperIfMarked, fetchPaperIfSubmittedForMarking, getResults, GetTopic, submitForMarkingByAttemptId } from "../app_backend";
import Grow from '@mui/material/Grow';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { DEMO_SUBSCRIPTION, EXPIRED_SUBSCRIPTION } from "../Stripe";
import GradeComponent from "../components/GradeComponent";
import { ReactComponent as MarkingToken } from "../images/MarkingToken.svg";
import "../style/MarkingTokensDisplay.css"
import { getMarkingTokens } from "../app_backend";


const ResultsList = ({ subject, quiz, setSelectedPage, setSelectedAttemptId, allowReattempt, subscription }) => {
  const [user, loading, error] = useAuthState(auth)
  const [attemptsList, setAttemptsList] = useState([])
  const params = useParams()
  const navigate = useNavigate();
  const [renderTransition, setRenderTransition] = useState(false)
  const [topic, setTopic] = useState('')

  const [tokens, setTokens] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(true)

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchAttemptList();
  }, [user, loading]);

  const fetchAttemptList = async () => {
    let attempt_list = await getResults(user?.uid, subject, quiz);
    setAttemptsList(attempt_list);
    if (attempt_list) {
      setRenderTransition(true);
    }
  }

  const fetchTopic = async () => {
    const topic = await GetTopic(subject, quiz)
    setTopic(topic)
  }

  const fetchIsSubmitted = async () => {
    if (attemptsList.length !== 0 && subject === 'writing') {
      const res = await fetchPaperIfSubmittedForMarking(attemptsList[0].id, user.uid, quiz)
      setIsSubmitted(res)
    }
  }

  const fetchTokens = async () => {
    const data = await getMarkingTokens(user.uid)
    setTokens(data)
  }

  const handleSubmitForMarking = async () => {
    setIsSubmitted("NOT_MARKED")
    const res = await submitForMarkingByAttemptId(user.uid, quiz, attemptsList[0].id)
    if (res.error === "NOT_ENOUGH_TOKENS") {
      alert("Not Enough Marking Tokens")
    } else {
      setIsSubmitted("NOT_MARKED")
    }
  }

  useEffect(() => {
    fetchTopic()
    fetchTokens()
  }, []);

  useEffect(() => {
    fetchIsSubmitted()
  }, [attemptsList])

  let reattemptPath = `/Quizzes/${subject}/${quiz}`

  const ReattemptButton = () => {
    if (subject === "writing") {
      if (isSubmitted === "NOT_MARKED") {
        return (
          <Button variant='contained' color='secondary'
            disabled={true}
            sx={{ my: '1rem', borderRadius: 7 }}>
            <Typography variant='h6' sx={{ mx: '2rem' }}>Submitted for Marking</Typography>
          </Button>
        )
      } else if (isSubmitted === "MARKED") {
        return (
          <Button variant='contained' color='secondary'
            disabled={true}
            sx={{ my: '1rem', borderRadius: 7 }}>
            <Typography variant='h6' sx={{ mx: '2rem' }}>Marked</Typography>
          </Button>
        )
      } else if (tokens === 0) {
        return (
          <Button
            variant='contained'
            color='secondary'
            disabled={true}
            sx={{ my: '1rem', borderRadius: 7, px: 3 }}>
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ height: '100%' }}>
                <MarkingToken className="token-image" />
              </Box>
              <Typography variant='h6' sx={{}}>Not Enough Tokens</Typography>
            </Box>
          </Button>
        )
      } else if (isSubmitted === "NOT_SUBMITTED") {
        return (
          <Button
            variant='contained'
            color='secondary'
            onClick={() => handleSubmitForMarking()}
            sx={{ my: '1rem', borderRadius: 7, px: 3 }}>
            <Box sx={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Box sx={{ height: '100%' }}>
                <MarkingToken className="token-image" />
              </Box>
              <Typography variant='h6' sx={{}}>Submit for Marking</Typography>
            </Box>
          </Button>
        )
      }
    } else {
      if (allowReattempt) {
        return (
          <Button variant='contained' color='secondary' onClick={() => setSelectedPage('quiz')}
            sx={{ my: '1rem', borderRadius: 7 }}>
            <Typography variant='h6' sx={{ mx: '2rem' }}>Reattempt</Typography>
          </Button>
        )
      } else {
        return (
          <Button variant='contained' disabled
            sx={{ my: '1rem', borderRadius: 7 }}>
            <Typography variant='h6' sx={{ mx: '2rem' }}>Reattempt Unavailable</Typography>
          </Button>
        )
      }
    }
  }

  return (
    <div>
      <Box sx={{
        mx: '2vw',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Button variant="outlined" sx={{ height: '3rem', borderRadius: 8, backgroundColor: '#f1f4ff' }} onClick={() => setSelectedPage('quiz_list')}>
          <Typography color='primary' sx={{ paddingRight: 1 }}>back to quizzes</Typography>
          <ExitToAppIcon color='primary' />
        </Button>
        {subscription === DEMO_SUBSCRIPTION ?
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Collapse
              in={true}
              timeout={{ enter: 500, exit: 500 }}
            >
              <Alert
                severity="info"
                variant="outlined"
                sx={{ mb: 2, width: '40vw', borderRadius: 4, backgroundColor: "#dff4fe" }}
              >
                <AlertTitle><strong>This is your results list.</strong></AlertTitle>
                Here you will be able to access and review your past attempts!
              </Alert>
            </Collapse>
          </Box> : subscription === EXPIRED_SUBSCRIPTION ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Collapse
                in={true}
                timeout={{ enter: 500, exit: 500 }}
              >
                <Alert
                  severity="info"
                  variant="outlined"
                  sx={{ mb: 2, width: '40vw', borderRadius: 4, backgroundColor: "#dff4fe" }}
                >
                  <AlertTitle><strong>Your Access has expired</strong></AlertTitle>
                  You will not be able to reattempt this exam and will only have access to your scores — <strong>visit to store to upgrade!</strong>
                </Alert>
              </Collapse>
            </Box> : null}
        <ReattemptButton />
      </Box>
      <Box sx={{ mx: '2vw', p: 4, borderRadius: 6, backgroundColor: '#f1f4ff' }}>
        <Stack direction='row' spacing={6} sx={{
        }}>
          <Box sx={{ width: '50%' }}>
            <Box sx={{
              height: '4rem', display: 'flex', justifyContent: 'left',
              alignItems: 'center'
            }}>
              <Paper sx={{
                height: '4rem',
                borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff'
              }} elevation={0}>
                <Typography variant='h4' sx={{ mx: '2rem', fontWeight: 900 }}>Previous Attempts</Typography>
              </Paper>
            </Box>
            <Paper
              sx={{
                my: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                borderRadius: 6,
                backgroundColor: "#ffffff",
              }}
              elevation={0}
            >
              <Stack
                spacing={2}
                sx={{
                  my: "1rem",
                  width: "95%",
                  maxHeight: 585,
                  overflow: "auto",
                }}
              >
                {attemptsList.map((attempt, i) => {
                  let reviewPath = "results";
                  return (
                    <Grow key={i} in={renderTransition} style={{ transformOrigin: '0 0 0' }}
                      {...(renderTransition ? { timeout: 600 } : {})}>
                      <Button variant='outlined' onClick={() => { setSelectedPage(reviewPath); setSelectedAttemptId(attempt.id) }} sx={{ width: '100%', borderRadius: 5 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', px: 2 }}>
                          <Typography sx={{ textTransform: 'None', fontSize: 18, color: '#babddb' }}>Attempt {i + 1}</Typography>
                          <Typography sx={{ textTransform: 'None', fontSize: 18 }}>{attempt.date}</Typography>
                          {subject !== 'writing' ? (
                            <Typography sx={{ textTransform: 'None', color: '#f26c6d', fontWeight: 800, fontSize: 20 }}>{attempt.score}/{attempt.total}</Typography>)
                            : null
                          }
                        </Box>
                      </Button>
                    </Grow>
                  );
                })}
              </Stack>
            </Paper>
          </Box>
          <Box sx={{ width: '50%' }}>
            <Box sx={{
              display: 'flex', justifyContent: 'right',
              alignItems: 'center'
            }}>
              <Paper sx={{
                height: '4rem',
                borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff'
              }} elevation={0}>
                <Typography variant='h4' sx={{ mx: '2rem', fontWeight: 900 }}>History</Typography>
              </Paper>
            </Box>
            <Paper sx={{
              my: 3, display: 'flex', justifyContent: 'center',
              alignItems: 'center', width: '100%', borderRadius: 6
            }} elevation={0}>
              <GradeComponent user_id={user?.uid} subject={subject} topic={topic} />
            </Paper>
          </Box>
        </Stack>
      </Box>
    </div>
  );
};

export default ResultsList
