import React from "react";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
import Grades from "./Grades";
import QuizList from "./QuizList";
import Quiz from "./Quiz";
import Questions from "./Questions";
import ResultsList from "./ResultsList";
import Writing from "./Writing";

import Profile from "./Profile";
import ErrorPage from "./ErrorPage";

import { Route, Routes } from "react-router-dom";

import WritingResults from "./WritingResults";
import Marking from "./Marking";
import MockList from "./MockList";
import BreakPage from "./BreakPage";
import MockResults from "./MockResults";
import ResultsAfterSubmit from "./ResultsAfterSubmit";
import MockResultsAfterSubmit from "./MockResultsAfterSubmit";
import MarkingInterface from "./MarkingInterface";
import WritingReview from "./WritingReview";


function Pages() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/Register" element={<Register />} />
      <Route path="/Reset" element={<Reset />} />
      <Route path="/Dashboard" element={<Dashboard />} />
      <Route path="/Grades" element={<Grades />} />
      <Route path="/Quizzes/:subject" element={<QuizList />} />
      <Route path="/Quizzes/:subject/:quiz" element={<Quiz />} />
      <Route path="/Quizzes/:subject/:quiz/Questions" element={<Questions />} />
      <Route
        path="/Quizzes/:mock/:subject/:quiz/Questions"
        element={<Questions />}
      />
      <Route path="/Break/:mock/:subject" element={<BreakPage />} />
      <Route
        path="/Results/:subject/:quiz/:attemptId"
        element={<ResultsAfterSubmit />}
      />
      <Route path="/MockResults/:mock" element={<MockResultsAfterSubmit />} />
      <Route path="/ResultsList/:subject/:quiz" element={<ResultsList />} />
      <Route path="/Writing/:quiz" element={<Writing />} />
      <Route path="/Writing/:mock/:quiz" element={<Writing />} />
      <Route path="/WritingResults/:quiz" element={<WritingResults />} />
      <Route path="/Marking" element={<Marking />} />
      <Route path="/Profile" element={<Profile />} />
      <Route path="/MarkingInterface" element={<MarkingInterface />} />
      <Route path="/WritingReview" element={<WritingReview />} />
      <Route path="/Revision/:subject/Questions" element={<Questions isReview={true} />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default Pages;
