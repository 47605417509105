import React, { useEffect, useState, useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { deleteReport, getReports, sendNotificationsArr } from "../app_backend";
import { Button, TextField } from "@mui/material";
import LoadingScreen from "../components/LoadingScreen";

const Reports = ({ user_id }) => {
  const [reports, setReports] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchReports = async () => {
    const data = await getReports();
    setReports(data);
    if (data) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, [refresh]);

  const handleResolve = async (reportObj) => {
    setLoading(true);
    deleteReport(reportObj.report_id).then(async () => {
      setRefresh(!refresh);
      await sendNotificationsArr(
        "Reported Question",
        `Our team has identified and fixed the issue in ${reportObj.quiz_id}. Thank you for contributing to our learning community!`,
        user_id,
        [reportObj.user_id]
      );
    });
  };

  const handleDelete = async (report_id) => {
    setLoading(true);
    await deleteReport(report_id);
    setRefresh(!refresh);
  };

  if (loading) {
    return <LoadingScreen text={"Loading..."} />;
  }

  return (
    <Box sx={{ p: 4 }}>
      <Stack
        spacing={4}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {reports.map((reportObj, i) => {
          return (
            <Box
              key={i}
              sx={{
                width: "600px",
                bgcolor: "#e5eafb",
                borderRadius: 4,
                px: 3,
                py: 1.5,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{ fontWeight: 700, fontSize: "20pt", display: "inline" }}
                >
                  {reportObj.quiz_id}
                  {reportObj.questionNumber
                    ? `, Question ${reportObj.questionNumber}`
                    : null}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: "14pt",
                    display: "inline",
                    color: "#595959",
                  }}
                >
                  {reportObj.subject}
                </Typography>
              </Box>
              <Typography color="primary" sx={{ fontSize: "16pt" }}>
                <strong>Question ID:</strong> {reportObj.question_id}
              </Typography>
              <Typography
                color="primary"
                sx={{ fontSize: "12pt", color: "#595959" }}
              >
                {reportObj.date}
              </Typography>
              <TextField
                focused
                variant="outlined"
                label="Message"
                required
                multiline
                value={reportObj.message}
                sx={{ width: "100%", my: 2, fontSize: "12pt" }}
              />
              <Typography sx={{ fontSize: "14pt" }}>
                <strong>uid:</strong> {reportObj.user_id}
              </Typography>
              <Typography sx={{ fontSize: "14pt" }}>
                <strong>Email:</strong> {reportObj.user_email}
              </Typography>
              <Typography sx={{ fontSize: "14pt" }}>
                <strong>Name:</strong> {reportObj.user_name}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    handleResolve(reportObj);
                  }}
                  sx={{ borderRadius: 4, marginTop: 1 }}
                >
                  Resolve
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    handleDelete(reportObj.report_id);
                  }}
                  sx={{ borderRadius: 4, marginTop: 1 }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          );
        })}
      </Stack>
    </Box>
  );
};

export default Reports;
