import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth } from "../auth"
import { getMockResult, getMockScores } from '../app_backend'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid'

import Results from "../components/Results";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { DEMO_SUBSCRIPTION, EXPIRED_SUBSCRIPTION, VALID_SUBSCRIPTION } from "../Stripe";
import LoadingScreen from "../components/LoadingScreen";
import HorizontalSubjectTabs from "../components/Practice/HorizontalSubjectTabs";
import MockOverview from "../components/MockOverview";
import CircularScoreWithLabel from "../components/CircularScoreWithLabel";
import RequestRedo from "../components/RequestRedo";

function MockResults({ mock, setSelectedPage, subscription }) {
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate()

  const [quizId, setQuizId] = useState("");

  const [mockData, setMockData] = useState([]);
  const [currentData, setCurrentData] = useState({});

  const [attemptError, setAttemptError] = useState(false);

  const [readingScore, setReadingScore] = useState(0);
  const [mathScore, setMathScore] = useState(0);
  const [thinkingScore, setThinkingScore] = useState(0);
  const [writingScore, setWritingScore] = useState(null)
  const [readingMax, setReadingMax] = useState(0);
  const [mathMax, setMathMax] = useState(0);
  const [thinkingMax, setThinkingMax] = useState(0);
  const [selectedSubject, setSelectedSubject] = useState("overview");
  const [date, setDate] = useState("");

  const [authLoading, setAuthLoading] = useState(true);
  const [resultsLoading, setResultsLoading] = useState(true);

  const fetchResult = async () => {
    try {
      const data = await getMockResult(user?.uid, mock);
      // console.log(data)
      setMockData(data.data_arr);
      setCurrentData(data.data_arr[0]);
      setQuizId(data.quiz_id);

      const data2 = await getMockScores(user?.uid, data.data_arr);
      // console.log(data2)
      setReadingScore(data2.scores.reading.score);
      setMathScore(data2.scores.maths.score);
      setThinkingScore(data2.scores.thinking.score);
      setDate(data2.date);
      setReadingMax(data2.scores.reading.attempt_answers.length)
      setMathMax(data2.scores.maths.attempt_answers.length)
      setThinkingMax(data2.scores.thinking.attempt_answers.length)
      if (data2.scores.writing.score === undefined) {
        setWritingScore(null)
      } else {
        setWritingScore(data2.scores.writing.score)
      }

      setResultsLoading(false);
    } catch (e) {
      setAttemptError(true)
      setResultsLoading(false)
      console.log(e)
    }
  };


  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchResult();
    setAuthLoading(false);
  }, [user, loading]);

  const subjectArr = ["reading", "maths", "thinking", "writing"];

  useEffect(() => {
    let idx = subjectArr.indexOf(selectedSubject);
    if (selectedSubject !== "overview") {
      setCurrentData(mockData[idx]);
    }
  }, [selectedSubject]);

  if (authLoading || resultsLoading) {
    return <LoadingScreen text={"Loading..."} />;
  }

  return (
    <Box sx={{ height: '100%' }}>
      {subscription === DEMO_SUBSCRIPTION ?
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Collapse
            in={true}
            timeout={{ enter: 500, exit: 500 }}
          >
            <Alert
              severity="info"
              variant="outlined"
              sx={{ mb: 2, width: '40vw', borderRadius: 4, bgcolor: '#dff4fe' }}
            >
              <AlertTitle><strong>This is the review page</strong></AlertTitle>
              Here you will be able to view your performance for each section as well as how you did compared to other students!
            </Alert>
          </Collapse>
        </Box> : subscription === EXPIRED_SUBSCRIPTION ?
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Collapse
              in={true}
              timeout={{ enter: 500, exit: 500 }}
            >
              <Alert
                severity="info"
                variant="outlined"
                sx={{ mb: 2, width: '40vw', borderRadius: 4, bgcolor: '#dff4fe' }}
              >
                <AlertTitle><strong>Your Access has expired</strong></AlertTitle>
                You will only be able to view your score and won't be able to access our in-depth explanations and answers — <strong>visit to store to upgrade!</strong>
              </Alert>
            </Collapse>
          </Box> : null}

      {!attemptError ?
        <Grid container direction='column' sx={{ height: '100%' }}>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box sx={{ display: 'flex', mb: 1 }}>
                <Paper elevation={0} sx={{ borderRadius: 4, backgroundColor: '#ffffff' }}>
                  <Box sx={{ p: 3, display: 'flex', height: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ mr: 4, width: '220px', overflowWrap: 'break-word' }}>
                      <Typography variant='h5' sx={{ fontWeight: 800 }}>{quizId}</Typography>
                      <Typography variant='h6' sx={{ color: '#1f2f98', fontSize: '14pt' }}>{date}</Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ ml: 4, }}>
                      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        <Box sx={{ textAlign: 'center', overflowWrap: 'break-word' }}>
                          <CircularScoreWithLabel score={readingScore} max={readingMax} />
                          <Typography sx={{ fontSize: '12pt', fontWeight: 600, color: '#3a3a3a', lineHeight: 1 }}>Reading</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        <Box sx={{ textAlign: 'center', overflowWrap: 'break-word' }}>
                          <CircularScoreWithLabel score={mathScore} max={mathMax} />
                          <Typography sx={{ fontSize: '12pt', fontWeight: 600, color: '#3a3a3a', lineHeight: 1 }}>Mathematical Reasoning</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        <Box sx={{ textAlign: 'center', overflowWrap: 'break-word' }}>
                          <CircularScoreWithLabel score={thinkingScore} max={thinkingMax} />
                          <Typography sx={{ fontSize: '12pt', fontWeight: 600, color: '#3a3a3a', lineHeight: 1 }}>Thinking<br />Skills</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                        <Box sx={{ textAlign: 'center', overflowWrap: 'break-word' }}>
                          <CircularScoreWithLabel score={writingScore} max={20} />
                          <Typography sx={{ fontSize: '12pt', fontWeight: 600, color: '#3a3a3a', lineHeight: 1 }}>Writing</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Box>
              <Button variant="outlined" sx={{ height: '3rem', borderRadius: 8, bgcolor: '#f1f4ff' }} onClick={() => setSelectedPage("quiz_list")}>
                <Typography color='primary' sx={{ paddingRight: 1 }}>back to mocks</Typography>
                <ExitToAppIcon color='primary' />
              </Button>
            </Box>
          </Grid>
          <Grid item xs sm md lg xl sx={{ height: '100%', overflow: 'auto' }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <HorizontalSubjectTabs selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} overview={true} />
              {selectedSubject !== "overview" ?
                <Results
                  subject={currentData.subject}
                  quiz={currentData.mock_id}
                  attemptId={currentData.attempt_id}
                  subscription={VALID_SUBSCRIPTION}
                  mockStyle={true}
                />
                :
                <MockOverview
                  user_id={user.uid}
                  mockData={mockData}
                  readingScores={{ score: readingScore, maxScore: readingMax }}
                  mathScores={{ score: mathScore, maxScore: mathMax }}
                  thinkingScores={{ score: thinkingScore, maxScore: thinkingMax }}
                  writingScores={{ score: writingScore, maxScore: 20 }}
                />
              }
            </Box>
          </Grid>
        </Grid>
        :
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Alert severity="error" sx={{ borderRadius: 4 }}>
            <AlertTitle sx={{ fontWeight: 800 }}>Attempt Error</AlertTitle>
            <Box>
              We have detected an error in your Full Mock attempt. This happens when a section of the exam was skipped, or the exam
              was exited before completion.
            </Box>
            <RequestRedo subject="full_mocks" quiz={mock} />
          </Alert>
        </Box>
      }
    </Box>
  );
}

export default MockResults;
