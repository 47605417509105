import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

//styling stuff
import "bootstrap/dist/css/bootstrap.min.css";
import { BsFillFlagFill } from "react-icons/bs";
import { useTimer } from "react-timer-hook";
//import Timer from "../components/Timer"
import { auth } from "../auth";
import {
  addAttemptId,
  GetQuestionList,
  GetTopic,
  submitAttempt,
  submitReviewAttempt,
} from "../app_backend";
import Question from "../components/Question";
import LoadingScreen from "../components/LoadingScreen";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Grid";

import { ReactComponent as LogoSVG } from "../images/logo-full-light.svg";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ReadingText from "../components/ReadingText";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "rgba(234,231,224,0.62)",
  boxShadow: 24,
  p: 4,
};


const submitStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};


export function getTimer(subject, num_questions) {
  let tLimit = 40;
  if (subject === "reading") {
    tLimit = Math.ceil((40 / 30) * num_questions)
  } else if (subject === "maths") {
    tLimit = Math.ceil((40 / 35) * num_questions)
  } else if (subject === "writing") {
    tLimit = 30;
  } else {
    tLimit = num_questions
  }
  if (JSON.parse(localStorage.getItem("timer_active"))) {
    if (localStorage.getItem("start_timer") === "0") {
      const time = new Date();
      time.setSeconds(time.getSeconds() + tLimit * 60);
      localStorage.setItem("end_timer", time);
      localStorage.setItem("start_timer", 1);
      return time;
    } else {
      return Date.parse(localStorage.getItem("end_timer"));
    }
  } else {
    return 0;
  }
}

const arrayBlock = (attempt, x) => {
  const attemptCopy = [...attempt];
  const array = attemptCopy.slice();
  const blocks = [];

  while (array.length) blocks.push(array.splice(0, x));

  return blocks;
};

function Questions({ isReview = false }) {
  //prevents vback button from being pressed
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(1);
  };

  const Timer = ({ expiryTimestamp }) => {
    const {
      seconds,
      minutes,
      /*
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,*/
    } = useTimer({ expiryTimestamp, onExpire: () => handleExpire() });
    if (expiryTimestamp === 0) {
      return;
    }

    return (
      <Box
        sx={{
          borderRadius: 6,
          bgcolor: '#2E41BC',
          width: "8rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div style={{ fontSize: "40px" }}>
            <span>{minutes}</span>:<span>{seconds}</span>
          </div>
        </div>
      </Box>
    );
  };

  //for questions
  const [user, loading, error] = useAuthState(auth)
  const qList = useRef([])
  const index = useRef(0)
  const [attempt, setAttempt] = useState([])
  const [qId, setQId] = useState("")
  const params = useParams()
  const navigate = useNavigate()
  const flagged = useRef([])
  const timed = useRef([])
  const startVisiting = useRef([])
  const [flagColor, setFlagColor] = useState('secondary')
  const [flagText, setFlagText] = useState('Flag')

  //for modal window
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //for modal on time expire
  const [openExpire, setOpenExpire] = useState(false);
  const handleOpenExpire = () => setOpenExpire(true);
  const handleCloseExpire = () => {
    setOpenExpire(false);
    submitAndClose();
  };

  //for modal on attempt to exit quiz
  const [openExit, setOpenExit] = useState(false);
  const handleOpenExit = () => setOpenExit(true);
  const handleCloseExit = () => {
    setOpenExit(false);
  };

  //for modal on attempt to submit quiz
  const [openSubmit, setOpenSubmit] = useState(false);
  const handleOpenSubmit = () => setOpenSubmit(true);
  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  const handleExpire = () => {
    handleOpenExpire();
  };

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    if (localStorage.length <= 1) {
      navigate("/Dashboard");
    }
  }, [user, loading]);

  useEffect(() => {
    if (localStorage.getItem("in_quiz") === "0") {
      const timer_active = JSON.parse(localStorage.getItem("timer_active"));
      let temp = [];
      if (localStorage.getItem("mockIds") !== null) {
        temp = JSON.parse(localStorage.getItem("mockIds"));
      }

      let tempReviewQList = []
      if (localStorage.getItem("qList") !== null) {
        tempReviewQList = JSON.parse(localStorage.getItem("qList"));
      }

      localStorage.clear();
      if (temp.length !== 0) {
        localStorage.setItem("mockIds", JSON.stringify(temp));
        localStorage.setItem("sectionId", 0);
      }

      if (tempReviewQList.length !== 0) {
        localStorage.setItem("qList", JSON.stringify(tempReviewQList));
      }
      localStorage.setItem("timer_active", timer_active);
      initQList();
      localStorage.setItem("start_timer", 0);

      // set current quiz and or mock in local storage to maintain session 
      if (params.mock) {
        localStorage.setItem("curr_mock", params.mock);
      }
      if (params.quiz) {
        localStorage.setItem("curr_quiz", params.quiz);
      }
      if (params.subject) {
        localStorage.setItem("curr_subject", params.subject);
      }

    } else if (localStorage.getItem("qList") !== null) {
      setQ();
    }
  }, []);

  useEffect(() => {
    const handleTabClose = event => {
      event.preventDefault();
      event.returnValue = 'Are you sure? Leaving will submit the rest of your quiz.'
      return 'Are you sure? Leaving will submit the rest of your quiz.'
    };
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [])

  const setQ = async () => {
    //syncs with local storage
    qList.current = JSON.parse(localStorage.getItem("qList"));
    setAttempt(JSON.parse(localStorage.getItem("attempt")))
    flagged.current = JSON.parse(localStorage.getItem("flagged"));
    index.current = JSON.parse(localStorage.getItem("curr_index"));
    timed.current = JSON.parse(localStorage.getItem("timed"));
    startVisiting.current = JSON.parse(localStorage.getItem("start_visiting"));
    setQId(qList.current[index.current]);
    updateFlagColor()
  };

  const initQList = async () => {
    //fetches the question list and initialises attempt and flagged arrays
    //on quiz open.
    try {
      let data = []

      if (isReview) {
        data = JSON.parse(localStorage.getItem("qList"));
      } else {
        data = await GetQuestionList(params.subject, params.quiz);
      }

      setQId(data[index.current]);
      qList.current = data;
      localStorage.setItem("qList", JSON.stringify(data));
      setAttempt(Array(data.length).fill("unanswered"))
      localStorage.setItem("attempt", JSON.stringify(Array(data.length).fill("unanswered")));

      timed.current = Array(data.length).fill(0);
      localStorage.setItem("timed", JSON.stringify(timed.current));
      startVisiting.current = Array(data.length).fill(null);
      startVisiting.current[0] = new Date();
      localStorage.setItem(
        "start_visiting",
        JSON.stringify(startVisiting.current)
      );

      flagged.current = Array(data.length).fill(false);
      localStorage.setItem("flagged", JSON.stringify(flagged.current));
      localStorage.setItem("curr_index", JSON.stringify(index.current));
    } catch (e) {
      console.log(e);
    }
    localStorage.setItem("in_quiz", 1);
  };

  const updateTimed = (prevIndex, newIndex) => {
    function getSecondsDiff(startDate, endDate) {
      const msInSecond = 1000;
      return Math.round(
        Math.abs(Date.parse(endDate) - Date.parse(startDate)) / msInSecond
      );
    }

    const timeNow = new Date();
    timed.current[prevIndex] =
      timed.current[prevIndex] +
      getSecondsDiff(startVisiting.current[prevIndex], timeNow);
    startVisiting.current[newIndex] = timeNow;
    localStorage.setItem("timed", JSON.stringify(timed.current));
    localStorage.setItem(
      "start_visiting",
      JSON.stringify(startVisiting.current)
    );
  };

  //updates the question for when the user goes presses next or previous
  const updateQuestion = async (direction) => {
    // await updateAttempt();
    const prevIndex = index.current;
    if (direction === "left" && index.current > 0) {
      index.current = index.current - 1;
    } else if (
      direction === "right" &&
      index.current < qList.current.length - 1
    ) {
      index.current = index.current + 1;
    }
    localStorage.setItem("curr_index", index.current);
    setQId(qList.current[index.current]);
    updateTimed(prevIndex, index.current);
    updateFlagColor()
  };

  const jumpToQuestion = async (q_index) => {
    // await updateAttempt();
    const prevIndex = index.current;
    index.current = q_index;
    localStorage.setItem("curr_index", index.current);
    setQId(qList.current[index.current]);
    updateTimed(prevIndex, index.current);
    updateFlagColor()
  };

  const updateFlagColor = () => {
    if (flagged.current[index.current] === true) {
      setFlagColor('warning')
      setFlagText('Flagged')
    } else {
      setFlagColor('secondary')
      setFlagText('Flag')
    }
  }

  const flag = async () => {
    if (flagged.current[index.current] === true) {
      flagged.current[index.current] = false
      setFlagColor('secondary')
      setFlagText('Flag')
    } else {
      flagged.current[index.current] = true
      setFlagColor('warning')
      setFlagText('Flagged')
    }
    localStorage.setItem("flagged", JSON.stringify(flagged.current))
  }

  const submitAndClose = async () => {
    setIsLoading(true);
    updateTimed(index.current, 0);
    if (isReview) {
      const attemptId = await submitReviewAttempt(
        user?.uid,
        params.subject,
        attempt,
        timed.current,
        qList.current
      );
      handleClose();
      localStorage.clear();
      let nextNav = `/Results/${params.subject}/review/${attemptId}`;
      navigate(nextNav);
      setIsLoading(false);
      return;
    }

    const attemptId = await submitAttempt(
      user?.uid,
      params.subject,
      params.quiz,
      attempt,
      timed
    );
    handleClose();
    let nextNav = "";

    if (params.mock === undefined) {
      localStorage.clear();
      nextNav = `/Results/${params.subject}/${params.quiz}/${attemptId}`;
    } else {
      await addAttemptId(user?.uid, params.mock, attemptId);
      const mockList = JSON.parse(localStorage.getItem("mockIds"));
      let nextQuiz = mockList[1];
      let nextSub = "";
      localStorage.clear();
      if (params.subject === "reading") {
        nextQuiz = mockList[1];
        nextSub = "maths";
        localStorage.setItem("break_length", 5);
      }
      if (params.subject === "maths") {
        nextQuiz = mockList[2];
        nextSub = "thinking";
        localStorage.setItem("break_length", 10);
      } else if (params.subject === "thinking") {
        nextQuiz = mockList[3];
        nextSub = "writing";
        localStorage.setItem("break_length", 5);
      }
      localStorage.setItem("mockIds", JSON.stringify(mockList));
      localStorage.setItem("next", nextQuiz);
      nextNav = `/Break/${params.mock}/${nextSub}`;
    }
    navigate(nextNav);
    setIsLoading(false);
  };

  useEffect(() => {
    if (attempt.length > 0) {
      localStorage.setItem("attempt", JSON.stringify(attempt));
    }
  }, [attempt])

  if (isLoading) {
    return <LoadingScreen text={"Submitting"} subtext={"Please do not close this tab"} />;
  }

  const getMargins = () => {
    if (params.subject === 'reading') {
      return { lg: '7rem', xl: '15rem' }
    } else {
      return "21vw"
    }
  }

  return (
    <div>
      <AppBar elevation={0} color="primary">
        <Toolbar
          sx={{
            mx: "12rem",
            height: "4.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <LogoSVG style={{ height: "3rem" }} />
          {qList.current.length === 0 ? null : <Timer expiryTimestamp={getTimer(params.subject, qList.current.length)} />}
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ my: '2rem' }} />
      <Box sx={{ mx: getMargins(), marginBottom: '1.5rem', display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" onClick={() => handleOpenExit()} sx={{ borderRadius: 4 }} disabled={params.mock !== undefined}>
          <Typography variant='h6' sx={{ textTransform: 'None' }}>Dashboard</Typography><ExitToAppIcon sx={{ marginLeft: 1 }} />
        </Button>
        <Button
          variant="contained"
          color={flagColor}
          onClick={() => {
            flag();
          }}
          sx={{ borderRadius: 4 }}
        >
          <Typography variant="h6" sx={{ textTransform: "None" }}>
            {flagText}
          </Typography>
        </Button>
      </Box>
      <Box sx={{ mx: getMargins(), display: 'flex', gap: 4, maxHeight: "70vh" }}>
        {params.subject === 'reading' ? <ReadingText subject={'reading'} question_id={qId} width={600} /> : null}
        <Box sx={{ width: "100%", maxHeight: "70vh", overflow: 'auto' }}>
          <Paper elevation={0} sx={{ borderRadius: 4, width: "100%" }}>
            <Box sx={{ p: "2rem" }}>
              <Question
                subject={params.subject}
                index={index}
                question_id={qId}
                attempt={attempt}
                setAttempt={setAttempt}
              />
            </Box>
          </Paper>
          <Box sx={{ paddingTop: "2rem", marginBottom: 8, height: '100px' }}>
            <Paper elevation={0} sx={{ borderRadius: 4 }}>
              <Box
                sx={{
                  px: "3rem",
                  py: "2rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Stack direction="row" spacing={4}>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: 4 }}
                    onClick={async () => {
                      await updateQuestion("left");
                      // updateButton();
                    }}
                    disabled={index.current === 0}
                  >
                    <Typography variant="h6">prev</Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: 4 }}
                    onClick={async () => {
                      await updateQuestion("right");
                      // updateButton();
                    }}
                    disabled={index.current === qList.current.length - 1}
                  >
                    <Typography variant="h6">next</Typography>
                  </Button>
                </Stack>
                <Button
                  variant="contained"
                  color="warning"
                  sx={{ borderRadius: 4 }}
                  onClick={() => {
                    handleOpen();
                    // updateAttempt();
                  }}
                >
                  <Typography variant="h6" sx={{ textTransform: "None" }}>
                    Review Quiz
                  </Typography>
                </Button>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        hideBackdrop={false}
      >
        <Box sx={modalStyle}>
          <Box
            sx={{
              backgroundColor: "background.paper",
              display: "flex",
              justifyContent: "center",
              mx: "15%",
              my: "1rem",
              borderRadius: 3,
              maxHeight: '75vh',
              py: '3%', px: '2%'
            }}
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 15 }}
              sx={{ overflow: 'auto' }}
            >
              {arrayBlock(attempt, 1).map((row, i) => {
                let color = "success";
                let answered = "ANSWERED";
                if (row[0] === "unanswered") {
                  color = "error";
                  answered = "UNANSWERED";
                }

                if (flagged.current[i]) {
                  return (
                    <Grid item xs={2} sm={4} md={3} key={i}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          sx={{ width: "10rem", borderRadius: 8 }}
                          variant="contained"
                          color="warning"
                          onClick={async () => {
                            await jumpToQuestion(i);
                            // updateButton();
                            handleClose();
                          }}
                        >
                          Question {i + 1}: {answered}
                          <BsFillFlagFill />
                        </Button>
                      </Box>
                    </Grid>
                  );
                } else {
                  return (
                    <Grid item xs={2} sm={4} md={3} key={i}>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Button
                          sx={{ width: "10rem", borderRadius: 8 }}
                          variant="contained"
                          color={color}
                          onClick={async () => {
                            await jumpToQuestion(i);
                            // updateButton();
                            handleClose();
                          }}
                        >
                          Question {i + 1}: {answered}
                        </Button>
                      </Box>
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>
          <Box sx={{ my: "2rem", display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              sx={{ width: "10rem", height: "5rem", borderRadius: 9 }}
              color="secondary"
              onClick={() => {
                handleClose();
              }}
            >
              <Typography variant="h5">Close</Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                mx: "2rem",
                width: "10rem",
                height: "5rem",
                borderRadius: 9,
              }}
              color="primary"
              onClick={() => handleOpenSubmit()}
            >
              <Typography variant="h5">Submit</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openExpire} onClose={handleCloseExpire} hideBackdrop={false}>
        <Box sx={submitStyle}>
          <Typography color="error">
            Your time has expired. Your current answers will now be submitted
          </Typography>
          <Button
            sx={{ marginTop: "2rem", borderRadius: 6 }}
            variant="contained"
            onClick={() => {
              handleCloseExpire();
            }}
          >
            Ok
          </Button>
        </Box>
      </Modal>

      <Modal open={openExit} onClose={handleCloseExit} hideBackdrop={false}>
        <Box sx={submitStyle}>
          <Typography color="error">
            Are you sure you want to exit? Your current answers will be
            submitted
          </Typography>
          <Stack direction="row" spacing={2} sx={{ marginTop: "1rem" }}>
            <Button
              sx={{ borderRadius: 6 }}
              variant="contained"
              onClick={() => {
                handleCloseExit();
              }}
            >
              Close
            </Button>
            <Button
              color="error"
              sx={{ borderRadius: 6 }}
              variant="contained"
              onClick={() => {
                handleCloseExit();
                submitAndClose();
              }}
            >
              Exit
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal open={openSubmit} onClose={handleCloseSubmit} hideBackdrop={false}>
        <Box sx={submitStyle}>
          <Typography color="error">
            Are you sure you want to submit? Your quiz will be marked.
          </Typography>
          <Stack direction="row" spacing={2} sx={{ marginTop: "1rem" }}>
            <Button
              sx={{ borderRadius: 6 }}
              variant="contained"
              onClick={() => {
                handleCloseSubmit();
              }}
            >
              Close
            </Button>
            <Button
              color="error"
              sx={{ borderRadius: 6 }}
              variant="contained"
              onClick={() => {
                handleCloseSubmit();
                submitAndClose();
              }}
            >
              Submit
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

export default Questions;
