import { initializeApp } from "firebase/app";

//production
const firebaseConfig = {
  apiKey: "AIzaSyDo-p5rvKc5gt-7GuxHY04AvckLdIoIi8I",

  authDomain: "pass-papers-education-website.firebaseapp.com",

  projectId: "pass-papers-education-website",

  storageBucket: "pass-papers-education-website.appspot.com",

  messagingSenderId: "826513094056",

  appId: "1:826513094056:web:9c247fa8c6e948d1c9d99d",

  measurementId: "G-L2VDLNVWN0",
};

const portalCloudFunctionTrigger =
  "https://us-central1-pass-papers-education-website.cloudfunctions.net/ext-firestore-stripe-payments-createPortalLink";

// // testing
// const firebaseConfig = {
//   apiKey: "AIzaSyA1dEsLUpreRWmSFiRO7rWuqEndl1ymxDs",
//   authDomain: "passpapers-testing-66cc6.firebaseapp.com",
//   projectId: "passpapers-testing-66cc6",
//   storageBucket: "passpapers-testing-66cc6.appspot.com",
//   messagingSenderId: "1030875134416",
//   appId: "1:1030875134416:web:41313826f343bc3b2621d5",
// };

// const portalCloudFunctionTrigger =
//   "https://australia-southeast1-passpapers-testing-66cc6.cloudfunctions.net/ext-firestore-stripe-payments-createPortalLink";

export { firebaseConfig, portalCloudFunctionTrigger };
