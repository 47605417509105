import React, { useEffect, useState, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../auth";
import { getMarkingQueue } from "../app_backend";
import Navbar from "../components/NavBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';

function Marking() {
  const [user, loading, error] = useAuthState(auth);
  const [markingList, setMarkingList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  const fetchMarkingList = async () => {
    const fetched = await getMarkingQueue();
    fetched.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date)
    });
    setMarkingList(fetched);
  };

  useEffect(() => {
    fetchMarkingList();
  }, []);

  return (
    <Box sx={{ p: 4 }}>
      <Stack
        spacing={4}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        {markingList.map((paper) => {
          return (
            <CardActionArea
              key={"markinglist" + `${paper.marking_id}`}
              sx={{ width: "100%", borderRadius: 5, backgroundColor: '#e5eafb', py: 2, px: 4 }}
              onClick={() =>
                navigate("/MarkingInterface", {
                  state: { markingId: paper.marking_id },
                })
              }
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <Typography
                    variant="h5"
                    color="text.primary"
                  >
                    {paper.user_name}
                  </Typography>
                  <Typography
                    color="text.primary"
                  >
                    {paper.quiz_id}
                  </Typography>
                </div>
                <Typography
                  color="primary"
                >
                  <b>{paper.date}</b>
                </Typography>
              </Box>
            </CardActionArea>
          );
        })}
      </Stack>
    </Box>
  );
}

export default Marking;
