import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../auth";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import "../../../style/Practice.css";
import { DEMO_SUBSCRIPTION, EXPIRED_SUBSCRIPTION } from "../../../Stripe";
import VerticalSubjectTabs from "../Practice/VerticalSubjectTabs";
import { GetQuizList } from "../../../app_backend";
import NoMobileDialog from "../NoMobileDialog";

const stepLabels = ['Mock Exam Type', 'Exams'];

const MobileMockList = ({ selectedSubject, setSelectedSubject }) => {
  const [user, loading, error] = useAuthState(auth)

  const [step, setStep] = useState(0)
  const [itemList, setItemList] = useState([
    'full_mocks',
    'reading',
    'maths',
    'thinking',
    'writing',
  ])
  const [labelList, setLabelList] = useState([
    'Full Mock Exams',
    'Reading Mocks',
    'Maths Mocks',
    'Thinking Mocks',
    'Writing Mocks',
  ])
  const [selectedQuiz, setSelectedQuiz] = useState('');

  const [open, setOpen] = useState(false)

  const handleNext = () => {
    if (step < 1) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step === 1) {
      setSelectedSubject("")
    }
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const fetchQuizList = async () => {
    try {
      let quiz_list = await GetQuizList(user?.uid, selectedSubject, 'MOCK', false)
      const toItemList = quiz_list.quizzes.map(quiz => quiz.id)
      setItemList(toItemList)
      setLabelList(toItemList)
      if (toItemList) {
        handleNext()
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getButtonText = () => {
    switch (step) {
      case 0:
        return ""
      case 1:
        return "Mock Type"
    }
  }

  useEffect(() => {
    if (selectedSubject) {
      fetchQuizList()
    }
  }, [selectedSubject])

  useEffect(() => {
    if (selectedQuiz) {
      setOpen(true)
    }
  }, [selectedQuiz])


  const Selector = () => {
    switch (step) {
      case 0:
        return (
          <Box>
            <VerticalSubjectTabs
              selectedItem={selectedSubject}
              setSelectedItem={setSelectedSubject}
              itemList={itemList}
              labelList={labelList}
            />
          </Box>
        )
      case 1:
        return (
          <Box>
            <VerticalSubjectTabs
              selectedItem={null}
              setSelectedItem={setSelectedQuiz}
              itemList={itemList}
              labelList={labelList}
            />
          </Box>
        )
    }
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        height: '100%',
        py: 1,
      }}>
      <Box sx={{ width: '100%', bgcolor: '#F1F4FF', py: 1, borderRadius: 9, height: '40px' }}>
        <Stepper activeStep={step}>
          {stepLabels.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', mt: 2, height: '30px' }}>
        <Button
          color="primary"
          variant="contained"
          disabled={step === 0}
          onClick={handleBack}
          disableElevation
          sx={{ mr: 1, borderRadius: 6, ml: 'auto' }}
        >
          <ArrowBackRoundedIcon /> {getButtonText()}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Selector />
      </Box>

      <NoMobileDialog open={open} setOpen={setOpen} />
    </Box>
  )
}

export default MobileMockList