import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1F2F98',
    },
    secondary: {
      main: '#37a5dd',
    },
    white_paper: {
      main: '#ffffff',
      light: '#e2e5f3',
      dark: '#e2e5f3',
      contrastText: '#263238',
    },
    background: {
      default: '#e2e5f3',
      paper: '#fbfcfe',
    },
    warning: {
      main: '#ffb74d',
    },
    success: {
      main: '#2ba831',
    },
    text: {
      primary: '#292929',
    },
    lms: {
      main: '#f2f3f9',
    },
    h_box: {
      main: 'babeee',
    },
    h_text: {
      main: '#3E54AC',
    },
  },
  typography: {
    fontFamily: 'Kanit',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: '#6b6b6b #2b2b2b',
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#fafafa',
            width: 11,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: '#b4bcd7',
            minHeight: 24,
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
          {
            backgroundColor: '#838ba4',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
          {
            backgroundColor: '#838ba4',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
          {
            backgroundColor: '#838ba4',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#838ba4',
          },
        },
      },
    },
  },
});
