import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import styled from 'styled-components';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { auth } from '../auth';
import { GetMockIds, GetNumQuestions, GetTopic } from '../app_backend';
import PreMockPage from './PreMockPage';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Switch from '@mui/material/Switch';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#f0eeea',
  boxShadow: 24,
  p: 4,
  borderRadius: 7,
  width: '30em',
};

function Quiz({ subject, quiz, setSelectedPage }) {
  const [user, loading, error] = useAuthState(auth);
  const [details, setDetails] = useState({});
  const [nextId, setNextId] = useState('');
  const [topic, setTopic] = useState('');
  const [wantTimer, setWantTimer] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  // modal for full mocks confirmation
  const [openStartModal, setOpenStartModal] = useState(false);

  const handleOpenStartModal = () => setOpenStartModal(true);
  const handleCloseStartModal = () => {
    setOpenStartModal(false);
  };

  const fetchQuizDetails = async () => {
    const quiz_details_summary = {
      maths: {
        total_marks: 35,
        time_limit: 40,
      },
      reading: {
        total_marks: 30,
        time_limit: 40,
      },
      thinking: {
        total_marks: 40,
        time_limit: 40,
      },
      writing: {
        total_marks: 25,
        time_limit: 30,
      },
    };
    if (subject !== 'full_mocks') {
      let numQuestions = await GetNumQuestions(subject, quiz);

      const data = {
        quiz_name: quiz,
        total_marks: numQuestions,
        time_limit: quiz_details_summary[`${subject}`]['time_limit'],
      };
      setDetails(data);
      localStorage.setItem('timer_active', true);
    } else {
      const mockData = await GetMockIds(quiz);
      setNextId(mockData[0]);
      localStorage.setItem('mockIds', JSON.stringify(mockData));
    }

    const quizTopic = await GetTopic(subject, quiz);
    setTopic(quizTopic);
    if (quizTopic === 'MOCK') {
      setWantTimer(true)
      localStorage.setItem('timer_active', true);
    } else {
      localStorage.setItem('timer_active', false);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
    localStorage.clear();
    fetchQuizDetails();
    localStorage.setItem('in_quiz', 0);
    localStorage.setItem('start_timer', 0);
  }, [user, loading]);

  useEffect(() => {
    if (wantTimer) {
      localStorage.setItem('timer_active', true);
    } else {
      localStorage.setItem('timer_active', false);
    }
  }, [wantTimer]);

  let path = `/Quizzes/${subject}/${quiz}/Questions`;
  if (subject === 'writing') {
    path = `/Writing/${quiz}`;
  } else if (subject === 'full_mocks') {
    path = `/Quizzes/${quiz}/reading/${nextId}/Questions`
  }

  return (
    <Box>
      <Button
        variant='outlined'
        sx={{ height: '3rem', borderRadius: 8, m: 2, ml: 5, backgroundColor: '#f1f4ff' }}
        onClick={() => setSelectedPage('quiz_list')}
      >
        <Typography color='primary' sx={{ paddingRight: 1 }}>
          back to quizzes
        </Typography>
        <ExitToAppIcon color='primary' />
      </Button>
      {subject === 'full_mocks' ? (
        <PreMockPage topic={topic} />
      ) : (
        <div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Paper sx={{ width: '65%', borderRadius: 6 }} elevation={0}>
              <Box sx={{ mx: '3rem', my: '3rem' }}>
                <h1>Selective High School Test</h1>
                <QuizText>
                  You are about to attempt {details.quiz_name}.<br></br>
                  This test consists of {details.total_marks} marks. Students
                  are recommended to complete this practice test under exam
                  conditions.<br></br>
                </QuizText>
                <h3>Exam conditions:</h3>
                <p>- Finish within the given time limit</p>
                <p>- Complete the exam without any external distractions</p>
                <p>
                  - No devices are to be used that will aid the student (e.g.
                  calculator)
                </p>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Box
                  variant='outlined'
                  sx={{
                    border: 1,
                    borderColor: 'error.main',
                    marginBottom: '2rem',
                    width: '70%',
                    borderRadius: 3,
                  }}
                >
                  <Typography
                    color='error'
                    variant='h6'
                    sx={{ mx: '1rem', my: '1rem' }}
                  >
                    Please ensure you have enough time to complete the quiz as
                    you will not be able to restart or return to dashboard.
                    Leaving the quiz will submit your answers.
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: '2rem',
          gap: '1rem',
        }}
      >
        {subject !== 'full_mocks' ? (
          <Box
            sx={{
              borderRadius: 99,
              bgcolor: '#f1f4ff',
              py: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '10rem',
            }}
          >
            <Switch
              checked={wantTimer}
              onChange={(e) => {
                setWantTimer(e.target.checked);
              }}
            />
            {wantTimer ? (
              <Typography
                sx={{
                  fontSize: '14pt',
                  fontWeight: 600,
                  color: '#1f2f98',
                  marginRight: 1,
                }}
              >
                Timer On
              </Typography>
            ) : (
              <Typography sx={{ fontSize: '14pt', marginRight: 1 }}>
                Timer Off
              </Typography>
            )}
          </Box>
        ) : null}
        <Button
          color='primary'
          variant='contained'
          onClick={() => {
            if (subject === 'full_mocks' || topic === 'MOCK') {
              setOpenStartModal(true);
            } else {
              navigate(path);
            }
          }}
          sx={{ width: '7rem', borderRadius: 7 }}
        >
          <Typography variant='h6'>Start</Typography>
        </Button>
      </Box>
      {/* Modal for confirming start for full mocks */}
      <Modal
        open={openStartModal}
        onClose={handleCloseStartModal}
        hideBackdrop={false}
        sx={{ width: '100vw' }}
      >
        <Box sx={modalStyle}>
          <Typography fontSize='13pt'>
            You are about to begin a{' '}
            {subject === 'full_mocks' ? <b>FULL MOCK</b> : <b>MOCK</b>}. You
            will need to set aside{' '}
            <u>
              {subject === 'full_mocks' ? (
                <b>170 minutes</b>
              ) : (
                <b>40 minutes</b>
              )}
            </u>{' '}
            for the full experience. Are you sure you want to begin?
          </Typography>
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
            }}
          >
            <Button
              sx={{ borderRadius: 6 }}
              variant='contained'
              color='error'
              onClick={() => {
                handleCloseStartModal();
              }}
            >
              I do not have enough time.
            </Button>
            <Button
              sx={{ borderRadius: 6 }}
              variant='contained'
              color='success'
              onClick={() => {
                navigate(path);
                handleCloseStartModal();
              }}
            >
              Start
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const QuizText = styled.p`
  font-size: 25px;
  margin-top: auto;
`;

export default Quiz;
