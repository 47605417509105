import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../auth';

import '../style/Dashboard.css';

import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Sidebar2 from '../components/Sidebar2';
import DashInterface from './DashInterface';
import Learn from './Learn';
import Create from './Create';
import Practice from './Practice';
import MockList from './MockList';
import Profile from './Profile';
import Marking from './Marking';
import WritingResults from './WritingResults';
import Notify from './Notify';

import {
  fetchAuthenticationLevel,
  attachMarkingTokenListeners,
} from '../app_backend';
import Store from './Store';
import { getUserSubscriptions, VALID_SUBSCRIPTION } from '../Stripe';
import LoadingScreen from '../components/LoadingScreen';
import Grades from './Grades';
import NoMobile from './NoMobile';
import Reports from './Reports';
import Revision from './Revision';
import ReviewPage from './ReviewPage';
import { getBreakPointStyle } from '../components/Mobile/StyleHelper';

function Dashboard() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState(
    window.innerWidth <= 1000 ? 'Store' : 'Dashboard'
  );
  const [authenticationLevel, setAuthenticationLevel] = useState(0);
  const [subscription, setSubscription] = useState(true);
  const [authLoading, setAuthLoading] = useState(true);
  const [subLoading, setSubLoading] = useState(true);

  const [markingObject, setMarkingObject] = useState(null);
  const [refreshTokens, setRefreshTokens] = useState(true);

  const [editQuiz, setEditQuiz] = useState(null);

  const [unsubListeners, setUnsubListeners] = useState([]);

  // const [unsubSubscription, setUnsubSubscription] = useState(() => {
  //   return () => {
  //     console.log('unsub subscription no-op');
  //   };
  // });
  // const [unsubPayment, setUnsubPayment] = useState(() => {
  //   return () => {
  //     console.log('unsub payment no-op');
  //   };
  // });

  useEffect(() => {
    if (loading) return;
    if (!user || !user.uid) {
      navigate('/');
    } else {
      fetchAuthenticationLevel(user.uid).then((level) => {
        setAuthenticationLevel(level);
        setAuthLoading(false);
      });
    }
  }, [user, loading]);

  useEffect(() => {
    if (loading) return;
    if (!user || !user.uid) {
      navigate('/');
    } else {
      const getUnsubFunctions = async () => {
        // attach marking token listeners for payments and subscriptions
        console.log('attaching listeners');
        const unsubFunctions = await attachMarkingTokenListeners();
        setUnsubListeners((prev) => [
          ...prev,
          unsubFunctions.subscriptionUnsub,
          unsubFunctions.paymentUnsub,
        ]);
      };

      getUserSubscriptions(user.uid).then((sub) => {
        setSubscription(sub);
        setSubLoading(false);
      });
      getUnsubFunctions();
    }
  }, [user, loading]);

  useEffect(() => {
    return () => {
      console.log('detaching listeners');
      unsubListeners.forEach((unsub) => unsub());
      setUnsubListeners([]);
    };
  }, []);

  useEffect(() => {
    const curr_mock = localStorage.getItem('curr_mock');
    const curr_quiz = localStorage.getItem('curr_quiz');
    const curr_subject = localStorage.getItem('curr_subject');
    const curr_break = localStorage.getItem('break_timer');

    if (curr_break) {
      navigate(`/Break/${curr_mock}/${curr_subject}`);
      return;
    }

    if (curr_quiz && curr_subject) {
      let url = '';
      if (curr_subject === 'writing') {
        url += '/Writing';
        if (curr_mock) {
          url += `/${curr_mock}`;
        }
        url += `/${curr_quiz}`;
      } else {
        url += '/Quizzes';
        if (curr_mock) {
          url += `/${curr_mock}`;
        }
        url += `/${curr_subject}`;
        url += `/${curr_quiz}`;
        url += '/Questions';
      }

      navigate(url);
    } else {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    setRefreshTokens(!refreshTokens);
  }, [selectedPage]);

  // if (window.innerWidth <= 1000) {
  //   // return <NoMobile />;
  // }

  if (authLoading || subLoading) {
    return <LoadingScreen text={'Loading Dashboard'} />;
  }

  const mobileSupport = ['Dashboard', 'Store', 'Practice', 'Mocks', 'Revision'];

  const Selector = () => {
    if (window.innerWidth <= 1000 && !mobileSupport.includes(selectedPage)) {
      return <NoMobile />;
    }
    switch (selectedPage) {
      case 'Dashboard':
        return (
          <DashInterface
            setSelectedPage={setSelectedPage}
            subscription={subscription}
          />
        );
      case 'Create':
        return <Create editQuiz={editQuiz} setEditQuiz={setEditQuiz} />;
      case 'Marking':
        return <Marking />;
      case 'Grades':
        return <Grades subscription={subscription} />;
      case 'Learn':
        return <Learn />;
      case 'Practice':
        return <Practice subscription={subscription} />;
      case 'Notify':
        return <Notify />;
      case 'Reports':
        return <Reports user_id={user.uid} />;
      case 'Writing Review':
        return (
          <Box sx={{ flexGrow: 1, m: getBreakPointStyle(1, 3), width: '100%' }}>
            <WritingResults markingObject={markingObject} />
          </Box>
        );
      case 'Mocks':
        return <MockList subscription={subscription} />;
      case 'Revision':
        return <ReviewPage user_id={user.uid} />;
      case 'Profile':
        return (
          <Profile
            subscription={subscription}
            setSelectedPage={setSelectedPage}
          />
        );
      case 'Store':
        return <Store userId={user.uid} />;
      default:
        return (
          <DashInterface
            setSelectedPage={setSelectedPage}
            subscription={subscription}
          />
        );
    }
  };

  return (
    <Box
      className='dashboard'
      sx={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Toolbar />
      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
        }}
      >
        <Sidebar2
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
          authenticationLevel={authenticationLevel}
          subscription={subscription}
          setMarkingObject={setMarkingObject}
          refreshTokens={refreshTokens}
        />
        <Selector />
      </Box>
    </Box>
  );
}

export default Dashboard;
