import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  submitWritingAttempt,
  GetWritingQuestion,
  addAttemptId,
  getMarkingTokens,
} from '../app_backend';
import { auth } from '../auth';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useTimer } from 'react-timer-hook';

import LoadingScreen from '../components/LoadingScreen';

import '../style/ResponseEditor.css';
import '../style/MarkingTokensDisplay.css';

import { ReactComponent as LogoSVG } from '../images/logo-full-light.svg';
import { ReactComponent as MarkingToken } from '../images/MarkingToken.svg';

import { getTimer } from './Questions';
import ResponseEditor from '../components/ResponseEditor';
import HTML2json from 'html2json';
import StringToHTML from '../components/StringToHTML';
import MarkingTokensDisplay from '../components/MarkingTokensDisplay';
var html2JSON = HTML2json.html2json;
var json2HTML = HTML2json.json2html;

const expireStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#ffffff',
  boxShadow: 24,
  p: 4,
  borderRadius: 7,
};

const confirmStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#ffffff',
  boxShadow: 24,
  p: 4,
  borderRadius: 7,
};

function Writing() {
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(1);
  };

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      event.returnValue =
        'Are you sure? Leaving will submit the rest of your quiz.';
      return 'Are you sure? Leaving will submit the rest of your quiz.';
    };
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  const handleExpire = () => {
    handleOpenConfirm();
  };

  const Timer = ({ expiryTimestamp }) => {
    const {
      seconds,
      minutes,
      /*
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,*/
    } = useTimer({ expiryTimestamp, onExpire: () => handleExpire() });
    if (expiryTimestamp === 0) {
      return;
    }

    return (
      <Box
        sx={{
          borderRadius: 6,
          bgcolor: '#2E41BC',
          width: '8rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div>
          <div style={{ fontSize: '40px' }}>
            <span>{minutes}</span>:<span>{seconds}</span>
          </div>
        </div>
      </Box>
    );
  };
  const [user, loading, error] = useAuthState(auth);

  const params = useParams();
  const navigate = useNavigate();
  //   const attempt = useRef([]);

  const [url, setUrl] = useState('');
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [tokens, setTokens] = useState(0);

  const [openExpire, setOpenExpire] = useState(false);
  const handleOpenExpire = () => setOpenExpire(true);
  const handleCloseExpire = () => {
    setOpenExpire(false);
  };

  const [openConfirm, setOpenConfirm] = useState(false);
  const handleOpenConfirm = () => setOpenConfirm(true);
  const handleCloseConfirm = () => {
    submitAndClose(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const fetchTokens = async () => {
    const numTokens = await getMarkingTokens(user.uid);
    setTokens(numTokens);
  };

  const [lastChange, setLastChange] = useState();
  // Use a ref to access the quill instance directly
  const quillRef = useRef();

  useEffect(() => {
    fetchTokens();
  }, []);

  const submitAndClose = async (usedToken) => {
    setOpenConfirm(false);
    setIsLoading(true);

    const responseHTML = quillRef.current.root.innerHTML;

    const req = await submitWritingAttempt(
      user?.uid,
      params.quiz,
      html2JSON(responseHTML),
      usedToken
    );
    const attemptId = req.attemptId;
    const markingId = req.markingId;
    //open modal
    if (localStorage.getItem('mockIds') !== null) {
      await addAttemptId(user?.uid, params.mock, attemptId);
      navigate(`/MockResults/${params.mock}`);
    } else {
      navigate('/Dashboard');
    }
    localStorage.clear();
  };

  const fetchQuestion = async () => {
    try {
      const data = await GetWritingQuestion(params.quiz);
      const question = data.question;
      setQuestion(json2HTML(question));
      setUrl(data.imgURL);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (localStorage.length <= 1) {
      navigate('/Dashboard');
      return;
    }
    // set current quiz and or mock in local storage to maintain session
    if (params.mock) {
      localStorage.setItem('curr_mock', params.mock);
    }
    if (params.quiz) {
      localStorage.setItem('curr_quiz', params.quiz);
    }
    localStorage.setItem('curr_subject', 'writing');

    fetchQuestion();
  }, []);

  if (isLoading) {
    return (
      <LoadingScreen
        text={'Submitting'}
        subtext={'Please do not close this tab'}
      />
    );
  }

  return (
    <React.Fragment>
      <AppBar elevation={0} color='primary'>
        <Toolbar
          sx={{
            mx: '12rem',
            height: '4.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <LogoSVG style={{ height: '3rem' }} />
          <Timer expiryTimestamp={getTimer('writing', 1)} />
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ my: '2rem' }} />
      <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 8 }}>
        <Stack
          sx={{
            width: '100%',
            mx: '15rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Paper sx={{ borderRadius: 4, p: 3, mb: 3 }}>
            <StringToHTML string={question} />
          </Paper>
          <Paper sx={{ borderRadius: 4, p: 3 }}>
            <ResponseEditor
              ref={quillRef}
              onTextChange={setLastChange}
              placeholder={'Enter Question'}
            />
          </Paper>
          <Box
            sx={{ paddingTop: 3, display: 'flex', justifyContent: 'center' }}
          >
            <Button
              variant='contained'
              sx={{ width: '10rem', borderRadius: 5 }}
              onClick={() => handleOpenExpire()}
            >
              Submit
            </Button>
          </Box>
        </Stack>
      </Box>
      <Modal open={openExpire} onClose={handleCloseExpire} hideBackdrop={false}>
        <Box sx={expireStyle}>
          <Typography sx={{ fontSize: '18pt', fontWeight: 600 }}>
            Are you sure you want to submit?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Button
              sx={{ marginTop: '2rem', borderRadius: 6 }}
              variant='contained'
              onClick={() => {
                handleCloseExpire();
                handleOpenConfirm();
              }}
            >
              Ok
            </Button>
            <Button
              sx={{ marginTop: '2rem', borderRadius: 6 }}
              variant='outlined'
              onClick={() => {
                handleCloseExpire();
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openConfirm}
        onClose={handleCloseConfirm}
        hideBackdrop={false}
      >
        <Box sx={confirmStyle}>
          <Typography
            color={'primary'}
            sx={{
              fontSize: '22pt',
              fontWeight: 700,
              lineHeight: 1.2,
              marginBottom: 1,
            }}
          >
            Quiz has finished.
          </Typography>
          <Typography
            sx={{ fontSize: '22pt', fontWeight: 700, lineHeight: 1.2 }}
          >
            Submit this writing attempt for marking?
          </Typography>
          <Box
            sx={{
              backgroundColor: '#e5eafb',
              borderRadius: 3,
              color: '#1f2f98',
              marginTop: 2,
              p: 1,
            }}
          >
            Get marking and feedback on your work.
            <br />
            This can be done later on the quiz review page.
            <br />
            <strong>Marking tokens cannot be refunded</strong>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              height: '40px',
              alignItems: 'center',
              marginTop: 2,
            }}
          >
            <Box sx={{ fontWeight: 700 }}>My tokens:</Box>
            <Box sx={{ width: '100px', display: 'inline' }}>
              <MarkingTokensDisplay box={false} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-evenly',
            }}
          >
            <Button
              sx={{ marginTop: '2rem', borderRadius: 6 }}
              disabled={tokens <= 0}
              color='primary'
              variant='contained'
              onClick={() => {
                submitAndClose(true);
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                }}
              >
                <MarkingToken className='token-image' />
                <Typography
                  color='warning'
                  sx={{ fontWeight: 600, color: '#ffffff' }}
                >
                  {' '}
                  -1{' '}
                </Typography>
              </Box>
            </Button>
            <Button
              sx={{ marginTop: '2rem', borderRadius: 6 }}
              variant='outlined'
              onClick={() => {
                submitAndClose(false);
              }}
            >
              Later
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default Writing;
