import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function Learn6() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#babeee",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            Units of time
          </Typography>
        </Box>
        <Typography sx={{ mx: 2 }}>
          Units of time include:
          <ul>
            <li>1 minute (min) = 60 seconds (s)</li>
            <li>1 hour (h) = 60 minutes (min)</li>
            <li>1 day = 24 hours (h)</li>
            <li>1 week = 7 days</li>
            <li>1 year = 12 months</li>
          </ul>
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 3,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 1
          </AlertTitle>
          2 hrs = 120 mins.
        </Alert>
        <Box
          sx={{
            backgroundColor: "#babeee",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 600 }}>
            24-hour time
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          a.m. or p.m. is used to describe the 12 hours before and after midday.
          <br />
          24-hour time shows the number of hours and minutes after midnight.
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 3,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 2
          </AlertTitle>
          Two examples of 24-hour time would be 0430 which is equivalent to 4:30
          a.m. and 1630 is equivalent to 4:30 p.m.
        </Alert>
      </Box>
    </Box>
  );
}

export default Learn6;
