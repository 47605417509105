import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';


import { Stack } from "react-bootstrap";
import { Fade } from "@mui/material";

const DemoBanner = () => {
  return (
    <Fade in={true}>
      <Box
        sx={{
          p: 2,
          overflow: "visible",
          borderRadius: 6,
          backgroundColor: "#e5eafb",
          display: "flex",
          justifyContent: "space-between",
          mb: '1rem'
        }}
      >
        <Box sx={{ overflow: "visible" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, paddingBottom: 0.9, color: "#1F2F98" }}
          >
            This is a Demo mock exam
          </Typography>
          <Typography sx={{ paddingBottom: 0.9, color: "#5053ab" }}>
            There are 12 Reading Questions, 10 Mathematical Reasoning Questions, 5 Thinking Skill Questions and 1 Sample Writing Question.
          </Typography>
          <Typography sx={{ paddingBottom: 0.5, color: "#5053ab" }}>
            Writing will not be marked.
          </Typography>
        </Box>
      </Box >
    </Fade>
  )
}

function PreMockPage({ topic = 'NOT DEMO' }) {
  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Paper sx={{ width: '65%', borderRadius: 4, pl: 5, pr: 3, py: 3, height: '65vh' }} elevation={0}>
          <Box sx={{ overflow: 'auto', height: '100%', width: '100%', pr: 2 }}>
            <Stack>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h2' sx={{ paddingBottom: 3 }}>Mock Exams</Typography>
              </Box>
              {/* {topic === "DEMO" ? <DemoBanner /> : null} */}
              <Typography sx={{ paddingBottom: 2 }}>Practicing Mock Exams will prepare you for the Selective Test. It will allow
                you to experience what it's like completing a full Selective Test.
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>Please allow 170 minutes to complete the exam.
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>You will not be able to redo the exam and you are not allowed to pause the exam.
                You must complete the exam in one sitting.
              </Typography>

              <Typography sx={{ paddingBottom: 2, fontWeight: 600 }}>Mock Exam Schedule:
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>The mock exam will follow the same schedule as the Selective Test.
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>The first exam will be the Reading Exam, this will consist of 30 questions to be completed in 40 minutes.
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>After the time is up, there will be a short 5 minute break just like the Selective Test, followed by the
                Mathematical Reasoning Exam, this will consist of 35 questions to be completed in 40 minutes.
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>There will be a 10 minute break following this section
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>After the break, the Thinking Skills Exam will start, this will consist of 40 questions to be completed in 40 minutes. Followed by a 5 minute break.
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>The final exam will be writing where you will receive a prompt and have 30 minutes to complete.
              </Typography>
              <Typography sx={{ paddingBottom: 2, fontWeight: 600 }}>You are not allowed to go back and alter your response after completing each part!
              </Typography>
              <Typography sx={{ paddingBottom: 2, fontWeight: 600 }}>Rules
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>To make the most out of your mock exams, you must follow the same rules as you do when attempting the Selective Test:
              </Typography>
              <ul>
                <li><Typography>No distractions - make sure you are in an isolated location where no one can distract you whilst you are doing the exam.</Typography></li>
                <li><Typography>No food or drink - no eating throughout the exam</Typography></li>
                <li><Typography>Do not use any devices that will aid you for the exam</Typography></li>
                <li><Typography>You may use scrap paper to write your working out on</Typography></li>
              </ul>
              <Typography sx={{ paddingBottom: 2, fontWeight: 600 }}>Results</Typography>
              <Typography sx={{ paddingBottom: 2 }}>You will receive your Reading, Mathematical Reasoning and Thinking Skills results immediately after completion with very detailed statistics.
              </Typography>
              <Typography sx={{ paddingBottom: 2 }}>It will take 2-3 days for your writing to be marked and then you will receive your result on how well you did as a whole for your Mock Exam.
              </Typography>
              <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>Good Luck!</Typography>
            </Stack>
          </Box>
        </Paper>
      </Box>
    </div>
  )
}

export default PreMockPage