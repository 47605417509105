import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function LoadingScreen({ text, size, subtext = null }) {
  const [loadingText, setLoadingText] = useState(text)

  setTimeout(() => {
    setLoadingText('Nearly finished...')
  }, 5000)

  if (size === 'small') {
    return (
      <Box sx={{
        backgroundColor: 'background',
        display: 'flex', justifyContent: 'center', alignItems: 'center',
        marginTop: '50px'
      }}>
        <Stack spacing={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress />
          <Typography >
            {loadingText}
          </Typography>
        </Stack>
      </Box>
    )
  } else {
    return (
      <Box sx={{
        width: '100vw', height: '100vh', backgroundColor: 'background',
        display: 'flex', justifyContent: 'center', alignItems: 'center'
      }}>
        <Stack spacing={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress />
          <Typography>
            {loadingText}
          </Typography>
          {subtext ?
            <Typography fontWeight={800} color={'primary'}>
              {subtext}
            </Typography>
            :
            null}
        </Stack>
      </Box>
    );
  }
}


export default LoadingScreen