import Pages from './pages/Pages';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './style/Theme1';

function App() {
  return (
    <div className='App'>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <BrowserRouter>
            <Pages />
          </BrowserRouter>
        </ThemeProvider>
      </CookiesProvider>
    </div>
  );
}

export default App;
