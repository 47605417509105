import React, { useEffect, useState } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../auth';
import LoadingScreen from '../components/LoadingScreen';
import {
  pushWritingNotifications,
  getNotMarkedPaper,
  changeAttemptStatus,
  GetWritingQuestion,
  submitMarking,
} from '../app_backend';
import { ReactComponent as LogoSVG } from '../images/logo-full-light.svg';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  Box,
  Toolbar,
  Paper,
  Stack,
  Button,
  Typography,
  TextField,
  Slider,
  AppBar,
  Modal,
  Tabs,
  Tab,
} from '@mui/material';

import {
  saveSelection,
  highlight,
  unhighlight,
  focusComment,
  unfocusAllComments,
  scrollIfNotInView,
} from '../utils';

import CommentBox from '../components/CommentBox';

import '../style/MarkingInterface.css';

import { HTMLToJSON } from 'html-to-json-parser';

import HTML2json from 'html2json';
import StringToHTML from '../components/StringToHTML';
var json2HTML = HTML2json.json2html;

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#f0eeea',
  boxShadow: 24,
  p: 4,
  borderRadius: 7,
};

// Comment Button component for selections
const CommentButton = ({ layout, hidden, saveAndRestoreSelection }) => {
  const commentButtonWidth = 100;
  const commentButtonHeight = 20;
  return (
    <Box
      hidden={hidden}
      sx={{
        position: 'absolute',
        left: layout.left + (layout.width - commentButtonWidth) / 2,
        top: window.scrollY + layout.top - commentButtonHeight - 30,
        width: { commentButtonWidth },
        height: { commentButtonHeight },
      }}
    >
      <Box className='buttons-group'>
        <Button
          onClick={saveAndRestoreSelection}
          variant='contained'
          id='comment-btn'
        >
          <Typography>Comment</Typography>
        </Button>
        <div className='button-arrow_tip'></div>
      </Box>
    </Box>
  );
};

// Marking Interface page
function MarkingInterface() {
  const [user, loading] = useAuthState(auth);
  const { state } = useLocation();
  let markingId = state.markingId;

  // fetch paper useStates
  const [paper, setPaper] = useState({});
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const navigate = useNavigate();

  // loading useState
  const [loadingScreen, setLoadingScreen] = useState(false);

  // selection useStates
  const [selection, setSelection] = useState(null);
  const [storeSelection, setStoreSelection] = useState(null);
  const [hideCommentButton, setHideCommentButton] = useState(true);
  const [hideCommentBox, setHideCommentBox] = useState(true);
  const [commentButtonLayout, setCommentButtonLayout] = useState(false);
  const [commentClassName, setCommentClassName] = useState(null);
  const [commentList, setCommentList] = useState([]);

  // tab index
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
  }, [user, loading]);

  const fetchPaper = async () => {
    const fetched = await getNotMarkedPaper(markingId);
    setPaper(fetched);
    setResponse(json2HTML(fetched.response));
    // fetch question
    const questionObj = await GetWritingQuestion(fetched.quiz_id);
    setQuestion(json2HTML(questionObj.question));
  };

  useEffect(() => {
    fetchPaper();
  }, []);

  useEffect(() => {
    window.addEventListener('mouseup', handleMouseButtonUp);

    // cleanup this component
    return () => {
      window.removeEventListener('mouseup', handleMouseButtonUp);
    };
  }, []);

  // criteria 1: content and organisation of ideas
  // criteria 2: writing techniques
  // criteria 3: punctuation
  // criteria 4: spelling
  // criteria 5: grammar
  // criteria 6: vocabulary

  // Slider configuration

  const defaultSliderMark = 0;

  const content_marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
  ];

  const technique_marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    }
  ];

  const criterion2_marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
  ];

  const [criteria1, setCritera1] = useState(defaultSliderMark);
  const [criteria2, setCritera2] = useState(defaultSliderMark);
  const [criteria3, setCritera3] = useState(defaultSliderMark);
  const [criteria4, setCritera4] = useState(defaultSliderMark);
  const [criteria5, setCritera5] = useState(defaultSliderMark);
  const [criteria6, setCritera6] = useState(defaultSliderMark);

  const [feedback, setFeedback] = useState('');

  // CHECK AUTH LEVEL

  // retrieve the said paper,
  // upon mark, move said paper into marked section and notify the user
  // marked database should contain the marks for each criteria as well as some feedback
  // should show up on writing review page

  // dashboard exit modal
  const [openExit, setOpenExit] = useState(false);

  const handleOpenExit = () => setOpenExit(true);
  const handleCloseExit = () => {
    setOpenExit(false);
  };

  // question modal
  const [openQuestionModal, setOpenQuestionModal] = useState(false);

  const handleOpenQuestionModal = () => setOpenQuestionModal(true);
  const handleCloseQuestionModal = () => {
    setOpenQuestionModal(false);
  };

  // marking confirmation modal
  const [openConfirmMarking, setOpenConfirmMarking] = useState(false);

  const handleOpenConfirmMarking = () => setOpenConfirmMarking(true);
  const handleCloseConfirmMarking = () => setOpenConfirmMarking(false);

  // submits marking paper
  const handleSubmitMarking = async () => {
    // set loading screen
    setLoadingScreen(true);

    // unfocus all elements first
    unfocusAllComments();

    // saves criteria, response, comment list, paper and feedbacks
    // response
    const responseElement = document.querySelector('#selectableContent');
    let responseJSON = await HTMLToJSON(responseElement, true);

    // paper
    let userId = paper?.user_id;
    let attempt_id = paper?.attempt_id;
    let now = new Date();
    const dateString = now.toDateString();

    const markingId = await pushWritingNotifications(userId, {
      // criteria
      criteria1: criteria1,
      criteria2: criteria2,
      criteria3: criteria3,
      criteria4: criteria4,
      criteria5: criteria5,
      criteria6: criteria6,
      // feedback
      feedback: feedback,
      // marking info fields
      markedBy: user.displayName,
      markedOn: dateString,
      marker_id: user.uid,
      // response
      responseJSON: responseJSON,
      // comment list
      commentList: commentList,
      // paper contains:
      // attempt_id, marking_id, date, quiz_id, response (tree), user_id, user_name
      ...paper,
    });

    await submitMarking({
      // criteria
      criteria1: criteria1,
      criteria2: criteria2,
      criteria3: criteria3,
      criteria4: criteria4,
      criteria5: criteria5,
      criteria6: criteria6,
      // feedback
      feedback: feedback,
      // marking info fields
      markedBy: user.displayName,
      markedOn: dateString,
      marker_id: user.uid,
      // response
      responseJSON: responseJSON,
      // comment list
      commentList: commentList,
      // paper contains:
      // attempt_id, marking_id, date, quiz_id, response (tree), user_id, user_name
      ...paper,
    });

    // change attempt status to marked
    await changeAttemptStatus(
      userId,
      attempt_id,
      paper.quiz_id,
      criteria1 + criteria2 + criteria3 + criteria4 + criteria5 + criteria6
    );

    navigate('/Dashboard');
  };

  const updateFeedback = (e) => {
    setFeedback(e.target.value);
  };

  if (loadingScreen) {
    return <LoadingScreen text={'Submitting marking...'}></LoadingScreen>;
  }

  const handleMouseButtonUp = () => {
    // update selection on mbup
    const selection = saveSelection();
    setSelection(selection);

    // if selection exists, display comment button and update layout
    if (selection) {
      const rect = selection.getBoundingClientRect();
      setCommentButtonLayout(rect);
      // first check if comment box is open (meaning comment btn was clicked before)
      var ele = document.getElementById('comment-box');
      if (window.getComputedStyle(ele).visibility !== 'hidden') {
        // if it is visible, do not allow comment button to show
        setHideCommentButton(true);
      } else {
        setHideCommentButton(false);
      }
    } else {
      // selection does not exist, do not show comment button
      setHideCommentButton(true);
    }
  };

  // comment button is clicked
  const saveAndRestoreSelection = () => {
    // highlight selected text
    const uniqueClassName = 'selector' + Date.now().toString();
    highlight(selection, uniqueClassName, setTabValue);
    setCommentClassName(uniqueClassName);
    // store selection
    setStoreSelection(selection);
    // show comment box
    setHideCommentBox(false);
    // hide comment button
    setHideCommentButton(true);

    // focus this comment
    focusComment(uniqueClassName);
  };

  // update comment list
  const updateCommentList = (updateState) => {
    setCommentList([...commentList, updateState]);
  };

  // remove comment from comment list
  const removeComment = (index) => {
    setCommentList(commentList.filter((_, i) => i !== index));
  };

  return (
    <Box id='marking-interface' sx={{ height: '100vh', display: 'flex' }}>
      <AppBar id='navbar' elevation={5} color='primary' position='static'>
        <Toolbar
          sx={{
            mx: '12rem',
            height: '4.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <LogoSVG style={{ height: '3rem' }} />
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          height: '6rem',
          alignItems: 'center',
        }}
      >
        <Button
          id='show-question-btn'
          variant='contained'
          sx={{
            borderRadius: 4,
            width: '15rem',
            ml: '5%',
          }}
          onClick={() => handleOpenQuestionModal()}
        >
          <Typography variant='h6' sx={{ textTransform: 'None' }}>
            Show Question
          </Typography>
        </Button>
        <Button
          variant='contained'
          onClick={() => handleOpenExit()}
          sx={{ borderRadius: 4, mr: '5%' }}
        >
          <Typography variant='h6' sx={{ textTransform: 'None' }}>
            Dashboard
          </Typography>
          <ExitToAppIcon sx={{ ml: 1 }} />
        </Button>
      </Box>
      <Box sx={{ mx: '5%', flexGrow: 1, pb: '2em' }}>
        <Stack
          sx={{ width: '100%', height: '100%' }}
          direction='row'
          spacing={10}
        >
          <Stack
            sx={{ width: '66%', height: '100%' }}
            direction='column'
            spacing={4}
          >
            {/* response box */}
            <Box sx={{ flexGrow: 1 }}>
              <Paper
                elevation={1}
                sx={{
                  borderRadius: 7,
                  width: '100%',
                  height: '100%',
                }}
              >
                <Box
                  sx={{
                    p: 3,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='h5'
                    color='primary'
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mb: 2,
                      width: '100%',
                      borderBottom: 1,
                    }}
                  >
                    Student's Response
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflow: 'auto',
                      height: 0,
                      scrollbarGutter: 'stable',
                    }}
                    id='scrollable-response'
                  >
                    <Typography
                      id='selectableContent'
                      sx={{ wordWrap: 'break-word' }}
                      component={'span'}
                    >
                      <StringToHTML string={response} />
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
            {/* Comment Box */}
            <CommentBox
              hidden={hideCommentBox}
              setHidden={setHideCommentBox}
              storeSelection={storeSelection}
              commentClassName={commentClassName}
              updateCommentList={updateCommentList}
              setTabValue={setTabValue}
            />
          </Stack>

          {/* marking utilities */}
          <Box sx={{ width: '33%', height: '100%' }}>
            <Paper
              elevation={1}
              sx={{
                borderRadius: 7,
                width: '100%',
                height: '100%',
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* Tabs */}
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                variant='fullWidth'
                sx={{ borderBottom: 1 }}
              >
                <Tab
                  label='Marking'
                  sx={{ fontSize: '1em', fontWeight: 700 }}
                />
                <Tab
                  label='Comments'
                  sx={{ fontSize: '1em', fontWeight: 700 }}
                />
              </Tabs>
              {/* scoring Tab */}
              <Stack
                direction='column'
                alignItems='center'
                justifyContent='center'
                sx={{ flexGrow: 1 }}
                hidden={tabValue !== 0}
              >
                <Box
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                  }}
                  alignItems='center'
                  alignContent='center'
                >
                  <Typography variant='h6' color='primary' textAlign='center'>
                    Content and Organisation of Ideas
                  </Typography>
                  <Slider
                    aria-label='criteria-1'
                    defaultValue={defaultSliderMark}
                    valueLabelDisplay='auto'
                    step={null}
                    marks={content_marks}
                    min={0}
                    max={9}
                    onChange={(_, val) => {
                      setCritera1(val);
                    }}
                    sx={{ width: '75%', ml: '12.5%' }}
                  />
                </Box>
                <Box
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                  }}
                  alignItems='center'
                  alignContent='center'
                >
                  <Typography variant='h6' color='primary' textAlign='center'>
                    Writing Techniques
                  </Typography>
                  <Slider
                    aria-label='criteria-2'
                    defaultValue={defaultSliderMark}
                    valueLabelDisplay='auto'
                    step={null}
                    marks={technique_marks}
                    min={0}
                    max={8}
                    onChange={(_, val) => {
                      setCritera2(val);
                    }}
                    sx={{ width: '75%', ml: '12.5%' }}
                  />
                </Box>
                <Box
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                  }}
                  alignItems='center'
                  alignContent='center'
                >
                  <Typography variant='h6' color='primary' textAlign='center'>
                    Punctuation
                  </Typography>
                  <Slider
                    aria-label='criteria-3'
                    defaultValue={defaultSliderMark}
                    valueLabelDisplay='auto'
                    step={null}
                    marks={criterion2_marks}
                    min={0}
                    max={2}
                    onChange={(_, val) => {
                      setCritera3(val);
                    }}
                    sx={{ width: '75%', ml: '12.5%' }}
                  />
                </Box>
                <Box
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                  }}
                  alignItems='center'
                  alignContent='center'
                >
                  <Typography variant='h6' color='primary' textAlign='center'>
                    Spelling
                  </Typography>
                  <Slider
                    aria-label='criteria-4'
                    defaultValue={defaultSliderMark}
                    valueLabelDisplay='auto'
                    step={null}
                    marks={criterion2_marks}
                    min={0}
                    max={2}
                    onChange={(_, val) => {
                      setCritera4(val);
                    }}
                    sx={{ width: '75%', ml: '12.5%' }}
                  />
                </Box>
                <Box
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                  }}
                  alignItems='center'
                  alignContent='center'
                >
                  <Typography variant='h6' color='primary' textAlign='center'>
                    Grammar
                  </Typography>
                  <Slider
                    aria-label='criteria-5'
                    defaultValue={defaultSliderMark}
                    valueLabelDisplay='auto'
                    step={null}
                    marks={criterion2_marks}
                    min={0}
                    max={2}
                    onChange={(_, val) => {
                      setCritera5(val);
                    }}
                    sx={{ width: '75%', ml: '12.5%' }}
                  />
                </Box>
                <Box
                  sx={{
                    flexDirection: 'column',
                    width: '100%',
                  }}
                  alignItems='center'
                  alignContent='center'
                >
                  <Typography variant='h6' color='primary' textAlign='center'>
                    Vocabulary
                  </Typography>
                  <Slider
                    aria-label='criteria-6'
                    defaultValue={defaultSliderMark}
                    valueLabelDisplay='auto'
                    step={null}
                    marks={criterion2_marks}
                    min={0}
                    max={2}
                    onChange={(_, val) => {
                      setCritera6(val);
                    }}
                    sx={{ width: '75%', ml: '12.5%' }}
                  />
                </Box>
                <Button
                  variant='contained'
                  id='mark-btn'
                  sx={{
                    width: '15rem',
                    backgroundColor: '#e5eafb',
                    border: 0.5,
                    borderRadius: 4,
                    borderColor: 'primary',
                    mt: '2rem',
                  }}
                  onClick={() => {
                    handleOpenConfirmMarking();
                  }}
                >
                  <Typography variant='h6' sx={{ textTransform: 'None' }}>
                    Submit Marking
                  </Typography>
                </Button>
              </Stack>
              {/* comment list tab */}
              <Box
                hidden={tabValue !== 1}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexGrow: 1,
                }}
              >
                <Box sx={{
                  overflow: 'auto',
                  height: 0,
                  flexGrow: 1,
                  borderBottom: 1,
                  scrollbarGutter: 'stable',
                }}>

                <Stack
                  direction='column'
                  alignItems='center'
                  sx={{
                    py: 2,
                  }}
                  spacing={2}
                  id='comment-list'
                >
                  {commentList.length === 0 && (
                    <Typography>
                      Highlight response to start commenting.
                    </Typography>
                  )}
                  {commentList.map((commentObj, index) => (
                    <Paper
                      key={'key' + commentObj.className}
                      className={'box' + commentObj.className}
                      elevation={2}
                      sx={{
                        width: '90%',
                        minHeight: '7rem',
                        backgroundColor: 'lightyellow',
                        p: 2,
                      }}
                    >
                      <Typography sx={{ wordWrap: 'break-word' }}>
                        {commentObj.comment}
                      </Typography>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          className='show-comment-btn'
                          sx={{
                            borderRadius: 8,
                            mt: 1,
                            mr: 2,
                          }}
                          onClick={() => {
                            // scroll into view
                            scrollIfNotInView(
                              `.${commentObj.className}`,
                              '#scrollable-response'
                            );
                            // focus comment
                            focusComment(commentObj.className);
                          }}
                        >
                          Show
                        </Button>
                        <Button
                          className='remove-comment-btn'
                          sx={{
                            borderRadius: 8,
                            mt: 1,
                          }}
                          onClick={() => {
                            // scroll into view
                            scrollIfNotInView(
                              `.${commentObj.className}`,
                              '#scrollable-response'
                            );
                            // unhighlight and remove
                            unhighlight(`.${commentObj.className}`);
                            removeComment(index);
                          }}
                        >
                          Remove
                        </Button>
                      </Box>
                    </Paper>
                  ))}
                </Stack>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: '10rem',
                  }}
                >
                  <Typography variant='h6' color='primary' sx={{ py: 1 }}>
                    Marker's Feedback:
                  </Typography>
                  <TextField
                    fullWidth
                    id='feedback'
                    multiline
                    variant='filled'
                    rows={3}
                    sx={{ width: '100%' }}
                    value={feedback}
                    onChange={updateFeedback}
                  />
                </Box>
              </Box>
            </Paper>
          </Box>
        </Stack>
      </Box>
      {/* Modal for showing question */}
      <Modal
        open={openQuestionModal}
        onClose={handleCloseQuestionModal}
        hideBackdrop={false}
        sx={{ width: '100vw' }}
      >
        <Box sx={modalStyle}>
          <Paper sx={{ borderRadius: 7, p: 3 }} elevation={0}>
            <Typography
              variant='h5'
              color='primary'
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 2,
                width: '100%',
                borderBottom: 1,
              }}
            >
              Question
            </Typography>
            <Box className='question-container'>
              <StringToHTML string={question} />
            </Box>
          </Paper>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              sx={{ borderRadius: 6 }}
              variant='contained'
              onClick={() => {
                handleCloseQuestionModal();
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Modal for handling exit. */}
      <Modal open={openExit} onClose={handleCloseExit} hideBackdrop={false}>
        <Box sx={modalStyle}>
          <Typography color='error'>
            Are you sure you want to exit? This paper will not be marked.
          </Typography>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              sx={{ borderRadius: 6, mr: 2 }}
              variant='contained'
              onClick={() => {
                handleCloseExit();
              }}
            >
              Close
            </Button>
            <Button
              color='error'
              sx={{ borderRadius: 6 }}
              variant='contained'
              onClick={() => {
                handleCloseExit();
                navigate('/Dashboard');
              }}
            >
              Exit
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Modal for confirming marking. */}
      <Modal
        open={openConfirmMarking}
        onClose={handleCloseConfirmMarking}
        hideBackdrop={false}
      >
        <Box sx={modalStyle}>
          <Typography color='error'>
            Submit marking for this student?
          </Typography>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              sx={{ borderRadius: 6, mr: 2 }}
              variant='contained'
              onClick={() => {
                handleCloseConfirmMarking();
              }}
            >
              Cancel
            </Button>
            <Button
              color='success'
              sx={{ borderRadius: 6 }}
              variant='contained'
              onClick={() => {
                handleSubmitMarking();
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* Comment Button. */}
      <CommentButton
        layout={commentButtonLayout}
        hidden={hideCommentButton}
        saveAndRestoreSelection={saveAndRestoreSelection}
      />
    </Box>
  );
}

export default MarkingInterface;
