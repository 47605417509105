import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";
import React from "react";
import Navbar from "../components/NavBar";
import { ReactComponent as ErrorSVG } from "../images/undraw_location_search_re_ttoj.svg";
import { ReactComponent as Blob } from "../images/blob.svg";
import bgBlob from "../images/blob.svg"
import { useNavigate } from "react-router-dom";

function ErrorPage() {
  const navigate = useNavigate()

  return (
    <div>
      <Navbar />
      <Box sx={{ width: '100vw', height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ backgroundRepeat: 'no-repeat', backgroundImage: `url(${bgBlob})`, backgroundPosition: 'center', backgroundSize: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ErrorSVG style={{ height: '47vh', }} />
            </Box>
            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography sx={{ fontSize: '4vw', fontWeight: 900, color: '#3f3d56' }}>
                Error! Page not found
              </Typography>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button size="large" variant="contained" onClick={() => navigate("/Dashboard")} sx={{ borderRadius: 6 }}>
                Go Back
              </Button>
            </Box>
          </Stack>
        </div>
      </Box>
    </div>
  )
}

export default ErrorPage;
