import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate, Link } from 'react-router-dom';
import { auth, SendPasswordReset } from '../auth';
import '../style/Reset.css';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';

function Reset() {
  const [email, setEmail] = useState('');
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
  }, [loading]);

  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleReset = async () => {
    try {
      await SendPasswordReset(email);
    } catch (err) {
      if (err.code === 'auth/invalid-password') {
        setErrorMsg('Invalid Password');
      } else if (err.code === 'auth/invalid-email') {
        setErrorMsg('Invalid Email Provided');
      } else if (err.code === 'auth/user-not-found') {
        setErrorMsg('Account does not exist');
      } else {
        setErrorMsg(err.code);
      }
      setOpenError(true);
    }
  };

  return (
    <div class='reset'>
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          className='reset__container'
          sx={{
            width: 400,
            height: 250,
            borderRadius: 10,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack spacing={2} sx={{ paddingTop: '2rem', width: '80%' }}>
            <Box sx={{ width: '100%' }}>
              <Collapse
                in={openError}
                timeout={{ enter: 500, exit: 500 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                addEndListener={() => {
                  setTimeout(() => {
                    setOpenError(false);
                  }, 7000);
                }}
              >
                <Alert
                  severity='error'
                  action={
                    <IconButton
                      aria-label='close'
                      color='inherit'
                      size='small'
                      onClick={() => {
                        setOpenError(false);
                      }}
                    >
                      <CloseIcon fontSize='inherit' />
                    </IconButton>
                  }
                >
                  {errorMsg}
                </Alert>
              </Collapse>
            </Box>
            <input
              type='text'
              className='reset__textBox'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='E-mail Address'
            />
            <Button
              variant='contained'
              onClick={() => handleReset()}
              sx={{ borderRadius: 10 }}
            >
              Send password reset email
            </Button>
            <div>
              Don't have an account? <Link to='/register'>Register</Link> now.
            </div>
          </Stack>
        </Box>
      </Box>
    </div>
  );
}

export default Reset;
