import React from "react";
import Learn0 from "./Learn0";
import Learn1 from "./Learn1";
import Learn10 from "./Learn10";
import Learn11 from "./Learn11";
import Learn12 from "./Learn12";
import Learn13 from "./Learn13";
import Learn14 from "./Learn14";
import Learn2 from "./Learn2";
import Learn3 from "./Learn3";
import Learn4 from "./Learn4";
import Learn5 from "./Learn5";
import Learn6 from "./Learn6";
import Learn7 from "./Learn7";
import Learn8 from "./Learn8";
import Learn9 from "./Learn9";
import Learn15 from "./Learn15";
import { Typography } from "@mui/material";

function LearnContent({ index }) {
  switch (index) {
    case 0:
      return <Learn0 />;
    case 1:
      return <Learn1 />;
    case 2:
      return <Learn2 />;
    case 3:
      return <Learn3 />;
    case 4:
      return <Learn4 />;
    case 5:
      return <Learn5 />;
    case 6:
      return <Learn6 />;
    case 7:
      return <Learn7 />;
    case 8:
      return <Learn8 />;
    case 9:
      return <Learn9 />;
    case 10:
      return <Learn10 />;
    case 11:
      return <Learn11 />;
    case 12:
      return <Learn12 />;
    case 13:
      return <Learn13 />;
    case 14:
      return <Learn14 />;
    case 15:
      return <Learn15 />;
    default:
      return <Typography sx={{ fontSize: '18pt', fontWeight: 700 }}>Coming Soon...</Typography>;
  }
}

export default LearnContent;
