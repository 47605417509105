import React, { useEffect, useState } from "react";
import {
  GetUserRanks,
  fetchPaperIfSubmittedForMarking,
  getMarkingTokens,
  submitForMarkingByAttemptId,
} from "../app_backend";
import LoadingScreen from "./LoadingScreen";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import MockOverviewInfo from "./MockOverviewInfo";
import { ReactComponent as MarkingToken } from "../images/MarkingToken.svg";
import "../style/MarkingTokensDisplay.css";

const MockOverview = ({
  user_id,
  mockData,
  readingScores,
  mathScores,
  thinkingScores,
  writingScores,
}) => {
  const [readingPercentile, setReadingPercentile] = useState(null);
  const [mathsPercentile, setMathsPercentile] = useState(null);
  const [thinkingPercentile, setThinkingPercentile] = useState(null);
  const [writingPercentile, setWritingPercentile] = useState(null);
  const [overallPercentile, setOverallPercentile] = useState(null);

  const [readingMedian, setReadingMedian] = useState(null);
  const [mathsMedian, setMathsMedian] = useState(null);
  const [thinkingMedian, setThinkingMedian] = useState(null);
  const [writingMedian, setWritingMedian] = useState(null);

  // const [readingRank, setReadingRank] = useState(null);
  // const [mathsRank, setMathsRank] = useState(null);
  // const [thinkingRank, setThinkingRank] = useState(null);
  // const [writingRank, setWritingRank] = useState(null);
  // const [overallRank, setOverallRank] = useState(null);

  const [loading, setLoading] = useState(true);

  const setters1 = [
    setReadingPercentile,
    setMathsPercentile,
    setThinkingPercentile,
    setWritingPercentile,
  ];
  const setters2 = [
    setReadingMedian,
    setMathsMedian,
    setThinkingMedian,
    setWritingMedian,
  ];
  // const setters3 = [
  //   setReadingRank,
  //   setMathsRank,
  //   setThinkingRank,
  //   setWritingRank,
  // ];

  const getAllRanks = async () => {
    const mockScores = [
      readingScores,
      mathScores,
      thinkingScores,
      writingScores,
    ];
    mockData.map(async (mock, i) => {
      const data = await GetUserRanks(
        mock.subject,
        mock.mock_id,
        mockScores[i].score
      );
      // console.log(mock.mock_id, data.allScores.length, data.allScores)
      setters1[i](data.percentile);
      setters2[i](data.median);
      // setters3[i](data.rank);
    });
    setTimeout(() => {
      setLoading(false);
    }, 500);
  };

  const [tokens, setTokens] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(true);

  const fetchTokens = async () => {
    const numTokens = await getMarkingTokens(user_id);
    setTokens(numTokens);
  };
  const fetchIsWritingSubmitted = async () => {
    const res = await fetchPaperIfSubmittedForMarking(
      mockData[3].attempt_id,
      user_id,
      mockData[3].mock_id
    );
    setIsSubmitted(res);
  };

  const handleSubmitForMarking = async () => {
    const res = await submitForMarkingByAttemptId(
      user_id,
      mockData[3].mock_id,
      mockData[3].attempt_id
    );
    if (res.error === "NOT_ENOUGH_TOKENS") {
      alert("Not Enough Marking Tokens");
    }
    setIsSubmitted("NOT_MARKED");
  };

  const getOverallPercentile = () => {
    setOverallPercentile(
      0.25 * readingPercentile +
        0.25 * mathsPercentile +
        0.35 * thinkingPercentile +
        0.15 * writingPercentile
    );
  };

  useEffect(() => {
    getAllRanks();
    fetchTokens();
    fetchIsWritingSubmitted();
  }, []);

  useEffect(() => {
    if (
      readingPercentile &&
      mathsPercentile &&
      thinkingPercentile &&
      writingPercentile
    ) {
      getOverallPercentile();
    }
  }, [
    readingPercentile,
    mathsPercentile,
    thinkingPercentile,
    writingPercentile,
  ]);

  // useEffect(() => {
  //   if (readingRank && mathsRank && thinkingRank && writingRank) {
  //     // getOverallRank()
  //   }
  // }, [readingRank, mathsRank, thinkingRank, writingRank]);

  if (loading) {
    return <LoadingScreen text={"Loading..."} size={"small"} />;
  }

  const getStyleBasedOnPercentile = (percentile, section) => {
    if (section === 0) {
      let base = {
        width: "60%",
        borderTopLeftRadius: 12,
        borderBottomLeftRadius: 12,
        borderTopRightRadius: 2,
        borderBottomRightRadius: 2,
        backgroundColor: "#dfe0ef",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        lineHeight: 0.9,
      };
      if (percentile !== null && percentile >= 0 && percentile <= 50) {
        base.backgroundColor = "#4148AF";
        base.color = "#ffffff";
      }
      return base;
    } else if (section === 1) {
      let base = {
        width: "16%",
        backgroundColor: "#dfe0ef",
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "center",
        alignItems: "center",
        px: "1rem",
        textAlign: "center",
        lineHeight: 0.9,
      };
      if (percentile > 50 && percentile <= 75) {
        base.backgroundColor = "#4148AF";
        base.color = "#ffffff";
      }
      return base;
    } else if (section === 2) {
      let base = {
        width: "16%",
        backgroundColor: "#dfe0ef",
        borderRadius: 1,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "center",
        alignItems: "center",
        px: "1rem",
        textAlign: "center",
        lineHeight: 0.9,
      };
      if (percentile > 75 && percentile <= 90) {
        base.backgroundColor = "#4148AF";
        base.color = "#ffffff";
      }
      return base;
    } else if (section === 3) {
      let base = {
        width: "16%",
        backgroundColor: "#dfe0ef",
        borderTopRightRadius: 12,
        borderBottomRightRadius: 12,
        borderTopLeftRadius: 2,
        borderBottomLeftRadius: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        justifyContent: "center",
        alignItems: "center",
        px: "1rem",
        textAlign: "center",
        lineHeight: 0.9,
      };
      if (percentile > 90 && percentile <= 100) {
        base.backgroundColor = "#4148AF";
        base.color = "#ffffff";
      }
      return base;
    }
  };

  const PersonIcon = ({ percentile, section }) => {
    if (section === 0) {
      if (percentile !== null && percentile >= 0 && percentile <= 50) {
        return <PersonPinIcon />;
      }
    } else if (section === 1) {
      if (percentile > 50 && percentile <= 75) {
        return <PersonPinIcon />;
      }
    } else if (section === 2) {
      if (percentile > 75 && percentile <= 90) {
        return <PersonPinIcon />;
      }
    } else if (section === 3) {
      if (percentile > 90 && percentile <= 100) {
        return <PersonPinIcon />;
      }
    }
    return null;
  };

  return (
    <Box
      sx={{
        borderRadius: 4,
        backgroundColor: "#F1F4FF",
        p: 3,
        overflow: "auto",
        height: "100%",
        width: "100%",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Stack
          sx={{
            width: "870px",
            backgroundColor: "#ffffff",
            borderRadius: 6,
            p: 3,
            boxSizing: "content-box",
          }}
          gap={4}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <MockOverviewInfo />
            <Box>
              <Box sx={{ textAlign: "center" }}>
                <Typography sx={{ fontWeight: 700, ml: "10.5rem" }}>
                  Student's performance
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "0.3rem",
                  background: "rgb(180,181,200)",
                  background:
                    "linear-gradient(90deg, rgba(180,181,200,1) 45%, rgba(32,211,127,1) 100%);",
                  borderRadius: 9,
                  width: "700px",
                  ml: "10.5rem",
                }}
              ></Box>
            </Box>
          </Box>
          <Box
            sx={{
              height: "7rem",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                mr: "2rem",
                width: "8rem",
              }}
            >
              <Typography
                sx={{ fontSize: "16pt", fontWeight: 700, textAlign: "right" }}
              >
                Overall
              </Typography>
            </Box>
            <Box
              sx={{
                position: "relative",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                width: "700px",
                gap: 0.5,
              }}
            >
              {
                // overlay if writing is not marked
                isNaN(writingPercentile) ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "101%",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      borderRadius: 2,
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      backdropFilter: "blur(1.5px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#ffffff",
                    }}
                  >
                    Wait for Writing mark
                  </Box>
                ) : null
              }
              <Paper sx={getStyleBasedOnPercentile(overallPercentile, 0)}>
                <PersonIcon percentile={overallPercentile} section={0} />
                50% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(overallPercentile, 1)}>
                <PersonIcon percentile={overallPercentile} section={1} />
                25% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(overallPercentile, 2)}>
                <PersonIcon percentile={overallPercentile} section={2} />
                15% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(overallPercentile, 3)}>
                <PersonIcon percentile={overallPercentile} section={3} />
                10% of students who sat the test
              </Paper>
            </Box>
          </Box>
          <Box
            sx={{
              height: "7rem",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                mr: "2rem",
                width: "8rem",
              }}
            >
              <Typography
                sx={{ fontSize: "16pt", fontWeight: 700, textAlign: "right" }}
              >
                Reading
              </Typography>
              <Typography color={"primary"}>
                Median mark: {readingMedian}
              </Typography>
              {/* <Typography color={"primary"}>
                Your rank: {readingRank}
              </Typography> */}
            </Box>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
                width: "700px",
                gap: 0.5,
              }}
            >
              <Paper sx={getStyleBasedOnPercentile(readingPercentile, 0)}>
                <PersonIcon percentile={readingPercentile} section={0} />
                50% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(readingPercentile, 1)}>
                <PersonIcon percentile={readingPercentile} section={1} />
                25% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(readingPercentile, 2)}>
                <PersonIcon percentile={readingPercentile} section={2} />
                15% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(readingPercentile, 3)}>
                <PersonIcon percentile={readingPercentile} section={3} />
                10% of students who sat the test
              </Paper>
            </Box>
          </Box>
          <Box
            sx={{
              height: "7rem",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                mr: "2rem",
                width: "8rem",
              }}
            >
              <Typography
                sx={{ fontSize: "16pt", fontWeight: 700, textAlign: "right" }}
              >
                Mathematical Reasoning
              </Typography>
              <Typography color={"primary"}>
                Median mark: {mathsMedian}
              </Typography>
              {/* <Typography color={"primary"}>Your rank: {mathsRank}</Typography> */}
            </Box>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
                width: "700px",
                gap: 0.5,
              }}
            >
              <Paper sx={getStyleBasedOnPercentile(mathsPercentile, 0)}>
                <PersonIcon percentile={mathsPercentile} section={0} />
                50% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(mathsPercentile, 1)}>
                <PersonIcon percentile={mathsPercentile} section={1} />
                25% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(mathsPercentile, 2)}>
                <PersonIcon percentile={mathsPercentile} section={2} />
                15% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(mathsPercentile, 3)}>
                <PersonIcon percentile={mathsPercentile} section={3} />
                10% of students who sat the test
              </Paper>
            </Box>
          </Box>
          <Box
            sx={{
              height: "7rem",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                mr: "2rem",
                width: "8rem",
              }}
            >
              <Typography
                sx={{ fontSize: "16pt", fontWeight: 700, textAlign: "right" }}
              >
                Thinking Skills
              </Typography>
              <Typography color={"primary"}>
                Median mark: {thinkingMedian}
              </Typography>
              {/* <Typography color={"primary"}>
                Your rank: {thinkingRank}
              </Typography> */}
            </Box>
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "row",
                width: "700px",
                gap: 0.5,
              }}
            >
              <Paper sx={getStyleBasedOnPercentile(thinkingPercentile, 0)}>
                <PersonIcon percentile={thinkingPercentile} section={0} />
                50% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(thinkingPercentile, 1)}>
                <PersonIcon percentile={thinkingPercentile} section={1} />
                25% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(thinkingPercentile, 2)}>
                <PersonIcon percentile={thinkingPercentile} section={2} />
                15% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(thinkingPercentile, 3)}>
                <PersonIcon percentile={thinkingPercentile} section={3} />
                10% of students who sat the test
              </Paper>
            </Box>
          </Box>
          <Box
            sx={{
              height: "7rem",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-end",
                mr: "2rem",
                width: "8rem",
              }}
            >
              <Typography
                sx={{ fontSize: "16pt", fontWeight: 700, textAlign: "right" }}
              >
                Writing
              </Typography>
              <Typography color={"primary"}>
                Median mark: {writingMedian}
              </Typography>
              {/* <Typography color={"primary"}>
                Your rank: {writingRank}
              </Typography> */}
            </Box>
            <Box
              sx={{
                position: "relative",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                width: "700px",
                gap: 0.5,
              }}
            >
              {
                // overlay if writing is not marked
                isNaN(writingPercentile) ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "101%",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      borderRadius: 2,
                      backgroundColor: "#f7f9fc",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      color={"primary"}
                      sx={{ fontWeight: 700, fontSize: "16pt" }}
                    >
                      {isSubmitted !== "NOT_MARKED"
                        ? "Submit for marking?"
                        : "Submitted for marking"}
                    </Typography>
                    <Button
                      size="small"
                      sx={{ borderRadius: 6 }}
                      disabled={tokens <= 0 || isSubmitted === "NOT_MARKED"}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleSubmitForMarking();
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <MarkingToken className="token-image-small" />
                        <Typography
                          color="warning"
                          sx={{ fontWeight: 600, color: "#ffffff" }}
                        >
                          {" "}
                          -1{" "}
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                ) : null
              }
              <Paper sx={getStyleBasedOnPercentile(writingPercentile, 0)}>
                <PersonIcon percentile={writingPercentile} section={0} />
                50% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(writingPercentile, 1)}>
                <PersonIcon percentile={writingPercentile} section={1} />
                25% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(writingPercentile, 2)}>
                <PersonIcon percentile={writingPercentile} section={2} />
                15% of students who sat the test
              </Paper>
              <Paper sx={getStyleBasedOnPercentile(writingPercentile, 3)}>
                <PersonIcon percentile={writingPercentile} section={3} />
                10% of students who sat the test
              </Paper>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export default MockOverview;
