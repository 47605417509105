import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";

function Learn14() {
  return (
    <div>
      <Box sx={{ px: 3, width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "#f1f4ff",
            padding: 2,
            marginBottom: 6,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#babeee",
              padding: 2,
              marginBottom: 3,
              borderRadius: 4,
              color: "#3E54AC",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Mathematical Analysis Problems
            </Typography>
            <Typography sx={{ paddingBottom: 2, mx: 2 }}>
              Maths analytical problems require students to think critically and
              process information for a solution. Such problems have a wide
              range of difficulties when combined with mathematical reasoning,
              leading to a variety of different problems which students may
              encounter.
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            To be successful in mathematical analysis, some of the key skills
            that are required include: strong algebraic skills, logical
            thinking, problem-solving skills, attention to detail and
            persistence. Hence, if students encounter an extended or complex
            analytical problem, it is adviced that you make a guess and attempt
            later if you have extra time.
          </Typography>

          <Alert
            icon={<NewReleasesRoundedIcon fontSize="large" />}
            severity="info"
            variant="outlined"
            sx={{
              borderRadius: 4,
              marginBottom: 3,
              fontSize: "12pt",
              backgroundColor: "#d9e7fe",
            }}
          >
            <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
              Example
            </AlertTitle>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              190 people but 6 own at most two basketballs. There were a total
              of 305 basketballs. How many people own one basketball?
              <Typography
                sx={{ paddingBottom: 2, fontSize: "12pt" }}
              ></Typography>
              <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
                A) 121
              </Typography>
              <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
                B) 64
              </Typography>
              <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
                C) 63
              </Typography>
              <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
                D) 132
              </Typography>
            </Typography>
            <Typography
              sx={{ paddingBottom: 2, fontWeight: 700, fontSize: "18pt" }}
            >
              Explanation:
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
              There are a total of 184 people that own at most 2 basketballs and
              at least 1. We assume that everyone owns 1 basketball, meaning 184
              basketballs were handed out. This leaves 121 basketballs left. We
              then hand these remaining basketballs to each person, one by one,
              meaning now 121 people will be getting 2 basketballs and the
              remaining still have one. Therefore, 63 people own one basketball,
              thus the answer is C.
            </Typography>
          </Alert>
        </Box>
      </Box>
    </div>
  );
}

export default Learn14;
