import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Collapse,
} from '@mui/material';
import { React, useState } from 'react';
import {
  unhighlight,
  isInViewport,
  scrollIfNotInView,
  unfocusAllComments,
} from '../utils';

function CommentBox({
  hidden,
  setHidden,
  storeSelection,
  commentClassName,
  updateCommentList,
  setTabValue,
}) {
  const [comment, setComment] = useState('');

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const reset = () => {
    setComment('');
  };

  const handleCancel = () => {
    // clear comment and hide comment box
    reset();
    setHidden(true);
    // unhighlight comment
    unhighlight(`.${commentClassName}`);
  };

  const postComment = () => {
    // check if comment is just whitespace
    if (comment.trim() === '') {
      // unable to post comment
      return;
    }

    // add to comment list
    updateCommentList({
      className: commentClassName,
      comment: comment,
    });

    // unfocus comment
    unfocusAllComments();

    // clear comment and hide comment box
    reset();
    setHidden(true);

    // set tab value
    setTabValue(1);
  };

  return (
    <Collapse
      in={!hidden}
      id='comment-box'
      onEntered={() => {
        // scroll ele into view if not visible
        scrollIfNotInView(`.${commentClassName}`, '#scrollable-response');
      }}
    >
      <Paper
        elevation={1}
        sx={{
          borderRadius: 7,
          width: '100%',
          height: '100%',
          p: 1,
        }}
      >
        <Typography
          sx={{ display: 'flex', justifyContent: 'center' }}
          variant='h5'
          color='primary'
        >
          Comment:
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
          <TextField
            multiline
            rows={3}
            variant='filled'
            fullWidth
            placeholder='Type your comment here...'
            sx={{ height: '50%' }}
            onChange={handleCommentChange}
            value={comment}
          />
        </Box>
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            id='cancel-btn'
            variant='contained'
            sx={{ mr: 2, backgroundColor: 'darkgray' }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button variant='contained' sx={{ mr: 2 }} onClick={postComment}>
            Post Comment
          </Button>
        </Box>
      </Paper>
    </Collapse>
  );
}

export default CommentBox;
