import parse from 'html-react-parser';
import "../style/ReadingText.css"
import Box from '@mui/material/Box';

function StringToHTML({ string }) {
  return (
    <Box className='question-text' sx={{ width: '100%' }}>
      {parse(string)}
    </Box>
  )
}

export default StringToHTML