import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function Learn10() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography variant="h4" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          What are fractions?
        </Typography>
        <Typography sx={{ paddingBottom: 2 }}>
          Generally, we refer to fractions as part of a whole. Fractions are
          written as one number divided by another
        </Typography>
        <Typography sx={{}}>
          <ul>
            <li>
              Top number is called the{" "}
              <span style={{ color: "#c6090f" }}>
                <b>numerator</b>
              </span>
            </li>
            <li>
              Bottom number is called the{" "}
              <span style={{ color: "#c6090f" }}>
                <b>denominator</b>
              </span>
            </li>
            <li>
              Remember that fractions are just another way to express division.
              This means that the numerator is divided by the denominator.
              <br />
              <sup>2</sup>&frasl;<sub>3</sub> is 2 divided by 3
            </li>
          </ul>
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Types of fractions
        </Typography>
        <Typography sx={{}}>
          <ul>
            <li>
              <b>Proper fraction:</b> the numerator is less than the denominator
              <br />
              <Alert
                icon={<NewReleasesRoundedIcon fontSize="large" />}
                severity="info"
                variant="outlined"
                sx={{ borderRadius: 4, my: 1, backgroundColor: "#d9e7fe" }}
              >
                <Typography>
                  <b>Example 1</b>:{" "}
                  <span style={{ fontSize: "16pt", marginLeft: 10 }}>
                    <sup>3</sup>&frasl;<sub>5</sub>
                  </span>
                </Typography>
              </Alert>
            </li>
            <li>
              <b>Improper fraction:</b> the numerator is greater than the
              denominator
              <br />
              <Alert
                icon={<NewReleasesRoundedIcon fontSize="large" />}
                severity="info"
                variant="outlined"
                sx={{ borderRadius: 4, my: 1, backgroundColor: "#d9e7fe" }}
              >
                <Typography>
                  <b>Example 2</b>:{" "}
                  <span style={{ fontSize: "16pt", marginLeft: 10 }}>
                    <sup>7</sup>&frasl;<sub>2</sub>
                  </span>
                </Typography>
              </Alert>
            </li>
            <li>
              <b>Mixed numbers:</b> combination of a whole number and a fraction
              <br />
              <Alert
                icon={<NewReleasesRoundedIcon fontSize="large" />}
                severity="info"
                variant="outlined"
                sx={{ borderRadius: 4, my: 1, backgroundColor: "#d9e7fe" }}
              >
                <Typography>
                  <b>Example 3</b>:{" "}
                  <span style={{ fontSize: "16pt", marginLeft: 10 }}>
                    <sup>2</sup>&frasl;<sub>3</sub>
                  </span>
                </Typography>
              </Alert>
            </li>
          </ul>
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#e9ebf6",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Simplifying fractions
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Simplifying a fraction means to rewrite a fraction as an equivalent
          fraction, so that the numerator and denominator are{" "}
          <b>as small as possible</b>.
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          You can only simplify a fraction if the numerator and denominator have
          a common factor.
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Remember to always simplify fractions if you can!
        </Typography>
        <Box
          sx={{
            backgroundColor: "#babeee",
            padding: 2,
            marginBottom: 3,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Method
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Dividing the numerator and denominator by the{" "}
          <b>Highest Common Factor (HCF)</b> will give you the simplest form of
          a fraction
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          But sometimes it may be hard to immediately think of the HCF, so we
          can keep simplifying with smaller common factors until the simplest
          form is reached!
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 2,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 4
          </AlertTitle>
          <Typography sx={{ paddingBottom: 1 }}>
            <span style={{ fontSize: "16pt" }}>
              <sup>21</sup>&frasl;<sub>28</sub>
            </span>{" "}
            ← numerator and denominator have HCF of 7 <br />
            so we can simplify it as{" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>3</sup>&frasl;<sub>4</sub>
            </span>
            , which is the result of dividing the numerator and denominator by
            7.
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          backgroundColor: "#e9ebf6",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Ordering fractions
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          To compare the size of two fractions with different denominators, you
          must choose a new denominator for both fractions.
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          The new denominator should be a common multiple of both denominators,
          preferably the <b>Lowest Common Multiple (LCM)</b>.
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 2,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 5
          </AlertTitle>
          <Typography sx={{ paddingBottom: 2 }}>
            Compare{" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>3</sup>&frasl;<sub>4</sub>
            </span>{" "}
            and
            <span style={{ fontSize: "16pt" }}>
              <sup>5</sup>&frasl;<sub>7</sub>
            </span>
            , which fraction is larger?
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            The denominators 4 and 7 have LCM of 28
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            <span style={{ fontSize: "16pt" }}>
              <sup>3</sup>&frasl;<sub>4</sub>
            </span>{" "}
            ={" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>21</sup>&frasl;<sub>28</sub>
            </span>{" "}
            and{" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>5</sup>&frasl;<sub>7</sub>
            </span>{" "}
            ={" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>20</sup>&frasl;<sub>28</sub>
            </span>
          </Typography>
          <Typography sx={{ paddingBottom: 1 }}>
            Now that denominators are the same, we can easily compare the
            numerators. Therefore{" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>3</sup>&frasl;<sub>4</sub>
            </span>{" "}
            is larger than{" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>5</sup>&frasl;<sub>7</sub>
            </span>
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          backgroundColor: "#e9ebf6",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Addition and subtraction of fractions
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Fractions can ONLY be added or subtracted when they have the{" "}
          <b>same denominator</b>.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#babeee",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h6">
            If the fractions have <b>SAME</b> denominator
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 1, mx: 2 }}>
          Simply add or subtract the numerators without changing the denominator
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            my: 1,
            backgroundColor: "#d9e7fe",
            marginBottom: 3,
          }}
        >
          <Typography>
            <b>Example 6</b>:{" "}
            <span style={{ fontSize: "16pt", marginLeft: 10 }}>
              <sup>1</sup>&frasl;<sub>8</sub> + <sup>5</sup>&frasl;<sub>8</sub>{" "}
              = <sup>6</sup>&frasl;<sub>8</sub> = <sup>3</sup>&frasl;
              <sub>4</sub>
            </span>
          </Typography>
        </Alert>
        <Box
          sx={{
            backgroundColor: "#babeee",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h6">
            If the fractions have <b>DIFFERENT</b> denominator
          </Typography>
        </Box>
        <Typography sx={{}}>
          <ol>
            <li>Find a common denominator (preferably LCM)</li>
            <li>
              Convert each fraction to equivalent fraction with the common
              denominator
            </li>
            <li>Add/subtract numerators with the first method</li>
          </ol>
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            my: 1,
            backgroundColor: "#d9e7fe",
            marginBottom: 3,
          }}
        >
          <Typography>
            <b>Example 7</b>:{" "}
            <span style={{ fontSize: "16pt", marginLeft: 10 }}>
              <sup>2</sup>&frasl;<sub>3</sub> + <sup>5</sup>&frasl;<sub>6</sub>{" "}
              =<sup>4</sup>&frasl;<sub>6</sub> + <sup>5</sup>&frasl;<sub>6</sub>{" "}
              = <sup>9</sup>&frasl;<sub>6</sub>= <sup>3</sup>&frasl;<sub>2</sub>
            </span>
          </Typography>
        </Alert>
        <Box
          sx={{
            backgroundColor: "#babeee",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h6">
            If the fractions are <b>MIXED FRACTIONS</b>
          </Typography>
        </Box>
        <Typography sx={{}}>
          <ul>
            <li>
              Add/subtract whole numbers separately then add/subtract fractional
              parts
            </li>
            <Alert
              icon={<NewReleasesRoundedIcon fontSize="large" />}
              severity="info"
              variant="outlined"
              sx={{
                borderRadius: 4,
                marginBottom: 2,
                fontSize: "12pt",
                backgroundColor: "#d9e7fe",
                marginTop: 2,
              }}
            >
              <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
                Example 8
              </AlertTitle>
              <Typography sx={{ paddingBottom: 1, fontSize: "16pt" }}>
                1<sup>3</sup>&frasl;<sub>4</sub> + 2<sup>1</sup>&frasl;
                <sub>3</sub> - 1<sup>1</sup>&frasl;<sub>2</sub> = (1 + 2 - 1) +
                <sup>3</sup>&frasl;<sub>4</sub> + <sup>1</sup>&frasl;
                <sub>3</sub> - <sup>1</sup>&frasl;<sub>2</sub>
                <br />= 2<sup>9+4-6</sup>&frasl;<sub>12</sub>
                <br />= 2<sup>7</sup>&frasl;<sub>12</sub>
              </Typography>
            </Alert>
            <li>
              OR, convert the mixed fractions into improper fractions before
              applying the previous methods to add/subtract
              <br />
            </li>
            <Alert
              icon={<NewReleasesRoundedIcon fontSize="large" />}
              severity="info"
              variant="outlined"
              sx={{
                borderRadius: 4,
                marginBottom: 2,
                fontSize: "12pt",
                backgroundColor: "#d9e7fe",
                marginTop: 2,
              }}
            >
              <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
                Example 9
              </AlertTitle>
              <Typography sx={{ paddingBottom: 1, fontSize: "16pt" }}>
                1<sup>3</sup>&frasl;<sub>4</sub> + 2<sup>1</sup>&frasl;
                <sub>3</sub> - 1<sup>1</sup>&frasl;<sub>2</sub> =<sup>7</sup>
                &frasl;<sub>4</sub> + <sup>7</sup>&frasl;<sub>3</sub> -{" "}
                <sup>3</sup>&frasl;<sub>2</sub>
                <br />= <sup>21+28-18</sup>&frasl;<sub>12</sub>
                <br />= <sup>31</sup>&frasl;<sub>12</sub> = 2<sup>7</sup>&frasl;
                <sub>12</sub>
              </Typography>
            </Alert>
          </ul>
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#e9ebf6",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Multiplication of fractions
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          The <b>product</b> of two fractions is found by{" "}
          <b>multiplying the numerators</b> and{" "}
          <b>multiplying the denominators separately</b>.
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 2,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 10
          </AlertTitle>
          <Typography sx={{ paddingBottom: 1, fontSize: "16pt" }}>
            <sup>3</sup>&frasl;<sub>4</sub> × <sup>2</sup>&frasl;<sub>7</sub> ={" "}
            <sup>3×2</sup>&frasl;<sub>4×7</sub>= <sup>6</sup>&frasl;
            <sub>28</sub> = <sup>3</sup>&frasl;<sub>14</sub> (after
            simplification)
          </Typography>
        </Alert>
        <Typography sx={{}}>
          <ul>
            <li>
              <b>Cancellation technique</b> (advanced): fractions can be
              simplified before multiplying where you 'cancel' out numbers using
              common factors in the numerator and denominator.
            </li>
            <Alert
              icon={<NewReleasesRoundedIcon fontSize="large" />}
              severity="info"
              variant="outlined"
              sx={{
                borderRadius: 4,
                my: 1,
                backgroundColor: "#d9e7fe",
                marginBottom: 3,
              }}
            >
              <Typography>
                <b>Example 11</b>:{" "}
                <span style={{ fontSize: "16pt", marginLeft: 10 }}>
                  <sup>3</sup>&frasl;<sub>4</sub> × <sup>2</sup>&frasl;
                  <sub>7</sub> =<sup>3</sup>&frasl;<sub>2</sub> × <sup>1</sup>
                  &frasl;<sub>7</sub>= <sup>3</sup>&frasl;<sub>14</sub> (after
                  simplification)
                </span>
              </Typography>
            </Alert>
            <li>
              <b>Multiplying mixed numbers</b>: change them to improper
              fractions first. You cannot multiply whole numbers and fractions
              separately.
            </li>
            <Alert
              icon={<NewReleasesRoundedIcon fontSize="large" />}
              severity="info"
              variant="outlined"
              sx={{
                borderRadius: 4,
                my: 1,
                backgroundColor: "#d9e7fe",
                marginBottom: 3,
              }}
            >
              <Typography>
                <b>Example 12</b>:{" "}
                <span style={{ fontSize: "16pt", marginLeft: 10 }}>
                  <sup>3</sup>&frasl;<sub>5</sub> × 2<sup>1</sup>&frasl;
                  <sub>7</sub> =<sup>6</sup>&frasl;<sub>5</sub> × <sup>15</sup>
                  &frasl;<sub>7</sub>= <sup>90</sup>&frasl;<sub>35</sub> ={" "}
                  <sup>18</sup>&frasl;<sub>7</sub> (after simplification)
                </span>
              </Typography>
            </Alert>
          </ul>
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#e9ebf6",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Division of fractions
        </Typography>
        <Typography sx={{ paddingBottom: 1, mx: 2 }}>
          The <b>Reciprocal</b> of a fraction is the fraction turned upside
          down. <br />
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            my: 1,
            backgroundColor: "#d9e7fe",
            marginBottom: 3,
          }}
        >
          <Typography>
            <b>Example 13</b>:{" "}
            <span style={{ fontSize: "16pt", marginLeft: 10 }}>
              the reciprocal of <sup>5</sup>&frasl;<sub>12</sub> is{" "}
              <sup>12</sup>&frasl;<sub>5</sub>
            </span>
          </Typography>
        </Alert>
        <Typography sx={{ paddingBottom: 1, mx: 2 }}>
          To divide two fractions, keep one fraction the same, then{" "}
          <b>multiply</b> it by the <b>reciprocal</b> of the other fraction.
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            my: 1,
            backgroundColor: "#d9e7fe",
            marginBottom: 3,
          }}
        >
          <Typography>
            <b>Example 14</b>:{" "}
            <span style={{ fontSize: "16pt", marginLeft: 10 }}>
              <sup>3</sup>&frasl;<sub>4</sub> ÷ <sup>6</sup>&frasl;<sub>7</sub>{" "}
              = <sup>3</sup>&frasl;<sub>4</sub> × <sup>7</sup>&frasl;
              <sub>6</sub> = <sup>1</sup>&frasl;<sub>4</sub> × <sup>7</sup>
              &frasl;<sub>2</sub> = <sup>7</sup>&frasl;<sub>8</sub>
            </span>
          </Typography>
        </Alert>
      </Box>
    </Box>
  );
}

export default Learn10;
