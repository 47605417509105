import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../auth";

import Box from "@mui/material/Box";
import GradeOverview from "../components/GradeOverview";
import QuizResults from "./QuizResults";

function Grades({ subscription }) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [selectedPage, setSelectedPage] = useState('overview')
  const [selectedQuiz, setSelectedQuiz] = useState('')
  const [selectedAttemptId, setSelectedAttemptId] = useState('')
  const [selectedSubject, setSelectedSubject] = useState('');

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  const Selector = () => {
    switch (selectedPage) {
      case 'overview':
        return <GradeOverview user_id={user?.uid}
          setSelectedPage={setSelectedPage}
          setSelectedQuiz={setSelectedQuiz}
          setSelectedAttemptId={setSelectedAttemptId}
          setSelectedSubject={setSelectedSubject}
        />
      case 'subject_grades':
        return
      case 'quiz_results':
        return <QuizResults
          subject={selectedSubject}
          quiz={selectedQuiz}
          attemptId={selectedAttemptId}
          setSelectedPage={setSelectedPage}
          subscription={subscription}
          page={"overview"}
        />
      case 'results_list':
        return
    }
  }


  return (
    <Box sx={{ flexGrow: 1, borderColor: "black", my: 1.5, mx: 3, width: "100%" }}>
      <Selector />
    </Box>
  )
}

export default Grades