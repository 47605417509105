import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useTimer } from 'react-timer-hook';
import Lottie from "lottie-react";
import animatedClock from "../images/67-clock-outline-edited.json"

import "../style/Break.css"

function BreakPage() {
  const params = useParams()
  const navigate = useNavigate();

  function getTimer() {
    const length = localStorage.getItem("break_length")
    if (localStorage.getItem("break_timer") === null) {
      const time = new Date()
      time.setSeconds(time.getSeconds() + (length * 60))
      localStorage.setItem("break_timer", time)
      return time
    } else {
      return Date.parse(localStorage.getItem("break_timer"))
    }
  }

  function goNext() {
    const next = localStorage.getItem("next")
    let path = `/Quizzes/${params.mock}/${params.subject}/${next}/Questions`
    if (params.subject === 'writing') {
      path = `/Writing/${params.mock}/${next}`
    }
    localStorage.setItem("in_quiz", 0)
    localStorage.setItem("start_timer", 0)
    localStorage.setItem("timer_active", true)
    navigate(path)
  }

  const getMessage = (subject) => {
    let sub = subject.toUpperCase()
    switch (sub) {
      case "MATHS":
        return "Mathematical Reasoning"
      case "THINKING":
        return "Thinking Skills"
      case "WRITING":
        return "Writing"
    }
  }

  const Timer = ({ expiryTimestamp }) => {
    const {
      seconds,
      minutes,
      /*
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,*/
    } = useTimer({ expiryTimestamp, onExpire: () => goNext() });

    return (
      <Box sx={{
        display: 'flex', justifyContent: 'center', alignItems: 'center'
      }}>
        <div>
          <Stack direction={'row'} spacing={7}>
            <Box sx={{ width: '150px' }}>
              <Lottie animationData={animatedClock} loop={true} />
            </Box>
            <div style={{ fontSize: '100px', color: "#e3e3e3" }}>
              <span>{minutes}</span>:<span>{seconds}</span>
            </div>
          </Stack>
        </div>
      </Box>
    )
  }

  useEffect(() => {
    localStorage.setItem("curr_mock", params.mock);
    localStorage.setItem("curr_subject", params.subject);
  }, [])

  return (
    <div style={{ backgroundColor: "#253393", width: '100vw', height: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Box className='main_box' sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack spacing={10}>
            <Typography variant="h2" color={"#e3e3e3"}>Mock Exam Break</Typography>
            <Timer expiryTimestamp={getTimer()} />
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h5" color={"#e3e3e3"}>The next exam is {getMessage(params.subject.toUpperCase())}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Button variant="contained" color='secondary' sx={{ width: '8rem', borderRadius: 10 }} onClick={() => goNext()}>
                <Typography variant="h6" color={"#e3e3e3"}>Skip</Typography>
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
    </div >
  )
}

export default BreakPage