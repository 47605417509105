import React, { useState } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import { ReactComponent as SmallLogoSVG } from "../images/logo-small.svg";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 3
};

const MockOverviewInfo = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ position: "absolute", display: "flex", top: "6px" }}>
      <Alert
        severity="info"
        onClick={handleOpen}
        sx={{ borderRadius: 99, ":hover": { boxShadow: 4, cursor: "pointer" }, py: 0 }}
      >
        What's this?
      </Alert>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              <Box sx={{ display: "inline", mr: 1 }}><SmallLogoSVG width={"1.5rem"} /></Box> Performance Overview
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This performance report shows how the student performed compared to all students who
            completed the Mock exam. <b>It closely mimics the official Selective Test performance report.</b>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            If <b>"15% of students who sat the test"</b> is highlighted for reading,
            it means the student performed in the 75<sup>th</sup> - 90<sup>th</sup> percentile.
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
}

export default MockOverviewInfo