import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { getPrivacy, getTermsConditions } from '../app_backend';

const Policies = () => {
  const [open, setOpen] = useState(false);
  const [policy, setPolicy] = useState('');
  const [pdf, setPdf] = useState(null);

  const handleOpen = async (policy) => {
    if (policy === 'privacy') {
      const url = await getPrivacy();
      console.log(url);
      setPdf(url);
    } else if (policy === 'terms_conditions') {
      const url = await getTermsConditions();
      setPdf(url);
    }
    setPolicy(policy);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', gap: 3, fontSize: '1.1em', mb: '30px' }}>
      <Link component='button' onClick={() => handleOpen('privacy')}>
        Privacy Policy
      </Link>
      <Link component='button' onClick={() => handleOpen('terms_conditions')}>
        Terms & Conditions
      </Link>
      <Dialog fullScreen open={open} onClose={handleClose}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {policy === 'terms' ? 'Terms & Conditions' : 'Privacy Policy'}
            </Typography>
            <IconButton
              edge='start'
              color='inherit'
              onClick={handleClose}
              aria-label='close'
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {pdf !== null && (
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
            <Viewer fileUrl={pdf} />;
          </Worker>
        )}
      </Dialog>
    </Box>
  );
};

export default Policies;
