import React, { useRef, useState, useEffect } from "react";

import { A_grade, B_grade, C_grade, D_grade, getTopicGrades, grade_colors, S_grade } from "../app_backend";

import Chart, { Chart as ChartJS } from 'chart.js/auto'
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2'
import LoadingScreen from './LoadingScreen'

function median(arr) {
  if (arr.length == 0) {
    return; // 0.
  }
  arr.sort((a, b) => a - b); // 1.
  const midpoint = Math.floor(arr.length / 2); // 2.
  const median = arr.length % 2 === 1 ?
    arr[midpoint] : // 3.1. If odd length, just take midpoint
    (arr[midpoint - 1] + arr[midpoint]) / 2; // 3.2. If even length, take median of midpoints
  return median;
}

function GradeComponent({ user_id, subject, topic }) {
  const [grades, setGrades] = useState([])
  const [timeseries, setTimeseries] = useState(true)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (user_id) {
      setLoading(true)
      fetchGradeData()
    }
  }, [subject, topic]);


  async function fetchGradeData() {
    if (subject !== "" && topic !== "") {
      const scoresArray = await getTopicGrades(user_id, subject, topic)
      let mergeGrades = new Map()

      //converting list of scores for each quiz to list of scores for each datetime
      for (const quiz in scoresArray) {
        scoresArray[quiz].forEach((value, key) => {
          if (mergeGrades.has(key)) {
            let existing_grades = mergeGrades.get(key)
            existing_grades = existing_grades.concat(value)
            mergeGrades.set(key, existing_grades)
          } else {
            mergeGrades.set(key, value)
          }
        })
      }
      let data = []
      if (mergeGrades.size < 2) {
        setTimeseries(false)
        let i = 1
        mergeGrades.forEach((value, key) => {
          value.forEach(v => {
            data.push({
              index: i,
              x: `Attempt ${i}`,
              y: v
            })
            i++;
          })
        })
        data.sort((grade1, grade2) => {
          return ((grade1.index < grade2.index) ? -1 : (grade1.index > grade2.index) ? 1 : 0)
        })
      } else {
        setTimeseries(true)
        mergeGrades.forEach((value, key) => {
          data.push({
            x: key,
            y: median(value)
          })
        })
        data.sort((grade1, grade2) => {
          return ((grade1.x < grade2.x) ? -1 : (grade1.x > grade2.x) ? 1 : 0)
        })
      }
      setGrades(data)
      setLoading(false)
    }
  }

  if (subject === "" || topic === "") {
    return
  }

  let x = {
    type: 'time',
    time: {
      unit: 'day'
    },

    grid: {
      display: false,
    },
    ticks: {
      color: '#babddb',
    },
  }

  if (!timeseries) {
    x = {
      type: 'category',
      grid: {
        display: false,
      },
      ticks: {
        color: '#babddb',
      },
    }
  }

  return (
    <React.Fragment>
      {loading ? <LoadingScreen text={"Loading..."} size={"small"} /> :
        <Line
          options={{
            scales: {
              x: x,
              y: {
                min: -0.02,
                max: 1,
                ticks: {
                  stepSize: 0.01,
                  callback: (value) => {
                    if (value === S_grade) {
                      return 'S'
                    } else if (value === A_grade) {
                      return 'A'
                    } else if (value === B_grade) {
                      return 'B'
                    } else if (value === C_grade) {
                      return 'C'
                    } else if (value === D_grade) {
                      return 'D'
                    } else if (value === 1) {
                      return 100
                    }
                  },
                  textStrokeColor: [
                    '#21c0a3',
                    '#32b1c2',
                    '#4d7ee9',
                    '#7f59ee',
                    '#f9c42f',
                    '#babddb'
                  ],
                  textStrokeWidth: 1,
                  color: [
                    '#21c0a3',
                    '#32b1c2',
                    '#4d7ee9',
                    '#7f59ee',
                    '#f9c42f',
                    '#babddb'
                  ],
                  padding: 10
                },
                grid: {
                  drawTicks: false,
                  color: '#e5eafb',
                  lineWidth: 2
                }
              }
            }, plugins: {
              legend: false
            }
          }}
          width={"100%"}
          height={"70vh"}
          data={{
            datasets: [{
              data: grades,
              backgroundColor: 'transparent',
              borderColor: '#f26c6d',
              pointBorderColor: 'transparent',
              pointBorderWidth: 4,
              tension: 0.05,
              borderWidth: 6,
              pointRadius: 10
            }]
          }}
        />
      }
    </React.Fragment>
  )
}

export default GradeComponent