import React, { useEffect, useState } from "react";
import { GetTopic, GetUserRanks, GetWritingQuestion } from "../app_backend";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Box, Paper, Stack, Button, Typography, Modal } from "@mui/material";
import "../style/MarkingInterface.css";

import { JSONToHTML } from "html-to-json-parser";

import { focusComment, scrollIfNotInView } from "../utils";

import SkillBar from "react-skillbars";

import HTML2json from "html2json";
import StringToHTML from "../components/StringToHTML";
var json2HTML = HTML2json.json2html;

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#e2e5f3",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
};

const barColor = {
  bar: "#44B0FF",
  title: {
    text: "#fff",
    background: "#2d8bc8",
  },
};

function WritingReview({ markingObject, setSelectedPage, page, showStats }) {
  const [question, setQuestion] = useState("");
  const [responseHTML, setResponseHTML] = useState(null);
  const [rankData, setRankData] = useState(null);
  const [topic, setTopic] = useState(null);

  useEffect(() => {
    fetchMarkingPaper();
  }, []);

  // question modal
  const [openQuestionModal, setOpenQuestionModal] = useState(false);

  const handleOpenQuestionModal = () => setOpenQuestionModal(true);
  const handleCloseQuestionModal = () => {
    setOpenQuestionModal(false);
  };

  // marks modal
  const [openMarksModal, setOpenMarksModal] = useState(false);

  const handleOpenMarksModal = () => setOpenMarksModal(true);
  const handleCloseMarksModal = () => {
    setOpenMarksModal(false);
  };

  // set response html
  useEffect(() => {
    let responseElement = document.getElementById("response");
    if (responseElement) {
      responseElement.innerHTML = responseHTML;
      // give new lines
      const paragraphs = responseElement.querySelectorAll("p");

      paragraphs.forEach((p) => {
        if (p.innerHTML.trim() === "") {
          p.classList.add("newline");
        }
      });
      // attach onclick
      if (markingObject.commentList) {
        markingObject.commentList.forEach((commentObj) => {
          const spans = document.getElementsByClassName(commentObj.className);
          for (let i = 0; i < spans.length; i++) {
            let span = spans[i];
            span.onclick = function () {
              focusComment(commentObj.className);
            };
          }
        });
      }
    }
  }, [responseHTML]);

  const fetchMarkingPaper = async () => {
    // set question
    const questionObj = await GetWritingQuestion(markingObject.quiz_id);
    setQuestion(json2HTML(questionObj.question));

    // parse html to set response
    let responseHTML = await JSONToHTML(markingObject.responseJSON);
    responseHTML.replace(/<p><\/p>/g, '<p class="empty-paragraph"></p>');
    setResponseHTML(responseHTML);

    if (showStats) {
      const rankData = await GetUserRanks(
        "writing",
        markingObject.quiz_id,
        totalMark
      );
      setRankData(rankData);

      const t = await GetTopic("writing", markingObject.quiz_id);
      setTopic(t);
    }
  };

  // marking scores

  const scores = [
    { type: "Content", level: Math.round((markingObject.criteria1 / 9) * 100) },
    {
      type: "Techniques",
      level: Math.round((markingObject.criteria2 / 8) * 100),
    },
    {
      type: "Punctuation",
      level: Math.round((markingObject.criteria3 / 2) * 100),
    },
    {
      type: "Spelling",
      level: Math.round((markingObject.criteria4 / 2) * 100),
    },
    { type: "Grammar", level: Math.round((markingObject.criteria5 / 2) * 100) },
    {
      type: "Vocabulary",
      level: Math.round((markingObject.criteria6 / 2) * 100),
    },
  ];

  const totalMark =
    markingObject.criteria1 +
    markingObject.criteria2 +
    markingObject.criteria3 +
    markingObject.criteria4 +
    markingObject.criteria5 +
    markingObject.criteria6;

  const totalScore = [
    {
      type: "Total",
      level: Math.round((totalMark / 25) * 100),
      color: {
        bar: "#25CA00",
        title: { background: "#1E9900" },
      },
    },
  ];

  return (
    <Box
      id="writing-review"
      sx={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          my: 3,
        }}
      >
        <Button
          id="show-question-btn"
          variant="contained"
          disableElevation
          sx={{
            borderRadius: 99,
          }}
          onClick={() => handleOpenQuestionModal()}
        >
          <Typography variant="h6" sx={{ textTransform: "None" }}>
            Show Question
          </Typography>
        </Button>
        <Button
          variant="contained"
          disableElevation
          sx={{
            borderRadius: 99,
          }}
          onClick={() => handleOpenMarksModal()}
        >
          <Typography variant="h6" sx={{ textTransform: "None" }}>
            Show Marks
          </Typography>
        </Button>
        {/* Back button is not rendered if accessed from notification. */}
        {setSelectedPage ? (
          <Button
            variant="outlined"
            sx={{ height: "3rem", borderRadius: 99 }}
            onClick={() => setSelectedPage(page)}
          >
            <Typography color="primary" sx={{ pr: 1 }}>
              Back
            </Typography>
            <ExitToAppIcon color="primary" />
          </Button>
        ) : null}
      </Box>
      <Box sx={{ flexGrow: 1, pb: "2em" }}>
        <Stack
          sx={{ width: "100%", height: "100%" }}
          direction="row"
          spacing={3}
        >
          <Stack
            sx={{ width: "66%", height: "100%" }}
            direction="column"
            spacing={4}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Paper
                elevation={0}
                sx={{
                  borderRadius: 4,
                  width: "100%",
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    p: 3,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h5"
                    color="primary"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: 2,
                      width: "100%",
                      borderBottom: 1,
                    }}
                  >
                    Your Response
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                      overflow: "auto",
                      height: 0,
                      scrollbarGutter: "stable",
                      wordWrap: "break-word",
                      maxWidth: 860,
                    }}
                    id="scrollable-response"
                  >
                    <Typography
                      id="response"
                      sx={{ wordWrap: "break-word" }}
                      component={"span"}
                    ></Typography>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Stack>
          <Box sx={{ width: "33%", height: "100%" }}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: 4,
                width: "100%",
                height: "100%",
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                }}
              >
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                    width: "100%",
                    borderBottom: 1,
                  }}
                >
                  Marker's Comments
                </Typography>
                {markingObject.commentList ? (
                  <Stack
                    direction="column"
                    alignItems="center"
                    sx={{
                      py: 2,
                      pl: 2,
                      height: 0,
                      overflow: "auto",
                      flexGrow: 1,
                      borderBottom: 1,
                      scrollbarGutter: "stable",
                    }}
                    spacing={2}
                    id="comment-list"
                  >
                    {markingObject.commentList.map((commentObj) => (
                      <Paper
                        key={"key" + commentObj.className}
                        className={"box" + commentObj.className}
                        elevation={2}
                        sx={{
                          width: "90%",
                          minHeight: "7rem",
                          backgroundColor: "lightyellow",
                          p: 2,
                        }}
                      >
                        <Typography sx={{ wordWrap: "break-word" }}>
                          {commentObj.comment}
                        </Typography>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            className="show-comment-btn"
                            sx={{
                              borderRadius: 8,
                              mt: 1,
                            }}
                            onClick={() => {
                              // scroll into view
                              scrollIfNotInView(
                                `.${commentObj.className}`,
                                "#scrollable-response"
                              );
                              // focus comment
                              focusComment(commentObj.className);
                            }}
                          >
                            Show
                          </Button>
                        </Box>
                      </Paper>
                    ))}
                  </Stack>
                ) : null}
                <Box
                  sx={{
                    width: "100%",
                    height: "10rem",
                    overflow: "auto",
                    maxWidth: 450,
                  }}
                >
                  <Typography variant="h6" color="primary" sx={{ py: 1 }}>
                    General Feedback:
                  </Typography>
                  <Box>
                    <Typography
                      sx={{ overflow: "auto", wordWrap: "break-word" }}
                    >
                      {markingObject.feedback}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Stack>
      </Box>
      {/* Modal for showing question */}
      <Modal
        open={openQuestionModal}
        onClose={handleCloseQuestionModal}
        hideBackdrop={false}
        sx={{ width: "100vw" }}
      >
        <Box sx={modalStyle}>
          <Paper sx={{ borderRadius: 4, p: 3 }} elevation={0}>
            <Typography
              variant="h5"
              color="primary"
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
                width: "100%",
                borderBottom: 1,
              }}
            >
              Question
            </Typography>
            <Box className="question-container">
              <StringToHTML string={question} />
            </Box>
          </Paper>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button
              sx={{ borderRadius: 6 }}
              variant="contained"
              onClick={() => {
                handleCloseQuestionModal();
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Modal for showing marks */}
      <Modal
        open={openMarksModal}
        onClose={handleCloseMarksModal}
        hideBackdrop={false}
        sx={{ width: "100vw" }}
      >
        <Box sx={modalStyle}>
          <Paper sx={{ borderRadius: 4, p: 3, width: "60em" }} elevation={0}>
            <Typography
              variant="h5"
              color="primary"
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: 2,
                width: "100%",
                borderBottom: 1,
              }}
            >
              Marking Score
            </Typography>
            <SkillBar
              skills={scores}
              colors={barColor}
              height={`5vh`}
              symbolColor={"black"}
              animationDelay={100}
              animationDuration={1000}
            />
            <Typography variant="h6" color="primary" sx={{ mb: 1 }}>
              Total Score: {totalMark}/25
            </Typography>
            <SkillBar
              skills={totalScore}
              height={`5vh`}
              symbolColor={"black"}
              animationDelay={1000}
              animationDuration={1000}
            />
            {topic === "MOCK" && showStats ? (
              <Box
                sx={{
                  p: 3,
                  display: "flex",
                  flexDirection: "row",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 800 }}>
                    Median
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ color: "#1f2f98", fontSize: "14pt" }}
                  >
                    {rankData?.median}{" "}
                    <span style={{ color: "#babcd6" }}>/</span> 25
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 800 }}>
                    Rank
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ color: "#1f2f98", fontSize: "14pt" }}
                  >
                    {rankData?.rank} of {rankData?.total + 1}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h5" sx={{ fontWeight: 800 }}>
                    Percentile
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={{ color: "#1f2f98", fontSize: "14pt" }}
                  >
                    {rankData?.percentile}
                    <span style={{ fontSize: "10pt" }}>th</span>
                  </Typography>
                </Box>
              </Box>
            ) : null}
          </Paper>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
            <Button
              sx={{ borderRadius: 6 }}
              variant="contained"
              onClick={() => {
                handleCloseMarksModal();
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default WritingReview;
