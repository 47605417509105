import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import Quill from 'quill';

const quillModules = {
  toolbar: [['bold', 'italic', 'underline', 'strike']],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

// ResponseEditor is ref Object
const ResponseEditor = forwardRef(({ onTextChange, placeholderText }, ref) => {
  const containerRef = useRef(null);
  const onTextChangeRef = useRef(onTextChange);

  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange;
  });

  useEffect(() => {
    const container = containerRef.current;
    const editorContainer = container.appendChild(
      container.ownerDocument.createElement('div')
    );

    const quill = new Quill(editorContainer, {
      modules: quillModules,
      placeholder: placeholderText,
      theme: 'snow',
    });

    ref.current = quill;

    quill.on(Quill.events.TEXT_CHANGE, (...args) => {
      onTextChangeRef.current?.(...args);
    });

    // cleanup
    return () => {
      ref.current = null;
      container.innerHTML = '';
    };
  }, [ref]);

  return <div ref={containerRef} style={{ width: '100%' }}></div>;
});

export default ResponseEditor;
