import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, LogoutUser } from '../auth';

import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { BsFillBellFill } from 'react-icons/bs';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';

import { fetchNotifications, updateSeenNotification } from '../app_backend';

export default function NotificationDrop({
  setSelectedPage,
  setMarkingObject,
  notificationCount,
  setNotificationCount,
}) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [opened, setOpened] = useState(false);
  const [notificationList, setNotificationList] = useState([]);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
  }, [user, loading]);

  useEffect(() => {
    if (user.uid && !loading) {
      fetchNotif();
    }
  }, [opened]);

  const fetchNotif = async () => {
    let notifList = await fetchNotifications(user?.uid);
    setNotificationList(notifList);
    let count = 0;
    for (let notif of notifList) {
      if (!notif.hasSeen) {
        count++;
      }
    }
    setNotificationCount(count);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (opened) {
      setOpened(false);
    } else {
      setOpened(true);
    }
  };
  const handleClose = async () => {
    setAnchorEl(null);
    for (let notif of notificationList) {
      if (!notif.hasSeen && !notif.isWriting) {
        await updateSeenNotification(user?.uid, notif.notification_id);
      }
    }
  };

  const removeNotification = async (notificationId) => {
    // await deleteNotification(user?.uid, notificationId);
  };

  // NOTIFICATION STRUCTURE

  /*
  notificationObject: {
    title: String,
    message: String,
    from: uid,
    to: [uids]
    isWriting: boolean,
    writingObject: Object
    hasSeen: boolean
    date: dateString
  }

  */

  // NOTIFICATION BEHAVIOUR

  /*
    message/announcement notifications: 
    - clicking does nothing
    - closing the menu turns hasSeen to true
    - number of non-hasSeen notifications is displayed on the bell

    marking notifications:
    - clicking redirects to writing review
    - only clicking turns hasSeen to true
    - number of non-hasSeen notifications is displayed on the bell
  */

  return (
    <React.Fragment>
      <Box>
        <Tooltip title='Notifications'>
          <IconButton
            onClick={handleClick}
            size='large'
            sx={{
              ml: 2,
              mt: 1.5,
              border: 2,
              borderRadius: 3,
              borderColor: '#2E41BC',
              p: 0.8,
            }}
            aria-controls={open ? 'notification-list' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge badgeContent={notificationCount} color='warning'>
              <NotificationsNoneIcon color='action' sx={{ color: '#ffffff' }} />
            </Badge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id='notification-list'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            maxHeight: '40rem',
            minWidth: '23rem',
            overflow: 'auto',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {notificationList.length === 0 && (
          <MenuItem>You have no notifications.</MenuItem>
        )}
        {notificationList.length !== 0 &&
          notificationList.map((notificationObj) => {
            let bgColour = 'white';
            if (!notificationObj.hasSeen) {
              bgColour = '#e1e2ff';
            }
            if (notificationObj.isWriting) {
              let writingObject = notificationObj.writingObject;
              return (
                <MenuItem
                  key={'notification' + `${writingObject.attempt_id}`}
                  sx={{ backgroundColor: bgColour, whiteSpace: 'normal' }}
                  onClick={async () => {
                    setSelectedPage('Writing Review');
                    setMarkingObject({
                      attempt_id: writingObject.attempt_id,
                      quiz_id: writingObject.quiz_id,
                    });
                    // await removeNotification(notificationObj.notification_id);
                    if (!notificationObj.hasSeen) {
                      setNotificationCount(notificationCount - 1);
                    }
                    updateSeenNotification(
                      user?.uid,
                      notificationObj.notification_id
                    );
                  }}
                >
                  <Card
                    elevation={0}
                    overflow='auto'
                    sx={{
                      borderRadius: 4,
                      backgroundColor: '#d9d6ff',
                      py: 1,
                      px: 2,
                      border: 1,
                      borderColor: '#f7f9fc',
                      width: '100%',
                      maxWidth: '23rem',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14pt',
                          fontweight: 600,
                          color: '#7268ff',
                          borderRadius: 3,
                          px: 2,
                          border: 1,
                          borderColor: '#7268ff',
                        }}
                      >
                        <b>{notificationObj.title}</b>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '12pt',
                          color: '#000037',
                          wordBreak: 'break-word',
                        }}
                      >
                        Your <b>"{writingObject.quiz_id}"</b> quiz has been
                        marked. <u>CLICK HERE</u> to review.
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography sx={{ color: '#4e79f5' }}>
                        by {writingObject.markedBy}
                      </Typography>
                      <Typography color={'primary'} sx={{ fontWeight: 600 }}>
                        {writingObject.markedOn}
                      </Typography>
                    </Box>
                  </Card>
                </MenuItem>
              );
            } else {
              /* notification object is not a writing one 
                  stuff to work on:
                  change unseen to somehow show that it is unseen
                  set max height to notifications
                  handle onclose to change seen
              */
              return (
                <MenuItem
                  key={'notification' + `${notificationObj.notification_id}`}
                  sx={{ backgroundColor: bgColour, whiteSpace: 'normal' }}
                >
                  <Card
                    elevation={0}
                    sx={{
                      borderRadius: 4,
                      backgroundColor: '#e5eafb',
                      py: 1,
                      px: 2,
                      border: 1,
                      borderColor: '#f7f9fc',
                      width: '100%',
                      maxWidth: '23rem',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '14pt',
                          fontweight: 600,
                          color: '#3e6cf2',
                          borderRadius: 3,
                          px: 2,
                          border: 1,
                          borderColor: '#4e79f5',
                        }}
                      >
                        <b>{notificationObj.title}</b>
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant='body1'
                        sx={{
                          fontSize: '12pt',
                          color: '#000037',
                          wordBreak: 'break-word',
                        }}
                      >
                        {notificationObj.message}
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                      <Typography sx={{ color: '#4e79f5' }}>
                        by PassPapers
                      </Typography>
                      <Typography color={'primary'} sx={{ fontWeight: 600 }}>
                        {notificationObj.date}
                      </Typography>
                    </Box>
                  </Card>
                </MenuItem>
              );
            }
          })}
      </Menu>
    </React.Fragment>
  );
}
