import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";

function Learn7() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography variant="h4" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          What is algebra?
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          Algebra is just like a puzzle where you try solving equations using
          unknown variables such as x.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          What is a variable?
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Variables act just like normal numbers. The operations done on numbers
          are the same as the operations done on unknown variables.
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          The number right in front of the variable shows how many times it is
          multiplied.
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          2x means 2 multiply by x<br />
          8x means 8 multiply by x
        </Typography>
        <Alert
          icon={<TipsAndUpdatesRoundedIcon fontSize="inherit" />}
          severity="warning"
          variant="filled"
          sx={{ borderRadius: 4, marginBottom: 3 }}
        >
          Tip: imagine replacing the letter 𝑥 with the number 3 in the equation.
          This is called <b>substitution</b>.
        </Alert>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 3,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 1
          </AlertTitle>
          <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
            2𝑥 + 𝑥 = 3𝑥
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            Just like normal addition 2 + 1 = 3
          </Typography>
          <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
            3𝑥 - 𝑥 = 2𝑥
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            Just like normal subtraction 3 - 1 = 2
          </Typography>
          <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
            2𝑥 × 3 = 6𝑥
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            Just like normal multiplication 2 × 3 = 6
          </Typography>
          <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
            6𝑥 ÷ 3 = 2𝑥
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            Just like normal division 6 ÷ 3 = 2
          </Typography>
        </Alert>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 3,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 2
          </AlertTitle>
          <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
            𝑥 + 6 = 9
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            We need to try to solve for 𝑥, which means that we have to try
            figure out what the value of 𝑥 should be in order to make this
            equation true.
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            The only number possible for the equation to be true is when 𝑥 = 3,
            since 3 + 6 = 9. Therefore, 𝑥 = 3 is the answer.
          </Typography>
        </Alert>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 3,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 3
          </AlertTitle>
          <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
            2𝑥 + 14 = 16
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            This time the variable 𝑥 is multiplied by 2. So, the equation can be
            written as 2 × 𝑥 + 14 = 16
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            We want to <b>subject</b> 𝑥, which means we must find a way to move
            everything <b>except</b> 𝑥 from one side.
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            We do this by first subtracting 14 from both sides. Followed bu
            dividing both sides by 2, which allows us to successfully{" "}
            <b>subject</b> 𝑥.
          </Typography>
          <Typography sx={{ paddingBottom: 2 }}>
            2𝑥 + 14 - 14 = 16 = 14
            <br />
            2𝑥 = 16 - 14
            <br />
            2𝑥 = 2<br />
            𝑥 = 1
          </Typography>
        </Alert>
      </Box>
    </Box>
  );
}

export default Learn7;
