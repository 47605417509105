import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { auth, RegisterWithEmailAndPassword } from '../auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import {
  Stack,
  Box,
  Input,
  InputAdornment,
  Button,
  Typography,
  CloseIcon,
  IconButton,
  Collapse,
  Alert,
  Link,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import Policies from '../components/Policies';
import LoadingComponent from '../components/LoadingScreen';
import { ReactComponent as LogoSVG } from '../images/logo-full.svg';
import { ReactComponent as ImageSVG } from '../images/login-icon.svg';

import '../style/Auth.css';

// Customised MUI
const InputBox = styled(Input)(({ theme }) => ({
  width: '100%',
  padding: '7px',
  paddingLeft: '20px',
  paddingRight: '20px',
  height: '3em',
  marginTop: '15px',
  borderRadius: '30px',
  border: 'none',
  fontSize: '1em',
  fontFamily: 'Montserrat, sans-serif',
  backgroundColor: 'rgb(229, 229, 229)',
  '& fieldset': { border: 'none' },
}));

const StyledLearnButton = styled(Button)(({ theme }) => ({
  width: '10em',
  height: '3em',
  marginTop: '35px',
  marginBottom: '20px',
  borderRadius: '30px',
  fontSize: '1.1em',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
  textTransform: 'none',
  color: '#27313a',
  backgroundColor: 'white',
  transition: '0.3s',
  '&:hover': {
    backgroundColor: 'white',
    marginTop: '25px',
    boxShadow: '0 6px 4px -4px gray',
  },
}));

const StyledRegisterButton = styled(Button)(({ theme }) => ({
  marginBottom: '40px',
  marginTop: '5px',
  borderRadius: '30px',
  height: '2.5em',
  fontSize: '1em',
  fontFamily: 'Montserrat, sans-serif',
  textTransform: 'none',
  backgroundColor: '#4285f4',
}));

//https://blog.logrocket.com/user-authentication-firebase-react-apps/

function Register() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      return;
    }
  }, [loading]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');

  const [disableRegister, setDisableRegister] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navLanding = () => {
    window.location.assign('https://passpapers.com.au/');
  };

  const isValidNumber = () => {
    var regex = /^\(?(\d{2})\)?[\s-]?\d{4}[\s-]?\d{4}$/;
    return regex.test(number);
  };

  const handleRegister = async () => {
    if (
      name.trim() === '' ||
      email.trim() === '' ||
      number.trim() === '' ||
      password === ''
    ) {
      setErrorMsg('Please Enter All Registration Details');
      setShowError(true);
      return;
    } else if (!isValidNumber()) {
      setErrorMsg('Please Use A Valid Phone Number');
      setShowError(true);
      return;
    } else {
      try {
        setDisableRegister(true);
        await RegisterWithEmailAndPassword(name, email, password, number);
        setDisableRegister(false);
      } catch (err) {
        if (err.code === 'auth/invalid-password') {
          setErrorMsg('Invalid Password');
        } else if (err.code === 'auth/invalid-email') {
          setErrorMsg('Invalid Email Provided');
        } else if (err.code === 'auth/email-already-in-use') {
          setErrorMsg('An account already exists with this email');
        } else if (err.code === 'auth/weak-password') {
          setErrorMsg('Length of password must be at least 6 characters');
        } else {
          setErrorMsg(err.code);
        }
        setShowError(true);
        setDisableRegister(false);
        return;
      }

      // Successfully registered.
      navigate('/', { state: { fromRegister: true } });
    }
  };

  return (
    <div class='auth'>
      <Stack direction='row' sx={{ height: '100%' }}>
        <Box
          className='auth__left_container'
          sx={{ width: '58%', minHeight: '100vh' }}
        >
          <Stack textAlign='center' alignItems='center' sx={{ mt: 8 }}>
            <Typography
              className='auth__left_container_textBox'
              sx={{
                fontSize: '2.1em',
                fontWeight: 600,
                lineHeight: 1.4,
                fontFamily: 'Montserrat, sans-serif',
              }}
              color='#27313a'
            >
              The best website for preparing online examinations.
            </Typography>
            <Box sx={{ width: '100%', mt: 5 }}>
              <ImageSVG className='auth__left_container_img' />
            </Box>
            <StyledLearnButton onClick={() => navLanding()}>
              Learn More
            </StyledLearnButton>
          </Stack>
        </Box>
        <Box
          className='auth__right_container'
          sx={{ flexGrow: 1, minHeight: '100vh', minWidth: '360px' }}
        >
          <Stack alignItems='center' sx={{ mt: 7 }}>
            <LogoSVG className='auth__logo' />
            <Box
              className='auth__right_container_title'
              sx={{ textAlign: 'center', fontWeight: 700, mt: 5 }}
            >
              Register your Account
            </Box>
            <Box className='auth__textBox'>
              {showError && (
                <Typography
                  fullWidth
                  textAlign='center'
                  sx={{
                    height: '2em',
                    fontSize: '0.9em',
                    color: 'red',
                    fontFamily: 'Montserrat, sans-serif',
                    backgroundColor: '#ffb9b9',
                    borderRadius: '20px',
                    pt: '3px',
                  }}
                >
                  {errorMsg}
                </Typography>
              )}
            </Box>
            <Box className='auth__textBox'>
              Full Name
              <InputBox
                type='text'
                className='auth_inputBox'
                value={name}
                onChange={(e) => setName(e.target.value)}
                disableUnderline
              />
            </Box>
            <Box className='auth__textBox'>
              Email Address
              <InputBox
                type='text'
                className='auth_inputBox'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disableUnderline
              />
            </Box>
            <Box className='auth__textBox'>
              Phone Number
              <InputBox
                type='text'
                className='auth_inputBox'
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                disableUnderline
              />
            </Box>
            <Box className='auth__textBox'>
              Password
              <InputBox
                type={showPassword ? 'text' : 'password'}
                className='auth_inputBox'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disableUnderline
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Box className='auth__textBox'>
              <Typography
                sx={{ fontSize: '0.8em', fontFamily: 'Montserrat, sans-serif' }}
              >
                By creating an account, you understand and agree with the{' '}
                <b>Terms & Conditions</b> and <b>Privacy Policy*</b>.
              </Typography>
            </Box>
            <Box className='auth__textBox'>
              <StyledRegisterButton
                fullWidth
                variant='contained'
                onClick={() => handleRegister()}
                disabled={disableRegister}
              >
                {disableRegister ? (
                  <CircularProgress size='1.4em' />
                ) : (
                  <Box>Create Account</Box>
                )}
              </StyledRegisterButton>
            </Box>
            <Box
              className='auth__textBox auth__policy_text'
              sx={{ textAlign: 'center' }}
            >
              Already have an account?{' '}
              <Link component='button' onClick={() => navigate('/')}>
                Login here.
              </Link>
            </Box>
            <Policies />
          </Stack>
        </Box>
      </Stack>
    </div>
  );
}

export default Register;
