import {
  createCheckoutSession,
  getCurrentUserPayments,
  getCurrentUserSubscriptions,
  getPrices,
  getProducts,
  getStripePayments,
  onCurrentUserSubscriptionUpdate,
} from '@stripe/firestore-stripe-payments';
import {
  fetchAuthenticationLevel,
  fetchRegisterDate,
  getProductDays,
  getProductName,
} from './app_backend';
import { firebaseConfig, portalCloudFunctionTrigger } from './Config';
import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallableFromURL } from 'firebase/functions';

const DEMO_SUBSCRIPTION = -1;
const EXPIRED_SUBSCRIPTION = 0;
const VALID_SUBSCRIPTION = 1;

const customerPortalLink =
  'https://billing.stripe.com/p/login/test_28o9Cg6MxdAa0EM5kk';

//contains functions that interact with the firestore database
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const app = initializeApp(firebaseConfig);

const payments = getStripePayments(app, {
  productsCollection: 'products',
  customersCollection: 'users',
});

async function getStoreObj() {
  let plans = {};
  let mTokens = {};
  const products = await getProducts(payments, { includePrices: true });
  products.forEach((prod) => {
    if (!prod.active) return;
    // console.log(prod)
    let activePriceIndex = 0;
    if (prod.prices.length > 1) {
      for (const i in prod.prices) {
        if (prod.prices[i].description) {
          activePriceIndex = i;
          break;
        }
      }
    }

    if (prod.prices[activePriceIndex].interval) {
      plans[prod.name] = {
        priceId: prod.prices[activePriceIndex].id,
        priceAmount: prod.prices[activePriceIndex].unit_amount,
        interval: prod.prices[activePriceIndex].interval,
        intervalCount: prod.prices[activePriceIndex].interval_count,
        discount: prod.metadata.discount,
      };
    } else {
      mTokens[prod.name] = {
        priceId: prod.prices[activePriceIndex].id,
        priceAmount: prod.prices[activePriceIndex].unit_amount,
        discount: prod.metadata.discount,
      };
    }
  });
  return {
    plans,
    mTokens,
  };
}

async function getPriceIds() {
  const products = await getProducts(payments, { includePrices: false });
  const data = Promise.all(
    products.map(async (product) => {
      const price = await getPrices(payments, product.id);
      return { [product.name]: price[0].id };
    })
  );
  return data;
}

async function sendToCheckout(priceId, isPlan) {
  let session = null;
  if (isPlan) {
    session = await createCheckoutSession(payments, {
      price: priceId,
      mode: 'subscription',
      allow_promotion_codes: true,
    });
  } else {
    session = await createCheckoutSession(payments, {
      price: priceId,
      mode: 'payment',
      allow_promotion_codes: true,
    });
  }
  window.location.href = session.url;
}

async function getUserSubscriptions(user_id) {
  const authLevel = await fetchAuthenticationLevel(user_id);
  if (authLevel >= 1) {
    return VALID_SUBSCRIPTION;
  }

  const subs = await getPlanPayments();
  if (subs.length === 0) {
    return DEMO_SUBSCRIPTION;
    //new user demo case
  }

  return VALID_SUBSCRIPTION;
}

const getPlanPayments = async () => {
  try {
    const subs = await getCurrentUserSubscriptions(payments, {
      status: 'active',
    });
    return subs;
  } catch (e) {
    console.log(e);
  }
};

const getAddOnPayments = async () => {
  const subs = await getCurrentUserPayments(payments);
  const products = await getProducts(payments, { includePrices: false });
  console.log(subs);
  console.log(products);
  // FIX
  // let productIds = []
  // products.map(prod => {
  //   if (prod.active) {
  //     if (!prod.metadata.days) {
  //       productIds.push(prod.id)
  //     }
  //   }

  // })
  // const productPayments = []
  // subs.map(sub => {
  //   if (productIds.includes(sub.prices[0].product)) {
  //     productPayments.push(sub)
  //   }
  // })
  // return productPayments
  return [];
};

async function getUserSubscriptionDetails(user_id) {
  const authLevel = await fetchAuthenticationLevel(user_id);
  if (authLevel === 1) {
    const expire_date = await fetchRegisterDate(user_id);
    const days = 365;
    const today = new Date();
    let expiry = new Date(expire_date);
    expiry.setDate(expiry.getDate() + days);
    const diffTime = expiry.getTime() - today.getTime();
    const daysLeft = Math.ceil(diffTime / (1000 * 3600 * 24));

    return {
      productName: 'Free Year Plan',
      daysLeft: daysLeft,
      expiry: expiry.toString(),
    };
  }
  const subs = await getPlanPayments();
  if (subs.length === 0) {
    return null;
  }

  const productName = await getProductName(subs[0].product);

  let subscriptionDetails = { ...subs[0], subscribed: true, productName };
  return subscriptionDetails;
}

async function sendToCustomerPortal() {
  const functions = getFunctions();
  const getPortalLink = httpsCallableFromURL(
    functions,
    portalCloudFunctionTrigger
  );

  const { data } = await getPortalLink({ returnUrl: window.location.origin });
  window.location.assign(data.url);
}

export {
  getStoreObj,
  getPriceIds,
  sendToCheckout,
  getUserSubscriptions,
  getUserSubscriptionDetails,
  getPlanPayments,
  DEMO_SUBSCRIPTION,
  EXPIRED_SUBSCRIPTION,
  VALID_SUBSCRIPTION,
  customerPortalLink,
  sendToCustomerPortal,
};
