import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../auth";

import "../style/Dashboard.css";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import LinearProgress from '@mui/material/LinearProgress';

import { ReactComponent as HeaderSVG } from "../images/undraw_online_test_re_kyfx.svg";

import {
  MdOutlineLightbulb,
  MdOutlineRule,
} from 'react-icons/md';
import { IoMdRefresh } from 'react-icons/io';
import { SlGraph } from 'react-icons/sl';
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import CalendarHeatMap from "../components/CalendarHeatMap";

import { DEMO_SUBSCRIPTION, VALID_SUBSCRIPTION, EXPIRED_SUBSCRIPTION } from "../Stripe.js"
import Progression from "../components/Progression";
import { getTestDate } from "../app_backend.js";
import RegisterStepper from "../components/RegisterStepper.js";

function DashInterface({ setSelectedPage, subscription }) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [selectiveDates, setSelectiveDates] = useState(null)

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  useEffect(() => {
    getSelectiveDate()
  }, [])

  const getSelectiveDate = async () => {
    const data = await getTestDate()
    setSelectiveDates(data)
  }

  const ValidSubBanner = () => {
    return (
      <Box
        sx={{
          height: "100%",
          overflow: "visible",
          borderRadius: 4,
          backgroundColor: "#F1F4FF",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mr: 10, justifyContent: 'space-around', overflow: 'auto' }}>
          <Box sx={{ pt: 1.5, paddingBottom: 2 }}>
            <Typography
              sx={{ px: 3, paddingBottom: 3, fontWeight: 700, fontSize: '18pt', color: "#1F2F98" }}
            >
              Welcome back, {user?.displayName?.split(' ')[0]}!
            </Typography>
            <Typography sx={{ px: 3, color: "#5053ab", pb: 1 }}>
              Sharpen your exam skills for the {selectiveDates ? new Date(selectiveDates.testDate).getFullYear() : ''} Selective Test
              with one of our Mocks.
            </Typography>
            <Typography sx={{ px: 3, color: "#5053ab" }}>
              Or work on your weaknesses in the Practice section!
            </Typography>
          </Box>
          <Box sx={{ width: '100%', px: 3 }}>
            {selectiveDates ?
              <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', gap: 2 }}>
                {/* <Box>
                  <Typography>
                    {new Date(selectiveDates.startDate).toDateString()}
                  </Typography>
                </Box> */}
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                  <LinearProgress variant="determinate" value={100 / (0.015 * Math.max(selectiveDates.days, 0) + 1)}
                    sx={{
                      height: '15px',
                      borderRadius: 9,
                      width: '100%'
                    }} />
                  <Box sx={{}}>
                    <Typography sx={{ fontWeight: 700, color: '#555a71', fontSize: '11pt' }}>
                      {selectiveDates.days > 1 ? `${selectiveDates.days} days remaining` : `${selectiveDates.days} day remaining`}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ mb: 3, display: 'flex' }}>
                  <Typography sx={{ fontWeight: 700, color: '#555a71' }}>
                    {new Date(selectiveDates.testDate).toDateString()}
                  </Typography>
                </Box>
              </Box>
              :
              <Skeleton animation="wave" variant='rounded' sx={{ mb: 3 }} height={40} />}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifySelf: "end",
            marginRight: 10,
          }}
        >
          <HeaderSVG height={"12rem"} width={"12rem"} />
        </Box>
      </Box>
    )
  }

  const DemoBanner = () => {
    return (
      <Box
        sx={{
          px: 3,
          pt: 2,
          pb: 3,
          height: "100%",
          overflow: "visible",
          borderRadius: 4,
          backgroundColor: "#F1F4FF",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mr: 10, justifyContent: 'space-between', overflow: 'auto' }}>
          <Box sx={{ overflow: "auto" }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: 700, paddingBottom: 0.9, color: "#1F2F98" }}
            >
              Thank you for registering! Welcome to PassPapers.
            </Typography>
            <Typography sx={{ paddingBottom: 0.9, color: "#5053ab" }}>
              Don't miss out on our helpful learning features and weekly updated Practice Quizzes and Mock Exams.
            </Typography>
          </Box>
          <Box>
            <RegisterStepper />
          </Box>
          <Box sx={{ display: 'flex', gap: 3, overflow: 'visible', alignItems: 'center' }}>
            <Button variant="contained" color="primary" disableElevation
              onClick={() => setSelectedPage("Store")} sx={{ px: 3, borderRadius: 10, textTransform: 'none' }}
            >
              Upgrade Now
            </Button>
            <Typography sx={{ color: "#5053ab" }}>
              Join hundreds of successful students today!
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifySelf: "end",
            marginRight: 10,
          }}
        >
          <HeaderSVG height={"12rem"} width={"12rem"} />
        </Box>
      </Box >
    )
  }

  const ExpiryBanner = () => {
    return (
      <Box
        sx={{
          p: 3,
          height: '100%',
          width: '100%',
          overflow: "visible",
          borderRadius: 4,
          backgroundColor: "#F1F4FF",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ overflow: "visible" }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: "#dc143c" }}
          >
            Unfortunately, your access has expired
          </Typography>
          <Typography sx={{ paddingBottom: 0.9, color: "#5053ab", fontWeight: 700 }}>
            Visit the store to renew your account!
          </Typography>
          <Button variant="outlined" onClick={() => setSelectedPage("Store")} sx={{ px: 3, borderRadius: 10 }}>Store</Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifySelf: "end",
            marginRight: 10,
          }}
        >
        </Box>
      </Box >
    )
  }

  const BannerSelector = () => {
    switch (subscription) {
      case VALID_SUBSCRIPTION:
        return <ValidSubBanner />
      case DEMO_SUBSCRIPTION:
        return <DemoBanner />
      case EXPIRED_SUBSCRIPTION:
        return <ExpiryBanner />
    }
  }

  if (window.innerWidth <= 1000) {
    return (
      <Box sx={{ flexGrow: 1, width: "100%", px: 1, py: 2 }}>
        <Grid container direction="column" sx={{ width: '100%', height: '100%' }}>
          <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" sx={{ maxHeight: '160px', marginBottom: 3 }}>
            <Grid container sx={{ height: '100%', }} columnSpacing={3}>
              <Grid item sx={{ height: '100%' }} xs sm md lg xl>
                <Box sx={{ height: '100%' }}>
                  {subscription === DEMO_SUBSCRIPTION ?
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        mr: 10,
                        justifyContent: 'space-between',
                        overflow: 'auto',
                        bgcolor: '#F1F4FF',
                        p: 1, borderRadius: 3
                      }}>
                      <Box sx={{ overflow: "auto", mb: 1, px: 1 }}>
                        <Typography
                          sx={{ paddingBottom: 0.9, color: "#1F2F98", fontSize: '14pt' }}
                        >
                          Join hundreds of successful students
                        </Typography>
                      </Box>
                      <Box>
                        <RegisterStepper />
                      </Box>
                    </Box>
                    :
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%', mr: 10,
                        justifyContent: 'space-around',
                        overflow: 'auto',
                        bgcolor: '#F1F4FF',
                        borderRadius: 3
                      }}>
                      <Box sx={{ paddingBottom: 1, p: 1 }}>
                        <Typography
                          sx={{ px: 1, fontSize: '14pt', color: "#1F2F98" }}
                        >
                          Welcome back, {user?.displayName?.split(' ')[0]}!
                        </Typography>
                      </Box>
                      <Box sx={{ width: '100%', px: 1 }}>
                        {selectiveDates ?
                          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', gap: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column' }}>
                              <LinearProgress variant="determinate" value={100 / (0.015 * Math.max(selectiveDates.days, 0) + 1)}
                                sx={{
                                  height: '10px',
                                  borderRadius: 9,
                                  width: '100%'
                                }} />
                              <Box sx={{}}>
                                <Typography sx={{ color: '#555a71', fontSize: '11pt' }}>
                                  {selectiveDates.days > 1 ? `${selectiveDates.days} days remaining` : `${selectiveDates.days} day remaining`}
                                </Typography>
                              </Box>
                            </Box>
                            <Box sx={{ mb: 3, display: 'flex' }}>
                              <Typography sx={{ color: '#555a71' }}>
                                {new Date(selectiveDates.testDate).toDateString()}
                              </Typography>
                            </Box>
                          </Box>
                          :
                          <Skeleton animation="wave" variant='rounded' sx={{ mb: 3 }} height={40} />}
                      </Box>
                    </Box>
                  }
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs sm md lg xl sx={{ overflow: 'auto' }}>
            <Grid container sx={{ height: '100%' }} columnSpacing={3}>
              <Grid item sx={{ height: '100%' }} xs sm md lg xl>
                <Box sx={{ height: '100%', overflow: 'auto', }}>
                  <Box sx={{
                    display: 'grid', minHeight: '100%',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
                    gridTemplateRows: 'repeat(auto-fit, minmax(200px, 1fr))',
                    gap: 3
                  }}>
                    <Box className='dash_tile_mobile'>
                      <CardActionArea sx={{
                        height: '100%', borderRadius: '15px',
                      }} onClick={() => setSelectedPage('Learn')}>
                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', border: 0, mx: 1, my: 0.5, backgroundColor: '#E6EEFA', borderRadius: 50, overflow: 'hidden', position: 'relative' }} >
                            <div className='learn_slider slider'></div>
                            <Stack direction={'row'} spacing={3} sx={{ height: '100%', width: '100%', zIndex: 1 }}>
                              <Box sx={{
                                borderRadius: 50, width: '90px', height: '90px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#a3e33b'
                              }}>
                                <MdOutlineLightbulb style={{ width: '70px', height: '70px', color: '#ffffff' }} />
                              </Box>
                              <Box sx={{ display: "flex", alignItems: "center", fontSize: '18pt' }}
                              >
                                Learn
                              </Box>
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: 3,
                              }}
                            >
                              <ArrowForwardIosOutlinedIcon
                                color="primary"
                                style={{ height: 20 }}
                                className="tile_img"
                              />
                            </Box>
                          </Box>
                          <Typography variant='body1' color='#959cc8' sx={{ p: 1.5 }}>Learn the ins and outs of the  Selective School Exam</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                    <Box className='dash_tile_mobile'>
                      <CardActionArea sx={{
                        height: '100%', borderRadius: '15px'
                      }} onClick={() => setSelectedPage('Practice')}>
                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', border: 0, mx: 1, my: 0.5, backgroundColor: '#E6EEFA', borderRadius: 50, overflow: 'hidden', position: 'relative' }}>
                            <div className='practice_slider slider'></div>
                            <Stack direction={'row'} spacing={3} sx={{ height: '100%', width: '100%', zIndex: 1 }}>
                              <Box sx={{
                                borderRadius: 50, width: '90px', height: '90px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#6dd6f4'
                              }}>
                                <MdOutlineRule style={{ width: '70px', height: '70px', color: '#ffffff' }} />
                              </Box>
                              <Box sx={{ display: "flex", alignItems: "center", fontSize: '18pt' }}>
                                Practice
                              </Box>
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: 3,
                              }}
                            >
                              <ArrowForwardIosOutlinedIcon
                                color="primary"
                                style={{ height: 20 }}
                                className="tile_img"
                              />
                            </Box>
                          </Box>
                          <Typography variant='body1' color='#959cc8' sx={{ p: 1.5 }}>Practice your skills with unlimited attempts</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                    <Box className='dash_tile_mobile'>
                      <CardActionArea sx={{
                        height: '100%', borderRadius: '15px'
                      }} onClick={() => setSelectedPage('Mocks')} >
                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', border: 0, mx: 1, my: 0.5, backgroundColor: '#E6EEFA', borderRadius: 50, overflow: 'hidden', position: 'relative' }}>
                            <div className='mock_slider slider'></div>
                            <Stack direction={'row'} spacing={3} sx={{ height: '100%', width: '100%', zIndex: 1 }}>
                              <Box sx={{
                                borderRadius: 50, width: '90px', height: '90px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#9A6AFF'
                              }}>
                                <SlGraph style={{ width: '70px', height: '70px', color: '#ffffff' }} />
                              </Box>
                              <Box sx={{ display: "flex", alignItems: "center", fontSize: '18pt' }}>
                                Mocks
                              </Box>
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: 3,
                              }}
                            >
                              <ArrowForwardIosOutlinedIcon
                                color="primary"
                                style={{ height: 20 }}
                                className="tile_img"
                              />
                            </Box>
                          </Box>
                          <Typography variant='body1' color='#959cc8' sx={{ p: 1.5 }}>Simulate the real exam under time pressure</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                    <Box className='dash_tile_mobile'>
                      <CardActionArea sx={{
                        height: '100%', borderRadius: '15px'
                      }} onClick={() => setSelectedPage('Revision')} >
                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', border: 0, mx: 1, my: 0.5, backgroundColor: '#E6EEFA', borderRadius: 50, overflow: 'hidden', position: 'relative' }}>
                            <div className='revision_slider slider'></div>
                            <Stack direction={'row'} spacing={3} sx={{ height: '100%', width: '100%', zIndex: 1 }}>
                              <Box sx={{
                                borderRadius: 50, width: '90px', height: '90px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffcb50'
                              }}>
                                <IoMdRefresh style={{ width: '70px', height: '70px', color: '#ffffff' }} />
                              </Box>
                              <Box sx={{ display: "flex", alignItems: "center", fontSize: '18pt' }}>
                                Revision
                              </Box>
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: 3,
                              }}
                            >
                              <ArrowForwardIosOutlinedIcon
                                color="primary"
                                style={{ height: 20 }}
                                className="tile_img"
                              />
                            </Box>
                          </Box>
                          <Typography variant='body1' color='#959cc8' sx={{ p: 1.5 }}>Retry incorrect questions from a topic of your choice</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {/* <Grid item sx={{ height: '100%' }} xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
              <Box sx={{ height: '100%' }}>
                <Box sx={{
                  backgroundColor: "background.paper",
                  borderRadius: 4,
                  width: '300px',
                  height: "100%",
                  overflow: 'auto'
                }}>
                  <Progression text_width={"7rem"} user_id={user?.uid} />
                </Box>
              </Box>
            </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Box >
    )
  }


  return (
    <Box sx={{ flexGrow: 1, width: "100%", p: 3 }}>
      <Grid container direction="column" sx={{ width: '100%', height: '100%' }}>
        <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto" sx={{ height: '260px', marginBottom: 3 }}>
          <Grid container sx={{ height: '100%', }} columnSpacing={3}>
            <Grid item sx={{ height: '100%' }} xs sm md lg xl>
              <Box sx={{ height: '100%' }}>
                <BannerSelector />
              </Box>
            </Grid>
            <Grid item sx={{ height: '100%' }} xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
              <Box sx={{ height: '100%' }}>
                <Box sx={{
                  backgroundColor: "background.paper",
                  borderRadius: 4,
                  width: '300px',
                  height: "100%",
                }}>
                  <CalendarHeatMap user_id={user?.uid} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs sm md lg xl sx={{ overflow: 'auto' }}>
          <Grid container sx={{ height: '100%' }} columnSpacing={3}>
            <Grid item sx={{ height: '100%' }} xs sm md lg xl>
              <Box sx={{ height: '100%', backgroundColor: "#F1F4FF", borderRadius: 4, p: 3 }}>
                <Box sx={{ height: '100%', overflow: 'auto', }}>
                  <Box sx={{
                    display: 'grid', minHeight: '100%',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(325px, 1fr))',
                    gridTemplateRows: 'repeat(auto-fit, minmax(200px, 1fr))',
                    gap: 3
                  }}>
                    <Box className='dash_tile'>
                      <CardActionArea sx={{
                        height: '100%', borderRadius: '15px',
                      }} onClick={() => setSelectedPage('Learn')}>
                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', border: 0, mx: 1, my: 0.5, backgroundColor: '#E6EEFA', borderRadius: 50, overflow: 'hidden', position: 'relative' }} >
                            <div className='learn_slider slider'></div>
                            <Stack direction={'row'} spacing={3} sx={{ height: '100%', width: '100%', zIndex: 1 }}>
                              <Box sx={{
                                borderRadius: 50, width: '90px', height: '90px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#a3e33b'
                              }}>
                                <MdOutlineLightbulb style={{ width: '70px', height: '70px', color: '#ffffff' }} />
                              </Box>
                              <Box sx={{ display: "flex", alignItems: "center", fontSize: '18pt' }}
                              >
                                Learn
                              </Box>
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: 3,
                              }}
                            >
                              <ArrowForwardIosOutlinedIcon
                                color="primary"
                                style={{ height: 20 }}
                                className="tile_img"
                              />
                            </Box>
                          </Box>
                          <Typography variant='body1' color='#959cc8' sx={{ p: 1.5 }}>Learn the ins and outs of the  Selective School Exam</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                    <Box className='dash_tile'>
                      <CardActionArea sx={{
                        height: '100%', borderRadius: '15px'
                      }} onClick={() => setSelectedPage('Practice')}>
                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', border: 0, mx: 1, my: 0.5, backgroundColor: '#E6EEFA', borderRadius: 50, overflow: 'hidden', position: 'relative' }}>
                            <div className='practice_slider slider'></div>
                            <Stack direction={'row'} spacing={3} sx={{ height: '100%', width: '100%', zIndex: 1 }}>
                              <Box sx={{
                                borderRadius: 50, width: '90px', height: '90px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#6dd6f4'
                              }}>
                                <MdOutlineRule style={{ width: '70px', height: '70px', color: '#ffffff' }} />
                              </Box>
                              <Box sx={{ display: "flex", alignItems: "center", fontSize: '18pt' }}>
                                Practice
                              </Box>
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: 3,
                              }}
                            >
                              <ArrowForwardIosOutlinedIcon
                                color="primary"
                                style={{ height: 20 }}
                                className="tile_img"
                              />
                            </Box>
                          </Box>
                          <Typography variant='body1' color='#959cc8' sx={{ p: 1.5 }}>Practice your skills with unlimited attempts</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                    <Box className='dash_tile'>
                      <CardActionArea sx={{
                        height: '100%', borderRadius: '15px'
                      }} onClick={() => setSelectedPage('Mocks')} >
                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', border: 0, mx: 1, my: 0.5, backgroundColor: '#E6EEFA', borderRadius: 50, overflow: 'hidden', position: 'relative' }}>
                            <div className='mock_slider slider'></div>
                            <Stack direction={'row'} spacing={3} sx={{ height: '100%', width: '100%', zIndex: 1 }}>
                              <Box sx={{
                                borderRadius: 50, width: '90px', height: '90px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#9A6AFF'
                              }}>
                                <SlGraph style={{ width: '70px', height: '70px', color: '#ffffff' }} />
                              </Box>
                              <Box sx={{ display: "flex", alignItems: "center", fontSize: '18pt' }}>
                                Mocks
                              </Box>
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: 3,
                              }}
                            >
                              <ArrowForwardIosOutlinedIcon
                                color="primary"
                                style={{ height: 20 }}
                                className="tile_img"
                              />
                            </Box>
                          </Box>
                          <Typography variant='body1' color='#959cc8' sx={{ p: 1.5 }}>Simulate the real exam under time pressure</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                    <Box className='dash_tile'>
                      <CardActionArea sx={{
                        height: '100%', borderRadius: '15px'
                      }} onClick={() => setSelectedPage('Revision')} >
                        <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', border: 0, mx: 1, my: 0.5, backgroundColor: '#E6EEFA', borderRadius: 50, overflow: 'hidden', position: 'relative' }}>
                            <div className='revision_slider slider'></div>
                            <Stack direction={'row'} spacing={3} sx={{ height: '100%', width: '100%', zIndex: 1 }}>
                              <Box sx={{
                                borderRadius: 50, width: '90px', height: '90px',
                                display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffcb50'
                              }}>
                                <IoMdRefresh style={{ width: '70px', height: '70px', color: '#ffffff' }} />
                              </Box>
                              <Box sx={{ display: "flex", alignItems: "center", fontSize: '18pt' }}>
                                Revision
                              </Box>
                            </Stack>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                paddingRight: 3,
                              }}
                            >
                              <ArrowForwardIosOutlinedIcon
                                color="primary"
                                style={{ height: 20 }}
                                className="tile_img"
                              />
                            </Box>
                          </Box>
                          <Typography variant='body1' color='#959cc8' sx={{ p: 1.5 }}>Retry incorrect questions from a topic of your choice</Typography>
                        </CardContent>
                      </CardActionArea>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item sx={{ height: '100%' }} xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
              <Box sx={{ height: '100%' }}>
                <Box sx={{
                  backgroundColor: "background.paper",
                  borderRadius: 4,
                  width: '300px',
                  height: "100%",
                  overflow: 'auto'
                }}>
                  <Progression text_width={"7rem"} user_id={user?.uid} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );
}

export default DashInterface;
