import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

//styling stuff
import "../style/modal.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { auth } from "../auth";
import {
  getResultWithId,
  GetQuestionList,
  GetUserRanks,
  GetTopic,
} from "../app_backend";
import Answer from "../components/Answer";
import Navbar from "../components/NavBar";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";

import ReadingText from "../components/ReadingText";
import Results from "../components/Results";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { VALID_SUBSCRIPTION } from "../Stripe";
import CircularScoreWithLabel from "../components/CircularScoreWithLabel";

const ResultsAfterSubmit = () => {
  const [user, loading, error] = useAuthState(auth);
  const params = useParams();
  const navigate = useNavigate();
  const index = useRef(0);
  const qList = useRef([]);
  const [quizId, setQuizId] = useState("");
  const [score, setScore] = useState(0);
  const [rankData, setRankData] = useState(null);
  const [topic, setTopic] = useState(null);

  const [date, setDate] = useState("");
  const attemptAnswers = useRef([]);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchResult();
  }, [user, loading]);

  const fetchResult = async () => {
    try {
      const data = await getResultWithId(
        user?.uid,
        params.subject,
        params.quiz,
        params.attemptId
      );
      setQuizId(data.quiz_id);
      setScore(data.score);
      setDate(data.date);
      attemptAnswers.current = data.attempt_answers;

      const rankData = await GetUserRanks(
        params.subject,
        params.quiz,
        data.score
      );
      setRankData(rankData);

      const t = await GetTopic(params.subject, params.quiz);
      setTopic(t);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box
      className="dashboard"
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Box sx={{ py: 3, mx: "12vw", height: "100%" }}>
        <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
          <Grid container direction="column" sx={{ height: "100%" }}>
            <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", mb: 3 }}>
                  <Paper
                    elevation={0}
                    sx={{ borderRadius: 4, backgroundColor: "#ffffff" }}
                  >
                    <Box
                      sx={{
                        p: 3,
                        display: "flex",
                        flexDirection: "row",
                        height: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ mr: 4 }}>
                        <Typography variant="h5" sx={{ fontWeight: 800 }}>
                          {quizId}
                        </Typography>
                        <Typography
                          variant="h6"
                          sx={{ color: "#1f2f98", fontSize: "14pt" }}
                        >
                          {date}
                        </Typography>
                      </Box>
                      <CircularScoreWithLabel
                        score={score}
                        max={attemptAnswers.current.length}
                      />
                      {topic === "MOCK" ? (
                        <Box
                          sx={{
                            ml: 4,
                            display: "flex",
                            flexDirection: "row",
                            borderLeft: 2,
                            height: "100%",
                            alignItems: "center",
                            borderColor: "#e2e5f4",
                          }}
                        >
                          <Box
                            sx={{
                              mx: 4,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h5" sx={{ fontWeight: 800 }}>
                              Median
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{ color: "#1f2f98", fontSize: "14pt" }}
                            >
                              {rankData?.median}{" "}
                              <span style={{ color: "#babcd6" }}>/</span>{" "}
                              {attemptAnswers.current.length}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              mx: 4,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h5" sx={{ fontWeight: 800 }}>
                              Rank
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{ color: "#1f2f98", fontSize: "14pt" }}
                            >
                              {rankData?.rank} of {rankData?.total + 1}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              ml: 4,
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <Typography variant="h5" sx={{ fontWeight: 800 }}>
                              Percentile
                            </Typography>
                            <Typography
                              variant="h5"
                              sx={{ color: "#1f2f98", fontSize: "14pt" }}
                            >
                              {rankData?.percentile}
                              <span style={{ fontSize: "10pt" }}>th</span>
                            </Typography>
                          </Box>
                        </Box>
                      ) : null}
                    </Box>
                  </Paper>
                </Box>
                <Button
                  variant="outlined"
                  sx={{ height: "3rem", borderRadius: 8, bgcolor: "#f1f4ff" }}
                  onClick={() => navigate("/Dashboard")}
                >
                  <Typography color="primary" sx={{ paddingRight: 1 }}>
                    back to dashboard
                  </Typography>
                  <ExitToAppIcon color="primary" />
                </Button>
              </Box>
            </Grid>
            <Grid item xs sm md lg xl sx={{ height: "100%", overflow: "auto" }}>
              <Results
                subject={params.subject}
                quiz={params.quiz}
                attemptId={params.attemptId}
                subscription={VALID_SUBSCRIPTION}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default ResultsAfterSubmit;
