import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../auth';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import LearnContent from '../components/Learn/LearnContent';
import Paper from '@mui/material/Paper';
import LearnContents from '../components/LearnContents';

function Learn() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [header, setHeader] = useState('What is the Selective Test?');

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
  }, [user, loading]);

  return (
    <Box sx={{ borderColor: 'black', mx: 4, width: '100%', height: '92vh' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 3,
          width: '100%',
        }}
      >
        <Typography variant='h3' sx={{ p: 3, fontWeight: 600 }}>
          {header}
        </Typography>
      </Box>
      <Stack direction='row' spacing={3} sx={{ height: '80%' }}>
        <Box
          sx={{
            width: '75%',
            height: '100%',
            display: 'flex',
            flexGrow: 1,
            overflow: 'auto',
          }}
        >
          <LearnContent index={selectedIndex} />
        </Box>
        <LearnContents
          setSelectedIndex={setSelectedIndex}
          setHeader={setHeader}
          selectedIndex={selectedIndex}
        />
      </Stack>
    </Box>
  );
}

export default Learn;
