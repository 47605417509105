import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../auth";

import { getResults, getReviewResultsList, shortSubjectToLong } from "../app_backend";
import Grow from '@mui/material/Grow';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';


const ReviewResultsList = ({ subject, topic, setSelectedPage, setSelectedAttemptId }) => {
  const [user, loading, error] = useAuthState(auth)
  const [attemptsList, setAttemptsList] = useState([])
  const params = useParams()
  const navigate = useNavigate();
  const [renderTransition, setRenderTransition] = useState(false)

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchAttemptList();
  }, [user, loading]);

  const fetchAttemptList = async () => {
    let attempt_list = await getReviewResultsList(user?.uid, subject);
    console.log(attempt_list)
    setAttemptsList(attempt_list);
    if (attempt_list) {
      setRenderTransition(true);
    }
  }

  return (
    <div>
      <Box sx={{
        mx: '2vw',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Button variant="outlined" sx={{ height: '3rem', borderRadius: 8, bgcolor: '#F1F4FF' }} onClick={() => setSelectedPage('revision')}>
          <Typography color='primary' sx={{ paddingRight: 1 }}>back to quizzes</Typography>
          <ExitToAppIcon color='primary' />
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <Box sx={{ p: 4, borderRadius: 6, backgroundColor: '#F1F4FF' }}>
          <Box sx={{
            height: '4rem', display: 'flex', justifyContent: 'space-between',
            alignItems: 'center', gap: 8
          }}>
            <Paper sx={{
              height: '4rem',
              borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffffff'
            }} elevation={0}>
              <Typography variant='h4' sx={{ mx: '2rem', fontWeight: 900 }}>Previous Attempts</Typography>
            </Paper>
            <Paper sx={{
              height: '4rem',
              borderRadius: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#e5eafb"
            }} elevation={0}>
              <Typography variant='h4' sx={{ mx: '2rem', fontWeight: 900, color: '#1f2f98' }}>{shortSubjectToLong(subject)}</Typography>
            </Paper>
          </Box>
          <Paper
            sx={{
              my: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              borderRadius: 6,
              backgroundColor: "#ffffff",
            }}
            elevation={0}
          >
            {attemptsList.length > 0 ?
              <Stack
                spacing={2}
                sx={{
                  my: "1rem",
                  width: "95%",
                  maxHeight: 585,
                  overflow: "auto",
                }}
              >
                {attemptsList.map((attempt, i) => {
                  let reviewPath = "results";
                  return (
                    <Grow key={i} in={renderTransition} style={{ transformOrigin: '0 0 0' }}
                      {...(renderTransition ? { timeout: 600 } : {})}>
                      <Button variant='outlined' onClick={() => { setSelectedPage(reviewPath); setSelectedAttemptId(attempt.id) }} sx={{ width: '100%', borderRadius: 5 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', px: 2 }}>
                          <Typography sx={{ textTransform: 'None', fontSize: 18, color: '#babddb' }}>{attempt.name}</Typography>
                          <Typography sx={{ textTransform: 'None', fontSize: 18 }}>{attempt.date}</Typography>
                          <Typography sx={{ textTransform: 'None', color: '#f26c6d', fontWeight: 800, fontSize: 20 }}>{attempt.score}/{attempt.total}</Typography>
                        </Box>
                      </Button>
                    </Grow>
                  );
                })}
              </Stack>
              :
              <Box sx={{ py: 2, color: '#9ba4bf' }}>
                No attempts to show
              </Box>
            }
          </Paper>
        </Box>
      </Box>
    </div>
  );
};

export default ReviewResultsList
