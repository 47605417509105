import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function Learn5() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography variant="h4" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          What is perimeter?
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          Perimeter is the total distance around the outside of a
          two-dimensional shape.
          <br />
          The word perimeter comes from the Greek words peri, meaning ‘around’,
          and metron, meaning ‘measure’.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Perimeter of Rectangles and Squares:
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2, fontSize: "16pt" }}>
          Perimeter of square = 4 × length
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2, fontSize: "16pt" }}>
          Perimeter of Rectangle = 2 × (length + width)
        </Typography>
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Perimeter of complex shapes
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Often there are sides of unknown length in complex shapes, which may
          be made up of multiple rectangles or squares. These need to be found
          by adding or subtracting lengths of parallel sides with known lengths.
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 3,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example
          </AlertTitle>
          What is the perimeter of a square with sides of 3cm?
          <br />
          We know that all sides of a square are equal, and there are four
          sides. Therefore, the perimeter will be 3 x 4, which is 12.
          <br />
          Thus, the answer is 12cm.
        </Alert>
      </Box>
    </Box>
  );
}

export default Learn5;
