import React from "react";
import {
  Toolbar,
  Typography,
  Box,
  Paper,
  Stack,
  Card,
  CardActionArea,
  CardContent,
  Button,
} from "@mui/material";

function Learn1() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
        }}
      >
        <Typography sx={{ paddingBottom: 2 }}>
          An integral part of preparing for the Selective Test is first
          understanding what you are preparing for. By now, you should know what
          the Selective Test consists of and have a slight idea of what
          questions you will see!
        </Typography>
        <Typography sx={{ paddingBottom: 2 }}>
          To further prepare for the Selective Test, students must understand
          the different <b>types</b> of questions that will be asked. This is
          where our <b>Learning</b> section will help!
        </Typography>
        <Typography sx={{ paddingBottom: 2 }}>
          PassPapers offers tutorials that break down each subject into{" "}
          <b>topics</b>. These tutorials, which can be accessed on the{" "}
          <b>right side of your screen</b>, will explain the most efficient ways
          to complete each question type!
        </Typography>
        <Typography sx={{ paddingBottom: 2 }}>
          After understanding the different questions, students can use their
          newly learned knowledge to <b>Practice</b>
          these questions under untimed conditions or exam conditions (
          <b>Mocks</b>).
        </Typography>

        <Box
          sx={{
            backgroundColor: "#babeee",
            padding: 2,
            marginBottom: 3,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h6" sx={{ paddingBottom: 2, fontWeight: 600 }}>
            The following tutorials will run through and break down each of the
            subtopics within each subject.{" "}
          </Typography>
          <Typography sx={{ fontWeight: 500, fontSize: "14pt" }}>
            Tutorials are designed to help further develop your approach to
            different question types whilst also teaching you the most efficient
            methods.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Learn1;
