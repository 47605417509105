import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  GetAllUserAnswerData,
  GetQuestion,
  GetQuizAndQuestionIndex,
} from "../app_backend";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LinearProgress from "@mui/material/LinearProgress";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "@mui/material/Skeleton";

import DoneIcon from "@mui/icons-material/Done";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import CloseIcon from "@mui/icons-material/Close";
import HTML2json from "html2json";
import StringToHTML from "./StringToHTML";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import ReportQuestion from "./Practice/ReportQuestion";
var json2HTML = HTML2json.json2html;

const Answer = ({
  index,
  question_id,
  attempt,
  subject,
  quiz,
  user_id,
  attempt_id,
}) => {
  const [content, setContent] = useState("");
  const [explanation, setExplanation] = useState("");
  const [quizName, setQuizName] = useState(null);
  const [url, setUrl] = useState("");
  const [options, setOptions] = useState([]);
  const [answerMap, setAnswerMap] = useState({});
  const params = useParams();

  const [openReport, setOpenReport] = useState(false);
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    if (question_id !== "") {
      setTransition(false);
      fetchQuestion(subject, question_id);
      //fetchAnswerData(params.subject)
    }
  }, [question_id]);

  useEffect(() => {
    if (quiz === "review") {
      fetchQuizName();
    }
  }, [question_id]);

  const fetchQuizName = async () => {
    // fetches actual quiz the question is from for review quiz
    const data = await GetQuizAndQuestionIndex(
      user_id,
      subject,
      attempt_id,
      index
    );
    setQuizName(data.quiz_id);
  };

  const fetchQuestion = async (subject, question_id) => {
    setAnswerMap({});
    const data = await GetQuestion(subject, question_id);
    const content = data.content;
    const explanation = data.explanation;
    setContent(json2HTML(content));
    setOptions(data.options);
    setExplanation(json2HTML(explanation));
    setUrl(data.imgURL);
    if (data) {
      setTransition(true);
    }
    const ansData = await GetAllUserAnswerData(
      subject,
      quiz,
      index,
      attempt_id,
      user_id
    );
    calculateAnswerData(ansData, data.options);
  };

  const calculateAnswerData = (data, options) => {
    if (data === []) {
      return;
    }

    let numAnswers = 0;
    let map = {};
    for (let i = 0; i < options.length; i++) {
      if (options[i] !== "") {
        map[i.toString()] = 0;
      }
    }
    if (data.length !== 0) {
      data.forEach((answer, i) => {
        if (answer) {
          if (map[answer.toString()] !== undefined) {
            numAnswers += 1;
            map[answer.toString()] = map[answer.toString()] + 1;
          }
        }
      });

      for (var key in map) {
        map[key] = isNaN(Math.round(100 * (map[key] / numAnswers)))
          ? 0
          : Math.round(100 * (map[key] / numAnswers));
      }
    }
    setAnswerMap(map);
  };

  function PercentageDisplay({ colour, i }) {
    if (answerMap === {}) {
      return;
    } else {
      return (
        <Tooltip title="Students who chose this answer on their first attempt">
          {answerMap[i.toString()] !== undefined ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ width: "70px", marginRight: 1 }}>
                <LinearProgress
                  variant="determinate"
                  color="primary"
                  value={answerMap[i.toString()]}
                  sx={{ width: "100%" }}
                />
              </Box>
              <Box
                sx={{
                  backgroundColor: "#e5eafb",
                  borderRadius: 50,
                  px: 1,
                  width: "3rem",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ color: colour }}>
                  {answerMap[i.toString()]}%
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: "#e5eafb",
                borderRadius: 50,
                px: 1,
                width: "3rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton
                variant="text"
                width={"50%"}
                height={"100%"}
                sx={{ fontSize: "1rem" }}
              />
            </Box>
          )}
        </Tooltip>
      );
    }
  }

  if (!attempt) {
    return;
  }
  if (!attempt.length) {
    return;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper elevation={0} sx={{ borderRadius: 4 }}>
        <Box sx={{ p: 3, maxWidth: "100%" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">Question {index + 1}</Typography>
            <Typography
              sx={{ fontSize: 16, fontWeight: 800, color: "#3949b0" }}
            >
              {quizName !== null ? `From ${quizName}` : <></>}
            </Typography>
          </Box>
          <Fade in={transition} timeout={{ enter: 300, exit: 0 }}>
            <Box sx={{ marginTop: "1rem" }}>
              <StringToHTML string={content} />
              <Box sx={{ marginTop: "1rem" }}>
                {options.map((option, i) => {
                  if (option !== "") {
                    let u_answer = attempt[index];
                    if (u_answer.isCorrect) {
                      if (i.toString() === u_answer.user_answer) {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 0.5,
                              gap: 2,
                            }}
                            key={i}
                          >
                            <Stack
                              direction="row"
                              sx={{
                                border: 2,
                                borderRadius: 4,
                                borderColor: "success.main",
                                maxWidth: "85%",
                              }}
                            >
                              <Typography sx={{ px: 2 }}>{option}</Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <DoneIcon sx={{ color: "success.main" }} />
                                <Typography
                                  sx={{ px: 1, color: "success.main" }}
                                >
                                  your answer
                                </Typography>
                              </Box>
                            </Stack>
                            <PercentageDisplay colour="success.main" i={i} />
                          </Box>
                        );
                      } else {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 0.5,
                              gap: 2,
                            }}
                            key={i}
                          >
                            <Box sx={{ maxWidth: "85%" }}>
                              <Typography sx={{ px: 2 }}>{option}</Typography>
                            </Box>
                            <PercentageDisplay i={i} />
                          </Box>
                        );
                      }
                    } else {
                      if (i.toString() === u_answer.user_answer) {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 0.5,
                              gap: 2,
                            }}
                            key={i}
                          >
                            <Stack
                              direction="row"
                              sx={{
                                border: 2,
                                borderRadius: 4,
                                borderColor: "error.main",
                                maxWidth: "85%",
                              }}
                            >
                              <Box>
                                <Typography sx={{ px: 2 }}>{option}</Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <CloseIcon sx={{ color: "error.main" }} />
                                <Typography sx={{ px: 1, color: "error.main" }}>
                                  Your answer
                                </Typography>
                              </Box>
                            </Stack>
                            <PercentageDisplay colour="error.main" i={i} />
                          </Box>
                        );
                      } else if (
                        i === u_answer.correct_answer ||
                        i.toString() === u_answer.correct_answer
                      ) {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 0.5,
                              gap: 2,
                            }}
                            key={i}
                          >
                            <Stack
                              direction="row"
                              sx={{
                                border: 2,
                                borderRadius: 4,
                                borderColor: "success.main",
                                maxWidth: "85%",
                              }}
                            >
                              <Typography sx={{ px: 2 }}>{option}</Typography>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                <DoneIcon sx={{ color: "success.main" }} />
                              </Box>
                            </Stack>
                            <PercentageDisplay colour="success.main" i={i} />
                          </Box>
                        );
                      } else {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginBottom: 0.5,
                              gap: 2,
                            }}
                            key={i}
                          >
                            <Box sx={{ maxWidth: "85%" }}>
                              <Typography sx={{ px: 2 }}>{option}</Typography>
                            </Box>
                            <PercentageDisplay i={i} />
                          </Box>
                        );
                      }
                    }
                  }
                })}
              </Box>
            </Box>
          </Fade>
        </Box>
      </Paper>
      <Paper elevation={0} sx={{ my: "1.5rem", borderRadius: 4 }}>
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" sx={{ fontWeight: 800 }}>
            Explanation
          </Typography>
          <StringToHTML string={explanation} />
        </Box>
      </Paper>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Box sx={{ paddingLeft: 2, display: "flex" }}>
          <AccessTimeIcon color="primary" sx={{ marginRight: 1 }} />
          <Typography>
            Time spent on this question: {attempt[index].time_spent} seconds
          </Typography>
        </Box>
        <Button
          size="small"
          variant="outlined"
          color="error"
          sx={{ borderRadius: 3 }}
          onClick={() => setOpenReport(true)}
        >
          <WarningAmberRoundedIcon />
          <Typography sx={{ textTransform: "None", marginLeft: 1 }}>
            Report a mistake
          </Typography>
        </Button>
      </Box>
      <ReportQuestion
        openReport={openReport}
        setOpenReport={setOpenReport}
        question_id={question_id}
        subject={subject}
        quiz={quiz}
        questionNumber={index + 1}
      />
    </Box>
  );
};

export default Answer;
