import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { addQuestionReport } from "../../app_backend";
import LoadingScreen from "../LoadingScreen";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#ffffff",
  boxShadow: 24,
  p: 4,
  borderRadius: 7,
};

const ReportQuestion = ({
  openReport,
  setOpenReport,
  question_id,
  subject,
  quiz,
  questionNumber,
}) => {
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("primary");
  const [loading, setLoading] = useState(false);

  const handleReportQuestion = async () => {
    if (message !== "") {
      setLoading(true);
      await addQuestionReport(
        subject,
        quiz,
        question_id,
        message,
        questionNumber
      );
      setTimeout(() => {
        setOpenReport(false);
      }, 600);
    } else {
      setMessageError("error");
    }
  };

  useEffect(() => {
    if (message === "") {
      setMessageError("error");
    } else {
      setMessageError("primary");
    }
  }, [message]);

  useEffect(() => {
    setMessage("");
    setLoading(false);
  }, [openReport]);

  return (
    <Modal
      open={openReport}
      onClose={() => setOpenReport(false)}
      hideBackdrop={false}
    >
      <Box sx={modalStyle}>
        {loading ? (
          <LoadingScreen size={"small"} text={"Submitting report..."} />
        ) : (
          <React.Fragment>
            <Typography sx={{ fontSize: "18pt" }}>
              Outline the details of the error:
            </Typography>
            <TextField
              focused
              variant="outlined"
              label="Message"
              required
              multiline
              rows={5}
              color={messageError}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              sx={{ width: "100%", marginTop: 2, fontSize: "12pt" }}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2rem",
              }}
            >
              <Button
                sx={{ borderRadius: 6 }}
                variant="outlined"
                onClick={() => {
                  setOpenReport(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ borderRadius: 6 }}
                onClick={handleReportQuestion}
              >
                <Typography sx={{ textTransform: "None" }}>Report</Typography>
              </Button>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </Modal>
  );
};

export default ReportQuestion;
