import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function Learn9() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography variant="h4" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          What are decimals?
        </Typography>
        <Typography sx={{ paddingBottom: 2 }}>
          A decimal is an alternative method of writing a fraction where the
          denominator is a power of ten.
        </Typography>
        <Typography sx={{ paddingBottom: 2 }}>
          <span style={{ color: "#c6090f" }}>
            <b>Remember:</b>
          </span>{" "}
          The number above the line is called the <b>numerator</b>, and the
          number below the line is called the <b>denominator</b>
        </Typography>
      </Box>
      <Alert
        icon={<NewReleasesRoundedIcon fontSize="large" />}
        severity="info"
        variant="outlined"
        sx={{
          borderRadius: 4,
          marginBottom: 3,
          fontSize: "12pt",
          backgroundColor: "#d9e7fe",
        }}
      >
        <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
          Example 1
        </AlertTitle>
        <Typography sx={{ paddingBottom: 2 }}>
          <sup>1</sup>&frasl;<sub>10</sub> = 0.1
        </Typography>
        <Typography sx={{ paddingBottom: 2 }}>
          <sup>1</sup>&frasl;<sub>100</sub> = 0.01
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          <sup>1</sup>&frasl;<sub>1000</sub> = 0.001
        </Typography>
      </Alert>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Converting a decimal to a mixed fraction
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          <ol>
            <li>
              Any numbers prior to the decimal point will remain as your whole
              number in the mixed fraction
            </li>
            <li>
              For the fraction component, write out the digits after the decimal
              point on the numerator. On the denominator, write the number 1,
              followed by as many zeroes as there are digits in the numerator.
            </li>
            <li>Finally, simplify the fraction as much as possible.</li>
          </ol>
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 2,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 2
          </AlertTitle>
          <Typography sx={{ paddingBottom: 1, fontSize: "14pt" }}>
            32.4605 &rarr; 32<sup>4605</sup>&frasl;<sub>10000</sub> &rarr; 32
            <sup>921</sup>&frasl;<sub>2000</sub>
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Converting fractions to decimals
        </Typography>
        <Typography sx={{ paddingBottom: 2 }}>
          <ul>
            <li>
              Perform long division and divide the numerator by the denominator
              to express fraction as a decimal
            </li>
            <li>OR, convert their denominators to powers of ten</li>
          </ul>
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 2,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 3
          </AlertTitle>
          <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
            <sup>17</sup>&frasl;<sub>25</sub> = <sup>17 × 4</sup>&frasl;
            <sub>25 × 4</sub> = <sup>68</sup>&frasl;<sub>100</sub> = 0.68
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#babeee",
            padding: 2,
            marginBottom: 3,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
            Terminating and recurring decimals
          </Typography>
          <Typography sx={{ paddingBottom: 1 }}>
            <span style={{ color: "#c6090f" }}>
              <b>Terminating decimals</b>
            </span>{" "}
            are decimals that end after a certain number of decimals, such as
            0.2 or 0.588
          </Typography>
          <Typography sx={{ paddingBottom: 1 }}>
            <span style={{ color: "#c6090f" }}>
              <b>Recurring decimals</b>
            </span>{" "}
            have one or more digits that repeat infinitely
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          If <b>one digit is recurring</b>, a dot is placed over the repeating
          digit
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, marginBottom: 2, backgroundColor: "#d9e7fe" }}
        >
          <Typography sx={{ fontSize: "16pt" }}>
            <b>Example 4</b>: 0.555555555... = 0.
            <span style={{ position: "relative" }}>
              5
              <span
                style={{
                  position: "absolute",
                  top: "-70%",
                  left: "22%",
                  fontSize: "20pt",
                }}
              >
                .
              </span>
            </span>
          </Typography>
        </Alert>

        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          If <b>two digits are recurring</b>, dots are placed over the two
          repeating digits
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, marginBottom: 2, backgroundColor: "#d9e7fe" }}
        >
          <Typography sx={{ fontSize: "16pt" }}>
            <b>Example 5</b>: 0.12463636363... = 0.124
            <span style={{ position: "relative" }}>
              6
              <span
                style={{
                  position: "absolute",
                  top: "-70%",
                  left: "22%",
                  fontSize: "20pt",
                }}
              >
                .
              </span>
            </span>
            <span style={{ position: "relative" }}>
              3
              <span
                style={{
                  position: "absolute",
                  top: "-70%",
                  left: "22%",
                  fontSize: "20pt",
                }}
              >
                .
              </span>
            </span>
          </Typography>
        </Alert>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          If <b>three or more digits are recurring</b>, dots are placed over the
          first and last recurring digit
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, marginBottom: 2, backgroundColor: "#d9e7fe" }}
        >
          <Typography sx={{ fontSize: "16pt" }}>
            <b>Example 6</b>: 0.14721472147214... = 0.
            <span style={{ position: "relative" }}>
              1
              <span
                style={{
                  position: "absolute",
                  top: "-70%",
                  left: "22%",
                  fontSize: "20pt",
                }}
              >
                .
              </span>
            </span>
            47
            <span style={{ position: "relative" }}>
              2
              <span
                style={{
                  position: "absolute",
                  top: "-70%",
                  left: "22%",
                  fontSize: "20pt",
                }}
              >
                .
              </span>
            </span>
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Multiplication of decimals
        </Typography>
        <Typography sx={{ paddingBottom: 1, mx: 2 }}>
          When multiplying a decimal by 10
          <sup>
            <i>n</i>
          </sup>
          , move the decimal point <i>n</i> places to the right.
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          When dividing a decimal by 10
          <sup>
            <i>n</i>
          </sup>
          , move the decimal point <i>n</i> places to the left.
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, marginBottom: 2, backgroundColor: "#d9e7fe" }}
        >
          <Typography sx={{ fontSize: "16pt" }}>
            <b>Example 7</b>: 0.0000426 × 10000 = 0.426
          </Typography>
        </Alert>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, marginBottom: 2, backgroundColor: "#d9e7fe" }}
        >
          <Typography sx={{ fontSize: "16pt" }}>
            <b>Example 8</b>: 425.67 ÷ 100 = 4.2567
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Rounding
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Rounding refers to approximating a number by reducing the number of
          digits it has{" "}
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          If you are rounding to a specific number of decimal places: <br />
          <ul>
            <li>If the next digit is 0, 1, 2, 3, or 4 round down</li>
            <li>If the next digit is 5, 6, 7, 8, or 9 round up</li>
          </ul>
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 2,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 9
          </AlertTitle>
          <Typography sx={{ paddingBottom: 2 }}>
            If we want to round 4.2567 to 2 decimal places we look at the next
            digit, which is the third digit.
            <br />
            In this case, it is 6, which means we round the second decimal up so
            the number becomes 4.26
          </Typography>
          <Typography sx={{ paddingBottom: 1 }}>
            If we want to round 81.23 to 1 decimal place we look at the next
            digit, which is the second digit.
            <br />
            In this case, it is 3, which means we round the second decimal down
            so the number becomes 81.2
          </Typography>
        </Alert>
      </Box>
    </Box>
  );
}

export default Learn9;
