import React, { useEffect, useState } from "react";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { customerPortalLink, getPriceIds, getStoreObj, getUserSubscriptionDetails, sendToCheckout, sendToCustomerPortal } from "../Stripe";
import LoadingScreen from "../components/LoadingScreen";
import "../style/Store.css"
import ButtonUnstyled from '@mui/base/ButtonUnstyled';

import { ReactComponent as OneMonth } from "../images/1monthicon.svg";
import { ReactComponent as ThreeMonth } from "../images/3monthicon.svg";
import { ReactComponent as HalfYear } from "../images/newhalfyearicon.svg";
import { ReactComponent as Year } from "../images/Yearplanicon.svg";
import { ReactComponent as MarkingToken } from "../images/MarkingToken.svg";
import { ReactComponent as ThreeMarkingToken } from "../images/3MarkingTokens.svg";
import { ReactComponent as FiveMarkingToken } from "../images/5MarkingTokens.svg";
import { Typography } from "@mui/material";

const numQuestions = 1200
const numMockExams = 23

function Store({ userId }) {
  const [storeObj, setStoreObj] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [userPlan, setUserPlan] = useState(null)

  const fetchPrices = async () => {
    const res = await getStoreObj()
    setStoreObj(res)
    if (res) {
      setIsLoading(false)
    }
  }

  const fetchUserPlan = async () => {
    if (userId !== undefined || userId !== null) {
      const data = await getUserSubscriptionDetails(userId)
      if (data !== null) {
        if (data.productName === "Free Year Plan") {
          setUserPlan("Yearly Plan")
        } else {
          setUserPlan(data.productName)
        }
      }
    }
  }

  const getButtonContent = (productName, userPlanName, isPlan) => {
    if (isPlan) {

      if (productName === userPlanName) {
        return (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', }}>
            <Typography className="priceAmount">
              Subscribed
            </Typography>
          </Box>
        )
      }
      if (userPlan) {
        return (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Typography className="priceAmount">
              Change Subscription
            </Typography>
          </Box>
        )
      }

      // if (storeObj.plans[productName].discount) {
      //   return (
      //     <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', gap: 1 }}>
      //       <Typography className="priceAmountDim">
      //         <strike>
      //           ${(storeObj.plans[productName].priceAmount / (1 - storeObj.plans[productName].discount)) / 100}
      //         </strike>
      //       </Typography>
      //       <Typography className="priceAmount">
      //         ${storeObj.plans[productName].priceAmount / 100}
      //       </Typography>
      //     </Box>
      //   )
      // }

      return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Typography className="priceAmount">
            Get Started
          </Typography>
        </Box>
      )

    } else {
      if (storeObj.mTokens[productName].discount) {
        return (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', gap: 1 }}>
            <Typography className="priceAmountDim">
              <strike>
                ${(storeObj.mTokens[productName].priceAmount / (1 - storeObj.mTokens[productName].discount)) / 100}
              </strike>
            </Typography>
            <Typography className="priceAmount">
              ${storeObj.mTokens[productName].priceAmount / 100}
            </Typography>
          </Box>
        )
      }

      return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <Typography className="priceAmount">
            ${storeObj.mTokens[productName].priceAmount / 100}
          </Typography>
        </Box>
      )
    }

  }

  // LEAVE THIS IN-LINE
  const displayDiscount = (price, discount) => {
    if (discount) {
      return (
        <span><span style={{ color: '#3d5d78' }}><span style={{ fontSize: '11pt' }}>$</span><strike>{discount / 100}</strike> </span><span><span style={{ fontSize: '11pt' }}>$</span>{price / 100} </span></span>
      )
    } else {
      return (
        <span><span style={{ fontSize: '11pt' }}>$</span>{price / 100} </span>
      )
    }
  }
  const getPriceContent = (productName, isPlan) => {
    if (isPlan) {
      const getIntervalText = () => {
        const singleInterval = storeObj.plans[productName].intervalCount === 1
        let intervalCount = singleInterval ? '' : storeObj.plans[productName].intervalCount
        let interval = storeObj.plans[productName].interval
        return `billed every ${intervalCount} ${interval}${singleInterval ? '' : 's'}`
      }

      const singleInterval = storeObj.plans[productName].intervalCount === 1
      const intervalIsWeek = storeObj.plans[productName].interval === 'week'
      const intervalIsMonth = storeObj.plans[productName].interval === 'month'

      if (intervalIsWeek && singleInterval) {
        return (
          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', justifyItems: 'center', mt: '10px', mb: '20px' }}>
            <Typography className="serviceName">
              {displayDiscount(storeObj.plans[productName].priceAmount, storeObj.plans[productName].discount)}
              <span style={{ fontSize: '12pt', color: '#5e6782' }}>per week</span>
            </Typography>
          </Box>
        )
      } else if (intervalIsMonth) {
        return (
          <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center', justifyItems: 'center', my: '10px', mb: '20px' }}>
            <Typography className="serviceName" sx={{ lineHeight: 1 }}>
              {displayDiscount(Math.round(((storeObj.plans[productName].priceAmount) / storeObj.plans[productName].intervalCount / 28) * 7), storeObj.plans[productName].discount)}
              <span style={{ fontSize: '12pt', color: '#5e6782' }}>per week</span>
            </Typography>
            <Box sx={{ fontSize: '12pt', color: '#5e6782', mt: 0 }}>
              ${storeObj.plans[productName].priceAmount / 100} {getIntervalText()}
            </Box>
          </Box>
        )
      }
    }
  }

  useEffect(() => {
    fetchPrices()
    fetchUserPlan()
  }, [])

  if (isLoading | redirect) {
    return (<LoadingScreen text={'Loading Store'} />)
  }

  const handleButtonClick = (productName, isPlan) => {
    setRedirect(true)
    if (isPlan) {
      if (userPlan) {
        // window.location.href = customerPortalLink
        sendToCustomerPortal()
        return
      }
      sendToCheckout(storeObj.plans[productName].priceId, true)
    } else {
      sendToCheckout(storeObj.mTokens[productName].priceId, false)
    }
  }

  return (
    <Box sx={{ flexGrow: 1, mx: { xs: 0, sm: 0, md: 4 }, width: "100%", mt: 2 }}>
      <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <Box sx={{ display: "flex", backgroundColor: "#ffffff", color: "#1ca7ec", fontSize: "22pt", fontWeight: 700, borderRadius: 3, px: 4 }}>
          Plans
        </Box>
      </Box>
      <div id="Pricing" className="pricing">
        <div className="container">
          <Stack direction='row' className='row'>
            <div className="priceBox">
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div className="icon">
                  <OneMonth className="image" />
                </div>
                <div className="priceContent">
                  {getPriceContent('Per Week', true)}
                </div>
                <ul className="includes">
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    {numQuestions}+ Practice Questions
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    {numMockExams}+ Mock Exams
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Regularly added quizzes
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    All-Inclusive Study Guides
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    In-depth Analysis
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Detailed Solutions
                  </li>
                </ul>
              </Box>
              <ButtonUnstyled
                onClick={() => {
                  handleButtonClick("Per Week", true)
                }}
              >
                {getButtonContent('Per Week', userPlan, true)}
              </ButtonUnstyled>
            </div>
            <div className="priceBox">
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Box sx={{
                  backgroundColor: "#10be7f",
                  px: 3,
                  py: 1,
                  borderRadius: 5,
                  fontWeight: 900,
                  color: '#FFFFFF',
                  marginBottom: 1
                }}>Save 30%</Box>
                <div className="icon">
                  <ThreeMonth className="image" />
                </div>
                <div className="priceContent">
                  {getPriceContent('Per Month', true)}
                </div>
                <ul className="includes">
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    <strong>
                      1 × free Marking Token
                    </strong>
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    {numQuestions}+ Practice Questions
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    {numMockExams}+ Mock Exams
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Regularly added quizzes
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    All-Inclusive Study Guides
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    In-depth Analysis
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Detailed Solutions
                  </li>
                </ul>
              </Box>
              <ButtonUnstyled
                onClick={() => {
                  handleButtonClick("Per Month", true)
                }}
              >
                {getButtonContent('Per Month', userPlan, true)}
              </ButtonUnstyled>
            </div>
            <div className="priceBox">
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Box sx={{
                  backgroundColor: "#ffb74d",
                  px: 3,
                  py: 1,
                  borderRadius: 5,
                  fontWeight: 900,
                  color: '#FFFFFF',
                  marginBottom: 1
                }}>Save 40%</Box>
                <div className="icon">
                  <Year className="image" />
                </div>
                <div className="priceContent">
                  {getPriceContent('Per Three Months', true)}
                </div>
                <ul className="includes">
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    <strong>
                      3 × free Marking Tokens
                    </strong>
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    {numQuestions}+ Practice Questions
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    {numMockExams}+ Mock Exams
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Regularly added quizzes
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    All-Inclusive Study Guides
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    In-depth Analysis
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Detailed Solutions
                  </li>
                </ul>
              </Box>
              <ButtonUnstyled
                onClick={() => {
                  handleButtonClick("Per Three Months", true)
                }}
              >
                {getButtonContent('Per Three Months', userPlan, true)}
              </ButtonUnstyled>
            </div>
          </Stack>
        </div>
      </div>

      <Box sx={{ display: "flex", width: "100%", justifyContent: "center", marginBottom: '2rem' }}>
        <Box sx={{ display: "flex", backgroundColor: "#ffffff", color: "#1ca7ec", fontSize: "22pt", fontWeight: 700, borderRadius: 3, px: 4 }}>
          Add-Ons
        </Box>
      </Box>
      <div id="Pricing" className="pricing">
        <div className="container">
          <Stack direction='row' className='row'>
            <div className="priceBox" style={{ minHeight: '500px' }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div className="icon">
                  <FiveMarkingToken className="image" />
                </div>
                <h3 className="serviceName">5 Marking Tokens</h3>
                <ul className="includes">
                  <li>
                    5 writing papers of your choice marked by qualified Selective Tutors
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Tailored feedback
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Prioritized marking
                  </li>
                </ul>
              </Box>
              <ButtonUnstyled
                onClick={() => {
                  handleButtonClick("5 Marking Tokens", false)
                }}
              >
                {getButtonContent('5 Marking Tokens', userPlan, false)}
              </ButtonUnstyled>
            </div>
            <div className="priceBox" style={{ minHeight: '500px' }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div className="icon">
                  <ThreeMarkingToken className="image" />
                </div>
                <h3 className="serviceName">3 Marking Tokens</h3>
                <ul className="includes">
                  <li>
                    3 writing papers of your choice marked by qualified Selective Tutors
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Tailored feedback
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Prioritized marking
                  </li>
                </ul>
              </Box>
              <ButtonUnstyled
                onClick={() => {
                  handleButtonClick("3 Marking Tokens", false)
                }}
              >
                {getButtonContent('3 Marking Tokens', userPlan, false)}
              </ButtonUnstyled>
            </div>
            <div className="priceBox" style={{ minHeight: '500px' }}>
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <div className="icon">
                  <MarkingToken className="image" />
                </div>
                <h3 className="serviceName">1 Marking Token</h3>
                <ul className="includes">
                  <li>
                    1 writing paper of your choice marked by qualified Selective Tutors
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Tailored feedback
                  </li>
                  <li>
                    <svg viewBox="0 0 20.2 15.4">
                      <polygon points="20.2,2.8 7.6,15.4 0,7.8 2.8,5 7.6,9.8 17.4,0 " />
                    </svg>
                    Prioritized marking
                  </li>
                </ul>
              </Box>
              <ButtonUnstyled
                onClick={() => {
                  handleButtonClick("1 Marking Token", false)
                }}
              >
                {getButtonContent('1 Marking Token', userPlan, false)}
              </ButtonUnstyled>
            </div>
          </Stack>
        </div>
      </div>
    </Box >
  )
}

export default Store