import React, { useEffect, useState } from "react";

import ReactTooltip from "react-tooltip";

import '../style/Heatmap.css';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "react-bootstrap/Stack";
import { getHeatMapValues } from "../app_backend";

import CalendarHeatmap from 'react-calendar-heatmap';
CalendarHeatmap.prototype.getHeight = function () {
  return this.getWeekWidth() + (this.getMonthLabelSize() - this.props.gutterSize);
};

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function getRange(count) {
  return Array.from({ length: count }, (_, i) => i);
}


function CalendarHeatMap({ user_id }) {
  const today = new Date()
  const [values, setValues] = useState([])

  const fetchHeatMap = async () => {
    const data = await getHeatMapValues(user_id, -60)
    setValues(data)
  }

  useEffect(() => {
    if (user_id !== undefined) {
      fetchHeatMap()
    }
  }, [user_id]);

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Box sx={{ paddingTop: 1 }}>
        <Box sx={{ width: '100%', px: 3 }}>
          <Typography sx={{ fontWeight: 800, fontSize: '1.2vw' }}>60 day activity</Typography>
        </Box>
        <Box sx={{ marginBottom: 2, width: '100%', height: '100%', pr: 2 }}>
          <CalendarHeatmap
            startDate={shiftDate(today, -61)}
            endDate={today}
            values={values}
            classForValue={(value) => {
              if (!value) {
                return "color-empty"
              } else if (value.count > 7) {
                return `color-github-${7}`
              } else {
                return `color-github-${value.count}`
              }
            }}
            tooltipDataAttrs={(value) => {
              if (value.count === null) {
                return {
                  "data-tip": `No quizzes completed`
                };
              }
              if (value.count === 1) {
                return {
                  "data-tip": `${value.count} quiz completed on ${value.date.toDateString()}`
                };
              }
              return {
                "data-tip": `${value.count} quizzes completed on ${value.date.toDateString()}`
              };
            }}
            horizontal={true}
            showWeekdayLabels={true}
            weekdayLabels={['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']}
            onClick={(value) => { }
            }
          />
          <ReactTooltip />
        </Box>
      </Box>
    </Box >
  )
}

export default CalendarHeatMap