import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";

function Learn3() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography
          variant="h4"
          id="cloze"
          sx={{ paddingBottom: 2, fontWeight: 600 }}
        >
          What is a Cloze Passage?
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          A cloze passage is a section in the Selective Test where sentences are
          removed from a text and students must fill in those missing gaps with
          appropriate sentences in the options provided.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 1, fontWeight: 600 }}>
          How to approach a Cloze Passage?
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          {" "}
          Cloze passages can be challenging to do. Follow the below recommended
          steps to help you.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 1: Understanding the text
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Understanding the text is an integral part of completing cloze
          passages. It allows you to understand the direction or perspective the
          writer is trying to take and thus will allow you to gain an idea of
          the purpose of the text.
        </Typography>
        <Alert
          icon={<TipsAndUpdatesRoundedIcon fontSize="inherit" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, marginBottom: 3 }}
        >
          Hint: Most of the time, the title will reveal what the text will be
          about.
        </Alert>
        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 2: Look at the missing sentence
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Look around the missing sentence for context. Do this by searching for
          clues in nearby words and sentences to gather an idea of what the
          missing sentence is about. Look through the provided options and try
          piecing them together.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 3: Reread the area of the missing sentence after selecting an
            option
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          For example, you selected “Alex decided to go to the shops to buy
          apples for Bing.” in the following passage. Bing really likes apples
          and wanted some for his birthday.{" "}
          <u>Alex decided to go to the shops to buy apples for Bing.</u> After
          Alex bought the apples, Bing was extremely excited to eat it.
          <br />
          Reread the whole section above, and if it makes sense, then it is the
          correct answer.
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography
          variant="h4"
          id="extracts"
          sx={{ paddingBottom: 2, fontWeight: 600 }}
        >
          What are extracts?
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          Extract is one of the subsections of the Reading test, where you are
          given a few small sections to read. They are called extracts because
          they are <i>extracted</i> from different texts. They usually all have
          a common theme.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          How to approach extracts?
        </Typography>
        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 1: Understand the different extracts
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Have a brief understanding of what each extract is talking about. The
          extracts usually all have a common theme, but talk about different
          things within that theme.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 2: Read the questions
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Read the questions and try to identify which extract will most likely
          answer that question.
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography
          variant="h4"
          id="narratives"
          sx={{ paddingBottom: 2, fontWeight: 600 }}
        >
          What are Narratives?
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          A narrative includes fictional and non-fictional writing that tells a
          story. It can vary from a novel that tells a long story, or it can be
          a very short passage. Narratives are the most common texts in the
          Selective Test.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          How to approach Narratives?
        </Typography>
        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 1: Understanding the Text
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          <b>Understanding text is essential</b>. Without it, you are not able
          to answer the questions that follow. The title is the very first thing
          that you see; it is really likely to tell you something about the
          text. After reading the title, we go down to the question section to
          figure out what we need to find.
        </Typography>
        <Alert
          icon={<TipsAndUpdatesRoundedIcon fontSize="inherit" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, marginBottom: 3 }}
        >
          Tip: Try skimming through the text to look for key words that help you
          answer the questions.
        </Alert>

        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 2: Understand what you are trying to find
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Read the question, now go back to the passage and try to find the
          answer to it. Be sure to manage your time and move on to the next
          question if you spend too much time on one.
        </Typography>
        <Alert
          icon={<TipsAndUpdatesRoundedIcon fontSize="inherit" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, marginBottom: 3 }}
        >
          Tip: The answers to the questions are usually found in chronological
          order; for e.g. Q1 will most likely be found near the top of the
          passage and so forth.
        </Alert>
      </Box>

      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography
          variant="h4"
          id="poetry"
          sx={{ paddingBottom: 2, fontWeight: 600 }}
        >
          What is Poetry?
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          Poetry is a form of writing that uses words and verses to evoke a
          writer's feelings and thoughts. However, these are hidden in the poems
          symbolism and language techniques, which is why poetry is often seen
          as the most challenging text to understand in the Selective Test.
        </Typography>
      </Box>

      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: 600, paddingBottom: 1 }}>
          How to approach Poetry?
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Understanding poetry can be very difficult at first, as poetry often
          has words that may confuse the reader more.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 1: Figure out the meaning of the poem
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Finding out the meaning or the purpose will guide your understanding
          of the poem in the right direction. There are often clues scattered
          throughout the poem that highlights the overall meaning. It can{" "}
          <b>usually</b> be found in the title of the poem or the last few lines
          of a poem.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 2: Understand the different techniques that are used
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          Often, poems utilise several techniques such as imagery or symbolism.
          One way to figure this out is by reading the poem and breaking down
          the techniques. For example, if the imagery is used, ask yourself,
          “Why is the image used? What is the poet trying to convey?” answering
          these questions will allow you to understand the poem further.
        </Typography>
        <Box
          sx={{
            backgroundColor: "#dae4f5",
            padding: 2,
            marginBottom: 1,
            borderRadius: 4,
            color: "#3E54AC",
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 600 }}>
            Step 3: Rereading the poem
          </Typography>
        </Box>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          It is often recommended to reread poems in the Selective Test in order
          to make sure you completely understand the meaning of it. Sometimes
          jumping over a sentence can cause you to misinterpret the idea being
          portrayed in the poem! So make sure to reread any parts you are unsure
          of!
        </Typography>
      </Box>
    </Box>
  );
}

export default Learn3;
