import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Toolbar, Typography, Box, Paper, Stack, Card, CardActionArea, CardContent, Button } from "@mui/material";
import QuizList from "../../pages/QuizList"
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { GetTopicList } from "../../app_backend"
import Fade from '@mui/material/Fade';

import "../../style/Practice.css"

function PracticeContent({ subject, setSelectedPage, setSelectedQuiz, subscription }) {
  const navigate = useNavigate();
  const [topics, setTopics] = useState([])
  const [selectedTopic, setSelectedTopic] = useState('All');
  const [renderTransition, setRenderTransition] = useState(false)

  const fetchTopicList = async () => {
    try {
      const data = await GetTopicList(subject)
      const ts = Array.from(data)
      ts.unshift('All')
      setTopics(ts)
      if (data) {
        setRenderTransition(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchTopicList()
  }, [subject])

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Stack direction='row' spacing={4} sx={{ py: '1rem', width: '100%', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{
          width: '70%', minHeight: '60vh', height: '62vh', display: 'flex',
          backgroundColor: '#F1F4FF', borderRadius: 6, px: 1, overflow: 'hidden'
        }}>
          <QuizList
            subject={subject}
            topic={selectedTopic}
            ignoreMocks={true}
            setSelectedPage={setSelectedPage}
            setSelectedQuiz={setSelectedQuiz}
            subscription={subscription}
          />
        </Box>
        <Box sx={{ width: '100%', maxWidth: 260, height: '25rem', borderRadius: 3 }}
          className='topic_tab'>
          <List component="nav">
            {topics.map((topic, i) => {
              return (
                <Fade key={i} in={renderTransition} >
                  <ListItemButton
                    key={`${topic}`}
                    selected={selectedTopic === topic}
                    onClick={() => { setSelectedTopic(topic); }}
                    sx={{ pl: 4 }}
                  >
                    <ListItemText primary={topic} />
                  </ListItemButton>
                </Fade>
              )
            })}
          </List>
        </Box>
      </Stack >
    </Box >
  )
}

export default PracticeContent