import React, { useEffect, useState, useRef } from "react";

import { auth } from "../auth";
import Navbar from "../components/NavBar";

import WritingReview from "./WritingReview";
import LoadingScreen from "../components/LoadingScreen";

import { fetchPaperIfMarked } from "../app_backend";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

function WritingResults({
  markingObject,
  setSelectedPage,
  page = null,
  showStats = true,
}) {
  const [user, loading, error] = useAuthState(auth);
  const [markedPaper, setMarkedPaper] = useState(null);

  const [authLoading, setAuthLoading] = useState(true);
  const [paperLoading, setPaperLoading] = useState(true);

  const navigate = useNavigate();

  const attemptId = markingObject?.attempt_id;
  const quiz = markingObject?.quiz_id;

  const fetchMarkedPaper = async () => {
    // console.log(attemptId, quiz);
    const fetched = await fetchPaperIfMarked(attemptId, user?.uid, quiz);
    setMarkedPaper(fetched);
    setPaperLoading(false);
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    if (markingObject !== null) {
      fetchMarkedPaper().then(() => {
        setAuthLoading(false);
      });
    }
  }, [user, loading]);

  if (authLoading || paperLoading) {
    return (
      <Box sx={{ height: "100%", width: "100%", pt: "10%" }}>
        <LoadingScreen text={"Checking Paper"} size={"small"} />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      {markedPaper === null ? (
        <Box>
          {page !== null ? (
            <Button
              variant="outlined"
              sx={{
                height: "3rem",
                borderRadius: 8,
                bgcolor: "#f1f4ff",
                mt: 3,
              }}
              onClick={() => setSelectedPage(page)}
            >
              <Typography color="primary" sx={{ paddingRight: 1 }}>
                back
              </Typography>
              <ExitToAppIcon color="primary" />
            </Button>
          ) : null}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: 6,
                my: "2rem",
                backgroundColor: "#f1f4ff",
                width: "50%",
              }}
            >
              <Box
                sx={{
                  p: "2rem",
                  width: "auto",
                  color: "#1f2f98",
                  fontSize: "16pt",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Check back later when your writing has been marked!
              </Box>
            </Paper>
          </Box>
        </Box>
      ) : (
        <WritingReview
          markingObject={markedPaper}
          setSelectedPage={setSelectedPage}
          page={page}
          showStats={showStats}
        />
      )}
    </Box>
  );
}

export default WritingResults;
