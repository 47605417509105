import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut, sendEmailVerification, updateProfile
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  addDoc,
  where,
  setDoc,
  doc,
} from "firebase/firestore";
import { firebaseConfig } from "./Config";
import { initializeApp } from "firebase/app";

initializeApp(firebaseConfig)

const db = getFirestore();
const auth = getAuth();

const LogInWithEmailAndPassword = async (email, password) => {
  await signInWithEmailAndPassword(auth, email, password);

}

const RegisterWithEmailAndPassword = async (name, email, password, number) => {
  const now = new Date()
  const res = await createUserWithEmailAndPassword(auth, email, password);
  const user = res.user;
  await sendVerification()
  await setDoc(doc(db, "users", user.uid), {
    name,
    authProvider: "local",
    email,
    number,
    registerDate: now.toDateString(),
    attemptMap: {},
    authenticationLevel: 0
  });
  await updateProfile(auth.currentUser, { displayName: name }).catch(
    (err) => console.log(err)
  );

  await setDoc(doc(db, "users", user.uid, "grades", "score_frequency"), {
    "S": 0,
    "A": 0,
    "B": 0,
    "C": 0,
    "D": 0
  })
}

const initUser = async (name, email, user) => {
  const now = new Date()
  await setDoc(doc(db, "users", user.uid), {
    name,
    authProvider: "local",
    email,
    registerDate: now.toDateString(),
    attemptMap: {}

  });
  const subjects = ["maths", "reading", "thinking", "writing"]
  subjects.forEach(async (subject) => {
    await setDoc(doc(db, "users", user.uid, "grades", subject), {
      num_attempts: 0
    })
  })
}

const sendVerification = async () => {
  var user = auth.currentUser
  try {
    await sendEmailVerification(user)
  } catch (err) {
    console.log(err)
  }

}

const SendPasswordReset = async (email) => {
  await sendPasswordResetEmail(auth, email);
  alert("Password reset link sent!");
  //replace alerts
}

const LogoutUser = () => {
  signOut(auth);
};

const ReauthenticateWithEmailPassword = () => { };

export {
  auth,
  db,
  LogInWithEmailAndPassword,
  RegisterWithEmailAndPassword,
  SendPasswordReset,
  LogoutUser,
  ReauthenticateWithEmailPassword,
};
