import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Switch from "@mui/material/Switch";

import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import { GetReviewTopicList, getNumReviewQuestions, getReviewQuestions } from '../app_backend'
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import { getBreakPointStyle } from '../components/Mobile/StyleHelper';
import NoMobileDialog from '../components/Mobile/NoMobileDialog';

const Revision = ({ user_id, setSelectedPage, setSelectedSubject, selectedSubject }) => {
  const [topic, setTopic] = useState('ANY')
  const [numQs, setNumQs] = useState(1)
  const [topicList, setTopicList] = useState(['ANY'])
  const [maxQs, setMaxQs] = useState(5)
  const [sliderDisabled, setSliderDisabled] = useState(false)
  const [wantTimer, setWantTimer] = useState(false)
  const [open, setOpen] = useState(false)

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false)

  const fetchMaxQuestions = async () => {
    const data = await getNumReviewQuestions(user_id, selectedSubject, topic)
    if (data <= 1) {
      setSliderDisabled(true)
    } else {
      setSliderDisabled(false)
      setMaxQs(data)
    }
  }
  const fetchQuestions = async () => {
    const data = await getReviewQuestions(user_id, selectedSubject, topic, numQs)
    localStorage.setItem('qList', JSON.stringify(data))
    return data
  }

  const fetchTopics = async () => {
    const topics = await GetReviewTopicList(user_id, selectedSubject)
    const list = Array.from(topics)
    list.unshift('ANY')
    setTopicList(list)
  }

  const handleStartRevision = async () => {
    if (window.innerWidth <= 1000) {
      setOpen(true)
      return;
    }
    setIsLoading(true)
    const data = await fetchQuestions()
    if (data) {
      setIsLoading(false)
    }
    navigate(`/Revision/${selectedSubject}/Questions`)
  }

  // when subject or topic is changed, fetch number of questions
  // belonging to that topic for the slider
  useEffect(() => {
    fetchMaxQuestions()
  }, [selectedSubject, topic])

  // When subject is changed, fetch new topic list
  useEffect(() => {
    setTopic('ANY')
    fetchTopics()
  }, [selectedSubject])

  useEffect(() => {
    localStorage.clear()
    localStorage.setItem("in_quiz", 0)
    localStorage.setItem("start_timer", 0)
  }, []);


  useEffect(() => {
    if (topic !== 'MOCK') {
      if (wantTimer) {
        localStorage.setItem("timer_active", true)
      } else {
        localStorage.setItem("timer_active", false)
      }
    }
  }, [wantTimer])

  if (isLoading) {
    return (
      <LoadingScreen text={"Starting Revision..."} />
    )
  }

  return (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', py: getBreakPointStyle(1, 0) }}>
      <Box sx={{ maxWidth: '600px', p: getBreakPointStyle(1, 3), bgcolor: '#F1F4FF', borderRadius: 3 }}>
        <Box sx={{
          p: 2,
          overflow: "visible",
          borderRadius: 3,
          backgroundColor: "#e5eafb",
        }}>
          <Typography sx={{ fontSize: '16pt', fontWeight: 700, color: '#1f2f98' }}>
            Review and reattempt challenging questions from your past quizzes
          </Typography>
          Select a subject and topic to attempt a randomly generated quiz containing questions you previously got incorrect.
        </Box>
        <Box sx={{
          borderRadius: 3,
          backgroundColor: "#ffffff",
          marginTop: 3,
          p: getBreakPointStyle(1, 2)
        }}>
          <Stack spacing={3} sx={{ width: '100%', px: getBreakPointStyle(1, 2) }}>
            <Box>
              <Typography sx={{ fontSize: getBreakPointStyle('14pt', '18pt'), fontWeight: 700, marginBottom: 1 }}>
                Subject
              </Typography>
              <Select
                id="subject-select"
                value={selectedSubject}
                label="Subject"
                onChange={(e) => { setSelectedSubject(e.target.value) }}
                sx={{ width: '100%' }}
              >
                <MenuItem value={"reading"}>Reading</MenuItem>
                <MenuItem value={"maths"}>Mathematical Reasoning</MenuItem>
                <MenuItem value={"thinking"}>Thinking Skills</MenuItem>
              </Select>
            </Box>

            <Box>
              <Typography sx={{ fontSize: getBreakPointStyle('14pt', '18pt'), fontWeight: 700, marginBottom: 1 }}>
                Topic
              </Typography>
              <Select
                id="topic-select"
                value={topic}
                label="Topic"
                onChange={(e) => { setTopic(e.target.value) }}
                sx={{ width: '100%' }}

              >
                {topicList.map((topic, i) => <MenuItem key={i} value={topic}>{topic}</MenuItem>)}
              </Select>
            </Box>

            <Box>
              <Typography sx={{ fontSize: getBreakPointStyle('14pt', '18pt'), fontWeight: 700, marginBottom: 3.5 }}>
                Number of Questions
              </Typography>
              <Slider
                valueLabelDisplay="on"
                step={1}
                marks
                min={1}
                max={maxQs}
                value={numQs}
                onChange={(e) => { setNumQs(e.target.value) }}
                disabled={sliderDisabled}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Button size='large' variant='outlined' sx={{ borderRadius: 6, display: getBreakPointStyle('none', 'flex') }} onClick={() => setSelectedPage("results_list")}>
                <PlaylistAddCheckOutlinedIcon sx={{ marginRight: 0.5, marginTop: 0.3 }} />
                Attempts
              </Button>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
                <Box sx={{ borderRadius: 99, bgcolor: '#e5eafb', py: 0.5, display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10rem' }}>
                  <Switch checked={wantTimer} onChange={(e) => { setWantTimer(e.target.checked) }} />
                  {wantTimer ?
                    <Typography sx={{ fontSize: '14pt', fontWeight: 600, color: '#1f2f98', marginRight: 1 }}>
                      Timer On
                    </Typography>
                    :
                    <Typography sx={{ fontSize: '14pt', marginRight: 1 }}>
                      Timer Off
                    </Typography>
                  }
                </Box>
                <Button
                  size='large'
                  variant='contained'
                  sx={{ borderRadius: 6 }}
                  onClick={() => { handleStartRevision() }}
                  disabled={sliderDisabled}
                >
                  {sliderDisabled ? "No Questions" : "Start"}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>

      <NoMobileDialog open={open} setOpen={setOpen} />
    </Box>
  )
}

export default Revision