import React, { useEffect, useState, useRef } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../auth';
import {
  checkUserIdExists,
  sendNotificationsAll,
  sendNotificationsArr,
} from '../app_backend';
import Navbar from '../components/NavBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {
  Modal,
  Radio,
  FormControlLabel,
  FormLabel,
  IconButton,
} from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { BsFillTrashFill } from 'react-icons/bs';
import LoadingScreen from '../components/LoadingScreen';
import Switch from '../components/Switch';

const modalStyle = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#f0eeea',
  boxShadow: 24,
  marginTop: '6rem',
  p: 5,
  borderRadius: 7,
};

function Notify() {
  /* 
    PAGE DESCRIPTION

    LEFT: Box with textfields for Title & Message

    RIGHT: Box with radiobuttons and accordion for send to all / specific uids

    SEND: Button which checks that a radio button has been selected and specific fields have been filled in.

  */

  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
  }, [user, loading]);

  const [content, setContent] = useState({
    title: '',
    message: '',
  });

  // handle change for title and message

  const handleChange = (prop) => (event) => {
    if (prop == 'title' && event.target.value.toString().length >= 20) {
      setIsTitleLong(true);
      return;
    } else {
      setIsTitleLong(false);
    }
    if (prop == 'message' && event.target.value.toString().length >= 200) {
      setIsMessageLong(true);
      return;
    } else {
      setIsMessageLong(false);
    }
    setContent({ ...content, [prop]: event.target.value });
  };

  const clearContentField = () => {
    setContent({
      title: '',
      message: '',
    });
  };

  // modal popup handling

  const [openExit, setOpenExit] = useState(false);

  const handleOpenExit = () => setOpenExit(true);
  const handleCloseExit = () => {
    setOpenExit(false);
  };

  // email option
  const [switchValue, setSwitchValue] = useState(false);

  // ERROR CHECKS

  const [isTitleLong, setIsTitleLong] = useState(false);
  const [isMessageLong, setIsMessageLong] = useState(false);

  // radio group

  const [selectedValue, setSelectedValue] = useState('all');
  const [openSelection, setOpenSelection] = useState(false);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === 'individual') {
      setOpenSelection(true);
    } else {
      setOpenSelection(false);
    }
  };

  // user id handling

  const [userId, setUserId] = useState('');
  const [arrUserIds, setArrUserIds] = useState([]);

  const [isUserValid, setIsUserValid] = useState(true);

  const handleUserIdChange = (event) => {
    setUserId(event.target.value);
  };

  const clearUserField = () => {
    setUserId('');
  };

  const clearUserArrField = () => {
    setArrUserIds([]);
  };

  const handleAddClick = async () => {
    // check if user is empty string OR is already in the array
    if (userId.trim() === '' || arrUserIds.includes(userId)) {
      setIsUserValid(false);
      return;
    }

    setIsUserValid(true);

    const flag = await checkUserIdExists(userId);

    if (!flag) {
      setIsUserValid(false);
      return;
    }

    arrUserIds.push(userId);
    clearUserField();
  };

  const removeElement = (index) => {
    const newArrUserIds = arrUserIds.filter((_, i) => i !== index);
    setArrUserIds(newArrUserIds);
  };

  // send notifications handling

  const [errMessage, setErrMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const checkError = async () => {
    let message = '';
    if (content.title.trim() === '' || content.message.trim() === '') {
      message = 'Content fields are not filled in properly.';
      setErrMessage(message);
      handleOpenExit();
    }
    if (selectedValue === 'individual' && arrUserIds.length === 0) {
      message = 'Select at least one user ID to send to.';
      setErrMessage(message);
      handleOpenExit();
    }
  };

  const sendNotifications = async () => {
    // check which radio buttons are selected
    // ALL

    // if switch value is true, then email too
    if (selectedValue === 'all') {
      setIsLoading(true);
      await sendNotificationsAll(
        content.title,
        content.message,
        user?.uid,
        switchValue
      );
    }
    // INDIVIDUAL
    if (selectedValue === 'individual') {
      setIsLoading(true);
      await sendNotificationsArr(
        content.title,
        content.message,
        user?.uid,
        arrUserIds,
        switchValue
      );
    }
    setIsLoading(false);
  };

  // email handling

  const sendEmailonNotifications = async () => {};

  // loading
  if (isLoading) {
    return <LoadingScreen text={'Sending Notifications...'}></LoadingScreen>;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        mx: 4,
        width: '100%',
        overflow: 'auto',
        p: 4,
      }}
    >
      <Stack
        direction='row'
        spacing={8}
        sx={{
          height: '40rem',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          p: 8,
        }}
      >
        <Box
          sx={{
            height: '100%',
            width: '40%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card
            elevation={0}
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 6,
            }}
          >
            <Stack
              direction='column'
              spacing={2}
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                p: 8,
              }}
            >
              <Typography variant='h5' color='text.primary'>
                Content
              </Typography>
              <TextField
                error={isTitleLong}
                helperText={isTitleLong ? 'Title is too long.' : ' '}
                fullWidth
                label='Title'
                id='notif-title'
                onChange={handleChange('title')}
                value={content.title}
              />
              <TextField
                error={isMessageLong}
                helperText={isMessageLong ? 'Message is too long.' : ' '}
                fullWidth
                label='Message'
                id='notif-message'
                onChange={handleChange('message')}
                value={content.message}
                multiline
                rows={10}
              />
            </Stack>
          </Card>
        </Box>
        <Box
          sx={{
            height: '100%',
            width: '60%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Card
            elevation={0}
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 6,
              overflow: 'auto',
            }}
          >
            <Stack
              direction='column'
              spacing={2}
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                p: 8,
              }}
            >
              <Typography variant='h5' color='text.primary' sx={{ mt: -4 }}>
                Delivery
              </Typography>
              <Box
                sx={{
                  height: '40%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby='delivery-group-label'
                    name='delivery-group'
                    value={selectedValue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value='all'
                      control={<Radio />}
                      label='Send to all accounts.'
                    />
                    <FormControlLabel
                      value='individual'
                      control={<Radio />}
                      label='Send to individual recipients.'
                    />
                  </RadioGroup>
                </FormControl>
                <Box sx={{ ml: '20%', display: 'flex', flexDirection: 'row' }}>
                  <Switch
                    isOn={switchValue}
                    handleToggle={() => {
                      setSwitchValue(!switchValue);
                    }}
                  />
                  {switchValue ? (
                    <Typography sx={{ ml: 2 }}>Notify & Email</Typography>
                  ) : (
                    <Typography sx={{ ml: 2 }}>Notify Only</Typography>
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  height: '60%',
                  width: '100%',
                }}
              >
                {openSelection && (
                  <Stack direction='row' sx={{ height: '100%', width: '100%' }}>
                    <Box
                      sx={{
                        height: '100%',
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }}
                    >
                      <TextField
                        error={!isUserValid}
                        helperText={
                          !isUserValid ? 'Invalid or duplicate User ID.' : ' '
                        }
                        label='User ID'
                        id='notif-uid'
                        onChange={handleUserIdChange}
                        value={userId}
                        sx={{ width: '85%' }}
                      />
                      <Button
                        variant='contained'
                        onClick={() =>
                          // handle adding user id
                          // handle clear
                          handleAddClick()
                        }
                        sx={{ borderRadius: 4, width: '40%', mt: 2 }}
                      >
                        <Typography
                          variant='h6'
                          fontSize={14}
                          sx={{ textTransform: 'None' }}
                        >
                          ADD
                        </Typography>
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        height: '100%',
                        width: '50%',
                        border: 1.3,
                        borderColor: 'black',
                        maxHeight: '10rem',
                        overflow: 'auto',
                        borderRadius: 2,
                      }}
                    >
                      <List dense={true}>
                        {/* light gray: #f2f3f9, dark gray: #c2c2c2 */}
                        {arrUserIds.map((uid, index) => {
                          return (
                            <ListItem
                              key={'listItem' + uid + index}
                              secondaryAction={
                                <IconButton
                                  edge='end'
                                  aria-label='delete'
                                  onClick={() => removeElement(index)}
                                >
                                  <BsFillTrashFill />
                                </IconButton>
                              }
                              sx={{
                                backgroundColor: '#f2f3f9',
                              }}
                            >
                              <Typography variant='h6' fontSize={14}>
                                {uid}
                              </Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </Stack>
                )}
              </Box>
              <Box
                sx={{
                  height: '10%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  variant='contained'
                  onClick={async () => {
                    // error check to open modal
                    checkError();
                    // send notification
                    await sendNotifications();
                    // clear all fields
                    clearUserField();
                    clearContentField();
                    clearUserArrField();
                  }}
                  sx={{ borderRadius: 4, width: '40%', mt: 2 }}
                >
                  <Typography
                    variant='h6'
                    fontSize={16}
                    sx={{ textTransform: 'None' }}
                  >
                    Send Notifications
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </Card>
        </Box>
      </Stack>

      <Modal open={openExit} onClose={handleCloseExit} hideBackdrop={false}>
        <Box sx={modalStyle}>
          <Typography color='error' variant='h6' fontSize={16}>
            {errMessage}
          </Typography>
          <Box
            sx={{
              marginTop: '1rem',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              sx={{ borderRadius: 6, mt: 3 }}
              variant='contained'
              onClick={() => {
                handleCloseExit();
              }}
            >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Notify;
