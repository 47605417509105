import React, { useEffect, useState, useRef } from "react";

import {
  addMCQuiz,
  addText,
  getTextRef,
  addFullMock,
  GetFreeMocks,
  addWritingQuiz,
  AdminGetQuizList,
  GetTopic,
  GetQuestionList,
  GetQuestion,
  GetText,
  GetTextWithId,
  canMakeMajorEdit,
  GetWritingQuestion,
  deleteQuestionsWithId,
  deleteTextsWithId,
  deleteQuizWithId,
} from "../app_backend";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Alert from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionActions from "@mui/material/AccordionActions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from "@mui/icons-material/Warning";
import Tooltip from "@mui/material/Tooltip";

import Editor from "../components/TextEditor";
import StringToHTML from "../components/StringToHTML";

import HTML2json from "html2json";
import parse from "html-react-parser";
var html2JSON = HTML2json.html2json;
var json2HTML = HTML2json.json2html;

class Question {
  constructor(
    options,
    correctOption,
    question,
    explanation,
    firebaseId = null
  ) {
    this.firebaseId = firebaseId;
    this.options = options;
    this.correctOption = correctOption;
    this.question = question;
    this.explanation = explanation;
  }
}

class ReadingQuestion {
  constructor(
    options,
    correctOption,
    question,
    explanation,
    textId,
    firebaseId = null
  ) {
    this.firebaseId = firebaseId;
    this.options = options;
    this.correctOption = correctOption;
    this.question = question;
    this.explanation = explanation;
    this.textId = textId;
  }
}

class Text {
  constructor(title, content, author, fireBaseRef) {
    this.title = title;
    this.content = content;
    this.author = author;
    this.fireBaseRef = fireBaseRef;
  }
}

function QuestionToDict(q) {
  const question = q.question + "";
  const explanation = q.explanation + "";
  return {
    firebaseId: q.firebaseId,
    options: q.options,
    answer: q.correctOption.toString(),
    content: html2JSON(question),
    explanation: html2JSON(explanation),
  };
}

function ReadingQuestionToDict(q) {
  const question = q.question + "";
  const explanation = q.explanation + "";
  console.log(q.firebaseId);
  return {
    firebaseId: q.firebaseId,
    options: q.options,
    answer: q.correctOption.toString(),
    content: html2JSON(question),
    explanation: html2JSON(explanation),
    textId: q.textId,
  };
}

function TextToDict(t) {
  const content = t.content + "";
  return {
    title: t.title,
    content: html2JSON(content),
    author: t.author,
  };
}

function Create({}) {
  const [quizName, setQuizName] = useState("");
  const [topic, setTopic] = useState("");
  const [subject, setSubject] = useState("");
  const [questions, setQuestions] = useState([]);
  const qs = useRef([]);
  const [texts, setTexts] = useState([]);
  const ts = useRef([]);
  const [saveOptions, setSaveOptions] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [openQuestionModal, setOpenQuestionModal] = useState(false);
  const [openTextModal, setOpenTextModal] = useState(false);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [qIndex, setQIndex] = useState(null);
  const [tIndex, setTIndex] = useState(null);

  const [originalQuiz, setOriginalQuiz] = useState(null);
  const [editWritingQuestion, setEditWritingQuestion] = useState(null);
  const [editQuiz, setEditQuiz] = useState(false);
  const [canMajorEdit, setCanMajorEdit] = useState(true);

  const deletedQIds = useRef([]);
  const deletedTIds = useRef([]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    fetchCanMakeMajorEdit();
  }, [editQuiz, originalQuiz]);

  async function fetchCanMakeMajorEdit() {
    if (originalQuiz) {
      const data = await canMakeMajorEdit(
        originalQuiz.subject,
        originalQuiz.quizId
      );
      console.log("CAN MAJOR EDIT:", data);
      setCanMajorEdit(data);
    }
  }

  function CreateQ() {
    // if (subject === "writing") {
    //   return <WritingArea />;
    // } else if (subject === "reading") {
    //   return <ReadingArea />;
    // } else if (subject === "full_mocks") {
    //   return <CreateFullMock />;
    // } else {
    //   return <CreateMCQuestion subject={subject} />;
    // }

    if (subject === "writing") {
      return <WritingArea editWritingQuestion={editWritingQuestion} />;
    } else if (subject === "full_mocks") {
      return <CreateFullMock />;
    }
  }

  function clearQuiz() {
    setQuizName("");
    setTopic("");
    setSubject("");
    setQuestions([]);
    setTexts([]);
    qs.current = [];
    ts.current = [];
  }

  function handleDeleteQuestion(index) {
    const currQuestions = questions.slice();

    if (editQuiz && currQuestions[index].firebaseId) {
      deletedQIds.current.push(currQuestions[index].firebaseId);
    }

    currQuestions.splice(index, 1);
    setQuestions(currQuestions);
    qs.current = currQuestions;
  }

  function handleEditQuestionWithId(index) {
    setQIndex(index);
    handleOpenQuestionModal();
  }

  function handleEditTextWithId(index) {
    setTIndex(index);
    handleOpenTextModal();
  }

  function handleDeleteText(index) {
    const currTexts = texts.slice();
    const textId = currTexts[index].fireBaseRef.id;

    if (editQuiz) {
      deletedTIds.current.push(textId);
    }
    currTexts.splice(index, 1);
    setTexts(currTexts);
    ts.current = currTexts;

    const currQuestions = questions.slice();
    for (const q of currQuestions) {
      if (q.textId === textId) {
        q.textId = "";
      }
    }
  }

  function validFields() {
    if ([quizName, topic, subject].includes("")) {
      return false;
    }
    if (questions === []) {
      return false;
    }
    if (topic.toUpperCase() === "MOCK") {
      let numQuestionsCorrect = true;
      // if (subject.toUpperCase() === "MATHS" && questions.length !== 35) {
      //   numQuestionsCorrect = false
      // } else if (subject.toUpperCase() === "THINKING" && questions.length !== 40) {
      //   numQuestionsCorrect = false
      // } else if (subject.toUpperCase() === "READING" && questions.length !== 30) {
      //   numQuestionsCorrect = false
      // }
      if (!numQuestionsCorrect) {
        alert("incorrect number of questions");
        return false;
      }
    }
    return true;
  }

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenQuestionModal = () => {
    setOpenQuestionModal(true);
  };

  const handleCloseQuestionModal = () => {
    setOpenQuestionModal(false);
    setQIndex(null);
  };

  const handleOpenTextModal = () => {
    setOpenTextModal(true);
  };

  const handleCloseTextModal = () => {
    setOpenTextModal(false);
    setTIndex(null);
  };

  const handleOpenSearch = () => {
    setOpenSearchModal(true);
  };

  const handleCloseSearchModal = () => {
    setOpenSearchModal(false);
  };

  function handleAddQuiz() {
    let valid = validFields();
    if (valid) {
      if (subject === "writing") {
        return;
      } else if (subject === "reading") {
        const questionsToDict = qs.current.map((q) => ReadingQuestionToDict(q));
        addMCQuiz(quizName, subject, topic, questionsToDict);
        texts.map((text) => {
          addText(text.fireBaseRef, TextToDict(text));
        });
      } else {
        const questionsToDict = qs.current.map((q) => QuestionToDict(q));
        addMCQuiz(quizName, subject, topic, questionsToDict);
      }

      if (editQuiz && canMajorEdit) {
        deleteQuestionsWithId(subject, deletedQIds.current);
        deleteTextsWithId(deletedTIds.current);
      }

      clearQuiz();
      setOpen1(true);
      setEditQuiz(false);
    } else {
      alert("Invalid. Please check fields.");
    }
  }

  function handleDeleteQuiz() {
    deleteQuizWithId(subject, quizName);
    deleteQuestionsWithId(
      subject,
      qs.current.map((q) => q.firebaseId)
    );
  }

  function TextForm({ tIndex }) {
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [author, setAuthor] = useState("");

    useEffect(() => {
      if (tIndex !== null) {
        const tObj = ts.current[tIndex];
        setTitle(tObj.title);
        setText(tObj.content);
        setAuthor(tObj.author);
      }
    }, [tIndex]);

    function clearFields() {
      setText("");
      setAuthor("");
      setTitle("");
    }

    function handleAddText() {
      if (text !== "" && author !== "" && title !== "") {
        let currTexts = texts.slice();
        if (tIndex === null) {
          currTexts.push(new Text(title, text, author, getTextRef()));
        } else {
          currTexts[tIndex] = new Text(
            title,
            text,
            author,
            currTexts[tIndex].fireBaseRef
          );
          handleCloseTextModal();
        }
        setTexts(currTexts);
        ts.current = currTexts;
        clearFields();
      } else {
        alert("Invalid. Please check fields.");
      }
    }
    return (
      <React.Fragment>
        <Box
          sx={{
            "& .MuiTextField-root": { my: 1, width: "100%" },
            width: "100%",
            maxWidth: "37vw",
          }}
          noValidate
          autoComplete="off"
        >
          <Stack sx={{ p: 2 }}>
            <FormControl>
              <TextField
                required
                id="title"
                label="Enter Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                multiline
              />
              <TextField
                required
                id="author"
                label="Enter Author"
                value={author}
                onChange={(event) => setAuthor(event.target.value)}
                multiline
              />
              <Editor
                value={text}
                setValue={setText}
                placeholder={"Enter Text"}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 1,
              }}
            >
              <Button
                variant="outlined"
                sx={{ width: "60%" }}
                onClick={() => {
                  handleAddText();
                }}
              >
                {tIndex !== null ? "Save Text" : "Add Text"}
              </Button>
            </Box>
          </Stack>
        </Box>
      </React.Fragment>
    );
  }

  function CreateFullMock() {
    const [mathId, setMathId] = useState("");
    const [readingId, setReadingId] = useState("");
    const [thinkingId, setThinkingId] = useState("");
    const [writingId, setWritingId] = useState("");

    const [mathChoices, setMathChoices] = useState([]);
    const [readingChoices, setReadingChoices] = useState([]);
    const [thinkingChoices, setThinkingChoices] = useState([]);
    const [writingChoices, setWritingChoices] = useState([]);

    const fetchChoices = async () => {
      const math = await GetFreeMocks("maths");
      const reading = await GetFreeMocks("reading");
      const thinking = await GetFreeMocks("thinking");
      const writing = await GetFreeMocks("writing");
      setMathChoices(math);
      setReadingChoices(reading);
      setThinkingChoices(thinking);
      setWritingChoices(writing);
    };

    const clearMock = async () => {
      setMathId("");
      setReadingId("");
      setThinkingId("");
      setWritingId("");
      clearQuiz();
    };

    useEffect(() => {
      fetchChoices();
    }, []);

    function handleAddMock() {
      const quizIds = [readingId, mathId, thinkingId, writingId];
      if (quizIds.includes("") || quizName === "") {
        alert("Invalid. Please check fields.");
      } else {
        addFullMock(quizName, quizIds);
        clearMock();
        setOpen1(true);
      }
    }

    return (
      <React.Fragment>
        <Stack>
          <Typography>Select a Math Quiz</Typography>
          <Select
            id="text-select"
            value={mathId}
            label="Text"
            onChange={(event) => {
              setMathId(event.target.value);
            }}
            sx={{ width: "60ch", marginBottom: 1 }}
          >
            {mathChoices.map((Id) => {
              return <MenuItem value={Id}>{Id}</MenuItem>;
            })}
          </Select>
          <Typography>Select a Reading Quiz</Typography>
          <Select
            id="text-select"
            value={readingId}
            label="Text"
            onChange={(event) => {
              setReadingId(event.target.value);
            }}
            sx={{ width: "60ch", marginBottom: 1 }}
          >
            {readingChoices.map((Id) => {
              return <MenuItem value={Id}>{Id}</MenuItem>;
            })}
          </Select>
          <Typography>Select a Thinking Quiz</Typography>
          <Select
            id="text-select"
            value={thinkingId}
            label="Text"
            onChange={(event) => {
              setThinkingId(event.target.value);
            }}
            sx={{ width: "60ch", marginBottom: 1 }}
          >
            {thinkingChoices.map((Id) => {
              return <MenuItem value={Id}>{Id}</MenuItem>;
            })}
          </Select>
          <Typography>Select a Writing Quiz</Typography>
          <Select
            id="text-select"
            value={writingId}
            label="Text"
            onChange={(event) => {
              setWritingId(event.target.value);
            }}
            sx={{ width: "60ch", marginBottom: 1 }}
          >
            {writingChoices.map((Id) => {
              return <MenuItem value={Id}>{Id}</MenuItem>;
            })}
          </Select>
        </Stack>
        <Button
          variant="contained"
          sx={{ margin: 3 }}
          onClick={() => {
            handleAddMock();
          }}
        >
          Add Mock
        </Button>
      </React.Fragment>
    );
  }

  function SearchQuiz({
    setQuizName,
    setTopic,
    setSubject,
    setQuestions,
    qs,
    setTexts,
    ts,
    setEditQuiz,
    setWritingQuestion,
  }) {
    const [selectedSubject, setSelectedSubject] = useState("");
    const [selectedQuiz, setSelectedQuiz] = useState("");
    const [selectedSection, setSelectedSection] = useState("");
    const [sectionIndex, setSectionIndex] = useState(null);
    const [quizzes, setQuizzes] = useState([]);

    const [loading, setLoading] = useState(false);

    const subjects = ["reading", "maths", "thinking", "writing"];

    useEffect(() => {
      if (selectedSubject) {
        fetchQuizzesFromSubject();
      }
    }, [selectedSubject]);

    const fetchQuizzesFromSubject = async () => {
      const data = await AdminGetQuizList(selectedSubject);
      setQuizzes(data);
    };

    const handleLoadQuizForEditting = async () => {
      setLoading(true);
      const quizId =
        selectedSubject === "full_mocks" ? selectedSection : selectedQuiz.id;
      const subject =
        selectedSubject === "full_mocks"
          ? subjects[sectionIndex]
          : selectedSubject;

      const topic = await GetTopic(subject, quizId);

      console.log(subject, quizId, topic);
      setOriginalQuiz({ subject, quizId, topic });

      if (subject !== "writing") {
        const questionIds = await GetQuestionList(subject, quizId);
        const questions = await Promise.all(
          questionIds.map(async (qId) => {
            const qData = await GetQuestion(subject, qId);
            // return {
            //   firebaseId: qId,
            //   ...qData,
            // };
            if (selectedSubject === "reading") {
              return new ReadingQuestion(
                qData.options,
                parseInt(qData.answer),
                json2HTML(qData.content),
                json2HTML(qData.explanation),
                qData.textId,
                qId
              );
            }
            return new Question(
              qData.options,
              parseInt(qData.answer),
              json2HTML(qData.content),
              json2HTML(qData.explanation),
              qId
            );
          })
        );

        console.log(questions);

        let textIds = [];
        if (subject === "reading") {
          textIds = questions.reduce((arr, obj) => {
            if (obj.textId && !arr.includes(obj.textId)) {
              arr.push(obj.textId);
            }
            return arr;
          }, []);
        }
        const texts = await Promise.all(
          textIds.map(async (tId) => {
            const tData = await GetTextWithId(tId);
            return new Text(
              tData.title,
              json2HTML(tData.content),
              tData.author,
              tData.fireBaseRef
            );
          })
        );

        setQuestions(questions);
        qs.current = questions;
        setTexts(texts);
        ts.current = texts;

        console.log(texts);
      } else {
        const writingQuestion = await GetWritingQuestion(quizId);
        setWritingQuestion(json2HTML(writingQuestion.question));
      }

      setQuizName(quizId);
      setSubject(subject);
      setTopic(topic);

      setEditQuiz(true);
      setLoading(false);
      handleCloseSearchModal();
    };

    return (
      <Box
        sx={{
          p: 1,
          width: "350px",
        }}
        noValidate
        autoComplete="off"
      >
        <Box>
          <TextField
            select={true}
            id="subject-select"
            value={selectedSubject}
            label="Select Subject"
            onChange={(event) => {
              setSelectedSubject(event.target.value);
            }}
            sx={{ width: "100%", marginBottom: 1 }}
          >
            <MenuItem value="reading">Reading</MenuItem>
            <MenuItem value="maths">Mathematical Reasoning</MenuItem>
            <MenuItem value="thinking">Thinking Skills</MenuItem>
            <MenuItem value="writing">Writing</MenuItem>
            <MenuItem value="full_mocks">Full Mocks</MenuItem>
          </TextField>
          <TextField
            select={true}
            disabled={!selectedSubject}
            id="quiz-select"
            value={selectedQuiz.id}
            label="Select Quiz"
            onChange={(event) => {
              setSelectedQuiz(event.target.value);
            }}
            sx={{ width: "100%", marginBottom: 1 }}
            SelectProps={{
              MenuProps: {
                style: {
                  maxHeight: 400,
                },
              },
            }}
          >
            {quizzes.map((quiz) => {
              return (
                <MenuItem key={quiz.id} value={quiz}>
                  {quiz.id}
                </MenuItem>
              );
            })}
          </TextField>
          {selectedSubject === "full_mocks" && selectedQuiz.quizIdList ? (
            <TextField
              select={true}
              disabled={!selectedQuiz}
              id="section-select"
              value={selectedSection}
              label="Select Section"
              onChange={(event) => {
                setSelectedSection(event.target.value);
              }}
              sx={{ width: "100%", marginBottom: 1 }}
              SelectProps={{
                MenuProps: {
                  style: {
                    maxHeight: 400,
                  },
                },
              }}
            >
              {selectedQuiz.quizIdList?.map((quiz, i) => {
                return (
                  <MenuItem
                    key={quiz}
                    value={quiz}
                    onClick={() => setSectionIndex(i)}
                  >
                    {quiz}
                  </MenuItem>
                );
              })}
            </TextField>
          ) : null}
          {loading ? (
            <Button variant="contained" fullWidth sx={{ mt: 1 }} disabled>
              Loading Quiz...
            </Button>
          ) : (
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 1 }}
              disabled={
                selectedSubject === "full_mocks"
                  ? !selectedSection
                  : !selectedQuiz
              }
              onClick={handleLoadQuizForEditting}
            >
              Review and Edit
            </Button>
          )}
        </Box>
      </Box>
    );
  }

  function CreateMCQuestion({ subject, qIndex }) {
    const [correct, setCorrect] = useState(-1);
    const [question, setQuestion] = useState("");
    const [explanation, setExplanation] = useState("");
    const [text, setText] = useState("");

    const [Options, setOptions] = useState([""]);
    const Os = useRef([""]);

    useEffect(() => {
      if (saveOptions) {
        const savedOptions = JSON.parse(localStorage.getItem("options"));
        if (savedOptions !== null) {
          setOptions(savedOptions);
          Os.current = savedOptions;
        }
      }
    }, []);

    useEffect(() => {
      if (qIndex !== null) {
        const qObj = qs.current[qIndex];
        setQuestion(qObj.question);
        setExplanation(qObj.explanation);
        setOptions(qObj.options);
        setCorrect(qObj.correctOption);
        setText(qObj.textId ? qObj.textId : "");
      }
    }, [qIndex]);

    function handleAddOption() {
      let currOptions = Options.slice();
      currOptions.push("");
      setOptions(currOptions);
      Os.current = currOptions;
    }
    function handleDeleteOption(index) {
      let currOptions = Options.slice();
      currOptions.splice(index, 1);
      setOptions(currOptions);
      Os.current = currOptions;
      localStorage.setItem("options", JSON.stringify(currOptions));
      if (index === correct) {
        setCorrect(-1);
      } else if (index < correct) {
        setCorrect(correct - 1);
      }
    }

    function handleChangeOption(change, index) {
      let currOptions = Options.slice();
      currOptions[index] = change;
      setOptions(currOptions);
      Os.current = currOptions;
      localStorage.setItem("options", JSON.stringify(currOptions));
    }

    function handleAddQuestion() {
      const valid = ValidOptions();
      if (valid) {
        let currOptions = Options.slice();
        let currQuestions = questions.slice();

        if (subject !== "reading") {
          if (qIndex === null) {
            currQuestions.push(
              new Question(currOptions, correct, question, explanation)
            );
          } else {
            currQuestions[qIndex] = new Question(
              currOptions,
              correct,
              question,
              explanation,
              currQuestions[qIndex].firebaseId
            );
            handleCloseQuestionModal();
          }
        } else {
          if (qIndex === null) {
            currQuestions.push(
              new ReadingQuestion(
                currOptions,
                correct,
                question,
                explanation,
                text
              )
            );
          } else {
            currQuestions[qIndex] = new ReadingQuestion(
              currOptions,
              correct,
              question,
              explanation,
              text,
              currQuestions[qIndex].firebaseId
            );
            handleCloseQuestionModal();
          }
        }
        setQuestions(currQuestions);
        qs.current = currQuestions;
        clearOptions();
      } else {
        alert("Invalid. Please check fields.");
      }
    }

    function TextSelect() {
      if (subject === "reading") {
        return (
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="subtitle2" color={"#1f2f98"}>
              Text Select
            </Typography>
            <Select
              id="text-select"
              value={text}
              label="Text"
              onChange={(event) => {
                setText(event.target.value);
              }}
              sx={{ width: "100%", marginBottom: 1 }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {texts.map((text) => {
                return (
                  <MenuItem
                    key={text.fireBaseRef.id}
                    value={text.fireBaseRef.id}
                  >
                    {text.title}
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
        );
      }
    }

    function clearOptions() {
      let currOptions = Options.slice();
      currOptions = [""];
      setOptions(currOptions);
      Os.current = currOptions;
      setCorrect(-1);
      setQuestion("");
      setExplanation("");
      setText("");
    }

    function ValidOptions() {
      if (correct < 0) {
        return false;
      }
      const fields = Options.slice();
      fields.push(question);
      fields.push(explanation);
      if (fields.includes("")) {
        return false;
      }

      return true;
    }

    return (
      <React.Fragment>
        <Box
          sx={{
            "& .MuiTextField-root": { m: 1, width: "60ch" },
            width: "100%",
            maxWidth: "37vw",
          }}
          noValidate
          autoComplete="off"
        >
          <Stack sx={{ p: 2 }}>
            <FormControl>
              <Typography variant="subtitle2" color={"#1f2f98"}>
                Question
              </Typography>
              <Editor
                value={question}
                setValue={setQuestion}
                placeholder={"Enter Question"}
              />
              {Options.map((option, i) => {
                return (
                  <Box
                    key={`option${i}`}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Stack sx={{ width: "100%" }}>
                      <Stack direction="row">
                        <IconButton
                          color="inherit"
                          size="small"
                          onClick={() => {
                            handleDeleteOption(i);
                          }}
                        >
                          <DeleteOutlineIcon fontSize="inherit" />
                        </IconButton>
                        <TextField
                          required
                          key={`option-${i}`}
                          id={`${i}`}
                          label={`Option ${i + 1}`}
                          value={option}
                          onChange={(event) => {
                            handleChangeOption(event.target.value, i);
                          }}
                          multiline
                        />
                        <Checkbox
                          checked={correct === i}
                          onChange={() => setCorrect(i)}
                        />
                      </Stack>
                      {i === Options.length - 1 ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <IconButton
                            color="inherit"
                            onClick={() => {
                              handleAddOption(i);
                            }}
                          >
                            <AddCircleOutlineIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ) : (
                        <></>
                      )}
                    </Stack>
                  </Box>
                );
              })}
              <Box>
                <Checkbox
                  checked={saveOptions}
                  onChange={() => setSaveOptions(!saveOptions)}
                />
                <Typography sx={{ display: "inline-block" }}>
                  Save Options
                </Typography>
              </Box>
              <Typography variant="subtitle2" color={"#1f2f98"}>
                Explanation
              </Typography>
              <Editor
                value={explanation}
                setValue={setExplanation}
                placeholder={"Enter Explanation"}
              />
              <TextSelect />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                variant="outlined"
                sx={{ width: "60%", marginTop: "2rem" }}
                onClick={() => {
                  handleAddQuestion();
                }}
              >
                {qIndex === null ? "Add Question" : "Save Question"}
              </Button>
            </Box>
          </Stack>
        </Box>
      </React.Fragment>
    );
  }

  function RightArea() {
    const [selectedHead, setSelectedHead] = useState(0);

    function textTitle(textId) {
      for (const text of texts) {
        if (textId === text.fireBaseRef.id) {
          return text.title;
        }
      }
    }

    function MenuArea() {
      if (selectedHead === 0) {
        return (
          <Box sx={{ overflow: "auto", maxHeight: "80vh", width: "100%" }}>
            <Stack spacing={2} sx={{ paddingTop: 2 }}>
              {questions.map((q, i) => {
                return (
                  <React.Fragment>
                    <Accordion key={`accordion${i}`} defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">
                          Question {i + 1} - {textTitle(q.textId)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box>
                          <StringToHTML string={q.question} />
                        </Box>
                        <Stack sx={{ marginTop: "1rem" }}>
                          {q.options.map((option, j) => {
                            if (q.correctOption === j) {
                              return (
                                <React.Fragment>
                                  <Typography
                                    color="success.main"
                                    sx={{ wordWrap: "break-word" }}
                                  >
                                    {option}
                                  </Typography>
                                </React.Fragment>
                              );
                            } else {
                              return (
                                <React.Fragment>
                                  <Typography
                                    color="red"
                                    sx={{ wordWrap: "break-word" }}
                                  >
                                    {option}
                                  </Typography>
                                </React.Fragment>
                              );
                            }
                          })}
                        </Stack>
                        <AccordionActions>
                          <Button
                            variant="contained"
                            sx={{ marginTop: 1 }}
                            onClick={() => handleEditQuestionWithId(i)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{ marginTop: 1 }}
                            onClick={() => handleDeleteQuestion(i)}
                            disabled={!canMajorEdit}
                          >
                            Delete
                          </Button>
                        </AccordionActions>
                      </AccordionDetails>
                    </Accordion>
                  </React.Fragment>
                );
              })}
            </Stack>
          </Box>
        );
      } else {
        return (
          <Box sx={{ overflow: "auto", maxHeight: "80vh", width: "100%" }}>
            <Stack spacing={2} sx={{ paddingTop: 2 }}>
              {texts.map((t, i) => {
                return (
                  <React.Fragment>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant="h5">
                          {t.title} by {t.author}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={{}}>
                          <StringToHTML string={t.content} />
                        </Box>
                        <AccordionActions>
                          <Button
                            variant="contained"
                            sx={{ marginTop: 1 }}
                            onClick={() => handleEditTextWithId(i)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{ marginTop: 1 }}
                            onClick={() => handleDeleteText(i)}
                            disabled={!canMajorEdit}
                          >
                            Delete
                          </Button>
                        </AccordionActions>
                      </AccordionDetails>
                    </Accordion>
                  </React.Fragment>
                );
              })}
            </Stack>
          </Box>
        );
      }
    }

    if (subject === "writing") {
      return;
    } else if (subject === "full_mocks") {
      return;
    } else if (subject === "reading") {
      return (
        <React.Fragment>
          <Stack spacing={1}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="outlined"
                sx={{ borderRadius: 9 }}
                onClick={handleOpenQuestionModal}
              >
                Add Question
              </Button>
              <Button
                variant="outlined"
                sx={{ borderRadius: 9 }}
                onClick={handleOpenTextModal}
              >
                Add Text
              </Button>
            </Box>
            <Paper elevation={1} sx={{ width: "100%", borderRadius: 6 }}>
              <Stack direction="row">
                <ListItemButton
                  sx={{ borderTopLeftRadius: 100, borderBottomLeftRadius: 100 }}
                  selected={selectedHead === 0}
                  onClick={() => {
                    setSelectedHead(0);
                  }}
                >
                  <Typography variant="h6">
                    Current Questions: {qs.current.length}
                  </Typography>
                </ListItemButton>
                <ListItemButton
                  sx={{
                    borderTopRightRadius: 100,
                    borderBottomRightRadius: 100,
                  }}
                  selected={selectedHead === 1}
                  onClick={() => {
                    setSelectedHead(1);
                  }}
                >
                  <Typography variant="h6">Texts</Typography>
                </ListItemButton>
              </Stack>
            </Paper>
            <MenuArea />
          </Stack>
        </React.Fragment>
      );
    } else {
      return (
        <Box sx={{ overflow: "auto", maxHeight: "80vh", width: "100%" }}>
          <Paper
            elevation={0}
            sx={{
              p: 1.5,
              borderRadius: 9,
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">
              Current Questions: {qs.current.length}
            </Typography>
            <Button
              variant="outlined"
              sx={{ borderRadius: 9 }}
              onClick={handleOpenQuestionModal}
            >
              Add Question
            </Button>
          </Paper>
          <Stack spacing={2} sx={{ paddingTop: 2 }}>
            {questions.map((q, i) => {
              return (
                <React.Fragment>
                  <Accordion defaultExpanded>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="h5">
                        Question {i + 1} - {q.textId}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ width: "100%" }}>
                        <StringToHTML string={q.question} />
                      </Box>
                      <Stack sx={{ marginTop: "1rem" }}>
                        {q.options.map((option, j) => {
                          if (q.correctOption === j) {
                            return (
                              <React.Fragment>
                                <Typography
                                  color="success.main"
                                  sx={{ wordWrap: "break-word" }}
                                >
                                  {option}
                                </Typography>
                              </React.Fragment>
                            );
                          } else {
                            return (
                              <React.Fragment>
                                <Typography
                                  color="red"
                                  sx={{ wordWrap: "break-word" }}
                                >
                                  {option}
                                </Typography>
                              </React.Fragment>
                            );
                          }
                        })}
                      </Stack>
                      <AccordionActions>
                        <Button
                          variant="contained"
                          sx={{ marginTop: 1 }}
                          onClick={() => handleEditQuestionWithId(i)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{ marginTop: 1 }}
                          onClick={() => handleDeleteQuestion(i)}
                          disabled={!canMajorEdit}
                        >
                          Delete
                        </Button>
                      </AccordionActions>
                    </AccordionDetails>
                  </Accordion>
                </React.Fragment>
              );
            })}
          </Stack>
        </Box>
      );
    }
  }

  function ReadingArea() {
    const [selectedHead, setSelectedHead] = useState(0);

    function MenuArea() {
      if (selectedHead === 0) {
        return <CreateMCQuestion subject={subject} />;
      } else {
        return <TextForm />;
      }
    }
    return (
      <React.Fragment>
        <Stack>
          <Paper sx={{ width: "100%" }}>
            <Stack direction="row">
              <ListItemButton
                selected={selectedHead === 0}
                onClick={() => {
                  setSelectedHead(0);
                }}
              >
                <Typography variant="h6">Add Question</Typography>
              </ListItemButton>
              <ListItemButton
                selected={selectedHead === 1}
                onClick={() => {
                  setSelectedHead(1);
                }}
              >
                <Typography variant="h6">Add Text</Typography>
              </ListItemButton>
            </Stack>
          </Paper>
          <MenuArea />
        </Stack>
      </React.Fragment>
    );
  }

  function WritingArea({ editWritingQuestion }) {
    const [question, setQuestion] = useState(
      editWritingQuestion ? editWritingQuestion : ""
    );

    function handleAddWriting() {
      if ([quizName, topic, question].includes("")) {
        alert("Invalid. Please check fields.");
      } else {
        const q = question + "";
        addWritingQuiz(quizName, topic, html2JSON(question));
        setEditQuiz(false);
        setOpen1(true);
        clearQuiz();
      }
    }

    return (
      <React.Fragment>
        <Box
          sx={{
            "& .MuiTextField-root": { m: 1 },
            border: 2,
            borderRadius: 1,
            borderColor: "#daddef",
            width: "100%",
          }}
          noValidate
          autoComplete="off"
        >
          <Editor
            value={question}
            setValue={setQuestion}
            placeholder={"Enter Question"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            py: 3,
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              handleAddWriting();
            }}
          >
            Add Writing Question
          </Button>
          {canMajorEdit && editQuiz ? (
            <Button variant="outlined" color="error" onClick={handleOpenDelete}>
              Delete Writing Quiz
            </Button>
          ) : null}
        </Box>
      </React.Fragment>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, borderColor: "black", m: 3, width: "80%" }}>
      <Box sx={{ width: "100%" }}>
        <Collapse
          in={open1}
          timeout={{ enter: 500, exit: 500 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
          addEndListener={() => {
            setTimeout(() => {
              setOpen1(false);
            }, 7000);
          }}
        >
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen1(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Quiz Added
          </Alert>
        </Collapse>
        <Collapse
          in={open2}
          timeout={{ enter: 500, exit: 500 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
          addEndListener={() => {
            setTimeout(() => {
              setOpen2(false);
            }, 7000);
          }}
        >
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen2(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            Invalid. Please check fields.
          </Alert>
        </Collapse>
      </Box>
      <Stack spacing={4} sx={{ width: "100%" }}>
        <Paper elevation={0} sx={{ borderRadius: 6, p: 3 }}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "50ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Stack>
              <FormControl>
                <Box sx={{ display: "flex" }}>
                  <TextField
                    required
                    id="quiz-name-input"
                    label="Quiz Name"
                    disabled={!canMajorEdit}
                    value={quizName}
                    onChange={(event) =>
                      setQuizName(event.target.value.toUpperCase())
                    }
                    helperText="Please Enter Quiz Name"
                  />
                  <TextField
                    required
                    id="topic-input"
                    label="Topic"
                    disabled={!canMajorEdit}
                    value={topic}
                    onChange={(event) =>
                      setTopic(event.target.value.toUpperCase())
                    }
                    helperText="Please Enter Topic"
                  />
                </Box>
              </FormControl>
              <RadioGroup
                row
                aria-labelledby="subject-group"
                name="subject-group"
                value={subject}
                onChange={(event) => {
                  setSubject(event.target.value);
                }}
              >
                <FormControlLabel
                  value="maths"
                  control={<Radio />}
                  disabled={!canMajorEdit}
                  label="Mathematical Reasoning"
                />
                <FormControlLabel
                  value="thinking"
                  control={<Radio />}
                  disabled={!canMajorEdit}
                  label="Thinking Skills"
                />
                <FormControlLabel
                  value="reading"
                  control={<Radio />}
                  disabled={!canMajorEdit}
                  label="Reading"
                />
                <FormControlLabel
                  value="writing"
                  control={<Radio />}
                  disabled={!canMajorEdit}
                  label="Writing"
                />
                <FormControlLabel
                  value="full_mocks"
                  control={<Radio />}
                  disabled={!canMajorEdit}
                  label="Full Mock"
                />
              </RadioGroup>
              <Box>
                <CreateQ />
              </Box>
            </Stack>
            {!["full_mocks", "writing"].includes(subject) ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 3,
                    m: 1,
                  }}
                >
                  <Button variant="contained" onClick={handleOpenConfirm}>
                    {editQuiz ? "Update Quiz" : "Add Quiz"}
                  </Button>
                  {canMajorEdit && editQuiz ? (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={handleOpenDelete}
                    >
                      Delete Quiz
                    </Button>
                  ) : null}
                  {!canMajorEdit ? (
                    <Tooltip
                      placement="right"
                      title="Major changes (such as updating the quiz name and deleting questions) cannot be made as a student has already attempted this quiz"
                    >
                      <WarningIcon color="warning" />
                    </Tooltip>
                  ) : null}
                </Box>
                <Button
                  variant="outlined"
                  sx={{ m: 1 }}
                  onClick={handleOpenSearch}
                  startIcon={<SearchIcon />}
                >
                  Search Quiz
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Paper>
        <Box>
          <RightArea />
        </Box>
      </Stack>
      <Dialog open={openConfirm} onClose={handleCloseConfirm}>
        <DialogTitle>Are you sure you want to add quiz?</DialogTitle>
        <DialogActions
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={handleCloseConfirm}>Cancel</Button>
          <Button
            variant="contained"
            sx={{ borderRadius: 6 }}
            onClick={() => {
              handleAddQuiz();
              handleCloseConfirm();
              if (editQuiz) {
                setEditQuiz(null);
              }
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle>
          Are you sure you want to <b>delete</b> this quiz and all of its
          questions?
        </DialogTitle>
        <DialogActions
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <Button
            variant="contained"
            color="error"
            sx={{ borderRadius: 6 }}
            onClick={() => {
              handleDeleteQuiz();
              handleCloseDelete();
              if (editQuiz) {
                setEditQuiz(null);
                setEditWritingQuestion("");
              }
              clearQuiz();
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openQuestionModal} onClose={handleCloseQuestionModal}>
        <CreateMCQuestion subject={subject} qIndex={qIndex} />
        <DialogActions
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={handleCloseQuestionModal}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTextModal} onClose={handleCloseTextModal}>
        <TextForm tIndex={tIndex} />
        <DialogActions
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={handleCloseTextModal}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSearchModal} onClose={handleCloseSearchModal}>
        <DialogTitle>Search for a quiz to view or edit</DialogTitle>
        <SearchQuiz
          setQuizName={setQuizName}
          setTopic={setTopic}
          setSubject={setSubject}
          setQuestions={setQuestions}
          qs={qs}
          setTexts={setTexts}
          ts={ts}
          setEditQuiz={setEditQuiz}
          setWritingQuestion={setEditWritingQuestion}
        />
        <DialogActions
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={handleCloseSearchModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Create;
