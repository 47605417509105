import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import { CircularProgress } from '@mui/material';
import { GetText } from "../app_backend";

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import HTML2json from 'html2json';
import StringToHTML from "./StringToHTML";
var json2HTML = HTML2json.json2html;

function ReadingText({ subject, question_id, width }) {
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const [author, setAuthor] = useState("")
  const [data, setData] = useState({})
  useEffect(() => {
    if (question_id !== '') {
      fetchText()
    }
  }, [question_id]);

  const fetchText = async () => {
    try {
      if (subject === 'reading') {
        const data = await GetText(question_id)
        setData(data)
        if (data === 'NO_TEXT') {
          return;
        }
        const content = data.content
        setTitle(data.title)
        setContent(json2HTML(content))
        setAuthor(data.author)
      }
    } catch (e) {
      console.log(e)
    }
  }

  if (subject !== 'reading') {
    return;
  } else if (data === 'NO_TEXT') {
    return null;
  }
  return (
    <React.Fragment>
      <Paper elevation={0} sx={{ borderRadius: 4, width: '100%' }}>
        <Box sx={{ overflow: 'auto', p: 2, maxHeight: "100%" }}>
          {title ? (
            <Typography variant='h4' sx={{ marginBottom: 2 }}>{title}</Typography>
          ) : (
            <Skeleton animation="wave" variant="text" width={400} sx={{ fontSize: '4rem' }} />
          )
          }
          {content ? (
            <StringToHTML string={content} />
          ) : (
            <Box sx={{ width: { width } }}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>
          )
          }
          <br></br>
          {author ? (<Typography sx={{ marginBottom: 2, color: '#1f2f98', opacity: 0.6 }}>Source: {author}</Typography>) : null}
        </Box>
      </Paper>
    </React.Fragment >
  )
}

export default ReadingText