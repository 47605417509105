import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { addRedoRequest } from "../app_backend";


const RequestRedo = ({ subject, quiz }) => {
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState('primary')
  const [submitted, setSubmitted] = useState(false)

  const handleSubmitRequest = async () => {
    if (message !== '') {
      setSubmitted(true)
      await addRedoRequest(subject, quiz, message)

    } else {
      setMessageError('error')
    }
  }

  useEffect(() => {
    if (message === '') {
      setMessageError('error')
    } else {
      setMessageError('primary')
    }
  }, [message])

  return (
    <React.Fragment>
      {submitted ?
        <Box sx={{ pt: 2, fontWeight: 800, fontSize: '14pt' }}>
          Thank you for your request. You will receive a notification once it has been approved.
        </Box> :
        <React.Fragment>
          <Typography sx={{ fontSize: '12pt', fontWeight: 800 }}>
            Please provide a brief description of what happened during the Full Mock Exam:
          </Typography>
          <TextField
            focused
            variant="outlined"
            label="Message"
            required
            multiline
            rows={5}
            color={messageError}
            value={message}
            onChange={(e) => { setMessage(e.target.value) }}
            sx={{ width: '100%', marginTop: 2, fontSize: '12pt' }}
          />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            <Button variant="outlined" color="error" sx={{ borderRadius: 6 }} onClick={handleSubmitRequest}>
              <Typography sx={{ textTransform: 'None' }}>Submit Request</Typography>
            </Button>
          </Box>
        </React.Fragment>}
    </React.Fragment>

  )
}

export default RequestRedo