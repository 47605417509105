import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress"
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ProfileIcon from "../components/ProfileIcon";
import ProfileErrorBanner from "../components/ProfileErrorBanner";
import "../style/Profile.css";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateProfile,
} from "firebase/auth";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../auth";
import { customerPortalLink, getUserSubscriptionDetails, sendToCustomerPortal, VALID_SUBSCRIPTION } from "../Stripe";

function Profile({ subscription, setSelectedPage }) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [subData, setSubData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [redirecting, setIsRedirecting] = useState(false)

  const fetchUserSubscriptionData = async () => {
    const data = await getUserSubscriptionDetails(user?.uid);
    setSubData(data);
  };

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [errorState, setErrorState] = useState("success");

  const [values, setValues] = useState({
    changeFirstName: "",
    changeLastName: "",
    newPassword: "",
    verifyPassword: "",
    currentPassword: "",
    showNewPassword: false,
    showVerifyPassword: false,
    showCurrentPassword: false,
  });

  const clearDisplayFields = () => {
    setValues({
      ...values,
      changeFirstName: "",
      changeLastName: "",
    });
  };

  const clearPasswordFields = () => {
    setValues({
      ...values,
      newPassword: "",
      verifyPassword: "",
      currentPassword: "",
      showNewPassword: false,
      showVerifyPassword: false,
      showCurrentPassword: false,
    });
  };

  useEffect(() => {
    clearDisplayFields();
    clearPasswordFields();
    if (loading) {
      return;
    }
    if (!user) {
      navigate("/Login");
    } else {
      if (subscription) {
        fetchUserSubscriptionData().then(() => {
          setIsLoading(false);
        });
      }
    }
  }, [user, loading]);

  const [currentDisplayName, setCurrentDisplayName] = useState(
    user?.displayName
  );

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  // toggle password visibility
  const handleClickShowNewPassword = () => {
    setValues({
      ...values,
      showNewPassword: !values.showNewPassword,
    });
  };

  const handleClickShowVerifyPassword = () => {
    setValues({
      ...values,
      showVerifyPassword: !values.showVerifyPassword,
    });
  };

  const handleClickShowCurrentPassword = () => {
    setValues({
      ...values,
      showCurrentPassword: !values.showCurrentPassword,
    });
  };

  const handleTimeSetError = () => {
    setShowError(false);
  };

  const updateDisplayName = async () => {
    var updateDisplayName = (
      values.changeFirstName +
      " " +
      values.changeLastName
    ).trim();
    if (
      updateDisplayName.replace(/\s/g, "").length === 0 ||
      updateDisplayName === user.displayName
    ) {
      // if string is empty or changed name is the same

      clearDisplayFields();

      setErrorState("fail");
      setErrorText("Original display name is kept.");
      setShowError(true);

      setTimeout(handleTimeSetError, 5000);
    } else {
      // else update displayName
      try {
        updateProfile(user, {
          displayName: updateDisplayName,
        }).then(() => {
          setCurrentDisplayName(updateDisplayName);

          setErrorState("success");
          setErrorText("Updated your display name.");
          setShowError(true);

          setTimeout(handleTimeSetError, 5000);
          console.log("User's displayName is updated to ", user.displayName);
        });
      } catch (e) {
        setErrorState("fail");
        setErrorText("There was an error updating your user display name.");
        setShowError(true);

        setTimeout(handleTimeSetError, 5000);
      }

      // clear fields
      clearDisplayFields();
    }
  };

  // const handleErrorPopUp

  const updateAndCheckPassword = async () => {
    var currentPassword = values.currentPassword;
    var newPassword = values.newPassword;
    var verifyPassword = values.verifyPassword;

    // reauthenticate with the old password first.
    var credential = EmailAuthProvider.credential(user.email, currentPassword);

    // Prompt the user to re-provide their sign-in credentials
    reauthenticateWithCredential(user, credential)
      .then(function () {
        // error checking with new password
        if (newPassword !== verifyPassword) {
          setErrorState("fail");
          setErrorText("Verified password and new password do not match.");
          setShowError(true);
          return;
        } else if (newPassword.length < 6) {
          setErrorState("fail");
          setErrorText(
            "Password is too short. At least 6 characters required."
          );
          setShowError(true);
          return;
        }

        updatePassword(user, newPassword)
          .then(() => {
            setErrorState("success");
            setErrorText("Password updated successfully.");
            setShowError(true);

            setTimeout(handleTimeSetError, 5000);
          })
          .catch((e) => {
            setErrorState("fail");
            setErrorText("Error when updating password.");
            console.log(e.message);
            setShowError(true);
          });
      })
      .catch(function (error) {
        // handle this error
        setErrorState("fail");
        setErrorText("Incorrect verification credentials.");
        setShowError(true);
        console.log(error);
      });

    clearPasswordFields();
  };

  const handleCustomerPortal = () => {
    // window.location.href = customerPortalLink
    setIsRedirecting(true)
    sendToCustomerPortal()
  }

  const getSubscriptionStatus = () => {
    if (subData.status === 'active' && subData.cancel_at_period_end) {
      return <span style={{ color: '#ffb74d', fontWeight: 700 }}>ACTIVE until end of period</span>
    }
    return <span style={{ color: '#2ba831', fontWeight: 700 }}>{subData.status.toUpperCase()}</span>
  }

  const SubscriptionDetails = () => {
    if (isLoading) {
      return (
        <Box>
          <Box sx={{ width: "18rem", p: 2 }}>
            <Skeleton animation="wave" sx={{ fontSize: "4rem" }} />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Box>
        </Box>
      )
    }

    if (subData.subscribed) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Box sx={{ p: 3 }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
                color: "#1F2F98",
              }}
            >
              Account Details
            </Typography>
            <Typography
              sx={{
                color: "#1F2F98",
                fontSize: '14pt'
              }}
            >
              Subscription: {getSubscriptionStatus()}
            </Typography>
            <Typography sx={{ mt: 1 }}>
              Created at {new Date(subData.created).toLocaleString()}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', p: 3, gap: 3 }}>
            <Typography>
              <strong>Current billing period end:</strong> {new Date(subData.current_period_end).toLocaleDateString()}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ borderRadius: 6, width: '183px' }}
              disableElevation
              onClick={handleCustomerPortal}
            >
              {redirecting ? <CircularProgress color="inherit" size={25} /> : 'Manage Subscription'}
            </Button>
          </Box>
        </Box>
      )
    }

    // for people on legacy plans, given free year plan
    return (
      <Box>
        <Typography
          variant="h5"
          sx={{
            px: 3,
            paddingTop: 3,
            fontWeight: 700,
            color: "#1F2F98",
          }}
        >
          Account Details
        </Typography>
        <Typography
          variant="h6"
          sx={{
            px: 3,
            paddingTop: 1,
            fontWeight: 700,
            color: "#1F2F98",
          }}
        >
          {subData.productName} - {subData.daysLeft} days left
        </Typography>
        <Typography sx={{ px: 3, paddingBottom: 3, paddingTop: 1 }}>
          Your access expires on {subData.expiry}
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ flexGrow: 1, borderColor: "black", mx: 4, width: "100%", overflow: 'auto', p: 4 }}>
      <Box
        sx={{
          borderRadius: 6,
          backgroundColor: "#f1f4ff",
          display: "flex",
          mx: 8,
          justifyContent: "space-between",
        }}
      >
        {subscription === VALID_SUBSCRIPTION && subData !== null ?
          <SubscriptionDetails />
          :
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Box sx={{ p: 3 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 700,
                  color: "#1F2F98",
                }}
              >
                Account Details
              </Typography>
              <Typography
                sx={{
                  color: "#1F2F98",
                  fontSize: '14pt'
                }}
              >
                Subscription: <span style={{ color: '#b82c2c', fontWeight: 700 }}>INACTIVE</span>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', p: 3, gap: 3 }}>
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: 6 }}
                disableElevation
                onClick={() => setSelectedPage('Store')}
              >Upgrade Now</Button>
            </Box>
          </Box>
        }
      </Box>
      <Stack
        direction="row"
        spacing={8}
        sx={{
          height: "50rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          p: 8,
        }}
      >
        <Box
          sx={{
            height: "100%",
            width: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            elevation={0}
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 6,
            }}
          >
            <ProfileIcon displayName={currentDisplayName} />
          </Card>
        </Box>
        <Box
          sx={{
            height: "100%",
            width: "70%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            elevation={0}
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 6,
            }}
          >
            <Stack
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={6}
            >
              <ProfileErrorBanner
                text={errorText}
                errorState={errorState}
                showError={showError}
              />
              <Typography variant="h5" sx={{ fontWeight: 900 }}>
                Update Details
              </Typography>
              <Stack direction="row" sx={{ mt: 5, mb: -3 }}>
                <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="first-name">First Name</InputLabel>
                  <OutlinedInput
                    autoComplete="off"
                    onChange={handleChange("changeFirstName")}
                    value={values.changeFirstName}
                    label="First Name"
                  />
                </FormControl>
                <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="last-name">Last Name</InputLabel>
                  <OutlinedInput
                    autoComplete="off"
                    onChange={handleChange("changeLastName")}
                    value={values.changeLastName}
                    label="Last Name"
                  />
                </FormControl>
              </Stack>
              <Button
                onClick={updateDisplayName}
                sx={{ width: "40%", borderRadius: 6 }}
                variant="contained"
              >
                {" "}
                Update Display Name{" "}
              </Button>
              <FormControl sx={{ m: 1, width: "52ch" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-current-password">
                  Verify Current Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-current-password"
                  autoComplete="current-password"
                  type={values.showCurrentPassword ? "text" : "password"}
                  value={values.currentPassword}
                  onChange={handleChange("currentPassword")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCurrentPassword}
                        edge="end"
                      >
                        {values.showCurrentPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Current Password"
                />
              </FormControl>
              <Stack direction="row" sx={{ mt: 5, mb: -3 }}>
                <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-new-password">
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    autoComplete="new-password"
                    type={values.showNewPassword ? "text" : "password"}
                    value={values.newPassword}
                    onChange={handleChange("newPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          edge="end"
                        >
                          {values.showNewPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                  />
                </FormControl>
                <FormControl sx={{ m: 1, width: "25ch" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-verify-password">
                    Verify Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password-verify"
                    autoComplete="new-password"
                    type={values.showVerifyPassword ? "text" : "password"}
                    value={values.verifyPassword}
                    onChange={handleChange("verifyPassword")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowVerifyPassword}
                          edge="end"
                        >
                          {values.showVerifyPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Verify Password"
                  />
                </FormControl>
              </Stack>
              <Button
                onClick={updateAndCheckPassword}
                variant="contained"
                sx={{ width: "40%", borderRadius: 6 }}
              >
                {" "}
                Update Password{" "}
              </Button>
            </Stack>
          </Card>
        </Box>
      </Stack>
    </Box>
  );
}

export default Profile;
