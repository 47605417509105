import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//import { CircularProgress } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { GetQuestion, GetQuestionList } from "../app_backend";
import "../style/Question.css"
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import HTML2json from 'html2json';
import StringToHTML from "./StringToHTML";
var json2HTML = HTML2json.json2html;

const Loading = () => {
  return (
    <div>
      Loading...
    </div>
  )
}

function Question({ subject, index, question_id, setAttempt, attempt }) {
  const [content, setContent] = useState("")
  const [options, setOptions] = useState([])
  const [url, setUrl] = useState("")
  const [loading, setLoading] = useState(true)
  const params = useParams()

  useEffect(() => {
    if (question_id !== '') {
      fetchQuestion(subject, question_id)
    }
  }, [question_id]);


  const fetchQuestion = async (subject, question_id) => {
    try {
      const data = await GetQuestion(subject, question_id)
      const content = data.content
      setContent(json2HTML(content))
      setOptions(data.options)
      setUrl(data.imgURL)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }


  const handleChange = (event) => {
    let attemptCopy = [...attempt]
    attemptCopy[index.current] = event.target.value
    setAttempt(attemptCopy)
  }

  return (
    <div>
      <Typography variant='h4'>Question {index.current + 1}</Typography>
      <Box sx={{ marginTop: '1rem', userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none', msUserSelect: 'none' }}>
        <StringToHTML string={content} />
        <Box sx={{ marginTop: '1rem' }}>
          <RadioGroup
            aria-labelledby="question-options"
            name="question-options-radio-buttons-group"
            value={attempt[index.current] ? attempt[index.current] : 'unanswered'}
            onChange={handleChange}
          >
            {options.map((option, i) => {
              if (option !== '') {
                return (
                  <Stack key={i} direction='row' spacing={3} sx={{ marginBottom: 0.8 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <FormControlLabel value={`${i}`} control={<Radio />} />
                      <Typography>{option}</Typography>
                    </Box>
                  </Stack>
                )
              }
            })}
          </RadioGroup>
        </Box>
      </Box>
    </div>
  );
}

export default Question;
