import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";

function Learn11() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography variant="h4" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          What are percentages?
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          Percentage is another way of writing a fraction with a denominator of
          100, indicating a portion of a whole. A percentage sign (%) is used to
          display percentages, e.g. 75%.
          <br />
          For example,
        </Typography>
      </Box>
      <Alert
        icon={<NewReleasesRoundedIcon fontSize="large" />}
        severity="info"
        variant="outlined"
        sx={{
          borderRadius: 4,
          my: 1,
          backgroundColor: "#d9e7fe",
          marginBottom: 2,
        }}
      >
        <Typography>
          <b>Example 1</b>: 41 out of 100 expressed as a fraction is{" "}
          <span style={{ fontSize: "16pt" }}>
            <sup>41</sup>&frasl;<sub>100</sub>
          </span>
          , which can also be expressed as percentage of 41%.
        </Typography>
      </Alert>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Converting percentage to a fraction:
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          Substitute the symbol with a fraction of denominator 100 and simplify
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, my: 1, backgroundColor: "#d9e7fe" }}
        >
          <Typography>
            <b>Example 2</b>: 15% ={" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>15</sup>&frasl;<sub>100</sub> = <sup>3</sup>&frasl;
              <sub>20</sub>
            </span>
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Converting fractions to percentages:
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          Multiplying the fraction by 100, add the percentage sign and then
          simplify. <br />
          Example:
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, my: 1, backgroundColor: "#d9e7fe" }}
        >
          <Typography>
            <b>Example 3</b>:{" "}
            <span style={{ fontSize: "16pt" }}>
              <sup>7</sup>&frasl;<sub>15</sub> = <sup>7</sup>&frasl;
              <sub>15</sub> × 100% = 46<sup>2</sup>&frasl;<sub>3</sub>%
            </span>
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Converting percentages to decimals and vice versa:
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          Take the value of the percentage and move the decimal place 2 places
          to the left. <br />
          To convert from decimal to percentage, multiply the decimal by 100 and
          add the percentage sign on the end. <br />
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{ borderRadius: 4, my: 1, backgroundColor: "#d9e7fe" }}
        >
          <Typography>
            <b>Example 4</b>:{" "}
            <span style={{ fontSize: "16pt" }}>124% = 1.24</span>
          </Typography>
        </Alert>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Percentage composition
        </Typography>
        <Typography sx={{ paddingBottom: 2, fontSize: "16pt", mx: 2 }}>
          <sup>
            <i>Part Amount</i>
          </sup>
          &frasl;
          <sub>
            <i>Whole Amount</i>
          </sub>{" "}
          × 100%
        </Typography>
        <Alert
          icon={<TipsAndUpdatesRoundedIcon fontSize="inherit" />}
          severity="warning"
          variant="filled"
          sx={{ borderRadius: 4, marginBottom: 3, fontSize: "12pt" }}
        >
          Tip: make sure your quantities have the <b>SAME UNITS</b> or else this
          formula won't work!
        </Alert>
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Percentage change of a quantity
        </Typography>
        <Typography sx={{ paddingBottom: 2, fontSize: "16pt", mx: 2 }}>
          <i>% Increase</i> ={" "}
          <sup>
            <i>Increase</i>
          </sup>
          &frasl;
          <sub>
            <i>Original Value</i>
          </sub>{" "}
          × 100%
          <br />
          <i>% Decrease</i> ={" "}
          <sup>
            <i>Decrease</i>
          </sup>
          &frasl;
          <sub>
            <i>Original Value</i>
          </sub>{" "}
          × 100%
        </Typography>
      </Box>
    </Box>
  );
}

export default Learn11;
