import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

//styling stuff
import "../style/modal.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { auth } from "../auth";
import { getResultWithId, GetQuestionList } from "../app_backend";
import Answer from "../components/Answer";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import ReadingText from "../components/ReadingText";
import WritingResults from "../pages/WritingResults";
import { VALID_SUBSCRIPTION } from "../Stripe";
import { Fade } from "@mui/material";

function Results({
  subject,
  quiz,
  attemptId,
  subscription,
  mockStyle = false,
}) {
  const [user, loading, error] = useAuthState(auth);
  const params = useParams();
  const navigate = useNavigate();
  const index = useRef(0);
  const qList = useRef([]);
  const [qId, setQId] = useState("");
  const attemptAnswers = useRef([]);
  const [transition, setTransition] = useState(false);

  function handleShow(i, sub) {
    if (quiz.includes("DEMO") || sub === VALID_SUBSCRIPTION) {
      index.current = i;
      setQId(qList.current[index.current]);
    } else {
      return;
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  useEffect(() => {
    setTransition(false);
    if (
      user &&
      quiz &&
      attemptId &&
      subject !== "writing" &&
      subject !== "overview"
    ) {
      fetchResult();
      fetchQList();
    }
  }, [user, subject, quiz, attemptId]);

  const fetchQList = async () => {
    try {
      const data = await GetQuestionList(subject, quiz, user?.uid, attemptId);
      setQId(data[index.current]);
      qList.current = data;
      if (data) {
        setTransition(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const fetchResult = async () => {
    try {
      const data = await getResultWithId(user?.uid, subject, quiz, attemptId);
      if (data.attempt_answers !== undefined) {
        attemptAnswers.current = data.attempt_answers;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateQuestion = (direction) => {
    if (direction === "left" && index.current > 0) {
      index.current = index.current - 1;
    } else if (
      direction === "right" &&
      index.current < qList.current.length - 1
    ) {
      index.current = index.current + 1;
    }

    setQId(qList.current[index.current]);
  };

  if (subject === "writing") {
    return (
      <WritingResults
        markingObject={{ attempt_id: attemptId, quiz_id: quiz }}
        showStats={!mockStyle}
      />
    );
  }

  return (
    <Box
      sx={{
        borderRadius: 4,
        backgroundColor: "#F1F4FF",
        p: 3,
        overflow: "auto",
        height: "100%",
        width: "100%",
        borderTopLeftRadius: mockStyle ? 0 : null,
        borderTopRightRadius: mockStyle ? 0 : null,
      }}
    >
      <Grid container columnGap={3} sx={{ height: "100%" }}>
        <Grid item xs sm md lg xl sx={{ height: "100%" }}>
          <Box sx={{ height: "100%", overflow: "auto" }}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: 3,
              }}
            >
              <ReadingText subject={subject} question_id={qId} width={1250} />
              <Answer
                index={index.current}
                question_id={qId}
                attempt={attemptAnswers.current}
                subject={subject}
                quiz={quiz}
                user_id={user?.uid}
                attempt_id={attemptId}
              />
            </Box>
            <Stack direction="row" spacing={4} sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                sx={{ borderRadius: 5 }}
                onClick={() => updateQuestion("left")}
              >
                previous
              </Button>
              <Button
                variant="outlined"
                sx={{ borderRadius: 5 }}
                onClick={() => updateQuestion("right")}
              >
                next
              </Button>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs="auto"
          sm="auto"
          md="auto"
          lg="auto"
          xl="auto"
          sx={{ height: "100%" }}
        >
          <Box
            sx={{
              width: "205px",
              height: "100%",
              pt: 2,
              pl: 2,
              pb: 2,
              pr: 1,
              bgcolor: "#ffffff",
              borderRadius: 4,
            }}
          >
            <Box
              sx={{
                height: "100%",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                pr: 1,
              }}
            >
              {attemptAnswers.current.map((ans, i) => {
                let color = "error";
                if (ans.isCorrect === true) {
                  color = "success";
                }
                return (
                  <Fade in={transition}>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        variant="contained"
                        disableElevation
                        color={color}
                        sx={{
                          borderRadius: 2,
                          width: "100%",
                          height: "42px",
                          justifyContent: "space-between",
                          py: 0,
                          px: 1.5,
                        }}
                        onClick={() => handleShow(i, subscription)}
                      >
                        <Typography
                          sx={{ textTransform: "None", fontSize: "14pt" }}
                        >
                          Question {i + 1}
                        </Typography>
                        <Box sx={{ width: "30px", height: "30px" }}>
                          {ans.isCorrect ? (
                            <CheckCircleOutlineRoundedIcon
                              fontSize="large"
                              sx={{ width: "100%", height: "100%" }}
                            />
                          ) : (
                            <HighlightOffRoundedIcon
                              fontSize="large"
                              sx={{ width: "100%", height: "100%" }}
                            />
                          )}
                        </Box>
                      </Button>
                    </Box>
                  </Fade>
                );
              })}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Results;
