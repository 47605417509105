import React from 'react';
import { Typography, Box } from '@mui/material';
import '../../style/LearnContent.css';
import AutoStoriesRoundedIcon from '@mui/icons-material/AutoStoriesRounded';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';

function Learn0() {
  return (
    <div>
      <Box sx={{ px: 3 }}>
        <Box
          sx={{
            backgroundColor: '#babeee',
            padding: 2,
            marginBottom: 3,
            borderRadius: 4,
          }}
        >
          <Typography
            variant='h5'
            color='primary'
            sx={{ paddingBottom: 2, fontWeight: 600 }}
          >
            What is the Selective Test and why take it?
          </Typography>
          <Typography color='primary' sx={{ paddingBottom: 2 }}>
            The Selective High School Placement Test is a test designed to
            evaluate a student's abilities in the following categories:
            mathematical reasoning, thinking skills, reading comprehension and
            writing. The test is designed to qualify students for Selective
            schools which group students into similar skill levels for an
            optimal learning pace.
          </Typography>
          <Box
            sx={{
              backgroundColor: '#e9ebf6',
              padding: 2,
              marginBottom: 2,
              borderRadius: 4,
              colour: 'aaccff',
            }}
          >
            <Typography variant='h5' sx={{ paddingBottom: 2, fontWeight: 600 }}>
              Why enroll into a Selective School?
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              Students who are enrolling in Selective schools can be ensured
              that their experience will be optimal for their learning pace.
              Selective schools help students of similar abilities by using
              evidence-based teaching (researched methods with the greatest
              learning impact) and promote fair and equitable learning
              opportunities for all academic levels. In addition to academic
              benefits, being with classmates of similar skill levels is
              beneficial for a student's well-being and encourages a social
              transition.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: '#f1f4ff',
            padding: 2,
            marginBottom: 6,
            borderRadius: 4,
          }}
        >
          <Typography variant='h5' sx={{ fontWeight: 600, paddingBottom: 1 }}>
            Topics in the Selective Test
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            The Selective Test is a 3 hour test including breaks in between each
            paper. The papers, assessed in order are Reading, Mathematical
            Reasoning, Thinking Skills and Writing. The papers each have a
            unique number of questions and/or time allocated.
          </Typography>
          <Box
            sx={{
              backgroundColor: '#dae4f5',
              padding: 2,
              marginBottom: 1,
              borderRadius: 4,
              color: '#3E54AC',
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 600 }}>
              Paper 1: Reading Comprehension
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            The reading test consists of 30 questions. Students have 40 minutes
            to complete the test. The questions are based on a diverse range of
            texts and assess a range of reading skills. The reading test
            questions are based on different genres such as non-fiction,
            fiction, poetry, magazine articles and reports. Some questions are
            not multiple choice and can include cloze passage options or
            selecting from multiple extracts based on a criteria.
          </Typography>
          <Box
            sx={{
              backgroundColor: '#dae4f5',
              padding: 2,
              marginBottom: 1,
              borderRadius: 4,
              color: '#3E54AC',
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 600 }}>
              Paper 2: Mathematical Reasoning
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            The mathematical reasoning test consists of 35 questions. Students
            have 40 minutes to complete the test. The questions are all multiple
            choice with 5 options. The mathematical reasoning test assesses the
            student's ability to apply mathematical understanding and knowledge
            to problems, with questions drawn from a range of mathematical
            content topics.
          </Typography>
          <Box
            sx={{
              backgroundColor: '#dae4f5',
              padding: 2,
              marginBottom: 1,
              borderRadius: 4,
              color: '#3E54AC',
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 600 }}>
              Paper 3: Thinking Skills
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            The thinking skills test consists of 40 questions. Students have 40
            minutes to complete the test. The questions are all multiple choice
            with 4 options. The thinking skills test assesses the student's
            ability in critical thinking and problem solving. There are a range
            of different question types in the test.
          </Typography>
          <Box
            sx={{
              backgroundColor: '#dae4f5',
              padding: 2,
              marginBottom: 1,
              borderRadius: 4,
              color: '#3E54AC',
            }}
          >
            <Typography variant='h5' sx={{ fontWeight: 600 }}>
              Paper 4: Writing
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            The writing test consists of a topic or prompt for students to write
            according to the instructions. Students have 30 minutes to complete
            the test. The test assesses the student’s creativity of ideas and
            ability to write effectively for a purpose and audience. The test
            will also assess grammar, punctuation, spelling and vocabulary.
            Addressing the topic is paramount in this paper as students who do
            not, regardless of fluency or creativity, will receive low marks.
          </Typography>
        </Box>
      </Box>
    </div>
  );
}

export default Learn0;
