import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";

//styling stuff
import "../style/modal.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { auth } from "../auth";
import {
  getResultWithId,
  GetQuestionList,
  GetUserRanks,
  GetTopic,
} from "../app_backend";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";

import Results from "../components/Results";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { DEMO_SUBSCRIPTION, EXPIRED_SUBSCRIPTION } from "../Stripe";
import CircularScoreWithLabel from "../components/CircularScoreWithLabel";

const QuizResults = ({
  subject,
  quiz,
  attemptId,
  setSelectedPage,
  subscription,
  page,
}) => {
  const [user, loading, error] = useAuthState(auth);
  const params = useParams();
  const navigate = useNavigate();
  const index = useRef(0);
  const qList = useRef([]);
  const [quizId, setQuizId] = useState("");
  const [score, setScore] = useState(0);
  const [rankData, setRankData] = useState(null);
  const [topic, setTopic] = useState(null);
  const [date, setDate] = useState("");
  const attemptAnswers = useRef([]);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchResult();
  }, [user, loading]);

  const fetchResult = async () => {
    try {
      const data = await getResultWithId(user?.uid, subject, quiz, attemptId);
      setQuizId(data.quiz_id);
      setScore(data.score);
      setDate(data.date);
      attemptAnswers.current = data.attempt_answers;

      const rankData = await GetUserRanks(subject, quiz, data.score);
      setRankData(rankData);

      const t = await GetTopic(subject, quiz);
      setTopic(t);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{ height: "100%" }}>
      {subscription === DEMO_SUBSCRIPTION ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Collapse in={true} timeout={{ enter: 500, exit: 500 }}>
            <Alert
              severity="info"
              variant="outlined"
              sx={{ mb: 3, width: "40vw", borderRadius: 4, bgcolor: "#dff4fe" }}
            >
              <AlertTitle>
                <strong>This is the review page</strong>
              </AlertTitle>
              Here you will be able to view your score and review your
              performance for each question!
            </Alert>
          </Collapse>
        </Box>
      ) : subscription === EXPIRED_SUBSCRIPTION ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Collapse in={true} timeout={{ enter: 500, exit: 500 }}>
            <Alert
              severity="info"
              variant="outlined"
              sx={{ mb: 2, width: "40vw", borderRadius: 4, bgcolor: "#dff4fe" }}
            >
              <AlertTitle>
                <strong>Your Access has expired</strong>
              </AlertTitle>
              You will only be able to view your score and won't be able to
              access our in-depth explanations and answers —{" "}
              <strong>visit to store to upgrade!</strong>
            </Alert>
          </Collapse>
        </Box>
      ) : null}
      <Grid container direction="column" sx={{ height: "100%" }}>
        <Grid item xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", mb: 3 }}>
              <Paper
                elevation={0}
                sx={{ borderRadius: 4, backgroundColor: "#ffffff" }}
              >
                <Box
                  sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: "row",
                    height: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ mr: 4 }}>
                    <Typography variant="h5" sx={{ fontWeight: 800 }}>
                      {quizId}
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ color: "#1f2f98", fontSize: "14pt" }}
                    >
                      {date}
                    </Typography>
                  </Box>
                  <CircularScoreWithLabel
                    score={score}
                    max={attemptAnswers.current.length}
                  />
                  {topic === "MOCK" ? (
                    <Box
                      sx={{
                        ml: 4,
                        display: "flex",
                        flexDirection: "row",
                        borderLeft: 2,
                        height: "100%",
                        alignItems: "center",
                        borderColor: "#e2e5f4",
                      }}
                    >
                      <Box
                        sx={{
                          mx: 4,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" sx={{ fontWeight: 800 }}>
                          Median
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{ color: "#1f2f98", fontSize: "14pt" }}
                        >
                          {rankData?.median}{" "}
                          <span style={{ color: "#babcd6" }}>/</span>{" "}
                          {attemptAnswers.current.length}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          mx: 4,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" sx={{ fontWeight: 800 }}>
                          Rank
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{ color: "#1f2f98", fontSize: "14pt" }}
                        >
                          {rankData?.rank} of {rankData?.total + 1}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          ml: 4,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5" sx={{ fontWeight: 800 }}>
                          Percentile
                        </Typography>
                        <Typography
                          variant="h5"
                          sx={{ color: "#1f2f98", fontSize: "14pt" }}
                        >
                          {rankData?.percentile}
                          <span style={{ fontSize: "10pt" }}>th</span>
                        </Typography>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Paper>
            </Box>
            <Button
              variant="outlined"
              sx={{ height: "3rem", borderRadius: 8, bgcolor: "#f1f4ff" }}
              onClick={() => setSelectedPage(page)}
            >
              <Typography color="primary" sx={{ paddingRight: 1 }}>
                back
              </Typography>
              <ExitToAppIcon color="primary" />
            </Button>
          </Box>
        </Grid>
        <Grid item xs sm md lg xl sx={{ height: "100%", overflow: "auto" }}>
          <Results
            subject={subject}
            quiz={quiz}
            attemptId={attemptId}
            subscription={subscription}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuizResults;
