import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../auth";

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import ListItemButton from '@mui/material/ListItemButton';
import PracticeContent from "../components/Practice/PracticeContent";

import "../style/Practice.css";
import Quiz from "./Quiz";
import ResultsList from "./ResultsList";
import QuizResults from "./QuizResults";
import MockResults from "./MockResults";
import WritingResults from "./WritingResults";
import { DEMO_SUBSCRIPTION, EXPIRED_SUBSCRIPTION } from "../Stripe";
import HorizontalSubjectTabs from "../components/Practice/HorizontalSubjectTabs";
import MobileQuizList from "../components/Mobile/Practice/MobileQuizList";
import NoMobile from "./NoMobile";
import { getBreakPointStyle } from "../components/Mobile/StyleHelper";

function Practice({ subscription }) {
  const [user, loading, error] = useAuthState(auth)
  const navigate = useNavigate();
  const [selectedSubject, setSelectedSubject] = useState(window.innerWidth <= 1000 ? "" : "reading");
  const [selectedQuiz, setSelectedQuiz] = useState('')
  const [selectedPage, setSelectedPage] = useState('quiz_list')
  const [selectedAttemptId, setSelectedAttemptId] = useState('')
  const [allowReattempt, setAllowReattempt] = useState(true)

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  }, [user, loading]);

  useEffect(() => {
    if (subscription === EXPIRED_SUBSCRIPTION) {
      setAllowReattempt(false)
    }
    if (selectedSubject === 'writing') {
      setAllowReattempt(false)
    } else {
      setAllowReattempt(true)
    }
  })

  const MobileSelector = () => {
    switch (selectedPage) {
      case "quiz_list":
        return (
          <MobileQuizList selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} />
        )
      default:
        return (
          <NoMobile />
        )
    }
  }

  const Selector = () => {
    switch (selectedPage) {
      case "quiz_list":
        return (
          <div>
            {subscription === DEMO_SUBSCRIPTION ?
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Collapse
                  in={true}
                  timeout={{ enter: 500, exit: 500 }}
                >
                  <Alert
                    severity="info"
                    variant="outlined"
                    sx={{ mb: 2, width: '40vw', borderRadius: 4, backgroundColor: "#dff4fe" }}
                  >
                    <AlertTitle><strong>Demo Account</strong></AlertTitle>
                    This is a demo account, which has limited access to our quizzes — <strong>visit our store to upgrade!</strong>
                  </Alert>
                </Collapse>
              </Box> : subscription === EXPIRED_SUBSCRIPTION ?
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Collapse
                    in={true}
                    timeout={{ enter: 500, exit: 500 }}
                  >
                    <Alert
                      severity="info"
                      variant="outlined"
                      sx={{ mb: 2, width: '40vw', borderRadius: 4, backgroundColor: "#dff4fe" }}
                    >
                      <AlertTitle><strong>Your Access has expired</strong></AlertTitle>
                      You will not be able to attempt new quizzes and will have limited access to completed attempts — <strong>visit our store to upgrade!</strong>
                    </Alert>
                  </Collapse>
                </Box> : null
            }
            <HorizontalSubjectTabs selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 2,
                width: "100%",
              }}
            >
              <PracticeContent
                subject={selectedSubject}
                setSelectedPage={setSelectedPage}
                setSelectedQuiz={setSelectedQuiz}
                subscription={subscription}
              />
            </Box>
          </div>
        )
      case 'quiz':
        return <Quiz subject={selectedSubject} quiz={selectedQuiz} setSelectedPage={setSelectedPage} />
      case 'results_list':
        return <ResultsList subject={selectedSubject}
          quiz={selectedQuiz}
          setSelectedPage={setSelectedPage}
          setSelectedAttemptId={setSelectedAttemptId}
          allowReattempt={allowReattempt}
          subscription={subscription}
        />
      case 'results':
        if (selectedSubject === "writing") {
          return (
            <WritingResults
              markingObject={{
                attempt_id: selectedAttemptId,
                quiz_id: selectedQuiz,
              }}
              setSelectedPage={setSelectedPage}
              page={"quiz_list"}
            />
          );
        } else {
          return (
            <QuizResults
              subject={selectedSubject}
              quiz={selectedQuiz}
              attemptId={selectedAttemptId}
              setSelectedPage={setSelectedPage}
              subscription={subscription}
              page={"quiz_list"}
            />
          );
        }
      case 'writing_results':
        return <WritingResults setSelectedPage={setSelectedPage} page={"quiz_list"} />
    }
  };

  return (
    <Box sx={{ flexGrow: 1, m: getBreakPointStyle(1, 3), width: "100%" }}>
      {window.innerWidth <= 1000 ? <MobileSelector /> : <Selector />}
    </Box>
  );
}

export default Practice;
