import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CircularScoreWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" value={100} thickness={6} size={110} sx={{ position: 'relative', color: '#E2E5F3' }} />
      {props.score ? <CircularProgress disableShrink variant="determinate" value={(props.score / props.max) * 100} {...props} thickness={6} size={110} sx={{
        position: 'absolute',
        left: 0,
        color: '#44B0FF'
      }} /> : null}
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.score === null || props.score === undefined ? <Typography component="div" fontWeight={700} fontSize='16pt' sx={{ color: '#f26c71' }}>
          Not Marked
        </Typography>
          :
          <Typography component="div" fontWeight={700} fontSize='16pt' sx={{ color: '#f26c71' }}>
            {`${props.score} / ${props.max}`}
          </Typography>}
      </Box>
    </Box>
  );
}

CircularScoreWithLabel.propTypes = {
  /**
   * The value of the user score for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  score: PropTypes.number.isRequired,
  /**
   * The value of the max score for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  max: PropTypes.number.isRequired,
};

