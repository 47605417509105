import {
  getFirestore,
  collection,
  addDoc,
  doc,
  query,
  where,
  orderBy,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  increment,
  arrayUnion,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from 'firebase/app-check';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth, updateProfile } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from './Config';
import { auth } from './auth';

//contains functions that interact with the firestore database
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const app = initializeApp(firebaseConfig);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    '6LcI_IYpAAAAAP3-fNcO--QcgP9gmFGwOcQMGOlK'
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});
const db = getFirestore();

/*    FIREBASE CLOUD FUNCTIONS      */

// const functions = require("firebase/functions");

// This is a listener function that triggers when user.displayName changes
// const FchangeDisplayName = functions.firestore.document
//   .ref("/users/{userId}")
//   .onUpdate((snapshot, context) => {
//     // Grab the current value of what was written to the Realtime Database.
//     const original = snapshot.val();
//     console.log("cloud based function" + original);
//     // functions.logger.log('Uppercasing', context.params.pushId, original);
//     // const uppercase = original.toUpperCase();
//     // // You must return a Promise when performing asynchronous tasks inside a Functions such as
//     // // writing to the Firebase Realtime Database.
//     // // Setting an "uppercase" sibling in the Realtime Database returns a Promise.
//     // return snapshot.ref.parent.child('uppercase').set(uppercase);
//   });

/*    PROJECT BACK-END FUNCTIONS      */

const GetSubjects = async () => {
  const allSubjects = await getDocs(collection(db, 'subjects'));
  let subject_array = [];
  allSubjects.forEach((doc) => {
    subject_array.push({
      id: doc.id,
      num_quiz: doc.data()['num_quiz'],
    });
  });

  return { subjects: subject_array };
};

const GetTopicList = async (subject) => {
  try {
    const topics = new Set();

    const quizRef = collection(db, 'subjects', subject, 'quizzes');
    const quizzesSnapshot = await getDocs(quizRef);

    quizzesSnapshot.forEach((quiz) => {
      if (quiz.data().topic !== undefined) {
        if (quiz.data().topic.toUpperCase() !== 'MOCK') {
          topics.add(quiz.data().topic.toUpperCase());
        }
      }
    });

    return topics;
  } catch (err) {
    console.error(err);
  }
};

// Gets the available topics for the current user in their review section
const GetReviewTopicList = async (user_id, subject) => {
  try {
    const topics = new Set();

    const questionsRef = collection(
      db,
      'users',
      user_id,
      'review',
      subject,
      'questions'
    );
    const questionsSnapshot = await getDocs(questionsRef);

    questionsSnapshot.forEach((q) => {
      const topic = q.data().topic;
      if (topic !== undefined) {
        topics.add(topic.toUpperCase());
      }
    });

    return topics;
  } catch (err) {
    console.error(err);
  }
};

const GetTopic = async (subject, quiz_id) => {
  const quizRef = doc(db, 'subjects', subject, 'quizzes', quiz_id);
  const quizSnapshot = await getDoc(quizRef);
  if (quizSnapshot.exists()) {
    if (quizSnapshot.data().topic !== undefined) {
      return quizSnapshot.data().topic.toUpperCase();
    }
  }
};

const getGradesOverview = async (user_id) => {
  const grades_ref = collection(db, 'users', user_id, 'grades');
  const grades_snap = await getDocs(grades_ref);
  const grades = {};
  grades_snap.forEach((data) => {
    grades[data.id] = data.data();
  });
  return grades;
};

// For all of the user's currently completed quizzes for the input topic
// Collect grade data.
const getTopicGrades = async (user_id, subject, topic) => {
  const subjectRef = collection(db, 'users', user_id, subject);
  const completedSnapshot = await getDocs(subjectRef);
  let quizIds = [];
  completedSnapshot.forEach((quizDone) => {
    if (!quizIds.includes(quizDone.id)) {
      if (quizDone.data().topic.toUpperCase() === topic.toUpperCase()) {
        quizIds.push(quizDone.id);
      }
    }
  });

  const scoresArray = Promise.all(
    quizIds.map(async (quizId) => {
      const attemptsRef = collection(
        db,
        'users',
        user_id,
        subject,
        quizId,
        'attempts'
      );
      const attemptSnap = await getDocs(attemptsRef);

      let grades_map = new Map();
      attemptSnap.forEach((attempt) => {
        const attempt_date = Date.parse(attempt.data().date);
        let attempt_grade = 0;
        if (subject === 'writing') {
          if (!attempt.data().marked_bool) {
            return;
          }
          attempt_grade = parseInt(attempt.data().score) / 20;
        } else {
          attempt_grade =
            parseInt(attempt.data().score) /
            parseInt(attempt.data().attempt_answers.length);
        }
        if (grades_map.has(attempt_date)) {
          let existing_grades = grades_map.get(attempt_date);
          existing_grades.push(attempt_grade);
          grades_map.set(attempt_date, existing_grades);
        } else {
          grades_map.set(attempt_date, [attempt_grade]);
        }
      });
      return grades_map;
    })
  );

  return scoresArray;
};

const GetQuizList = async (user_id, subject, topic = 'All', ignoreMocks) => {
  try {
    const ref = collection(db, 'users', user_id, subject);
    const completedSnapshot = await getDocs(ref);
    let completed = [];
    completedSnapshot.forEach((quizDone) => {
      if (!completed.includes(quizDone.id)) {
        if (subject === 'full_mocks') {
          completed.push(quizDone.id);
        } else if (topic === 'All') {
          if (quizDone.data().topic.toUpperCase() === 'MOCK') {
            if (!ignoreMocks) {
              completed.push(quizDone.id);
            }
          } else {
            completed.push(quizDone.id);
          }
        } else {
          if (quizDone.data().topic.toUpperCase() === topic) {
            if (topic === 'MOCK') {
              if (quizDone.data().free) {
                completed.push(quizDone.id);
              }
            } else {
              completed.push(quizDone.id);
            }
          }
        }
      }
    });

    // Non-completed quizzes
    const quizRef = collection(db, 'subjects', subject, 'quizzes');
    const quizzesSnapshot = await getDocs(quizRef);
    let quiz_list = [];

    quizzesSnapshot.forEach((quiz) => {
      if (subject === 'full_mocks') {
        quiz_list.push({
          id: quiz.id,
          topic: quiz.data().topic.toUpperCase(),
          completed: completed.includes(quiz.id),
        });
      } else if (topic === 'All') {
        if (quiz.data().topic.toUpperCase() === 'MOCK') {
          if (!ignoreMocks) {
            quiz_list.push({
              id: quiz.id,
              topic: quiz.data().topic.toUpperCase(),
              completed: completed.includes(quiz.id),
            });
          }
        } else if (quiz.data().topic.toUpperCase() === 'DEMO') {
          quiz_list.unshift({
            id: quiz.id,
            topic: quiz.data().topic.toUpperCase(),
            completed: completed.includes(quiz.id),
          });
        } else {
          quiz_list.push({
            id: quiz.id,
            topic: quiz.data().topic.toUpperCase(),
            completed: completed.includes(quiz.id),
          });
        }
      } else {
        if (quiz.data().topic.toUpperCase() === topic) {
          if (topic === 'MOCK') {
            if (quiz.data().free) {
              quiz_list.push({
                id: quiz.id,
                topic: quiz.data().topic.toUpperCase(),
                completed: completed.includes(quiz.id),
              });
            }
          } else {
            quiz_list.push({
              id: quiz.id,
              topic: quiz.data().topic.toUpperCase(),
              completed: completed.includes(quiz.id),
            });
          }
        }
      }
    });
    let collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });
    quiz_list.sort((a, b) => {
      if (a.id.includes('DEMO') && !b.id.includes('DEMO')) {
        return -99;
      } else if (!a.id.includes('DEMO') && b.id.includes('DEMO')) {
        return 99;
      } else {
        return collator.compare(a.id, b.id);
      }
    });

    return { quizzes: quiz_list };
  } catch (err) {
    console.error(err);
  }
};

// Get mock quizzes that are not currently used in full mocks
const GetFreeMocks = async (subject) => {
  const quizRef = collection(db, 'subjects', subject, 'quizzes');
  const q = query(quizRef, where('free', '==', true));
  const quizzesSnapshot = await getDocs(q);

  let quiz_list = [];
  quizzesSnapshot.forEach((quiz) => {
    quiz_list.push(quiz.id);
  });

  return quiz_list;
};

const GetQuestionList = async (
  subject,
  quiz_id,
  user_id = null,
  attempt_id = null
) => {
  if (quiz_id !== 'review') {
    const quizRef = doc(db, 'subjects', subject, 'quizzes', quiz_id);
    const quiz = await getDoc(quizRef);
    const question_id_list = quiz.data().questions;
    return question_id_list;
  } else {
    const quizRef = doc(
      db,
      'users',
      user_id,
      'review',
      subject,
      'attempts',
      attempt_id
    );
    const quiz = await getDoc(quizRef);
    const question_id_list = quiz.data().question_ids;
    return question_id_list;
  }
};

const GetAllUserAnswerData = async (
  subject,
  quiz_id,
  questionIndex,
  attempt_id = null,
  user_id = null
) => {
  if (quiz_id === 'review') {
    const q_data = await GetQuizAndQuestionIndex(
      user_id,
      subject,
      attempt_id,
      questionIndex
    );
    quiz_id = q_data.quiz_id;
    questionIndex = q_data.question_index;
  }
  const quiz = await getDoc(doc(db, 'subjects', subject, 'quizzes', quiz_id));
  const answers = [];
  if (quiz.data().first_attempts !== undefined) {
    quiz.data().first_attempts.forEach((attempt) => {
      answers.push(attempt[questionIndex]);
    });
  }
  return answers;
};

const percentile = (arr, num) =>
  Math.floor((arr.filter((item) => item <= num).length / arr.length) * 100);
const median = (sortedArr) => {
  const mid = Math.floor(sortedArr.length / 2);
  return sortedArr.length % 2 !== 0
    ? sortedArr[mid]
    : (sortedArr[mid - 1] + sortedArr[mid]) / 2;
};
function ranking(testScores, score) {
  const scoresWithInput = [...testScores, score];
  // Sort the test scores in descending order
  const sortedScores = scoresWithInput.sort((a, b) => b - a);
  // Find the rank of the input score
  const rank = sortedScores.indexOf(score) + 1;
  return rank;
}
const GetUserRanks = async (subject, quiz_id, userScore) => {
  const quiz = await getDoc(doc(db, 'subjects', subject, 'quizzes', quiz_id));
  let allScores =
    quiz.data().first_scores !== undefined ? quiz.data().first_scores : [];
  //sorts high to low
  allScores.sort((a, b) => b - a);

  if (userScore === null) {
    return {
      percentile: NaN,
      median: median(allScores),
      rank: NaN,
      total: allScores.length,
    };
  }
  return {
    percentile: percentile(allScores, userScore),
    median: median(allScores),
    rank: ranking(allScores, userScore),
    total: allScores.length,
  };
  // const quizzes = (await getDocs(collection(db, "subjects", "thinking", "quizzes"))).docs.map(async (doc) => {
  //   const attempts = doc.data().first_attempts
  //   const answers = doc.data().answers
  //   if (attempts !== undefined) {
  //     let first_scores = []
  //     attempts.forEach(attempt => {
  //       let score = 0
  //       answers.forEach((answer, i) => {
  //         if (answer === attempt[i]) {
  //           score += 1
  //         }
  //       })
  //       first_scores.push(score)
  //     })
  //     console.log(first_scores)
  //     await updateDoc(doc.ref, {
  //       first_scores: first_scores
  //     })
  //   }
  // })
};

const GetQuizAndQuestionIndex = async (
  user_id,
  subject,
  attempt_id,
  review_index
) => {
  // review_index = question index of the desired question in the context of the review quiz
  const reviewAttempt = await getDoc(
    doc(db, 'users', user_id, 'review', subject, 'attempts', attempt_id)
  );
  const qId = reviewAttempt.data().attempt_answers[review_index].qId;
  const quiz_id = reviewAttempt.data().attempt_answers[review_index].quiz_id;
  const quizDoc = await getDoc(
    doc(db, 'subjects', subject, 'quizzes', quiz_id)
  );
  return {
    quiz_id: quiz_id,
    question_index: quizDoc.data().questions.indexOf(qId),
  };
};

const GetQuestion = async (subject, question_id) => {
  const questionRef = doc(db, 'subjects', subject, 'questions', question_id);
  const question = await getDoc(questionRef);
  return question.data();
};

const deleteQuestionsWithId = async (subject, question_ids) => {
  console.log(question_ids);
  await Promise.all(
    question_ids.map(async (question_id) => {
      await deleteDoc(doc(db, 'subjects', subject, 'questions', question_id));
    })
  );
};

const deleteTextsWithId = async (text_ids) => {
  await Promise.all(
    text_ids.map(async (text_id) => {
      await deleteDoc(doc(db, 'subjects', 'reading', 'texts', text_id));
    })
  );
};

const deleteQuizWithId = async (subject, quiz_id) => {
  await deleteDoc(doc(db, 'subjects', subject, 'quizzes', quiz_id));
};

const GetText = async (question_id) => {
  const qData = await GetQuestion('reading', question_id);
  if (qData.textId !== undefined && qData.textId !== '') {
    const textRef = doc(db, 'subjects', 'reading', 'texts', qData.textId);
    const text = await getDoc(textRef);
    return text.data();
  } else {
    return 'NO_TEXT';
  }
};

const GetTextWithId = async (text_id) => {
  const textRef = doc(db, 'subjects', 'reading', 'texts', text_id);
  const text = await getDoc(textRef);
  return {
    fireBaseRef: textRef,
    ...text.data(),
  };
};

const GetMockIds = async (mockName) => {
  const mockRef = doc(db, 'subjects', 'full_mocks', 'quizzes', mockName);
  const mock = await getDoc(mockRef);
  return mock.data().quizIdList;
};

const GetWritingQuestion = async (quiz_id) => {
  const questionRef = doc(db, 'subjects', 'writing', 'quizzes', quiz_id);
  const question = await getDoc(questionRef);
  return question.data();
};

const AdminGetQuizList = async (subject) => {
  const quizRef = collection(db, 'subjects', subject, 'quizzes');
  const quizzesSnapshot = await getDocs(quizRef);
  let quiz_list = [];

  quizzesSnapshot.forEach((quiz) => {
    if (subject === 'full_mocks') {
      quiz_list.push({
        id: quiz.id,
        quizIdList: quiz.data().quizIdList,
      });
    } else {
      quiz_list.push({
        id: quiz.id,
      });
    }
  });

  return quiz_list;
};

const GetNumQuestions = async (subject, quiz_id) => {
  const quizRef = doc(db, 'subjects', subject, 'quizzes', quiz_id);
  const quiz = await getDoc(quizRef);
  if (subject.toLowerCase() === 'writing') {
    return 25;
  }
  return quiz.data().questions.length;
};

const addQuestionReport = async (
  subject,
  quiz_id,
  question_id,
  message,
  questionNumber
) => {
  const user = getAuth(app).currentUser;
  const user_id = user.uid;
  const user_email = user.email;
  const user_name = user.displayName;
  const now = new Date();
  const date = now.toDateString();

  await addDoc(collection(db, 'reports'), {
    user_id,
    user_email,
    user_name,
    date,
    subject,
    quiz_id,
    question_id,
    message,
    questionNumber,
  });

  // notify relevant auth members

  const authEmails = ['bangers0212@gmail.com', 'Samson.he123@gmail.com'];
  authEmails.forEach((email) => {
    sendEmailFromFirebase(
      email,
      'Question Report Submitted.',
      'A student has reported a question.',
      null
    );
  });
};

const addRedoRequest = async (subject, quiz_id, message) => {
  const user = getAuth(app).currentUser;
  const user_id = user.uid;
  const user_email = user.email;
  const user_name = user.displayName;
  const now = new Date();
  const date = now.toDateString();

  await addDoc(collection(db, 'reports'), {
    user_id,
    user_email,
    user_name,
    date,
    subject,
    quiz_id,
    question_id: 'REDO REQUEST',
    message,
  });

  // notify relevant auth members

  const authEmails = ['bangers0212@gmail.com', 'Samson.he123@gmail.com'];
  authEmails.forEach((email) => {
    sendEmailFromFirebase(
      email,
      'Redo Request Submitted.',
      'A student has requested to redo a quiz.',
      null
    );
  });
};

const getReports = async () => {
  const reports = await getDocs(collection(db, 'reports'));
  return reports.docs.map((doc) => {
    let obj = doc.data();
    obj.report_id = doc.id;
    return obj;
  });
};

const deleteReport = async (report_id) => {
  return await deleteDoc(doc(db, 'reports', report_id));
};

const pushWritingNotifications = async (user_id, review_state) => {
  const now = new Date();
  const dateString = now.toDateString();
  const notificationRef = doc(
    collection(db, 'users', user_id, 'notifications')
  );
  await setDoc(notificationRef, {
    title: 'Marking',
    message: null,
    from: review_state.marker_id,
    to: [user_id],
    isWriting: true,
    writingObject: review_state,
    hasSeen: false,
    date: dateString,
  });
  await updateDoc(notificationRef, {
    notification_id: notificationRef.id,
  });

  return notificationRef.id;
};

const submitMarking = async (markingObj) => {
  // structure of markingObj:

  // CRITERIA
  /* 
    criteria1: criteria1,
    criteria2: criteria2,
    criteria3: criteria3,
    criteria4: criteria4,
    criteria5: criteria5,
    criteria6: criteria6, 
  */

  // FEEDBACK
  /* feedback: feedback, */

  // MARKING INFO
  /*
    markedBy: user.displayName,
    markedOn: dateString,
    attempt_id: attempt_id,
    marker_id: user.uid,
  */

  // RESPONSE
  /* responseJSON: responseJSON, */

  // COMMENT LIST
  /* commentList: commentList, */

  // PAPER
  /* 
    attempt_id, 
    marking_id, 
    date, 
    quiz_id, 
    response (tree), 
    user_id, 
    user_name 
  */

  const markingId = markingObj.marking_id;

  // remove doc from 'not marked' queue

  const unmarkedDocRef = doc(db, 'marking', 'writing', 'not marked', markingId);
  await deleteDoc(unmarkedDocRef);

  // add object to marked queue with same id

  const markedDocRef = doc(db, 'marking', 'writing', 'marked', markingId);
  await setDoc(markedDocRef, markingObj);

  // return the marking id

  return markedDocRef.id;
};

const fetchNotifications = async (user_id) => {
  // chronological
  const ref = collection(db, 'users', user_id, 'notifications');
  const notifSnap = await getDocs(ref);

  let notifArr = [];

  notifSnap.forEach((notif) => {
    notifArr.push(notif.data());
  });

  notifArr.sort((a, b) => {
    var aDate = new Date(a.date);
    var bDate = new Date(b.date);
    var aTime = aDate.getTime();
    var bTime = bDate.getTime();
    if (aTime > bTime) {
      return -1;
    }
    if (bTime > aTime) {
      return 1;
    } else {
      return 0;
    }
  });
  return notifArr;
};

const getPaperFromNotification = async (user_id, notification_id) => {
  const doc_ref = doc(db, 'users', user_id, 'notifications', notification_id);
  const doc_snap = await getDoc(doc_ref);
  return {
    criteria1: doc_snap.data().criteria1,
    criteria2: doc_snap.data().criteria2,
    criteria3: doc_snap.data().criteria3,
    criteria4: doc_snap.data().criteria4,
    feedback: doc_snap.data().feedback,
    response: doc_snap.data().response,
    markedBy: doc_snap.data().markedBy,
    markedOn: doc_snap.data().markedOn,
    quiz_id: doc_snap.data().quiz_id,
    attempt_id: doc_snap.data().attempt_id,
  };
};

const updateSeenNotification = async (user_id, notification_id) => {
  const doc_ref = doc(db, 'users', user_id, 'notifications', notification_id);
  await updateDoc(doc_ref, {
    hasSeen: true,
  });
};

// const deleteNotification = async (user_id, notification_id) => {
//   const notifRef = doc(db, "users", user_id, "notifications", notification_id);
//   await deleteDoc(notifRef);
//   return;
// };

const fetchPaperIfMarked = async (attempt_id, user_id, quiz_id) => {
  // check if paper has been marked, if it has not, return null
  // else return the paper

  const attemptRef = doc(
    db,
    'users',
    user_id,
    'writing',
    quiz_id,
    'attempts',
    attempt_id
  );

  const attemptSnap = await getDoc(attemptRef);

  const markingId = attemptSnap.data().marking_id;

  // check if paper is not marked
  if (!attemptSnap.data().marked_bool) {
    return null;
  }

  const markedRef = doc(db, 'marking', 'writing', 'marked', markingId);
  const markedSnap = await getDoc(markedRef);

  return markedSnap.data();
};

const fetchPaperIfSubmittedForMarking = async (
  attempt_id,
  user_id,
  quiz_id
) => {
  // check if paper has been marked, if it has not, return null
  // else return the paper

  const attemptRef = doc(
    db,
    'users',
    user_id,
    'writing',
    quiz_id,
    'attempts',
    attempt_id
  );
  const attemptSnap = await getDoc(attemptRef);
  const markingId = attemptSnap.data().marking_id;

  if (markingId === undefined) {
    return 'NOT_SUBMITTED';
  } else {
    if (attemptSnap.data().marked_bool) {
      return 'MARKED';
    } else {
      return 'NOT_MARKED';
    }
  }
};

const fetchUserName = async (user_id) => {
  const userRef = doc(db, 'users', user_id);
  const userSnap = await getDoc(userRef);
  if (userSnap.exists()) {
    return userSnap.data().name;
  } else {
    console.log('No such document!');
  }
};

const addWritingQuiz = async (quizName, topic, question) => {
  const newQuizRef = doc(db, 'subjects', 'writing', 'quizzes', quizName);
  if (topic.toUpperCase() !== 'MOCK') {
    await setDoc(newQuizRef, {
      topic: topic,
      question: question,
    });
  } else {
    await setDoc(newQuizRef, {
      free: true,
      topic: topic,
      question: question,
    });
  }
};

const canMakeMajorEdit = async (subject, quiz_id) => {
  const quizRef = doc(db, 'subjects', subject, 'quizzes', quiz_id);
  const quiz = await getDoc(quizRef);
  if (!quiz.exists()) {
    return true;
  }
  if (quiz.data().first_attempts || quiz.data().first_scores) {
    return false;
  }
  return true;
};

const addMCQuestion = (subject, questionDetails) => {
  if (questionDetails.firebaseId) {
    const docRef = doc(
      db,
      'subjects',
      subject,
      'questions',
      questionDetails.firebaseId
    );
    setDoc(docRef, { ...questionDetails, editTime: Date.now() });
    return questionDetails.firebaseId;
  }
  const docRef = doc(collection(db, 'subjects', subject, 'questions'));
  setDoc(docRef, questionDetails);
  return docRef.id;
};

// Add each question individually to questions collection,
// then add quiz to subject collection
const addMCQuiz = async (quizName, subject, topic, questionDetailsList) => {
  const newQuizRef = doc(db, 'subjects', subject, 'quizzes', quizName);

  var qList = [];
  var ansList = [];

  console.log(questionDetailsList);
  questionDetailsList.forEach((q) => {
    var qId = addMCQuestion(subject, q);
    qList.push(qId);
    ansList.push(q.answer);
  });
  if (topic.toUpperCase() !== 'MOCK') {
    await setDoc(newQuizRef, {
      topic: topic,
      questions: qList,
      answers: ansList,
    });
  } else {
    await setDoc(newQuizRef, {
      free: true,
      topic: topic,
      questions: qList,
      answers: ansList,
    });
  }
};

// Add full mock and then each section individually
const addFullMock = async (mockName, quizIdList) => {
  const newMockRef = doc(db, 'subjects', 'full_mocks', 'quizzes', mockName);
  await setDoc(newMockRef, {
    quizIdList: quizIdList,
    topic: 'mock',
  });
  const readingRef = doc(db, 'subjects', 'reading', 'quizzes', quizIdList[0]);
  await updateDoc(readingRef, {
    free: false,
  });
  const mathRef = doc(db, 'subjects', 'maths', 'quizzes', quizIdList[1]);
  await updateDoc(mathRef, {
    free: false,
  });
  const thinkingRef = doc(db, 'subjects', 'thinking', 'quizzes', quizIdList[2]);
  await updateDoc(thinkingRef, {
    free: false,
  });
  const writingRef = doc(db, 'subjects', 'writing', 'quizzes', quizIdList[3]);
  await updateDoc(writingRef, {
    free: false,
  });
};

const getTextRef = () => {
  return doc(collection(db, 'subjects', 'reading', 'texts'));
};

const addText = async (textRef, textDetails) => {
  setDoc(textRef, textDetails);
};

const addQuestionStats = async (subject, question_id, answer) => {
  const option_ref = doc(
    db,
    'subjects',
    subject,
    'questions',
    question_id,
    'first_attempt',
    answer
  );
  const option = await getDoc(option_ref);
  if (!option.exists()) {
    await setDoc(option_ref, {
      count: 1,
    });
  } else {
    await updateDoc(option_ref, {
      count: increment(1),
    });
  }
};

const S_grade = 0.9;
const A_grade = 0.75;
const B_grade = 0.6;
const C_grade = 0.4;
const D_grade = 0;

const grade_colors = ['#f9c42f', '#7f59ee', '#4d7ee9', '#32b1c2', '#21c0a3'];

// updates the user's score frequency given percentage mark
const submitGrade = async (user_id, percent_mark) => {
  const grades_ref = doc(db, 'users', user_id, 'grades', 'score_frequency');
  const grades_snap = await getDoc(grades_ref);
  const scores = grades_snap.data();
  if (percent_mark >= S_grade) {
    scores['S'] += 1;
  } else if (percent_mark >= A_grade) {
    scores['A'] += 1;
  } else if (percent_mark >= B_grade) {
    scores['B'] += 1;
  } else if (percent_mark >= C_grade) {
    scores['C'] += 1;
  } else {
    scores['D'] += 1;
  }
  await updateDoc(grades_ref, scores);

  const avg_grade_ref = doc(db, 'users', user_id, 'grades', 'average_grade');
};

const updateHeatMap = async (user_id, now) => {
  const userRef = doc(db, 'users', user_id);
  const user = await getDoc(userRef);
  const values = user.data().attemptMap;
  if (values[now.toDateString()] >= 0) {
    values[now.toDateString()] += 1;
  } else {
    values[now.toDateString()] = 1;
  }
  await updateDoc(userRef, {
    attemptMap: values,
  });
};

const submitAttempt = async (
  user_id,
  subject,
  quiz_id,
  answer_list,
  timed_list
) => {
  const now = new Date();
  await updateHeatMap(user_id, now);

  const ref = collection(db, 'users', user_id, subject, quiz_id, 'attempts');
  const quiz_ref = doc(db, 'subjects', subject, 'quizzes', quiz_id);
  const quiz = await getDoc(quiz_ref);

  const doc_ref = doc(db, 'users', user_id, subject, quiz_id);
  const doc_snap = await getDoc(doc_ref);

  // setting first attempt in user doc
  if (!doc_snap.exists()) {
    if (quiz.data().topic.toUpperCase() === 'MOCK') {
      await setDoc(doc_ref, {
        topic: quiz.data().topic,
        free: quiz.data().free,
        first_attempt: answer_list,
      });
    } else {
      await setDoc(doc_ref, {
        topic: quiz.data().topic,
        first_attempt: answer_list,
      });
    }
  }

  // setting first attempt in quiz doc
  const first_attempt_ref = doc(db, 'subjects', subject, 'quizzes', quiz_id);
  const first_attempt = await getDoc(first_attempt_ref);
  let new_first_attempts = [];
  const user_answers = answer_list;
  const answers_map = {};
  user_answers.forEach((ans, i) => {
    answers_map[i] = ans;
  });
  if (first_attempt.data().first_attempts === undefined) {
    new_first_attempts.push(answers_map);
  } else {
    const curr_attempts = first_attempt.data().first_attempts;
    curr_attempts.push(answers_map);
    new_first_attempts = curr_attempts;
  }
  await updateDoc(first_attempt_ref, {
    first_attempts: new_first_attempts,
  });

  const quiz_answers = quiz.data().answers;
  const quiz_questions = quiz.data().questions;
  const quiz_topic = quiz.data().topic;
  let correct = 0;
  let attemptAnswers = [];
  answer_list.forEach(async (answer, i) => {
    if (answer === quiz_answers[i]) {
      correct++;
      attemptAnswers.push({
        user_answer: answer,
        isCorrect: true,
        time_spent: timed_list.current[i],
      });
    } else if (answer !== quiz_answers[i]) {
      attemptAnswers.push({
        user_answer: answer,
        correct_answer: quiz_answers[i],
        isCorrect: false,
        time_spent: timed_list.current[i],
      });

      // add incorrect answer to review section
      await setDoc(
        doc(
          db,
          'users',
          user_id,
          'review',
          subject,
          'questions',
          quiz_questions[i]
        ),
        {
          quiz_id: quiz_id,
          user_answer: answer,
          topic: quiz_topic,
        }
      );
    }
  });

  let new_first_scores = [];
  if (correct > 0) {
    if (first_attempt.data().first_scores === undefined) {
      new_first_scores.push(correct);
    } else {
      const curr_scores = first_attempt.data().first_scores;
      curr_scores.push(correct);
      new_first_scores = curr_scores;
    }
    await updateDoc(first_attempt_ref, {
      first_scores: new_first_scores,
    });
  }

  const attemptRef = await addDoc(ref, {
    quiz_id: quiz_id,
    score: correct,
    attempt_answers: attemptAnswers,
    date: now.toDateString(),
  });

  await submitGrade(
    user_id,
    parseInt(correct) / parseInt(attemptAnswers.length)
  );
  const recent_ref = doc(db, 'users', user_id, 'grades', 'most_recent');
  await setDoc(recent_ref, {
    quiz_id: quiz_id,
    attempt_id: attemptRef.id,
    subject: subject,
    score: correct,
    total: attemptAnswers.length,
    date: now.toDateString(),
  });
  return attemptRef.id;
};

const submitWritingAttempt = async (
  user_id,
  quiz_id,
  reponse,
  usedToken = false
) => {
  const now = new Date();
  await updateHeatMap(user_id, now);

  const quiz_ref = doc(db, 'subjects', 'writing', 'quizzes', quiz_id);
  const quiz = await getDoc(quiz_ref);
  const doc_ref = doc(db, 'users', user_id, 'writing', quiz_id);
  const doc_snap = await getDoc(doc_ref);
  if (!doc_snap.exists()) {
    if (quiz.data().topic.toUpperCase() === 'MOCK') {
      await setDoc(doc_ref, {
        topic: quiz.data().topic,
        free: quiz.data().free,
      });
    } else {
      await setDoc(doc_ref, {
        topic: quiz.data().topic,
      });
    }
  }
  const ref = collection(db, 'users', user_id, 'writing', quiz_id, 'attempts');
  const attemptRef = await addDoc(ref, {
    quiz_id: quiz_id,
    reponse: reponse,
    date: now.toDateString(),
    marked_bool: false,
  });

  if (usedToken) {
    const currTokens = await getMarkingTokens(user_id);
    if (currTokens > 0) {
      await setMarkingTokens(user_id, currTokens - 1);
      // add to marking queue
      const markingId = await addWritingMarkingQueue(
        user_id,
        quiz_id,
        reponse,
        now.toDateString(),
        attemptRef.id
      );

      await updateDoc(attemptRef, {
        marking_id: markingId,
      });

      // email relevant markers

      // hard coded to just bing and samsons for now
      const markerEmails = ['bangers0212@gmail.com', 'Samson.he123@gmail.com'];
      markerEmails.forEach((email) => {
        sendEmailFromFirebase(
          email,
          'Writing queued for Marking.',
          'A student has submitted a new writing for marking.',
          null
        );
      });
    }
  }
  return { attemptId: attemptRef.id };
};

const sendEmailFromFirebase = (email, subject, text, html) => {
  addDoc(collection(db, 'mail'), {
    to: email,
    message: {
      subject: subject,
      text: text,
      html: html,
    },
  }).then(() => console.log('Queued email for delivery!'));
};

const submitForMarkingByAttemptId = async (user_id, quiz_id, attempt_id) => {
  const currTokens = await getMarkingTokens(user_id);
  if (currTokens > 0) {
    const now = new Date();

    const attemptRef = doc(
      db,
      'users',
      user_id,
      'writing',
      quiz_id,
      'attempts',
      attempt_id
    );
    const attempt = await getDoc(attemptRef);

    const markingId = await addWritingMarkingQueue(
      user_id,
      quiz_id,
      attempt.data().reponse,
      now.toDateString(),
      attempt_id
    );

    await updateDoc(attemptRef, {
      marking_id: markingId,
    });

    await setMarkingTokens(user_id, currTokens - 1);
    return {};
  } else {
    return { error: 'NOT_ENOUGH_TOKENS' };
  }
};

const addWritingMarkingQueue = async (
  user_id,
  quiz_id,
  response,
  date,
  attempt_id
) => {
  const ref = collection(db, 'marking', 'writing', 'not marked');
  const user_name = await fetchUserName(user_id);

  var markingRef = doc(ref);
  await setDoc(markingRef, {
    quiz_id: quiz_id,
    user_id: user_id,
    response: response,
    user_name: user_name,
    marking_id: markingRef.id,
    date: date,
    attempt_id: attempt_id,
  });

  return markingRef.id;
};

const changeAttemptStatus = async (user_id, attempt_id, quiz_id, score) => {
  const numAttempts = (
    await getDocs(
      collection(db, 'users', user_id, 'writing', quiz_id, 'attempts')
    )
  ).docs.length;

  if (numAttempts === 1) {
    const quizRef = doc(db, 'subjects', 'writing', 'quizzes', quiz_id);
    const quiz = await getDoc(quizRef);
    let new_first_scores = [];
    if (score > 0) {
      if (quiz.data().first_scores === undefined) {
        new_first_scores.push(score);
      } else {
        const curr_scores = quiz.data().first_scores;
        curr_scores.push(score);
        new_first_scores = curr_scores;
      }
      await updateDoc(quizRef, {
        first_scores: new_first_scores,
      });
    }
  }

  const docRef = doc(
    db,
    'users',
    user_id,
    'writing',
    quiz_id,
    'attempts',
    attempt_id
  );
  await updateDoc(docRef, {
    marked_bool: true,
    score: score,
  });
  await submitGrade(user_id, score / 20);
  return;
};

const getMarkingQueue = async () => {
  const ref = collection(db, 'marking', 'writing', 'not marked');
  const markingSnapshot = await getDocs(ref);
  let papers = [];
  markingSnapshot.forEach((paper) => {
    papers.push({ ...paper.data() });
  });

  return papers;
};

const getNotMarkedPaper = async (markingId) => {
  const doc_ref = doc(db, 'marking', 'writing', 'not marked', markingId);
  const doc_snap = await getDoc(doc_ref);
  return {
    ...doc_snap.data(),
  };
};

const addAttemptId = async (user_id, mock_id, attempt_id) => {
  const ref = doc(db, 'users', user_id, 'full_mocks', mock_id);
  const docSnap = await getDoc(ref);
  if (docSnap.exists()) {
    await updateDoc(ref, {
      attempts: arrayUnion(attempt_id),
    });
  } else {
    await setDoc(ref, {
      attempts: [attempt_id],
    });
  }
};

//gets marks using the attempt id
const getResultWithId = async (user_id, subject, quiz_id, attempt_id) => {
  let attemptRef = undefined;
  if (quiz_id !== 'review') {
    attemptRef = doc(
      db,
      'users',
      user_id,
      subject,
      quiz_id,
      'attempts',
      attempt_id
    );
  } else {
    attemptRef = doc(
      db,
      'users',
      user_id,
      'review',
      subject,
      'attempts',
      attempt_id
    );
  }
  const docSnap = await getDoc(attemptRef);
  return docSnap.data();
};

const getMockResult = async (user_id, mock_id) => {
  const mockRef = doc(db, 'subjects', 'full_mocks', 'quizzes', mock_id);
  const mock = await getDoc(mockRef);
  const mockIds = mock.data().quizIdList;

  const mockAttemptRef = doc(db, 'users', user_id, 'full_mocks', mock_id);
  const mockSnap = await getDoc(mockAttemptRef);
  const attemptIds = mockSnap.data().attempts;

  const subjects = ['reading', 'maths', 'thinking', 'writing'];

  let arr = [];

  for (let i = 0; i < attemptIds.length; i++) {
    arr.push({
      subject: subjects[i],
      mock_id: mockIds[i],
      attempt_id: attemptIds[i],
    });
  }

  return {
    data_arr: arr,
    quiz_id: mock_id,
  };
};

const getMockScores = async (user_id, data_arr) => {
  // data_arr contains arrays of: {attempt_id, quiz_id, subject}

  // const marked = (await getDocs(collection(db, "marking", "writing", "marked"))).docs.map((doc) => {
  //   const data = doc.data()
  //   const score = data.criteria1 + data.criteria2 + data.criteria3 + data.criteria4 + data.criteria5 + data.criteria6
  //   return {
  //     user_id: data.user_id,
  //     quiz_id: data.quiz_id,
  //     attempt_id: data.attempt_id,
  //     score: score
  //   }
  // })

  // marked.map(async (mark) => {
  //   console.log(mark.user_id)
  //   const quiz_ref = doc(db, "subjects", "writing", "quizzes", mark.quiz_id)
  //   await updateDoc(quiz_ref, {
  //     first_scores: [0, 4, 5, 6, 6, 8, 9, 10, 11, 11, 12, 12, 12, 13, 13, 13, 14, 14, 14, 15, 15, 16, 17, 18, 19, 20]
  //   })
  // })

  const scores = {};
  let date = '';

  for (let data_ele of data_arr) {
    const attemptRef = doc(
      db,
      'users',
      user_id,
      data_ele.subject,
      data_ele.mock_id,
      'attempts',
      data_ele.attempt_id
    );
    const ref = await getDoc(attemptRef);
    const data = ref.data();
    date = ref.data().date;
    scores[data_ele.subject] = data;
  }
  return {
    scores: scores,
    date: date,
  };
};

const getResults = async (user_id, subject, quiz_id) => {
  const quizAttemptsRef = collection(
    db,
    'users',
    user_id,
    subject,
    quiz_id,
    'attempts'
  );
  const q = query(
    quizAttemptsRef,
    where('quiz_id', '==', quiz_id),
    orderBy('date', 'desc')
  );
  const qSnapshot = await getDocs(q);
  let resultsList = [];
  qSnapshot.forEach((doc) => {
    if (subject !== 'writing') {
      resultsList.push({
        id: doc.id,
        date: doc.data().date,
        score: doc.data().score,
        total: doc.data().attempt_answers.length,
      });
    } else {
      resultsList.push({
        id: doc.id,
        date: doc.data().date,
      });
    }
  });
  return resultsList;
};

const shortSubjectToLong = (subject) => {
  if (subject.toUpperCase() === 'READING') {
    return 'Reading';
  } else if (subject.toUpperCase() === 'MATHS') {
    return 'Mathematical Reasoning';
  } else if (subject.toUpperCase() === 'THINKING') {
    return 'Thinking Skills';
  }
};

const getReviewResultsList = async (user_id, subject) => {
  const quizAttemptsRef = collection(
    db,
    'users',
    user_id,
    'review',
    subject,
    'attempts'
  );
  const qSnapshot = await getDocs(quizAttemptsRef);
  let resultsList = [];
  qSnapshot.forEach((doc) => {
    resultsList.push({
      id: doc.id,
      name: `${shortSubjectToLong(subject)} - ${doc.data().topic}`,
      date: doc.data().date,
      score: doc.data().score,
      total: doc.data().attempt_answers.length,
    });
  });
  return resultsList;
};

const getHeatMapValues = async (user_id, window) => {
  function addHours(date, hours) {
    date.setHours(date.getHours() + hours);
    return date;
  }
  const now = new Date();
  const newDate = new Date(now);
  newDate.setDate(newDate.getDate() + window);

  const userRef = doc(db, 'users', user_id);
  const user = await getDoc(userRef);
  const values = user.data().attemptMap;
  const dates = [];

  Object.entries(values).forEach(([k, v]) => {
    dates.push({
      date: addHours(new Date(k), 5),
      count: v,
    });
  });

  return dates;
};

const getNumQuizDoneSubject = async (user_id, subject) => {
  try {
    const subjectRef = collection(db, 'subjects', subject, 'quizzes');
    const subjectSnap = await getDocs(subjectRef);
    let total = 0;
    subjectSnap.forEach((quiz) => {
      total += 1;
    });
    const userRef = collection(db, 'users', user_id, subject);
    const userSnap = await getDocs(userRef);
    let count = 0;
    userSnap.forEach((quiz) => {
      count += 1;
    });
    return (count / total) * 100;
  } catch (e) {}
};

// async function GetUserProfile(user_id) {

// }

// const setQuestionImage = async (subject, question_id, imageUrl) => {
//     const questionRef = doc(db, "subjects", subject, "questions", question_id)
//     const question = await getDoc(questionRef)
//     question.data["imageUrl"] = imageUrl

// }

const uploadImg = async (file, subject, question_id) => {
  const storageRef = ref(getStorage(app), 'images/' + question_id + file.name);
  uploadBytes(storageRef, file).then((snapshot) => {
    getDownloadURL(snapshot.ref).then((url) =>
      storeDlURL(url, subject, question_id)
    );
  });
};

const uploadPhotoGetURL = async (file, currentUser) => {
  const fileRef = ref(getStorage(app), currentUser.uid + 'loading' + '.png');

  const snapshot = await uploadBytes(fileRef, file);
  const photoURL = await getDownloadURL(fileRef);

  return photoURL;
};

const uploadProfileImage = async (file, currentUser, setLoading) => {
  const fileRef = ref(getStorage(app), currentUser.uid + '.png');

  setLoading(true);

  const snapshot = await uploadBytes(fileRef, file);
  const photoURL = await getDownloadURL(fileRef);

  await updateProfile(currentUser, { photoURL });

  setLoading(false);
};

const storeDlURL = async (url, subject, question_id) => {
  const questionRef = doc(db, 'subjects', subject, 'questions', question_id);
  const docSnapshot = await getDoc(questionRef);
  if (!docSnapshot.exists()) {
    console.error('Snapshot does not exist.');
    return;
  }

  console.log('should not run if snapshot doesnt exist');
  await updateDoc(questionRef, {
    imgURL: url,
  });
  console.log('uploaded');
};

const updateScript = async (subject) => {
  const updateQs = await getDocs(
    collection(db, 'subjects', subject, 'questions')
  );
  updateQs.forEach(async (question) => {
    const questionRef = doc(db, 'subjects', subject, 'questions', question.id);
    await updateDoc(questionRef, {
      imgURL: '',
    });
  });
};

const fetchRegisterDate = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  return userDoc.data().registerDate;
};

const fetchAuthenticationLevel = async (userId) => {
  const userRef = doc(db, 'users', userId);
  const userDoc = await getDoc(userRef);
  if (userDoc.data().authenticationLevel !== undefined) {
    // console.log("authentication alr set")
    return userDoc.data().authenticationLevel;
  }
  await updateDoc(userRef, { authenticationLevel: 0 });
};

// const resetAllUserAuthentication = async () => {
//   const users = await getDocs(collection(db, "users"));
//   users.forEach(async (user) => {
//     const userRef = doc(db, "users", user.id);
//     await updateDoc(userRef, {
//       authenticationLevel: 0,
//     });
//   });
// };

const getProductDays = async (productId) => {
  try {
    const productRef = doc(db, 'products', productId);
    const productDoc = await getDoc(productRef);
    return parseInt(productDoc.data().metadata.days);
  } catch {}
};

const getProductName = async (productId) => {
  const productRef = doc(db, 'products', productId);
  const productDoc = await getDoc(productRef);
  return productDoc.data().name;
};

const checkUserIdExists = async (uid) => {
  const docRef = doc(db, 'users', uid);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return true;
  }
  return false;
};

const getEmailFromUid = async (uid) => {
  const docRef = doc(db, 'users', uid);

  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data().email;
  }
  return null;
};

const sendNotificationsAll = async (title, message, user_id, switchValue) => {
  // if switch value is true, send email too
  const usersRef = collection(db, 'users');
  const usersSnap = await getDocs(usersRef);

  const now = new Date();
  const dateString = now.toDateString();

  // Creates an array of promises that can be awaited later
  // You can use the docs property which is an array, and then you can use map on it
  return await Promise.all(
    usersSnap.docs.map(async (userDoc) => {
      const notificationRef = doc(
        collection(db, 'users', userDoc.id, 'notifications')
      );
      await setDoc(notificationRef, {
        title: title,
        message: message,
        from: user_id,
        to: [userDoc.id],
        isWriting: false,
        writingObject: null,
        hasSeen: false,
        date: dateString,
      });
      await updateDoc(notificationRef, {
        notification_id: notificationRef.id,
      });

      if (switchValue && userDoc.email) {
        sendEmailFromFirebase(userDoc.email, title, message, null);
      }
    })
  );
};

const sendNotificationsArr = async (
  title,
  message,
  user_id,
  arr,
  switchValue
) => {
  // if switch value is true, send email too
  const now = new Date();
  const dateString = now.toDateString();

  return await Promise.all(
    arr.map(async (uid) => {
      const notificationRef = doc(
        collection(db, 'users', uid, 'notifications')
      );
      await setDoc(notificationRef, {
        title: title,
        message: message,
        from: user_id,
        to: [uid],
        isWriting: false,
        writingObject: null,
        hasSeen: false,
        date: dateString,
      });
      await updateDoc(notificationRef, {
        notification_id: notificationRef.id,
      });

      if (switchValue) {
        const email = await getEmailFromUid(uid);
        if (email !== null) {
          sendEmailFromFirebase(email, title, message, null);
        }
      }
    })
  );
};

// initialises number of starting tokens depending on user plan
const initMarkingTokens = async (userId, productId) => {
  // const userRef = doc(db, "users", userId);
  // const productName = await getProductName(productId);
  // const user = await getDoc(userRef);
  // if (user.data().init_tokens_applied === undefined) {
  //   let prevTokens =
  //     user.data().markingTokens === undefined ? 0 : user.data().markingTokens;
  //   if (productName === "Per Month") {
  //     await updateDoc(userRef, {
  //       markingTokens: prevTokens + 1,
  //       init_tokens_applied: true,
  //     });
  //   } else if (productName === "Per Three Months") {
  //     await updateDoc(userRef, {
  //       markingTokens: prevTokens + 3,
  //       init_tokens_applied: true,
  //     });
  //   }
  // }
};

const getMarkingTokens = async (userId) => {
  const user = await getDoc(doc(db, 'users', userId));
  if (user.data().markingTokens === undefined) {
    return 0;
  }
  return user.data().markingTokens;
};

const setMarkingTokens = async (userId, tokens) => {
  await updateDoc(doc(db, 'users', userId), {
    markingTokens: tokens,
  });
};

const incrementMarkingTokens = async (userId, numIncrementTokens) => {
  const oldNumTokens = await getMarkingTokens(userId);
  const newNumTokens = oldNumTokens + numIncrementTokens;
  await setMarkingTokens(userId, newNumTokens);
};

// Returns ubsubscribe for attached listeners.
const attachMarkingTokenListeners = async () => {
  const subscriptionListenerUnsubscribe =
    await attachSubscriptionMarkingTokenListener();
  const paymentListenerUnsubscribe = await attachPaymentMarkingTokenListener();

  return {
    subscriptionUnsub: subscriptionListenerUnsubscribe,
    paymentUnsub: paymentListenerUnsubscribe,
  };
};

// Returns unsubscribe for subscription listeners.
const attachSubscriptionMarkingTokenListener = async () => {
  const userSubscriptionCollectionRef = collection(
    db,
    'users',
    auth.currentUser.uid,
    'subscriptions'
  );

  const subscriptionQuery = query(
    userSubscriptionCollectionRef,
    where('status', '==', 'active')
  );

  const subscriptionListenerUnsubscribe = onSnapshot(
    subscriptionQuery,
    async (snapshot) => {
      const listenerPromises = snapshot.docChanges().map(async (change) => {
        // Initial Subscription Purchase
        if (
          change.type === 'added' &&
          (change.doc.data().tokens_applied === undefined ||
            change.doc.data().tokens_applied === false)
        ) {
          const productRef = change.doc.data().product;
          const productDoc = await getDoc(productRef);

          const tokensToApply = parseInt(productDoc.data().metadata.tokens);

          await incrementMarkingTokens(auth.currentUser.uid, tokensToApply);

          await updateDoc(change.doc.ref, { tokens_applied: true });
        }
      });

      await Promise.all(listenerPromises);
    }
  );

  return subscriptionListenerUnsubscribe;
};

// Returns unsubscribe for payment listeners.
const attachPaymentMarkingTokenListener = async () => {
  const userPaymentCollectionRef = collection(
    db,
    'users',
    auth.currentUser.uid,
    'payments'
  );

  // Only grab marking token payments.
  const paymentQuery = query(
    userPaymentCollectionRef,
    where('items', '!=', null)
  );

  const paymentListenerUnsubscribe = onSnapshot(
    paymentQuery,
    async (snapshot) => {
      const listenerPromises = snapshot.docChanges().map(async (change) => {
        if (
          change.type === 'added' &&
          (change.doc.data().tokens_applied === undefined ||
            change.doc.data().tokens_applied === false)
        ) {
          const paymentDoc = change.doc;
          const paymentData = paymentDoc.data();

          const productData = paymentData.items[0];

          let tokensToApply;
          switch (productData.description) {
            case '1 Marking Token':
              tokensToApply = 1;
              break;
            case '3 Marking Tokens':
              tokensToApply = 3;
              break;
            case '5 Marking Tokens':
              tokensToApply = 5;
              break;
          }

          await incrementMarkingTokens(auth.currentUser.uid, tokensToApply);

          await updateDoc(paymentDoc.ref, { tokens_applied: true });
        }
      });

      await Promise.all(listenerPromises);
    }
  );

  return paymentListenerUnsubscribe;
};

const updateMarkingTokens = async () => {
  // const addOnPayments = await getAddOnPayments();
  const paymentsCollection = collection(
    db,
    'users',
    auth.currentUser.uid,
    'subscriptions'
  );
  const payments = await getDocs(paymentsCollection);

  for (const payment of payments.docs) {
    const paymentRef = doc(
      db,
      'users',
      auth.currentUser.uid,
      'subscriptions',
      payment.id
    );
    const paymentDoc = await getDoc(paymentRef);
    if (
      paymentDoc.data().tokens_applied === undefined ||
      paymentDoc.data().tokens_applied === false
    ) {
      await updateDoc(paymentRef, {
        tokens_applied: true,
      });
      const userRef = doc(db, 'users', auth.currentUser.uid);
      const userDoc = await getDoc(userRef);

      const product = await getDoc(paymentDoc.data().product);

      if (userDoc.data().markingTokens === undefined) {
        let init_tokens = 0;
        if (product.data().metadata.tokens) {
          init_tokens = parseInt(product.data().metadata.tokens);
        }
        await updateDoc(userRef, {
          markingTokens: init_tokens,
        });
      } else {
        let added_tokens = 0;
        if (product.data().metadata.tokens) {
          added_tokens = parseInt(product.data().metadata.tokens);
        }
        let newTokens = userDoc.data().markingTokens + added_tokens;
        await updateDoc(userRef, {
          markingTokens: newTokens,
        });
      }
    }
  }
};

const getReviewQuestions = async (userId, subject, topic, num_questions) => {
  let questionsSnapshot = [];

  if (topic === 'ANY') {
    questionsSnapshot = await getDocs(
      query(collection(db, 'users', userId, 'review', subject, 'questions'))
    );
  } else {
    questionsSnapshot = await getDocs(
      query(
        collection(db, 'users', userId, 'review', subject, 'questions'),
        where('topic', '==', topic)
      )
    );
  }

  if (questionsSnapshot.size < num_questions) {
    return [];
  }
  let questionIds = [];
  const docs = questionsSnapshot.docs;
  docs.sort(() => 0.5 - Math.random());
  for (let i = 0; i < num_questions; i++) {
    questionIds.push(docs[i].id);
  }
  return questionIds;
};

const getNumReviewQuestions = async (userId, subject, topic) => {
  let questionsSnapshot = [];

  if (topic === 'ANY') {
    questionsSnapshot = await getDocs(
      query(collection(db, 'users', userId, 'review', subject, 'questions'))
    );
  } else {
    questionsSnapshot = await getDocs(
      query(
        collection(db, 'users', userId, 'review', subject, 'questions'),
        where('topic', '==', topic)
      )
    );
  }

  return questionsSnapshot.size;
};

const submitReviewAttempt = async (
  user_id,
  subject,
  answer_list,
  timed_list,
  question_list
) => {
  const now = new Date();
  await updateHeatMap(user_id, now);

  let topics = [];
  let correct = 0;
  let attemptAnswers = [];
  let i = 0;

  for (const qId in question_list) {
    const reviewQuestionRef = await getDoc(
      doc(
        db,
        'users',
        user_id,
        'review',
        subject,
        'questions',
        question_list[qId]
      )
    );
    topics.push(reviewQuestionRef.data().topic);
    let quiz_id = reviewQuestionRef.data().quiz_id;
    const questionRef = await getDoc(
      doc(db, 'subjects', subject, 'questions', question_list[qId])
    );
    const questionData = questionRef.data();

    if (answer_list[i] === questionData.answer) {
      correct++;
      attemptAnswers.push({
        qId: question_list[qId],
        quiz_id: quiz_id,
        user_answer: answer_list[i],
        isCorrect: true,
        time_spent: timed_list[i],
      });
      await deleteDoc(
        doc(
          db,
          'users',
          user_id,
          'review',
          subject,
          'questions',
          question_list[qId]
        )
      );
    } else if (answer_list[i] !== questionData.answer) {
      attemptAnswers.push({
        qId: question_list[qId],
        quiz_id: quiz_id,
        user_answer: answer_list[i],
        correct_answer: questionData.answer,
        isCorrect: false,
        time_spent: timed_list[i],
      });
    }
    i++;
  }

  const ref = collection(db, 'users', user_id, 'review', subject, 'attempts');

  let topic = '';
  if (topics.every((val, i, arr) => val === arr[0])) {
    topic = topics[0];
  } else {
    topic = 'ANY';
  }

  const attemptRef = await addDoc(ref, {
    quiz_id: `${subject.toUpperCase()} Review - ${
      attemptAnswers.length
    } questions`,
    score: correct,
    attempt_answers: attemptAnswers,
    date: now.toDateString(),
    topic: topic,
    question_ids: question_list,
  });

  return attemptRef.id;
};

const dayDiff = (firstDate, secondDate) => {
  firstDate.setHours(0, 0, 0);
  secondDate.setHours(0, 0, 0);
  return Math.round(Math.abs((firstDate - secondDate) / (24 * 60 * 60 * 1000)));
};

const getTestDate = async () => {
  const dateDoc = await getDoc(doc(db, 'selectiveDates', 'testDate'));

  const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
  const registerDate = new Date(userDoc.data().registerDate);

  const firstDate = new Date(dateDoc.data().date.seconds * 1000);
  const secondDate = new Date();

  return {
    testDate: firstDate,
    days: dayDiff(firstDate, secondDate),
    totalDays: dayDiff(firstDate, registerDate),
    startDate: registerDate,
  };
};

const getPrivacy = async () => {
  const url = await getDownloadURL(
    ref(getStorage(app), '/policies/Privacy Policy for Website.pdf')
  );
  return url;
};

const getTermsConditions = async () => {
  const url = await getDownloadURL(
    ref(
      getStorage(app),
      'policies/Website Terms and Conditions of Use [Services].pdf'
    )
  );
  return url;
};

export {
  /* ################## USER ################## */
  GetSubjects,
  fetchUserName,
  GetQuizList,
  GetTopicList,
  GetReviewTopicList,
  GetAllUserAnswerData,
  GetTopic,
  checkUserIdExists,
  getHeatMapValues,
  getNumQuizDoneSubject,
  uploadProfileImage,
  uploadImg,
  updateScript,
  fetchAuthenticationLevel,
  getProductDays,
  getProductName,
  getMarkingTokens,
  setMarkingTokens,
  attachMarkingTokenListeners,
  shortSubjectToLong,
  getTestDate,
  /* ################## SUBMITTING ATTEMPTS ################## */
  submitAttempt,
  submitWritingAttempt,
  GetWritingQuestion,
  addAttemptId,
  submitReviewAttempt,
  /* ################## QUESTION & ANSWERS ################## */
  GetQuestionList,
  GetQuestion,
  /* ################## RESULTS ################## */
  getResultWithId,
  getResults,
  getMockResult,
  getMockScores,
  GetUserRanks,
  /* ################## ADMIN ################## */
  canMakeMajorEdit,
  addMCQuiz,
  AdminGetQuizList,
  GetNumQuestions,
  addText,
  getTextRef,
  deleteQuestionsWithId,
  deleteTextsWithId,
  deleteQuizWithId,
  GetText,
  GetTextWithId,
  GetFreeMocks,
  addFullMock,
  addWritingQuiz,
  GetMockIds,
  addQuestionReport,
  addRedoRequest,
  getReports,
  deleteReport,
  /* ################## MARKING && NOTIFICATIONS ################## */
  submitForMarkingByAttemptId,
  getMarkingQueue,
  getNotMarkedPaper,
  pushWritingNotifications,
  fetchNotifications,
  updateSeenNotification,
  getPaperFromNotification,
  fetchPaperIfMarked,
  fetchPaperIfSubmittedForMarking,
  changeAttemptStatus,
  sendNotificationsAll,
  sendNotificationsArr,
  submitMarking,
  /* ################## GRADES PAGE ################## */
  getTopicGrades,
  getGradesOverview,
  S_grade,
  A_grade,
  B_grade,
  C_grade,
  D_grade,
  grade_colors,
  fetchRegisterDate,
  /* ################## Review ################## */
  getReviewQuestions,
  getNumReviewQuestions,
  GetQuizAndQuestionIndex,
  getReviewResultsList,
  /* ################## Policies ################## */
  getPrivacy,
  getTermsConditions,
};
