import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Stack, Typography, Collapse, Alert } from "@mui/material";

function ProfileErrorBanner({ text, errorState, showError }) {
  return (
    <Box sx={{ m: 1, width: "50ch" }}>
      {errorState === "fail" && (
        <Collapse in={showError}>
          <Alert severity="error">{text}</Alert>
        </Collapse>
      )}
      {errorState === "success" && (
        <Collapse in={showError}>
          <Alert severity="success">{text}</Alert>
        </Collapse>
      )}
    </Box>
  );
}

export default ProfileErrorBanner;
