import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";

function Learn13() {
  return (
    <div>
      <Box sx={{ px: 3, width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "#f1f4ff",
            padding: 2,
            marginBottom: 6,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#babeee",
              padding: 2,
              marginBottom: 3,
              borderRadius: 4,
              color: "#3E54AC",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              How do we draw conclusions from a given text?
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            When we draw conclusions, we are trying to assess what information
            or message is being implied or inferred from a given text. It is
            never clearly stated, and you must use hints and evidence to
            understand what the text is implying or inferring.
          </Typography>

          <Alert
            icon={<NewReleasesRoundedIcon fontSize="large" />}
            severity="info"
            variant="outlined"
            sx={{
              borderRadius: 4,
              marginBottom: 3,
              fontSize: "12pt",
              backgroundColor: "#d9e7fe",
            }}
          >
            <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
              Example
            </AlertTitle>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              Jenny spent the entire day cleaning her room. She dusted the
              shelves, vacuumed the carpet, and organized her closet. She even
              wiped down the windows and polished her mirror. By the time she
              was finished, her room was spotless.
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              What is the most likely reason for Jenny's intense cleaning spree?
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
              A) She is expecting guests and wants to impress them with her
              cleanliness.
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
              B) She is bored and has nothing else to do.
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
              C) She has been procrastinating cleaning her room and finally
              decided to tackle it.
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
              D) She is preparing to move out of her house and wants to leave a
              clean space.
            </Typography>
            <Typography
              sx={{ paddingBottom: 2, fontWeight: 700, fontSize: "18pt" }}
            >
              Explanation:
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
              The correct answer is C. Based on the information in the
              paragraph, Jenny spent the entire day cleaning her room,
              indicating that it was likely quite messy to begin with.
              Additionally, the specific actions she took, such as dusting the
              shelves and organizing her closet, suggest that she was trying to
              tackle a substantial amount of clutter. Therefore, it is most
              likely that Jenny had been putting off cleaning her room and
              finally decided to tackle it, rather than any of the other options
              presented.
            </Typography>
          </Alert>
        </Box>
      </Box>
    </div>
  );
}

export default Learn13;
