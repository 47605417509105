import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";

function Learn8() {
  return (
    <div>
      <Box sx={{ px: 3, width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "#f1f4ff",
            padding: 2,
            marginBottom: 6,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#babeee",
              padding: 2,
              marginBottom: 3,
              borderRadius: 4,
              color: "#3E54AC",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Addition (+)
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            Addition in maths is the process of adding two or more numbers
            together to find the sum.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            An example of addition would be 2 + 2 = 4.
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#f1f4ff",
            padding: 2,
            marginBottom: 6,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#babeee",
              padding: 2,
              marginBottom: 3,
              borderRadius: 4,
              color: "#3E54AC",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Subtraction (-)
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            Subtraction in maths is the process of removing or taking a number
            away from another.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            An example of subtraction would be 4 - 2 = 2.
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#f1f4ff",
            padding: 2,
            marginBottom: 6,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#babeee",
              padding: 2,
              marginBottom: 3,
              borderRadius: 4,
              color: "#3E54AC",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Multiplication: (× or *)
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            Multiplication in maths is the process of repeatedly adding a
            specific number for a certain amount of times to get the{" "}
            <b>product</b>.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            The product is the result of the multiplication.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            For example, 9 x 4 means that 9 is added to itself 4 times, as shown
            below:
            <br />9 + 9 + 9 + 9 = 36
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            Therefore, the <b>product</b> is 36.
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#f1f4ff",
            padding: 2,
            marginBottom: 6,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#babeee",
              padding: 2,
              marginBottom: 3,
              borderRadius: 4,
              color: "#3E54AC",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Division (÷ or /)
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            Division in maths is the opposite of multiplication. The result that
            is obtained from the division is called the <b>Quotient</b>.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            For example, 12 / 3 means we need to find how many 3’s can fit into
            12, as shown below:
            <br />3 + 3 + 3 + 3 = 12, thus we find that it takes 3 added by
            itself four times for it to equal to 12.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            Therefore, the quotient is 4.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            Sometimes, the division cannot be divided exactly, meaning that
            there would be leftover or commonly known as the <b>remainder</b>.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            For example, 13 ÷ 3, we know that 4 can fit into 12 three times.
            however there is still 1 that is remaining that 4 cannot divide, so
            1 is left as a remainder.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            Therefore, the answer would be 4 r 1, where r stands for remainder.
          </Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#f1f4ff",
            padding: 2,
            marginBottom: 6,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#babeee",
              padding: 2,
              marginBottom: 3,
              borderRadius: 4,
              color: "#3E54AC",
            }}
          >
            <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
              What is BODMAS?
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              BODMAS is an acronym that stands for Brackets, Order, Division,
              Multiplication, Addition and Subtraction.
              <br />
              It is the order of operations you must follow when finding the
              answer to an equation with multiple
              <b>Arithmetic Operations</b>
            </Typography>
          </Box>
          <Alert
            icon={<TipsAndUpdatesRoundedIcon fontSize="inherit" />}
            severity="warning"
            variant="filled"
            sx={{ borderRadius: 4, marginBottom: 3, fontSize: "12pt" }}
          >
            Tip: <br />
            Divide and Multiply are equal so evaluate from left to right
            <br />
            Add and Subtract are equal so evaluate from left to right
          </Alert>
          <Alert
            icon={<NewReleasesRoundedIcon fontSize="large" />}
            severity="info"
            variant="outlined"
            sx={{
              borderRadius: 4,
              marginBottom: 3,
              fontSize: "12pt",
              backgroundColor: "#d9e7fe",
            }}
          >
            <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
              Example 1
            </AlertTitle>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              2 + 5 ÷ 5 - 3 = ?
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              Division is the first operation found in this question as there
              are no brackets
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              After evaluating the division operation first, the equation then
              becomes
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              2 + 1 - 3
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              The next operation is addition as there is no multiplication.
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              After evaluating the addition, the equation becomes
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              3 - 3
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              And finally evaluating the subtraction, the equation becomes 0,
              our answer!
            </Typography>
          </Alert>

          <Alert
            icon={<NewReleasesRoundedIcon fontSize="large" />}
            severity="info"
            variant="outlined"
            sx={{
              borderRadius: 4,
              marginBottom: 3,
              fontSize: "12pt",
              backgroundColor: "#d9e7fe",
            }}
          >
            <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
              Example 2
            </AlertTitle>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              18 - 3 + (6 × 3) ÷ 2 = ?
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              Evaluating the brackets is the first operation. 16 × 3 = 18 so the
              equation becomes
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              18 - 3 + 18 ÷ 2
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              The next operation is division. 18 ÷ 2 = 9 so the equation becomes
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              18 - 3 + 9
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              Now that there is only addition and subtraction, we evaluate from
              left to right as they are equal, giving an <b> answer of 24.</b>
            </Typography>
          </Alert>

          <Alert
            icon={<NewReleasesRoundedIcon fontSize="large" />}
            severity="info"
            variant="outlined"
            sx={{
              borderRadius: 4,
              marginBottom: 3,
              fontSize: "12pt",
              backgroundColor: "#d9e7fe",
            }}
          >
            <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
              Example 3
            </AlertTitle>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              9 × 9 - 24 ÷ 3 + (85 ÷ 5) = ?
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              Evaluating the brackets is the first operation. 85 ÷ 5 = 17 so the
              equation becomes
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              9 × 9 - 24 ÷ 3 + 17 = ?
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              After evaluating the multiplication. The equation then becomes
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              81 - 24 ÷ 3 + 17 = ?
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              Next we evaluate the division operation. The equation then becomes
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              81 - 8 + 17 = ?
            </Typography>
            <Typography sx={{ paddingBottom: 2 }}>
              Now that there is only addition and subtraction, we evaluate from
              left to right as they are equal, giving an <b> answer of 56.</b>
            </Typography>
          </Alert>
        </Box>
      </Box>
    </div>
  );
}

export default Learn8;
