import React from 'react'
import { Toolbar, Typography, Box, Paper, Stack, Card, CardActionArea, CardContent, Button } from "@mui/material";

function Learn2() {
  return (
    <div>
      <Box sx={{ px: 3 }}>
        2
      </Box>
    </div>
  )
}

export default Learn2