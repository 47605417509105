import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

function Learn4() {
  return (
    <Box sx={{ px: 3, width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#babeee",
          padding: 2,
          marginBottom: 3,
          borderRadius: 4,
          color: "#3E54AC",
        }}
      >
        <Typography variant="h4" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          What is Area?
        </Typography>
        <Typography sx={{ paddingBottom: 1 }}>
          The area is the amount of space within an enclosed area or shape. It
          is measured in square units.
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundColor: "#f1f4ff",
          padding: 2,
          marginBottom: 6,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Area of Rectangles and Squares:
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          The area for <b>rectangles and squares</b> is calculated by
          multiplying the length by its width.
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 3,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 1
          </AlertTitle>
          What is the area of a rectangle with sides 3cm by 4cm?
          <br />
          We multiply the sides to get the area which is 12cm<sup>2</sup>.
        </Alert>
        <Typography variant="h5" sx={{ paddingBottom: 2, fontWeight: 600 }}>
          Area of Triangles:
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2 }}>
          The area for <b>triangles</b> is calculated by multiplying the height
          by the base and dividing it by 2, as shown below:
        </Typography>
        <Typography sx={{ paddingBottom: 2, mx: 2, fontSize: "16pt" }}>
          Area of Triangle = ½ × base × height
        </Typography>
        <Alert
          icon={<NewReleasesRoundedIcon fontSize="large" />}
          severity="info"
          variant="outlined"
          sx={{
            borderRadius: 4,
            marginBottom: 3,
            fontSize: "12pt",
            backgroundColor: "#d9e7fe",
          }}
        >
          <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
            Example 2
          </AlertTitle>
          What is the area of a triangle with a height of 3cm and a base of 2cm?
          <br />
          We multiply the height and the base and then divide it by 2.
          <br />
          (3 × 2) ÷ 2 = 3<br />
          Therefore, the area is 3cm<sup>2</sup>.
        </Alert>
      </Box>
    </Box>
  );
}

export default Learn4;
