import React, { useRef, useState, useEffect } from "react";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { getGradesOverview, getNumQuizDoneSubject, GetTopicList, grade_colors } from "../app_backend";
import CalendarHeatMap from "./CalendarHeatMap";
import FileOpenRoundedIcon from '@mui/icons-material/FileOpenRounded';

import { Chart as ChartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
import GradeComponent from "./GradeComponent";

function GradeOverview({ user_id, setSelectedPage, setSelectedQuiz, setSelectedAttemptId, setSelectedSubject }) {
  const [overviewData, setOverviewData] = useState({})
  const [trans, setTrans] = useState(false)
  const [subject, setSubject] = useState("reading")
  const [topic, setTopic] = useState("")
  const [topics, setTopics] = useState([])

  useEffect(() => {
    if (user_id) {
      fetchGradeData().then(() => { setTrans(true) })
    }
  }, [user_id]);

  useEffect(() => {
    fetchTopics()
  }, [subject]);

  async function fetchGradeData() {
    const data = await getGradesOverview(user_id)
    setOverviewData(data)
    if (data.most_recent !== undefined) {
      setSelectedQuiz(data.most_recent.quiz_id)
      setSelectedAttemptId(data.most_recent.attempt_id)
      setSelectedSubject(data.most_recent.subject)
    }
  }

  async function fetchTopics() {
    const data = await GetTopicList(subject)
    const topics = Array.from(data)
    topics.push("MOCK")
    setTopics(topics)
  }

  function handleRecentClick() {
    if (overviewData.most_recent === undefined) {
      return
    } else {
      setSelectedPage('quiz_results')
    }
  }

  const handleChangeSubject = (event) => {
    setSubject(event.target.value);
  };
  const handleChangeTopic = (event) => {
    setTopic(event.target.value);
  };



  return (
    <Box sx={{ my: 2, width: "100%" }}>
      <Stack direction={"row"} spacing={3} sx={{ width: '100%' }}>
        <Stack spacing={3} sx={{ width: '70%', borderRadius: 4, bgcolor: '#f1f4ff', p: 3 }}>
          {overviewData.most_recent !== undefined ?
            <Fade in={trans}>
              <Box sx={{ display: 'flex', width: '60%' }}>
                <CardActionArea onClick={() => { handleRecentClick() }} sx={{ borderRadius: 4, height: '100%', backgroundColor: "#e2e5f3" }}>
                  <CardContent sx={{ height: '100%' }}>
                    <Box sx={{ display: 'flex', height: '100%', borderRadius: 4, justifyContent: 'space-between' }}>
                      <Stack sx={{ height: '100%' }} spacing={2}>
                        <Typography variant="h5" color={"primary"} sx={{ fontWeight: 900 }}>Recently Completed</Typography>
                        <Stack direction={"row"} spacing={3} sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box sx={{
                            borderRadius: 99, backgroundColor: '#babddb', p: 2.5,
                            width: '5rem', height: '5rem', display: 'flex', justifyContent: 'center', alignItems: 'center'
                          }}>
                            <Typography color={"primary"} sx={{ fontWeight: 800, fontSize: 25 }}>{overviewData.most_recent.score}/{overviewData.most_recent.total}</Typography>
                          </Box>
                          <Stack sx={{ borderRadius: 3, backgroundColor: '#d8dbf4', px: 3, py: 1.1 }}>
                            <Typography sx={{ fontSize: 22, fontWeight: 800 }}>{overviewData.most_recent.quiz_id.toUpperCase()}</Typography>
                            <Typography color={"primary"} >{overviewData.most_recent.date}</Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <FileOpenRoundedIcon sx={{ height: '100%', width: '10%', color: "#babddb" }} />
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Box></Fade> : null}
          <Box sx={{
            width: "100%",
            backgroundColor: "#ffffff",
            borderRadius: 4, p: 3
          }}>
            <Stack>
              <Stack direction={"row"} sx={{ width: "40%" }} spacing={2}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="subject-select-label">Subject</InputLabel>
                  <Select
                    labelId="subject-select-label"
                    id="subject-select"
                    value={subject}
                    label="Subject"
                    onChange={handleChangeSubject}
                  >
                    <MenuItem value={'reading'}>Reading</MenuItem>
                    <MenuItem value={'maths'}>Mathematical Reasoning</MenuItem>
                    <MenuItem value={'thinking'}>Thinking Skills</MenuItem>
                    <MenuItem value={'writing'}>Writing</MenuItem>
                    <MenuItem value={'full_mocks'}>Full Mocks</MenuItem>

                  </Select>
                </FormControl>
                <FormControl size="small" fullWidth>
                  <InputLabel id="topic-select-label">Topic</InputLabel>
                  <Select
                    labelId="topic-select-label"
                    id="toppic-select"
                    value={topic}
                    label="Topic"
                    onChange={handleChangeTopic}
                  >
                    {topics.map((topic, i) => {
                      return <MenuItem value={topic} key={i}>{topic}</MenuItem>
                    })}
                  </Select>
                </FormControl>
              </Stack>
              <GradeComponent user_id={user_id} subject={subject} topic={topic} />

            </Stack>
          </Box>
        </Stack>
        <Stack spacing={3} sx={{ width: '30%' }}>
          <Box sx={{
            backgroundColor: "background.paper",
            borderRadius: 6,
            width: "100%",
          }}>
            <CalendarHeatMap user_id={user_id} />
          </Box>
          <Fade in={trans}>
            <Box sx={{
              backgroundColor: "background.paper",
              borderRadius: 6,
              width: "100%",
              p: 3
            }}>
              <Typography sx={{ fontWeight: 800, fontSize: '3vh' }}>Scores</Typography>
              {overviewData.score_frequency !== undefined ?
                <Bar
                  options={{
                    responsive: true,
                    indexAxis: 'y',
                    barPercentage: 0.5,
                    scales: {
                      x: {
                        grid: {
                          display: true,
                          drawBorder: false,
                          drawTicks: false,
                          color: '#e5eafb'
                        },
                        ticks: {
                          textStrokeColor: '#babddb',
                          textStrokeWidth: 1,
                          color: '#babddb',
                          precision: 0
                        },
                        border: {
                          width: 0
                        }
                      },
                      y: {
                        grid: {
                          display: false,
                          drawBorder: false,
                          drawTicks: false
                        },
                        ticks: {
                          textStrokeColor: grade_colors,
                          textStrokeWidth: 1,
                          color: grade_colors,
                          padding: 10
                        }
                      }
                    },
                    plugins: {
                      legend: {
                        display: false
                      }
                    }
                  }}
                  width={"100%"}
                  height={"70vh"}
                  data={{
                    labels: ['S', 'A', 'B', 'C', 'D'],
                    datasets: [{
                      axis: 'y',
                      label: 'Grade Frequency',
                      data: [
                        overviewData.score_frequency['S'],
                        overviewData.score_frequency['A'],
                        overviewData.score_frequency['B'],
                        overviewData.score_frequency['C'],
                        overviewData.score_frequency['D']
                      ],

                      backgroundColor: grade_colors,
                      borderWidth: 0,
                      borderRadius: 99,
                    }]
                  }}
                /> : null}
            </Box>
          </Fade>
        </Stack>
      </Stack>
    </Box>
  )
}

export default GradeOverview