import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import TipsAndUpdatesRoundedIcon from "@mui/icons-material/TipsAndUpdatesRounded";

function Learn15() {
  return (
    <div>
      <Box sx={{ px: 3, width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "#f1f4ff",
            padding: 2,
            marginBottom: 6,
            borderRadius: 4,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#babeee",
              padding: 2,
              marginBottom: 3,
              borderRadius: 4,
              color: "#3E54AC",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              What are Logic problems?
            </Typography>
            <Typography sx={{ paddingBottom: 2, mx: 2 }}>
              Logic problems are problems which require logical reasoning and
              deduction to arrive at a solution. These problems typically
              require the solver to think critically, analyze information, and
              make deductions based on the given set of rules or information.
            </Typography>
          </Box>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            1. Read the statements carefully and identify the key pieces of
            information: Start by reading the given statements carefully and
            identifying the key pieces of information. This may involve
            underlining or highlighting important words or phrases.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            2. Determine the relationship between the statements: Once you have
            identified the key pieces of information, try to determine the
            relationship between the statements. Look for any connections or
            patterns that may exist between the statements.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            3. Identify the specific inference that needs to be made: Based on
            the given statements and the relationships between them, determine
            the specific inference that needs to be made. This may involve
            making logical deductions or drawing conclusions based on the
            information provided.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            4.Eliminate irrelevant information: If there are any statements or
            pieces of information that are irrelevant to the specific inference
            being made, eliminate them.
          </Typography>
          <Typography sx={{ paddingBottom: 2, mx: 2 }}>
            5. Choose the correct answer: Once you have identified the specific
            inference and eliminated any irrelevant information, choose the
            answer that best supports the inference.
          </Typography>

          <Alert
            icon={<NewReleasesRoundedIcon fontSize="large" />}
            severity="info"
            variant="outlined"
            sx={{
              borderRadius: 4,
              marginBottom: 3,
              fontSize: "12pt",
              backgroundColor: "#d9e7fe",
            }}
          >
            <AlertTitle sx={{ fontWeight: 700, fontSize: "18pt" }}>
              Example
            </AlertTitle>
            <Typography sx={{ paddingBottom: 2, fontSize: "16pt" }}>
              Which statements are required to prove that JACK HAS A COUSIN
              NAMED LINDA.
              <ol>
                <li>Jack has a cousin named Jill.</li>
                <li>Jack has a grandfather named Jeffrey.</li>
                <li>Jill has a brother named James.</li>
                <li>James has a cousin named Linda.</li>
                <li>Jeffrey has a granddaughter named Linda.</li>
              </ol>
              <Typography
                sx={{ paddingBottom: 2, fontSize: "12pt" }}
              ></Typography>
              <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
                A) 1 and 3
              </Typography>
              <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
                B) 1, 3 and 4
              </Typography>
              <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
                C) 2 and 4
              </Typography>
              <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
                D) 3 and 4
              </Typography>
            </Typography>
            <Typography
              sx={{ paddingBottom: 2, fontWeight: 700, fontSize: "18pt" }}
            >
              Explanation:
            </Typography>
            <Typography sx={{ paddingBottom: 2, fontSize: "12pt" }}>
              The correct answer is D. Statement 3: Jill has a brother named
              James, which means James is Jack's cousin (since Jack and Jill
              share a common relative, such as an aunt or uncle). Statement 4:
              James has a cousin named Linda, which means Linda is also Jack's
              cousin (since Jack and James share a common relative, such as an
              aunt or uncle). Therefore, both statements 3 and 4 are necessary
              to prove that Jack has a cousin named Linda. Statements 1 and 2
              are not relevant to this inference.
            </Typography>
          </Alert>
        </Box>
      </Box>
    </div>
  );
}

export default Learn15;
