import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../auth";
import { uploadProfileImage, uploadPhotoGetURL } from "../app_backend";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

export default function ProfileIcon({ displayName }) {
  const [currentUser] = useAuthState(auth);
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [photoURL, setPhotoURL] = useState(
    "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
  );

  useEffect(() => {
    if (currentUser?.photoURL) {
      setPhotoURL(currentUser.photoURL);
    }
  }, [currentUser]);

  function handleChange(e) {
    if (e.target.files[0]) {
      setPhoto(e.target.files[0]);
    }
  }

  const handleClick = async () => {
    await uploadProfileImage(photo, currentUser, setLoading);
    setPhotoURL(currentUser.photoURL);
  };

  return (
    <Stack>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography gutterBottom variant="h4" sx={{ paddingBottom: 2 }}>
          {displayName}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50%",
        }}
      >
        <Avatar
          src={photoURL}
          alt="Avatar"
          className="avatar"
          sx={{
            height: "240px",
            width: "240px",
            mx: "12rem",
          }}
        />
      </Box>
      <Stack
        direction="row"
        spacing={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "50%",
          my: "1.4rem",
        }}
      >
        <Button variant="contained" component="label" sx={{ borderRadius: 6 }}>
          Upload File
          <input type="file" onChange={handleChange} hidden />
        </Button>
        <Button
          variant="contained"
          disabled={loading || !photo}
          onClick={handleClick}
          sx={{ borderRadius: 6 }}
        >
          Upload
        </Button>
      </Stack>
    </Stack>
  );
}
