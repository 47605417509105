import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../auth"

import { GetQuizList } from "../app_backend";
import Grow from '@mui/material/Grow';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import Checkbox from '@mui/material/Checkbox';

import { VALID_SUBSCRIPTION } from "../Stripe";

function QuizList({ subject, topic, ignoreMocks, setSelectedPage, setSelectedQuiz, subscription }) {
  const [user, loading, error] = useAuthState(auth)
  const [quizzes, setQuizzes] = useState([])
  const params = useParams()
  const navigate = useNavigate();
  const [renderTransition, setRenderTransition] = useState(false)
  const [showCompleted, setShowCompleted] = useState(true)


  const fetchQuizList = async () => {
    try {
      let quiz_list = await GetQuizList(user?.uid, subject, topic, ignoreMocks)
      setQuizzes(quiz_list.quizzes)
      if (quiz_list) {
        setRenderTransition(true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    fetchQuizList()
  }, [user, loading, subject, topic])

  return (
    <Box sx={{ width: '100%', m: 2, height: '95%' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={showCompleted}
          onChange={(event) => { setShowCompleted(event.target.checked) }}
          inputProps={{ 'aria-label': 'controlled' }}
          sx={{}}
        />
        <Box sx={{ display: 'inline', backgroundColor: '#e5eafb', borderRadius: 9, px: 2 }}>
          <Typography sx={{ display: 'inline', fontWeight: 700, color: '#1F2F98' }}>
            Show Completed
          </Typography>
        </Box>
      </Box>
      <Box sx={{ height: '95%' }}>
        <Stack spacing={2} sx={{
          width: '100%', display: 'flex', height: '95%',
          overflow: 'auto', px: 1
        }}>
          {quizzes.map((quiz, i) => {
            // if subscription is DEMO, disable all other quizzes except demo
            let disabled = subscription !== VALID_SUBSCRIPTION && quiz.topic !== "DEMO"
            if (quiz.completed) {
              if (showCompleted) {
                let path = 'results_list'
                if (subject === 'full_mocks') {
                  path = 'mock_results'
                }
                return (
                  <Grow key={i} in={renderTransition} style={{ transformOrigin: '0 0 0' }}
                    {...(renderTransition ? { timeout: 600 } : {})}>
                    <Button key={`${quiz.id}`}
                      variant='filled'
                      onClick={() => {
                        setSelectedPage(path);
                        setSelectedQuiz(quiz.id)
                      }}
                      sx={{ width: '100%', borderRadius: 5, backgroundColor: '#dcdef0', textTransform: 'none' }}
                      disabled={disabled}
                    >
                      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ color: '#757893', fontSize: '14pt' }}>Completed</Typography>
                        <Typography sx={{ color: '#676f80' }} variant='h6'>{quiz.id}</Typography>
                        <Box sx={{ backgroundColor: '#ffb74d', borderRadius: 10, width: '6rem', textAlign: 'center' }}>
                          <Typography sx={{ color: '#ffffff', fontSize: '14pt', textAlign: 'center' }}>Review</Typography>
                        </Box>
                      </Box>
                    </Button>
                  </Grow>
                )
              }
            } else {
              return (
                <Grow key={i} in={renderTransition} style={{ transformOrigin: '0 0 0' }}
                  {...(renderTransition ? { timeout: 600 } : {})}>
                  <Button key={`${quiz.id}`}
                    variant='outlined'
                    onClick={() => {
                      setSelectedPage('quiz');
                      setSelectedQuiz(quiz.id)
                    }}
                    sx={{ width: '100%', borderRadius: 5, backgroundColor: '#ffffff', textTransform: 'none', borderColor: '#b3c3dc', transition: 'all 0.3s ease-out' }}
                    disabled={disabled}
                  >
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography sx={{ color: disabled ? '#e2e3e3' : '#3b3f63', fontSize: '14pt' }}>Incomplete</Typography>
                      <Typography variant='h6'>{quiz.id}</Typography>
                      <Box sx={{
                        backgroundColor: disabled ? '#99c4a5' : '#2db85b',
                        borderRadius: 10, width: '6rem', textAlign: 'center', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly'
                      }}>
                        <PlayArrowRoundedIcon sx={{ color: '#ffffff' }} />
                        <Typography sx={{ color: '#ffffff', fontSize: '14pt', mr: 1 }}>Start</Typography>
                      </Box>
                    </Box>
                  </Button>
                </Grow>
              )
            }
          })}
        </Stack>
      </Box>
    </Box>
  )
}

export default QuizList