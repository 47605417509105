// util function to check if child node is contained in parent node with given id
// returns true if child node is contained in parent node, else false
const hasParentWithId = (childNode, parentId) => {
  // Start from the child node and traverse up the DOM hierarchy
  let currentNode = childNode;

  // Continue traversing until reaching the top of the DOM or finding a parent with the specified ID
  while (currentNode !== null && currentNode.parentNode !== null) {
    currentNode = currentNode.parentNode;

    // Check if the current parent node has the specified ID
    if (currentNode.id === parentId) {
      return true; // Found a parent with the specified ID
    }
  }

  // If no parent with the specified ID is found in the traversal
  return false;
};

// Selection utils (requires IE9+)
// returns selection on screen
const saveSelection = () => {
  if (window.getSelection) {
    const sel = window.getSelection();
    if (sel.isCollapsed) {
      // nothing is selected
      return null;
    }
    // check if selection is in parent node with id='selectableContent'
    if (
      hasParentWithId(sel.anchorNode.parentElement, 'selectableContent') &&
      hasParentWithId(sel.focusNode.parentElement, 'selectableContent')
    ) {
      return sel.getRangeAt(0);
    }
  }

  return null;
};

// walks through selection range and splits accordingly to elements
function walkRange(range) {
  let ranges = [];

  let el = range.startContainer;
  let elsToVisit = true;
  while (elsToVisit) {
    // startOffset is range.startOffset if first element in range, otherwise it starts at 0
    let startOffset = el === range.startContainer ? range.startOffset : 0;
    // endOffset is range.endOffset if last element in range, otherwise it ends at textContent.length
    let endOffset =
      el === range.endContainer ? range.endOffset : el.textContent.length;
    let tempRange = document.createRange();
    tempRange.setStart(el, startOffset);
    tempRange.setEnd(el, endOffset);
    ranges.push(tempRange);

    /// Move to the next text container in the tree order if not endContainer
    elsToVisit = false;
    while (!elsToVisit && el !== range.endContainer) {
      let nextEl = getFirstTextNode(el.nextSibling);
      if (nextEl) {
        // nextEl is not null: visit nextEl
        el = nextEl;
        elsToVisit = true;
      } else {
        if (el.nextSibling) {
          // el.nextSibling is not a text node
          el = el.nextSibling;
        } else if (el.parentNode) {
          // el.nextSibling does not exist, climb back up tree
          el = el.parentNode;
        } else {
          // finish looping through all nodes
          break;
        }
      }
    }
  }

  return ranges;
}

// gets the first text node element
function getFirstTextNode(el) {
  /// Degenerate cases: either el is null, or el is already a text node
  if (!el) return null;
  if (el.nodeType === Node.TEXT_NODE) return el;

  for (let child of el.childNodes) {
    if (child.nodeType === Node.TEXT_NODE) {
      return child;
    } else {
      // child is not a text node, search for one within child
      let textNode = getFirstTextNode(child);
      if (textNode !== null) {
        return textNode;
      }
    }
  }

  return null;
}

// highlights text given range
const highlight = (range, className, setTabValue) => {
  for (let rangeEle of walkRange(range)) {
    let span = document.createElement('span');
    span.setAttribute('class', className);
    span.style.backgroundColor = 'pink';
    // add on click function to match comment list
    span.onclick = function () {
      // change tab to comment list
      setTabValue(1);
      // focus comment
      focusComment(className);

      /* known QOL issue: when clicking on highlight, 
      if corresponding comment is out of view, 
      it does not scroll into view */
    };
    // add hover effect
    span.style.setProperty('cursor', 'pointer');
    rangeEle.surroundContents(span);
  }

  // reset selection
  var sel = window.getSelection();
  sel.removeAllRanges();
};

// removes highlight and restores nodes
const unhighlight = (selector) => {
  document.querySelectorAll(selector).forEach((element) => {
    element.replaceWith(...element.childNodes);
  });

  // reset selection
  var sel = window.getSelection();
  sel.removeAllRanges();
};

// check if element is visible in viewport
const isInViewport = (element, viewPortEle) => {
  const viewRect = viewPortEle.getBoundingClientRect();
  const itemRect = element.getBoundingClientRect();

  const isAbove = itemRect.bottom < viewRect.top;
  const isBelow = itemRect.top > viewRect.bottom;

  const isSlightlyAbove = itemRect.bottom - 10 < viewRect.top;
  const isSlightlyBelow = itemRect.top + 10 > viewRect.bottom;

  return !(isAbove || isBelow || isSlightlyAbove || isSlightlyBelow);
};

// scroll to element within div if not in view
const scrollIfNotInView = (eleSelector, parentSelector) => {
  const ele = document.querySelector(eleSelector);
  const viewPortEle = document.querySelector(parentSelector);
  if (!isInViewport(ele, viewPortEle)) {
    ele.scrollIntoView({ behavior: 'smooth' });
  }
};

// unfocuses all comments
const unfocusAllComments = () => {
  document.querySelectorAll('.focused').forEach((ele) => {
    ele.style.backgroundColor = 'pink';
    ele.classList.remove('focused');
  });
  // unfocus all comment boxes
  document.querySelectorAll('.focused-box').forEach((ele) => {
    ele.style.setProperty('background-color', 'lightyellow');
    ele.classList.remove('.focused-box');
  });
};

// focuses on the Comment
const focusComment = (className) => {
  // unfocus previous comments
  unfocusAllComments();
  // focus this comment
  document.querySelectorAll(`.${className}`).forEach((ele) => {
    ele.classList.add('focused');
    ele.style.setProperty('background-color', 'lightgreen', 'important');

    // focus children
    ele.querySelectorAll('*').forEach((child) => {
      child.classList.add('focused');
      child.style.setProperty('background-color', 'lightgreen', 'important');
    });
  });

  // focus relevant comment box
  const boxEle = document.querySelector(`.box${className}`);
  if (boxEle) {
    // scroll if not in view
    scrollIfNotInView(`.box${className}`, '#comment-list');
    boxEle.classList.add('focused-box');
    boxEle.style.setProperty('background-color', 'lightgreen');
  }
};

export {
  hasParentWithId,
  saveSelection,
  highlight,
  unhighlight,
  isInViewport,
  focusComment,
  unfocusAllComments,
  scrollIfNotInView,
};
