import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';

export default function HorizontalSubjectTabs({ selectedSubject, setSelectedSubject, overview = false }) {
  let subjectList = ["reading", "maths", "thinking", "writing"]
  let labelList = ["Reading", "Mathematical Reasoning", "Thinking Skills", "Writing"]
  if (overview) {
    subjectList = ["overview", "reading", "maths", "thinking", "writing"]
    labelList = ["Overview", "Reading", "Mathematical Reasoning", "Thinking Skills", "Writing"]
  }

  if (overview) {
    return (
      <Box
        sx={{
          display: "flex",
          width: '100%',
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 2,
        }}
      >
        <Box sx={{
          borderRadius: 4, borderBottomLeftRadius: 0, borderBottomRightRadius: 0,
          backgroundColor: "#ffffff", width: '100%', borderBottom: 3, borderBottomColor: '#1F2F98'
        }}>
          <Stack direction="row" sx={{ px: 3 }}>
            {subjectList.map((subject, i) => {
              return (
                <Button
                  variant='contained'
                  color={selectedSubject === subject ? 'primary' : 'inherit'}
                  disableElevation
                  selected={selectedSubject === subject}
                  onClick={() => {
                    setSelectedSubject(subject);
                  }}
                  sx={{
                    px: 2, bgcolor: selectedSubject === subject ? '#1F2F98' : '#ffffff', color: selectedSubject === subject ? '#ffffff' : 'text.primary',
                    textAlign: 'center', textTransform: 'none', borderRadius: 0, width: '100%'
                  }}
                  key={i}
                >
                  <Typography variant="h6" sx={{ width: '100%', fontSize: 'clamp(10pt, 1vw, 12pt)' }}>{labelList[i]}</Typography>
                </Button>
              )
            })}
          </Stack>
        </Box>
      </Box >
    );
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 2,
      }}
    >
      <Box sx={{ borderRadius: 7, backgroundColor: "#ffffff" }}>
        <Stack direction="row" sx={{ px: 3 }}>
          {subjectList.map((subject, i) => {
            return (
              <ListItemButton
                selected={selectedSubject === subject}
                onClick={() => {
                  setSelectedSubject(subject);
                }}
                sx={{ px: 2 }}
                key={i}
              >
                <Typography variant="h6">{labelList[i]}</Typography>
              </ListItemButton>
            )
          })}
        </Stack>
      </Box>
    </Box>
  );
}

