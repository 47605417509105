import React, { useEffect, useState } from "react";
import LinearProgressWithLabel from "../components/Progressbar";
import CircularProgressWithLabel from "../components/CircularProgressWithLabel";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { getNumQuizDoneSubject } from "../app_backend";
import Grid from '@mui/material/Grid';

function Progression({ text_width, user_id }) {

  const [readingProg, setReadingProg] = useState(0);
  const [mathsProg, setMathsProg] = useState(0);
  const [thinkingProg, setThinkingProg] = useState(0);
  const [writingProg, setWritingProg] = useState(0);
  const fetchProg = async () => {
    const r = await getNumQuizDoneSubject(user_id, "reading");
    setReadingProg(r);
    const m = await getNumQuizDoneSubject(user_id, "maths");
    setMathsProg(m);
    const t = await getNumQuizDoneSubject(user_id, "thinking");
    setThinkingProg(t);
    const w = await getNumQuizDoneSubject(user_id, "writing");
    setWritingProg(w);
  };
  useEffect(() => {
    fetchProg();
  }, []);

  return (
    <Stack
      spacing={1}
      sx={{
        width: "100%",
        px: '1vw',
        paddingBottom: '2vh',
        paddingTop: '1vh',
      }}
    >
      <Typography sx={{ fontWeight: 800, fontSize: '1.2vw', marginBottom: 1 }}>Progression</Typography>

      <Grid container direction="column" sx={{ width: '100%', height: '100%' }}>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ height: '100%', marginBottom: 3 }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
              sx={{
                height: '100%', alignItems: 'center',
                justifyContent: 'center', display: 'flex', flexDirection: 'column', gap: 2
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#e5eafb",
                  borderRadius: 50,
                  px: 2,
                  width: text_width,
                  height: '2.5rem',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: 'center'
                }}
              >
                <Typography variant="body2" fontSize='11pt' lineHeight={1}>Reading</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgressWithLabel value={readingProg} />
              </Box>
            </  Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
              sx={{
                height: '100%', alignItems: 'center',
                justifyContent: 'center', display: 'flex', flexDirection: 'column', gap: 2
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#e5eafb",
                  borderRadius: 50,
                  px: 2,
                  width: text_width,
                  height: '2.5rem',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: 'center'
                }}
              >
                <Typography variant="body2" fontSize='11pt' lineHeight={1}>Mathematical Reasoning</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgressWithLabel value={mathsProg} />
              </Box>
            </  Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} sx={{ height: '100%', marginBottom: 3 }}>
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
              sx={{
                height: '100%', alignItems: 'center',
                justifyContent: 'center', display: 'flex', flexDirection: 'column', gap: 2
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#e5eafb",
                  borderRadius: 50,
                  px: 2,
                  width: text_width,
                  height: '2.5rem',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: 'center'
                }}
              >
                <Typography variant="body2" fontSize='11pt' lineHeight={1}>Thinking Skills</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgressWithLabel value={thinkingProg} />
              </Box>
            </  Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}
              sx={{
                height: '100%', alignItems: 'center',
                justifyContent: 'center', display: 'flex', flexDirection: 'column', gap: 2
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#e5eafb",
                  borderRadius: 50,
                  px: 2,
                  width: text_width,
                  height: '2.5rem',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: 'center'
                }}
              >
                <Typography variant="body2" fontSize='11pt' lineHeight={1}>Writing</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <CircularProgressWithLabel value={writingProg} />
              </Box>
            </  Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default Progression