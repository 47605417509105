import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import NoMobile from '../../pages/NoMobile';

const NoMobileDialog = ({ open, setOpen }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Work in Progress!
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description">
            Sorry! For the best learning experience, please use a laptop or desktop device.
          </DialogContentText> */}
        <NoMobile />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="contained" color="primary" sx={{ borderRadius: 6 }} disableElevation>Ok</Button>
      </DialogActions>
    </Dialog>
  )
}

export default NoMobileDialog