import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { auth, LogInWithEmailAndPassword } from '../auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import {
  Stack,
  Box,
  Input,
  InputAdornment,
  Button,
  Typography,
  CloseIcon,
  IconButton,
  Collapse,
  Alert,
  Link,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Modal,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import Policies from '../components/Policies';
import LoadingComponent from '../components/LoadingScreen';
import { ReactComponent as LogoSVG } from '../images/logo-full.svg';
import { ReactComponent as ImageSVG } from '../images/login-icon.svg';
import cookieImage from '../images/cookie.png';

import '../style/Auth.css';

// Customised MUI
const InputBox = styled(Input)(({ theme }) => ({
  width: '100%',
  padding: '7px',
  paddingLeft: '20px',
  paddingRight: '20px',
  height: '3em',
  marginTop: '15px',
  borderRadius: '30px',
  border: 'none',
  fontSize: '1em',
  fontFamily: 'Montserrat, sans-serif',
  backgroundColor: 'rgb(229, 229, 229)',
  '& fieldset': { border: 'none' },
}));

const StyledLearnButton = styled(Button)(({ theme }) => ({
  width: '10em',
  height: '3em',
  marginTop: '35px',
  marginBottom: '20px',
  borderRadius: '30px',
  fontSize: '1.1em',
  fontFamily: 'Montserrat, sans-serif',
  fontWeight: 700,
  textTransform: 'none',
  color: '#27313a',
  backgroundColor: 'white',
  transition: '0.3s',
  '&:hover': {
    backgroundColor: 'white',
    marginTop: '25px',
    boxShadow: '0 6px 4px -4px gray',
  },
}));

const StyledLoginButton = styled(Button)(({ theme }) => ({
  marginBottom: '40px',
  borderRadius: '30px',
  height: '2.5em',
  fontSize: '1em',
  fontFamily: 'Montserrat, sans-serif',
  textTransform: 'none',
  backgroundColor: '#4285f4',
}));

const StyledCookieButton = styled(Button)(({ theme }) => ({
  marginBottom: '10px',
  borderRadius: '5px',
  height: '2.5em',
  width: '50%',
  fontSize: '1em',
  fontFamily: 'Montserrat, sans-serif',
  textTransform: 'none',
  fontWeight: 700,
}));

const consentStyle = {
  position: 'absolute',
  width: '100%',
  bottom: 0,
  bgcolor: '#e6e9ff',
  boxShadow: 24,
  p: 4,
  minHeight: '20vh',
};

//https://blog.logrocket.com/user-authentication-firebase-react-apps/

/*
  TODOS:
  - Cookie Consent
*/

function Login() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();

  const fromRegister = location.state?.fromRegister || false;

  const [cookie, setCookie] = useCookies(['rememberLogin']);
  const [optIn, setOptIn] = useState(false);
  const [openConsent, setOpenConsent] = useState(false);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (cookie.rememberLogin) {
      if (user) {
        auth.currentUser.reload();
        if (auth.currentUser.emailVerified) {
          navigate('/Dashboard');
        }
      }
    }
    if (sessionStorage.getItem('cookiePreference') === 'set') {
      return;
    } else {
      setOpenConsent(true);
    }
  }, [loading]);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [disableLogin, setDisableLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [rememberCheck, setRememberCheck] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navLanding = () => {
    window.location.assign('https://passpapers.com.au/');
  };

  const handleCheckChange = (event) => {
    setRememberCheck(event.target.checked);
  };

  const handleCloseConsent = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpenConsent(false);
  };

  const handleAcceptCookies = () => {
    setOptIn(true);
    setOpenConsent(false);
    sessionStorage.setItem('cookiePreference', 'set');
  };

  const handleRejectCookies = () => {
    setOptIn(false);
    setOpenConsent(false);
    sessionStorage.setItem('cookiePreference', 'set');
  };

  const handleLogin = async () => {
    if (email.trim() === '' || password === '') {
      setErrorMsg('Please Enter All Login Details');
      setShowError(true);
      return;
    } else {
      try {
        setDisableLogin(true);
        await LogInWithEmailAndPassword(email, password);
        setDisableLogin(false);
      } catch (err) {
        if (
          err.code === 'auth/wrong-password' ||
          err.code === 'auth/invalid-email'
        ) {
          setErrorMsg('Invalid credentials.');
        } else if (err.code === 'auth/user-not-found') {
          setErrorMsg('Account not found. Register now!');
        } else {
          setErrorMsg(err.code);
        }
        setShowError(true);
        setDisableLogin(false);
        return;
      }

      // Successfully logged in.
      if (rememberCheck) {
        setCookie('rememberLogin', true, {
          path: '/',
          sameSite: 'None',
          secure: true,
        });
      } else {
        setCookie('rememberLogin', false, {
          path: '/',
          sameSite: 'None',
          secure: true,
        });
      }

      await auth.currentUser.reload();
      if (auth.currentUser.emailVerified) {
        navigate('/Dashboard');
      } else {
        setErrorMsg('Make sure to verify your email first!');
        setShowError(true);
        setDisableLogin(false);
      }
    }
  };

  return (
    <div class='auth'>
      <Modal open={openConsent} onClose={handleCloseConsent}>
        <Box sx={consentStyle}>
          <Stack direction='row' fullWidth>
            <img
              class='cookie_img'
              src={cookieImage}
              alt='Have a cookie.'
              height='200'
            />
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                sx={{
                  fontSize: '1.8em',
                  fontFamily: 'Montserrat, sans-serif',
                  fontWeight: 700,
                }}
              >
                Have a cookie.
              </Typography>
              <Typography
                sx={{ fontSize: '1.3em', fontFamily: 'Montserrat, sans-serif' }}
              >
                PassPapers uses cookies for the best personal experience on our
                learning platform. To disable further notifications,{' '}
                <b>accept cookies</b> and check <b>Remember Me</b> when logging
                in.
              </Typography>
              <Stack
                direction='row'
                fullWidth
                justifyContent='center'
                spacing={3}
                sx={{ mt: '20px' }}
              >
                <StyledCookieButton
                  onClick={() => {
                    handleAcceptCookies();
                  }}
                  sx={{
                    backgroundColor: '#6a7cfc',
                    color: '#e6e9ff',
                    '&.MuiButtonBase-root:hover': {
                      backgroundColor: '#6a7cfc',
                    },
                  }}
                >
                  Accept
                </StyledCookieButton>
                <StyledCookieButton
                  onClick={() => {
                    handleRejectCookies();
                  }}
                  sx={{
                    color: '#6a7cfc',
                    borderColor: '#6a7cfc',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                  }}
                >
                  Decline
                </StyledCookieButton>
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Modal>
      <Stack direction='row' sx={{ height: '100%' }}>
        <Box
          className='auth__left_container'
          sx={{ width: '58%', minHeight: '100vh' }}
        >
          <Stack textAlign='center' alignItems='center' sx={{ mt: 8 }}>
            <Typography
              className='auth__left_container_textBox'
              sx={{
                fontSize: '2.1em',
                fontWeight: 600,
                lineHeight: 1.4,
                fontFamily: 'Montserrat, sans-serif',
              }}
              color='#27313a'
            >
              The best website for preparing online examinations.
            </Typography>
            <Box sx={{ width: '100%', mt: 5 }}>
              <ImageSVG className='auth__left_container_img' />
            </Box>
            <StyledLearnButton onClick={() => navLanding()}>
              Learn More
            </StyledLearnButton>
          </Stack>
        </Box>
        <Box
          className='auth__right_container'
          sx={{ flexGrow: 1, minHeight: '100vh', minWidth: '360px' }}
        >
          <Stack alignItems='center' sx={{ mt: 7 }}>
            <LogoSVG className='auth__logo' />
            <Box
              className='auth__right_container_title'
              sx={{ textAlign: 'center', fontWeight: 700, mt: 5 }}
            >
              Login to your Account
            </Box>
            <Box className='auth__textBox'>
              {fromRegister && (
                <Alert
                  severity='info'
                  sx={{
                    padding: '10px',
                    fontSize: '0.9em',
                    fontFamily: 'Montserrat, sans-serif',
                    pt: '3px',
                  }}
                >
                  A verification email has been sent. Make sure to check spam
                  folders!
                </Alert>
              )}
            </Box>
            <Box className='auth__textBox'>
              {showError && (
                <Typography
                  fullWidth
                  textAlign='center'
                  sx={{
                    height: '2em',
                    fontSize: '0.9em',
                    color: 'red',
                    fontFamily: 'Montserrat, sans-serif',
                    backgroundColor: '#ffb9b9',
                    borderRadius: '20px',
                    pt: '3px',
                  }}
                >
                  {errorMsg}
                </Typography>
              )}
            </Box>
            <Box className='auth__textBox'>
              Email Address
              <InputBox
                type='text'
                className='auth_inputBox'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disableUnderline
              />
            </Box>
            <Box className='auth__textBox'>
              Password
              <InputBox
                type={showPassword ? 'text' : 'password'}
                className='auth_inputBox'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disableUnderline
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Stack
                className='auth__accessory_text'
                direction='row'
                justifyContent='space-between'
                sx={{ mt: 1 }}
              >
                <Box>
                  {optIn && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rememberCheck}
                          onChange={handleCheckChange}
                        />
                      }
                      label={
                        <Typography
                          sx={{ fontFamily: 'Montserrat, sans-serif' }}
                        >
                          Remember Me
                        </Typography>
                      }
                    />
                  )}
                </Box>
                <Link component='button' onClick={() => navigate('/Reset')}>
                  Forgot Password?
                </Link>
              </Stack>
            </Box>
            <Box className='auth__textBox'>
              <StyledLoginButton
                fullWidth
                variant='contained'
                onClick={() => handleLogin()}
                disabled={disableLogin}
              >
                {disableLogin ? (
                  <CircularProgress size='1.4em' />
                ) : (
                  <Box>Login</Box>
                )}
              </StyledLoginButton>
            </Box>
            <Box
              className='auth__textBox auth__policy_text'
              sx={{ textAlign: 'center' }}
            >
              Haven't registered on PassPapers?{' '}
              <Link component='button' onClick={() => navigate('/Register')}>
                Create a free account!
              </Link>
            </Box>
            <Policies />
          </Stack>
        </Box>
      </Stack>
    </div>
  );
}

export default Login;
