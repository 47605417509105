import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ListItemIcon from "@mui/material/ListItemIcon";
import Chip from "@mui/material/Chip";

import Box from "@mui/material/Box";

import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import "../style/LearnContent.css";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import CalculateRoundedIcon from "@mui/icons-material/CalculateRounded";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";

export default function LearnContents({
  setSelectedIndex,
  setHeader,
  selectedIndex,
}) {
  // selective
  const [open1, setOpen1] = useState(true);
  // reading
  const [open2, setOpen2] = useState(false);
  // maths
  const [open3, setOpen3] = useState(false);
  // thinking
  const [open4, setOpen4] = useState(false);
  // writing
  const [open5, setOpen5] = useState(false);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  // INDEX PAGE:
  // 0-1: Selective
  // 3: Reading ...... wtf?
  // 4-12: Maths

  // NOTE: page 2 is never used for some reason...

  const getHeight = () => {
    if (!open1 && !open2 && !open3 && !open4) {
      return "17.25rem";
    }
    // If "Selective Test" or "Reading" page is selected, set a height
    // If "Mathematical Reasoning" page is selected, set a height
    // If "Thinking SKills" page is selected, set a height
    // Does not account for "Writing" page yet
    if (selectedIndex === 0 || selectedIndex === 1) {
      return "23.25rem";
    } else if (selectedIndex === 3) {
      return "29.25rem";
    } else if (selectedIndex === 4) {
      return "44.25rem";
    }
  };

  return (
    <Box
      className="content"
      sx={{
        width: "25%",
        bgcolor: "background.paper",
        borderRadius: 4,
        height: getHeight,
        display: "flex",
        overflow: "auto",
      }}
    >
      <List component="nav" sx={{ width: "100%" }}>
        <ListItemButton
          selected={selectedIndex === 0}
          onClick={() => {
            handleListItemClick(0);
            setHeader("What is the Selective Test?");
            setOpen1(!open1);
            setOpen2(false);
            setOpen3(false);
            setOpen4(false);
          }}
        >
          <ListItemIcon>
            <SchoolRoundedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 700, fontSize: "14pt" }}>
              Selective Test
            </Typography>
          </ListItemText>
          {open1 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={selectedIndex === 0}
              onClick={() => {
                handleListItemClick(0);
                setHeader("What is the Selective Test?");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="What is the Selective Test?" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={() => {
                handleListItemClick(1);
                setHeader("Preparing for the Selective Test");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Preparing for the Selective Test" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          selected={selectedIndex === 3}
          onClick={() => {
            handleListItemClick(3);
            setHeader("Reading");
            setOpen2(!open2);
            setOpen1(false);
            setOpen3(false);
            setOpen4(false);
          }}
        >
          <ListItemIcon>
            <AutoStoriesRoundedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 700, fontSize: "14pt" }}>
              Reading
            </Typography>
          </ListItemText>
          {open2 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open2} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                const element = document.getElementById("cloze");
                element.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <ListItemText primary="Cloze Passage" />
              <Chip label="Intermediate" variant="outlined" color="warning" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                const element = document.getElementById("extracts");
                element.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <ListItemText primary="Extracts" />
              <Chip label="Foundation" variant="outlined" color="success" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                const element = document.getElementById("narratives");
                element.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <ListItemText primary="Narrative" />
              <Chip label="Foundation" variant="outlined" color="success" />
            </ListItemButton>
            <ListItemButton
              sx={{ pl: 4 }}
              onClick={() => {
                const element = document.getElementById("poetry");
                element.scrollIntoView({ behavior: "smooth" });
              }}
            >
              <ListItemText primary="Poetry" />
              <Chip label="Advanced" variant="outlined" color="error" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          selected={selectedIndex === 4}
          onClick={() => {
            handleListItemClick(4);
            setHeader("Area");
            setOpen3(!open3);
            setOpen1(false);
            setOpen2(false);
            setOpen4(false);
          }}
        >
          <ListItemIcon>
            <CalculateRoundedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 700, fontSize: "14pt" }}>
              Mathematical Reasoning
            </Typography>
          </ListItemText>
          {open3 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open3} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={selectedIndex === 4}
              onClick={() => {
                handleListItemClick(4);
                setHeader("Area");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Area" />
              <Chip label="Intermediate" variant="outlined" color="warning" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 5}
              onClick={() => {
                handleListItemClick(5);
                setHeader("Perimeter");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Perimeter" />
              <Chip label="Intermediate" variant="outlined" color="warning" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 6}
              onClick={() => {
                handleListItemClick(6);
                setHeader("Time");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Time" />
              <Chip label="Intermediate" variant="outlined" color="warning" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 7}
              onClick={() => {
                handleListItemClick(7);
                setHeader("Algebra");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Algebra" />
              <Chip label="Advanced" variant="outlined" color="error" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 8}
              onClick={() => {
                handleListItemClick(8);
                setHeader("Arithmetic Operations");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Arithmetic Operations" />
              <Chip label="Foundation" variant="outlined" color="success" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 9}
              onClick={() => {
                handleListItemClick(9);
                setHeader("Decimals");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Decimals" />
              <Chip label="Foundation" variant="outlined" color="success" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 10}
              onClick={() => {
                handleListItemClick(10);
                setHeader("Fractions");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Fractions" />
              <Chip label="Foundation" variant="outlined" color="success" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 11}
              onClick={() => {
                handleListItemClick(11);
                setHeader("Percentages");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Percentages" />
              <Chip label="Foundation" variant="outlined" color="success" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 12}
              onClick={() => {
                handleListItemClick(12);
                setHeader("Probability");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Probability" />
              <Chip label="Advanced" variant="outlined" color="error" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          selected={selectedIndex === 13}
          onClick={() => {
            setOpen1(false);
            setOpen2(false);
            setOpen3(false);
            setOpen4(!open4);
            handleListItemClick(13);
            setHeader("Drawing Conclusions");
          }}
        >
          <ListItemIcon>
            <ExtensionRoundedIcon color="primary" />
          </ListItemIcon>

          <ListItemText>
            <Typography sx={{ fontWeight: 700, fontSize: "14pt" }}>
              Thinking Skills
            </Typography>
          </ListItemText>
          {open4 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open4} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              selected={selectedIndex === 13}
              onClick={() => {
                handleListItemClick(13);
                setHeader("Drawing Conclusions");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Drawing Conclusions" />
              <Chip label="Advanced" variant="outlined" color="error" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 14}
              onClick={() => {
                handleListItemClick(14);
                setHeader("Mathematical Analysis");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Mathematical Analysis" />
              <Chip label="Intermediate" variant="outlined" color="warning" />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 15}
              onClick={() => {
                handleListItemClick(15);
                setHeader("Logic");
              }}
              sx={{ pl: 4 }}
            >
              <ListItemText primary="Logic" />
              <Chip label="Intermediate" variant="outlined" color="warning" />
            </ListItemButton>
          </List>
        </Collapse>
        <ListItemButton
          selected={selectedIndex === 16}
          onClick={() => {
            setOpen1(false);
            setOpen2(false);
            setOpen3(false);
            handleListItemClick(16);
            setHeader("Writing");
          }}
        >
          <ListItemIcon>
            <DriveFileRenameOutlineRoundedIcon color="primary" />
          </ListItemIcon>
          <ListItemText>
            <Typography sx={{ fontWeight: 700, fontSize: "14pt" }}>
              Writing
            </Typography>
          </ListItemText>
        </ListItemButton>
      </List>
    </Box>
  );
}
