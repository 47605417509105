import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { TbHome } from 'react-icons/tb';
import {
  AiOutlineFileAdd,
  AiOutlineFileDone,
  AiOutlineShoppingCart,
  AiOutlineWarning,
} from 'react-icons/ai';
import {
  MdOutlineAssessment,
  MdOutlineLightbulb,
  MdOutlineRule,
  MdOutlineNotificationAdd,
} from 'react-icons/md';
import { IoMdRefresh } from 'react-icons/io';
import { SlGraph } from 'react-icons/sl';

// import { ReactComponent as LogoSVG } from '../images/logo-full-light.svg';
import LogoSVG from '../images/logo-full-light.svg';
import { ReactComponent as SmallLogoSVG } from '../images/logo-small.svg';

import Button from '@mui/material/Button';
import ProfileDropMenu from './ProfileDropMenu';
import NotificationDrop from './NotificationDrop';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import SvgIcon from '@mui/material/SvgIcon';
import { VALID_SUBSCRIPTION } from '../Stripe';
import MarkingTokensDisplay from './MarkingTokensDisplay';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: '100%',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Sidebar2({
  selectedPage,
  setSelectedPage,
  authenticationLevel,
  subscription,
  setMarkingObject,
  refreshTokens,
}) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(
    window.innerWidth <= 1000 ? false : true
  );
  const [notificationCount, setNotificationCount] = useState(0);

  const handleDrawer = () => {
    if (window.innerWidth <= 1000) {
      return;
    }
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <AppBar
        position='fixed'
        open={open}
        color='primary'
        elevation={0}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={handleDrawer}
                sx={{
                  borderRadius: 3,
                  color: '#ffffff',
                  ml: '-12px',
                  mr: '15px',
                }}
              >
                <MenuIcon />
              </IconButton>
              <IconButton
                onClick={() => setSelectedPage('Dashboard')}
                sx={{ borderRadius: 10, height: '100%' }}
              >
                <img
                  src={LogoSVG}
                  alt='PassPapers Logo'
                  style={{ height: '40px' }}
                />
              </IconButton>

              {/* <Typography variant='h4' fontWeight={600}>
                {selectedPage}
              </Typography> */}
            </Box>
            <Stack sx={{}} direction='row'>
              <MarkingTokensDisplay
                refresh={refreshTokens}
                mobile={window.innerWidth <= 1000}
              />
              <NotificationDrop
                setSelectedPage={setSelectedPage}
                setMarkingObject={setMarkingObject}
                notificationCount={notificationCount}
                setNotificationCount={setNotificationCount}
              />
              <ProfileDropMenu setSelectedPage={setSelectedPage} />
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <Toolbar />
        <List>
          <ListItem key='dashboard' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedPage === 'Dashboard'}
              onClick={() => setSelectedPage('Dashboard')}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                paddingLeft: 3,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <TbHome
                  style={{
                    color: '1F2F98',
                    width: '100%',
                    transform: 'scale(1.5)',
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <Typography variant='h6'>Dashboard</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>

          {authenticationLevel >= 2 ? (
            <ListItem key='create' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={selectedPage === 'Create'}
                onClick={() => setSelectedPage('Create')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  paddingLeft: 3,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <AiOutlineFileAdd
                    style={{
                      color: '1F2F98',
                      width: '100%',
                      transform: 'scale(1.5)',
                    }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <Typography variant='h6'>Create</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ) : null}
          {authenticationLevel >= 3 ? (
            <ListItem key='marking' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={selectedPage === 'Marking'}
                onClick={() => setSelectedPage('Marking')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  paddingLeft: 3,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <AiOutlineFileDone
                    style={{
                      color: '1F2F98',
                      width: '100%',
                      transform: 'scale(1.5)',
                    }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <Typography variant='h6'>Marking</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ) : null}
          {authenticationLevel >= 3 ? (
            <ListItem key='notify' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={selectedPage === 'Notify'}
                onClick={() => setSelectedPage('Notify')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  paddingLeft: 3,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <MdOutlineNotificationAdd
                    style={{
                      color: '1F2F98',
                      width: '100%',
                      transform: 'scale(1.5)',
                    }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <Typography variant='h6'>Notify</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ) : null}
          {authenticationLevel >= 3 ? (
            <ListItem key='reports' disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                selected={selectedPage === 'Reports'}
                onClick={() => setSelectedPage('Reports')}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  paddingLeft: 3,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <AiOutlineWarning
                    style={{
                      color: '1F2F98',
                      width: '100%',
                      transform: 'scale(1.5)',
                    }}
                  />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  <Typography variant='h6'>Reports</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ) : null}
          <ListItem key='grades' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedPage === 'Grades'}
              onClick={() => setSelectedPage('Grades')}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                paddingLeft: 3,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MdOutlineAssessment
                  style={{
                    color: '1F2F98',
                    width: '100%',
                    transform: 'scale(1.5)',
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <Typography variant='h6'>Grades</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem key='stripe' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedPage === 'Store'}
              onClick={() => setSelectedPage('Store')}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                paddingLeft: 3,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Badge color='secondary' variant='dot'>
                  <AiOutlineShoppingCart
                    style={{
                      color: '1F2F98',
                      width: '100%',
                      transform: 'scale(1.5)',
                    }}
                  />
                </Badge>
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <Typography variant='h6'>Store</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <Divider
            textAlign='left'
            sx={{ display: open ? 'flex' : 'none', my: open ? 0.5 : 0 }}
          >
            <Chip label='Preparation' />
          </Divider>
          <ListItem key='learn' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedPage === 'Learn'}
              onClick={() => setSelectedPage('Learn')}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                paddingLeft: 3,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MdOutlineLightbulb
                  style={{
                    color: '1F2F98',
                    width: '100%',
                    transform: 'scale(1.5)',
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <Typography variant='h6'>Learn</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem key='practice' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedPage === 'Practice'}
              onClick={() => setSelectedPage('Practice')}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                paddingLeft: 3,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MdOutlineRule
                  style={{
                    color: '1F2F98',
                    width: '100%',
                    transform: 'scale(1.5)',
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <Typography variant='h6'>Practice</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem key='mocks' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedPage === 'Mocks'}
              onClick={() => setSelectedPage('Mocks')}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                paddingLeft: 3,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <SlGraph
                  style={{
                    color: '1F2F98',
                    width: '100%',
                    transform: 'scale(1.5)',
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <Typography variant='h6'>Mocks</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem key='revision' disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              selected={selectedPage === 'Revision'}
              onClick={() => setSelectedPage('Revision')}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                paddingLeft: 3,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <IoMdRefresh
                  style={{
                    color: '1F2F98',
                    width: '100%',
                    transform: 'scale(1.5)',
                  }}
                />
              </ListItemIcon>
              <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                <Typography variant='h6'>Revision</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
}
