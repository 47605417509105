import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../auth';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import QuizList from './QuizList';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import '../style/Practice.css';
import MockResults from './MockResults';
import Quiz from './Quiz';
import ResultsList from './ResultsList';
import QuizResults from './QuizResults';
import WritingResults from './WritingResults';
import { DEMO_SUBSCRIPTION, EXPIRED_SUBSCRIPTION } from '../Stripe';
import MobileMockList from '../components/Mobile/Mock/MobileMockList';
import NoMobile from './NoMobile';
import { getBreakPointStyle } from '../components/Mobile/StyleHelper';

function MockList({ subscription }) {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();
  const [selectedSubject, setSelectedSubject] = useState(window.innerWidth <= 1000 ? "" : 'full_mocks');
  const [selectedQuiz, setSelectedQuiz] = useState('');
  const [selectedPage, setSelectedPage] = useState('quiz_list');
  const [selectedAttemptId, setSelectedAttemptId] = useState('');

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate('/');
  }, [user, loading]);

  function SubjectMenu() {
    const subjectList = [
      'full_mocks',
      'reading',
      'maths',
      'thinking',
      'writing',
    ];
    const labelList = [
      'Full Mocks',
      'Reading Mocks',
      'Maths Mocks',
      'Thinking Mocks',
      'Writing Mocks',
    ];
    return (
      <Box
        sx={{ width: '100%', maxWidth: 260, borderRadius: 3, height: '25rem' }}
        className='topic_tab'
      >
        <List component='nav'>
          {subjectList.map((subject, i) => {
            return (
              <ListItemButton
                key={i}
                selected={selectedSubject === subject}
                onClick={() => {
                  setSelectedSubject(subject);
                }}
                sx={{ pl: 4 }}
              >
                <ListItemText primary={labelList[i]} />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    );
  }
  const MobileSelector = () => {
    switch (selectedPage) {
      case "quiz_list":
        return (
          <MobileMockList selectedSubject={selectedSubject} setSelectedSubject={setSelectedSubject} />
        )
      default:
        return (
          <NoMobile />
        )
    }
  }

  const Selector = () => {
    switch (selectedPage) {
      case 'quiz_list':
        return (
          <div>
            {subscription === DEMO_SUBSCRIPTION ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Collapse in={true} timeout={{ enter: 500, exit: 500 }}>
                  <Alert
                    severity='info'
                    variant='outlined'
                    sx={{ mb: 2, width: '40vw', borderRadius: 4, backgroundColor: "#dff4fe" }}
                  >
                    <AlertTitle>
                      <strong>Demo Account</strong>
                    </AlertTitle>
                    This is a demo account, which has limited access to our mock
                    exams — <strong>visit our store to upgrade!</strong>
                  </Alert>
                </Collapse>
              </Box>
            ) : subscription === EXPIRED_SUBSCRIPTION ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Collapse in={true} timeout={{ enter: 500, exit: 500 }}>
                  <Alert
                    severity='info'
                    variant='outlined'
                    sx={{ mb: 2, width: '40vw', borderRadius: 4, backgroundColor: "#dff4fe" }}
                  >
                    <AlertTitle>
                      <strong>Your Access has expired</strong>
                    </AlertTitle>
                    You will not be able to attempt new mocks and will have
                    limited access to completed attempts —{' '}
                    <strong>visit our store to upgrade!</strong>
                  </Alert>
                </Collapse>
              </Box>
            ) : null}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingTop: 2,
              }}
            >
              <Stack direction='row' spacing={4} sx={{ py: '1rem' }}>
                <Box
                  sx={{
                    width: '60vw',
                    minHeight: '60vh',
                    height: '62vh',
                    display: 'flex',
                    overflow: 'hidden',
                    backgroundColor: '#f7f9fc',
                    borderRadius: 6,
                    px: 1,
                  }}
                >
                  <QuizList
                    subject={selectedSubject}
                    topic={'MOCK'}
                    ignoreMocks={false}
                    setSelectedPage={setSelectedPage}
                    setSelectedQuiz={setSelectedQuiz}
                    subscription={subscription}
                  />
                </Box>
                <SubjectMenu />
              </Stack>
            </Box>
          </div>
        );
      case 'quiz':
        return (
          <Quiz
            subject={selectedSubject}
            quiz={selectedQuiz}
            setSelectedPage={setSelectedPage}
          />
        );
      case 'results_list':
        return (
          <ResultsList
            subject={selectedSubject}
            quiz={selectedQuiz}
            setSelectedPage={setSelectedPage}
            setSelectedAttemptId={setSelectedAttemptId}
            allowReattempt={false}
            subscription={subscription}
          />
        );
      case 'results':
        if (selectedSubject === 'writing') {
          return (
            <WritingResults
              markingObject={{
                attempt_id: selectedAttemptId,
                quiz_id: selectedQuiz,
              }}
            />
          );
        } else {
          return (
            <QuizResults
              subject={selectedSubject}
              quiz={selectedQuiz}
              attemptId={selectedAttemptId}
              setSelectedPage={setSelectedPage}
              subscription={subscription}
              page={'quiz_list'}
            />
          );
        }
      case 'writing_results':
        return <WritingResults />;
      case 'mock_results':
        return (
          <MockResults
            mock={selectedQuiz}
            setSelectedPage={setSelectedPage}
            subscription={subscription}
          />
        );
    }
  };

  return (
    <Box sx={{ flexGrow: 1, width: '100%', m: getBreakPointStyle(1, 3) }}>
      {window.innerWidth <= 1000 ? <MobileSelector /> : <Selector />}
    </Box>
  );
}

export default MockList;
